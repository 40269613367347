.news-card {
	display: flex;
	overflow: hidden;
	background: var(--color-gray-3);

	@include mediaTablet {
		flex-direction: column;
	}

	&__link {
		display: inline-flex;
		font-size: 0;
	}

	&__fig {
		display: block;
		margin: 0;
		padding: 0;
		overflow: hidden;

		width: rem(395);

		@include mediaBigDesktop {
			width: big(395);
		}

		@include mediaDesktop {
			width: rem(290);
		}

		@include mediaLaptop {
			width: rem(220);
		}

		@include mediaTablet {
			width: 100%;
			height: rem(240);
		}
	}

	&__pic {
		display: block;
		width: 100%;
		height: 100%;
		font-size: 0;
	}

	&__img {
		width: 100%;
		height: 100%;
		object-fit: cover;

		transition: transform var(--anim-direction);
	}

	&__content {
		position: relative;
		flex: 1;
		padding: rem(100);

		@include mediaBigDesktop {
			padding: big(100);
		}

		@include mediaDesktop {
			padding: rem(68);
		}

		@include mediaLaptop {
			padding: rem(48);
		}

		@include mediaTablet {
			padding: rem(24);
		}

		@include mediaMobile {
			padding: rem(20);
		}
	}

	&__tags {
		position: relative;
		z-index: 2;
		display: inline-flex;
		flex-wrap: wrap;
		gap: rem(5);
		margin-bottom: rem(24);

		@include mediaBigDesktop {
			gap: big(5);
			margin-bottom: big(24);
		}

		@include mediaDesktop {
			margin-bottom: rem(20);
		}

		@include mediaLaptop {
			margin-bottom: rem(16);
		}

		@include mediaTablet {
			margin-bottom: rem(12);
		}
	}

	&__title {
		display: block;
		font: var(--font-h5);
		color: var(--color-dark);
		text-decoration: none;

		transition: color var(--anim-direction);

		&:after {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			z-index: 1;
			display: block;
			width: 100%;
			height: 100%;
		}
	}

	&__date {
		display: block;
		font: var(--font-body-M-caption);
		text-transform: uppercase;
		color: var(--color-text-dark-secondary);
		margin-top: rem(24);

		@include mediaBigDesktop {
			margin-top: big(24);
		}

		@include mediaDesktop {
			margin-top: rem(20);
		}

		@include mediaLaptop {
			margin-top: rem(16);
		}

		@include mediaTablet {
			margin-top: rem(12);
		}
	}

	&:hover {
		.news-card {
			&__img {
				transform: scale(1.1);
			}

			&__title {
				color: var(--color-secondary);
			}
		}
	}
}