.sec-types {
	height: 100vh;
	overflow: hidden;

	@include mediaTablet {
		height: auto;
	}

	&__row {
		display: flex;
		gap: var(--section-offset);

		@include mediaTablet {
			gap: rem(24);
		}

		@include mediaMobile {
			flex-direction: column;
			gap: 0;
		}
	}

	&__col {
		flex: 1;

		.block-accordion-icon {
			&:not(:last-child) {
				border-bottom: 1px solid var(--stroke-dark-secondary);
			}
		}

		@include mediaMobile {
			&_right {
				.block-accordion-icon {
					&:not(:first-child) {
						border-bottom: 0;
						border-top: 1px solid var(--stroke-dark-secondary);
					}

					&:first-child {
						border-bottom: 0;
					}
				}
			}
		}

		&_left {
			@include mediaMobile {
				.block-accordion-icon {
					&:first-child {
						.block-accordion-icon__header {
							padding-top: 0;
						}
					}
				}
			}
		}

		&_image {
			text-align: center;
			margin-bottom: rem(-100);

			@include mediaBigDesktop {
				margin-bottom: big(-100);
			}

			@include mediaDesktop {
				margin-bottom: rem(-68);
			}

			@include mediaLaptop {
				margin-bottom: rem(-48);
			}

			@include mediaTablet {
				display: none;
			}

			figure {
				font-size: 0;
				margin: 0;
			}

			picture {
				display: inline-block;
				width: 100%;
			}

			img {
				max-width: rem(306);
				width: 100%;
				height: auto;

				@include mediaBigDesktop {
					max-width: big(306);
				}

				@include mediaDesktop {
					max-width: rem(228);
				}

				@include mediaLaptop {
					max-width: rem(173);
				}
			}
		}

		&_right {
			.block-accordion-icon {
				&__header {
					flex-direction: row-reverse;
				}

				&__body {
					text-align: right;
				}
			}

			@include mediaTablet {
				.block-accordion-icon {
					&__header {
						flex-direction: row;
					}

					&__body {
						text-align: left;
					}
				}
			}

			@include mediaMobile {
				border-top: 1px solid var(--stroke-dark-secondary);
			}
		}

		.file-button {
			margin-top: rem(40);
			margin-left: auto;

			@include mediaBigDesktop {
				margin-top: big(40);
			}

			@include mediaDesktop {
				margin-top: rem(32);
			}

			@include mediaTablet {
				margin-top: rem(28);
			}

			@include mediaMobile {
				margin-top: 0;
				margin-left: 0;
			}
		}
	}
}

@supports(height: 100dvh) {
	.sec-types {
		height: 100dvh;

		@include mediaTablet {
			height: auto;
		}
	}
}

.block-accordion-icon {
	label {
		display: block;
		position: relative;
	}

	&__header {
		display: flex;
		align-items: center;
		gap: rem(32);
		padding: rem(40) 0;
		width: 100%;
		cursor: pointer;

		@include mediaBigDesktop {
			gap: big(32);
			padding: big(40) 0;
		}

		@include mediaDesktop {
			padding: rem(32) 0;
		}

		@include mediaLaptop {
			gap: rem(24);
			padding: rem(24) 0;
		}

		@include mediaTablet {
			gap: rem(16);
			padding: rem(24) 0 rem(16) 0;
		}

		@include mediaMobile {
			padding-top: rem(20);
		}
	}

	&__arrow {
		width: rem(24);
		height: rem(24);
		fill: var(--color-text-dark-secondary);

		transition: transform var(--anim-direction);

		@include mediaBigDesktop {
			width: big(24);
			height: big(24);
		}

		@include mediaTablet {
			display: none;
		}
	}

	&__icon {
		width: rem(48);
		margin: 0;
		font-size: 0;

		@include mediaBigDesktop {
			width: big(48);
		}

		@include mediaLaptop {
			width: rem(36);
		}

		@include mediaTablet {
			width: rem(32);
		}

		picture {
			display: inline-block;
			width: 100%;
		}

		img {
			width: 100%;
			max-width: 100%;
			height: auto;
		}
	}

	&__title {
		font: var(--font-h5);
		letter-spacing: var(--letter-spacing-h5);

		transition: color var(--anim-direction);
	}

	&__body {
		visibility: hidden;
		opacity: 0;
		max-height: 0;
		padding-bottom: 0;
		color: var(--color-text-dark-secondary);

		transition: visibility var(--anim-direction),
		opacity var(--anim-direction),
		max-height var(--anim-direction),
		padding-bottom var(--anim-direction);

		@include mediaTablet {
			visibility: visible;
			opacity: 1;
			max-height: none;
			padding-bottom: rem(24);
		}

		p {
			&:first-child {
				margin-top: 0;
			}

			&:last-child {
				margin-bottom: 0;
			}
		}

		&.js_active {
			visibility: visible;
			opacity: 1;
			max-height: rem(999);
			padding-bottom: rem(40);

			@include mediaBigDesktop {
				max-height: big(999);
				padding-bottom: big(40);
			}

			@include mediaDesktop {
				padding-bottom: rem(32);
			}

			@include mediaLaptop {
				padding-bottom: rem(24);
			}
		}
	}
}

.visually-hidden {
	&:checked {
		+ .block-accordion-icon {
			&__header {
				padding-bottom: rem(24);

				@include mediaBigDesktop {
					padding-bottom: big(24);
				}

				@include mediaDesktop {
					padding-bottom: rem(20);
				}

				@include mediaLaptop {
					padding-bottom: rem(16);
				}

				.block-accordion-icon {
					&__arrow {
						transform: rotate(180deg);
					}

					&__title {
						color: var(--color-secondary);

						@include mediaTablet {
							color: var(--color-text-dark);
						}
					}
				}
			}
		}
	}
}