.sec-reviews {
	background-color: var(--color-gray-3);

	&__slider {
		@include mediaTablet {
			margin-right: rem(-40);
		}

		@include mediaMobile {
			margin-right: rem(-16);
		}
	}
}
