.tag {
	display: inline-block;

	padding: 0 rem(16);
	margin: 0;
	width: fit-content;

	font: var(--font-body-M-caption);
	letter-spacing: var(--text-spacing);
	line-height: rem(40);
	text-decoration: none;
	text-transform: uppercase;

	color: var(--color-secondary);

	background: var(--color-gray-3);
	border: none;
	border-radius: rem(40);

	cursor: pointer;
	user-select: none;
	overflow: hidden;

	transition: color var(--anim-direction), background-color var(--anim-direction);

	@include mediaBigDesktop {
		padding: 0 big(16);
		line-height: big(40);
		border-radius: big(40);
	}

	@include mediaDesktop {
		padding: 0 rem(12);
		line-height: rem(28);
		border-radius: rem(28);
	}

	@include mediaLaptop {
		line-height: rem(24);
		border-radius: rem(24);
	}

	&:hover {
		background: var(--color-secondary);
		color: var(--color-white);
	}

	&:disabled,
	&.disabled {
		cursor: default;
		background: var(--color-gray-3);
		color: var(--color-text-dark-disable);

		pointer-events: none;
	}

	&_light {
		background: var(--color-white);
	}
}
