.sec-slider-utp {
	position: relative;
	padding-top: 0;

	.sec {
		&__title {
			max-width: 45%;

			@include mediaMobile {
				max-width: 100%;
			}
		}
	}

	&__wrap {
		position: relative;

		.container {
			padding-top: var(--section-offset);
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			z-index: 2;

			@include mediaMobile {
				padding-top: rem(40);
			}
		}
	}

	&__bg {
		width: 100%;
		z-index: 1;
		font-size: 0;

		picture {
			display: block;
			width: 100%;
		}

		img {
			max-width: 100%;
			width: 100%;
			height: auto;

			@include mediaTablet {
				height: rem(400);
				object-fit: cover;
			}
		}
	}

	&__slider {
		margin-top: rem(-100);

		@include mediaBigDesktop {
			margin-top: big(-100);
		}

		@include mediaDesktop {
			margin-top: rem(-115);
		}

		@include mediaLaptop {
			margin-top: rem(-100);
		}

		@include mediaTablet {
			margin-top: rem(-150);
			margin-right: rem(-40);
		}

		@include mediaMobile {
			margin-top: rem(-80);
			margin-right: rem(-16);
		}

		.swiper-slide {
			height: auto;
		}

		.utp-card {
			height: 100%;
		}

		&-btns {
			display: flex;
			align-items: center;
			justify-content: flex-end;
			margin-top: var(--section-offset);

			@include mediaTablet {
				display: none;
			}

			.icon-button {
				margin-left: rem(12);

				@include mediaBigDesktop {
					margin-left: big(12);
				}

				@include mediaLaptop {
					margin-left: rem(8);
				}
			}
		}
	}
}

.utp-card {
	overflow: hidden;
	position: relative;
	background-color: var(--color-gray-3);
	color: var(--color-dark);
	padding: rem(40);
	padding-bottom: rem(400);

	@include mediaBigDesktop {
		padding: big(40);
		padding-bottom: big(400);
	}

	@include mediaDesktop {
		padding: rem(32);
		padding-bottom: rem(310);
	}

	@include mediaLaptop {
		padding: rem(24);
		padding-bottom: rem(237);
	}

	@include mediaMobile {
		padding: rem(20);
		padding-bottom: rem(180);
	}

	@include media(#{rem(480)}) {
		padding-bottom: rem(110);
	}

	&__count {
		display: block;
		font: var(--font-body-M-caption);
		color: var(--color-secondary);
		margin-bottom: rem(25);

		@include mediaBigDesktop {
			margin-bottom: big(25);
		}

		@include mediaDesktop {
			margin-bottom: rem(16);
		}

		@include mediaLaptop {
			margin-bottom: rem(12);
		}

		@include mediaTablet {
			margin-bottom: rem(8);
		}
	}

	&__title {
		position: relative;
		z-index: 2;
		font: var(--font-h5);
		letter-spacing: var(--letter-spacing-h5);
	}

	&__fig {
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		z-index: 1;
		font-size: 0;
		text-align: center;

		picture {
			display: block;
			width: 100%;
		}

		img {
			max-width: 100%;
			height: auto;
		}
	}
}