.button-tab {
	position: relative;
	display: inline-table;

	padding: 0 rem(40);
	margin: 0;

	width: fit-content;

	font: var(--font-body-M);
	letter-spacing: var(--text-spacing);
	line-height: rem(58);
	color: var(--color-dark);
	font-style: normal;
	text-decoration: none;

	background: transparent;
	border: 1px solid var(--stroke-dark-secondary);
	border-radius: rem(60);

	cursor: pointer;
	user-select: none;
	overflow: hidden;

	transition: color var(--anim-direction), border-color var(--anim-direction), background-color var(--anim-direction);

	@include mediaBigDesktop {
		padding: 0 big(40);
		line-height: big(58);
		border-radius: big(60);
	}

	@include mediaDesktop {
		padding: 0 rem(32);
		line-height: rem(52);
	}

	@include mediaLaptop {
		padding: 0 rem(24);
		line-height: rem(42);
	}
	
	//@include mediaMobile {
	//	padding: 0 rem(16);
	//	line-height: rem(30);
	//}

	&__text {
		position: relative;
		z-index: 2;
	}

	.button-hover-effects {
		position: absolute;
		top: 0;
		right: -50px;
		display: block;
		width: 0;
		height: 0;
		background-color: var(--color-dark);
		transition: width var(--anim-direction);
		z-index: 1;

		&::before {
			content: "";
			padding-top: 100%;
			width: 100%;
			float: left;
			border-radius: 50%;
			transform: translate(-50%, -50%);
			background-color: inherit;
			transition: width var(--anim-direction), transform var(--anim-direction);
		}
	}

	&:hover {
		color: var(--color-white);
		border-color: var(--color-dark);

		.button-hover-effects {
			width: 250%;
		}
	}

	&_active {
		background: var(--color-dark);
		border-color: var(--color-dark);
		color: var(--color-white);
	}

	&_dark {
		border-color: var(--color-text-light-secondary);
		color: var(--color-white);

		.button-hover-effects {
			background: var(--color-white);
		}

		&:hover {
			border-color: var(--color-white);
			color: var(--color-dark);
		}

		&.button-tab_active {
			border-color: var(--color-white);
			background: var(--color-white);
			color: var(--color-dark);
		}
	}
}

.button-tab-line {
	position: relative;
	display: inline-flex;
	align-items: center;

	padding: 0;
	padding-bottom: rem(20);
	margin: 0;

	width: fit-content;

	font: var(--font-body-M);
	letter-spacing: var(--text-spacing);
	color: var(--color-dark);
	font-style: normal;
	text-decoration: none;

	background: transparent;
	border: 0;
	border-radius: 0;

	cursor: pointer;
	user-select: none;
	overflow: hidden;

	transition: color var(--anim-direction);

	@include mediaBigDesktop {
		padding-bottom: big(20);
	}

	@include mediaLaptop {
		padding-bottom: rem(16);
	}

	@include mediaMobile {
		padding-bottom: rem(8);
	}

	&:after {
		content: "";
		position: absolute;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 2;

		display: block;
		width: 0;
		height: rem(3);
		margin: 0 auto;

		background: var(--color-secondary);

		transition: width var(--anim-direction);

		@include mediaBigDesktop {
			height: big(3);
		}
	}

	&_active {
		.button-tab-line__count {
			background-color: var(--color-secondary);
		}

		&:after {
			width: 100%;
		}
	}

	&:hover {
		&:after {
			width: 100%;
		}
	}

	&__count {
		display: inline-flex;
		align-items: center;
		justify-content: center;
		line-height: rem(44);
		height: rem(44);
		width: rem(44);
		min-width: rem(44);
		padding: 0;
		background-color: var(--color-dark);
		color: var(--color-white);
		border-radius: 50%;
		margin-right: rem(15);
		font: var(--font-body-M-caption);

		transition: background-color var(--anim-direction), color var(--anim-direction);

		@include mediaBigDesktop {
			line-height: big(44);
			height: big(44);
			width: big(44);
			min-width: big(44);
			margin-right: big(15);
		}

		@include mediaDesktop {
			line-height: rem(36);
			height: rem(36);
			width: rem(36);
			min-width: rem(36);
		}

		@include mediaLaptop {
			line-height: rem(32);
			height: rem(32);
			width: rem(32);
			min-width: rem(32);
			margin-right: rem(12);
		}

		@include mediaTablet {
			line-height: rem(24);
			height: rem(24);
			width: rem(24);
			min-width: rem(24);
			margin-right: rem(8);
		}
	}

	&_light {
		color: var(--color-white);

		.button-tab-line {
			&__count {
				background-color: var(--color-white);
				color: var(--color-text-dark);
			}
		}
	}
}

.visually-hidden {
	&:checked {
		+ .button-tab {
			background: var(--color-dark);
			border-color: var(--color-dark);
			color: var(--color-white);

			&_dark {
				border-color: var(--color-white);
				background: var(--color-white);
				color: var(--color-dark);
			}
		}

		+ .button-tab-line {
			&:after {
				width: 100%;
			}
		}
	}

	&:disabled {
		+ .button-tab {
			cursor: default;
			background: transparent;
			border-color: var(--stroke-dark-secondary);
			color: var(--color-text-light-disable);

			.button-hover-effects {
				display: none;
			}

			&_dark {
				background: transparent;
				border-color: var(--stroke-light-secondary);
				color: var(--color-text-light-disable);
			}
		}
	}
}