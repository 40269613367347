.sec-logos {
	&__list {
		margin-top: var(--section-offset);
		display: flex;
		flex-wrap: wrap;

		border-left: 1px solid var(--stroke-dark-secondary);
		border-top: 1px solid var(--stroke-dark-secondary);

		.block-logo {
			width: 10%;

			@include mediaTablet {
				width: 16.666%;
			}

			@include mediaMobile {
				width: 25%;

				//&:nth-last-child(-n+2) {
				//	display: none;
				//}
			}
		}
	}
}

.block-logo {
	display: flex;
	align-items: center;
	justify-content: center;

	border-bottom: 1px solid var(--stroke-dark-secondary);
	border-right: 1px solid var(--stroke-dark-secondary);
	font-size: 0;
	padding: rem(25);

	@include mediaBigDesktop {
		padding: big(25);
	}

	@include mediaMobile {
		padding: rem(15);
	}

	picture {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
	}

	&__img {
		width: 100%;
		height: auto;

		object-fit: cover;
	}
}