.review-card {
	position: relative;
	display: flex;
	overflow: hidden;

	padding: rem(40);
	background: var(--color-white);

	@include mediaBigDesktop {
		padding: big(40);
	}

	@include mediaDesktop {
		padding: rem(32);
	}

	@include mediaLaptop {
		padding: rem(24);
	}

	@include mediaMobile {
		flex-direction: column;
		padding: rem(20);
	}

	&__content {
		flex: 1;
	}

	&__header {
		display: flex;
		align-items: center;

		padding-bottom: rem(32);
		border-bottom: 1px solid var(--stroke-dark-secondary);

		@include mediaBigDesktop {
			padding-bottom: big(32);
		}

		@include mediaDesktop {
			padding-bottom: rem(24);
		}

		@include mediaMobile {
			padding-bottom: rem(12);
		}
	}

	&__avatar {
		display: block;
		width: 100%;
		max-width: rem(60);
		margin: 0;
		padding: 0;
		margin-right: rem(16);

		@include mediaBigDesktop {
			max-width: big(60);
			margin-right: big(16);
		}

		@include mediaDesktop {
			max-width: rem(44);
			margin-right: rem(16);
		}

		@include mediaMobile {
			margin-right: rem(12);
		}

		&-pic {
			display: block;
			width: 100%;
			font-size: 0;
		}

		&-img {
			width: 100%;
			max-width: 100%;
			height: auto;
		}
	}

	&__title {
		font: var(--font-body-M-semibold);
	}

	&__subtitle {
		font: var(--font-body-M-caption);
		color: var(--color-text-dark-secondary);
		text-transform: uppercase;
		margin-top: rem(4);

		@include mediaBigDesktop {
			margin-top: big(4);
		}

		@include mediaMobile {
			margin-top: 0;
		}
	}

	&__text {
		overflow: hidden;
		text-overflow: ellipsis;
		-webkit-box-orient: vertical;
		display: -webkit-box;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 6;
		padding-top: rem(24);

		@include mediaBigDesktop {
			padding-top: big(24);
		}

		@include mediaLaptop {
			padding-top: rem(16);
		}

		@include mediaMobile {
			padding-top: rem(12);
		}

		p {
			margin-top: 0;

			&:last-child {
				margin-bottom: 0;
			}
		}
	}

	&__btn {
		margin-top: rem(16);

		@include mediaBigDesktop {
			margin-top: big(16);
		}

		@include mediaLaptop {
			margin-top: rem(12);
		}
	}

	&__media {
		position: relative;
		margin-left: rem(100);
		width: rem(265);

		@include mediaBigDesktop {
			margin-left: big(100);
			width: big(265);
		}

		@include mediaDesktop {
			margin-left: rem(68);
			width: rem(219);
		}

		@include mediaLaptop {
			margin-left: rem(48);
			width: rem(198);
		}

		@include mediaTablet {
			margin-left: rem(40);
			width: rem(176);
		}

		@include mediaMobile {
			margin-left: 0;
			margin-top: rem(20);
			width: rem(80);
		}

		&-fig {
			margin: 0;
			padding: 0;
		}

		&-pic {
			display: block;
			width: 100%;
			font-size: 0;
		}

		&-img {
			width: 100%;
			max-width: 100%;
			height: auto;

			border: 1px solid var(--stroke-dark-secondary);
		}

		.icon-text-button,
		.icon-button:not(.icon-text-button__circle) {
			position: absolute;
			left: 50%;
			top: 50%;
			z-index: 2;
			transform: translate(-50%, -50%);
		}

		.icon-button:not(.icon-text-button__circle) {
			opacity: 0;

			transition: opacity var(--anim-direction);
		}

		&:hover {
			.icon-button {
				opacity: 1;
			}
		}
	}
}