.sec-company {
	position: relative;
	z-index: 2;
	background-color: var(--color-dark);
	color: var(--color-white);
	padding: 0;
	overflow: hidden;

	&__container {
		position: absolute;
		left: 0;
		top: 0;
		z-index: 2;
		width: 100%;
		padding-top: var(--section-offset);

		@include mediaMobile {
			padding-top: rem(40);
		}
	}

	&__image {
		margin: 0;
		font-size: 0;
		display: block;
		width: 100%;

		picture {
			display: block;
			width: 100%;
		}

		img {
			width: 100%;
			height: auto;
			max-width: 100%;
			object-fit: cover;

			@include mediaTablet {
				height: rem(300);
			}
		}
	}

	&__numbers {
		padding-top: var(--section-offset);
		padding-bottom: var(--section-offset);
		display: grid;
		grid-template-columns: 30.7% 1fr 1fr 1fr 1fr;
		grid-template-rows: repeat(3, 1fr);
		grid-template-areas:
			"quote . one . two"
			". tree . four ."
			"five . six . btn";

		@include mediaTablet {
			display: flex;
			flex-wrap: wrap;
		}

		@include mediaMobile {
			padding-top: rem(40);
			padding-bottom: rem(40);
		}

		&-col {
			grid-area: quote;
			position: relative;

			@include mediaTablet {
				width: 100%;
				margin-bottom: rem(40);
			}

			@include mediaMobile {
				margin-bottom: rem(28);
			}

			.block-company-quote {
				position: absolute;
				left: 0;
				bottom: 0;
				z-index: 2;
				width: 100%;

				@include mediaTablet {
					position: static;
					margin-top: rem(-80);
				}
			}
		}

		.block-company-number {
			height: rem(345);
			margin-left: -1px;
			margin-top: -1px;

			@include mediaBigDesktop {
				height: big(345);
			}

			@include mediaDesktop {
				height: rem(256);
			}

			@include mediaLaptop {
				height: rem(236);
			}

			@include mediaTablet {
				height: rem(230);
				width: 33.333%;
			}

			@include mediaMobile {
				height: auto;
				width: 100%;
				margin-left: 0;
				min-height: rem(146);
			}

			&.item1 {
				grid-area: one;
			}

			&.item2 {
				grid-area: two;
			}

			&.item3 {
				grid-area: tree;
			}

			&.item4 {
				grid-area: four;
			}

			&.item5 {
				grid-area: five;
			}

			&.item6 {
				grid-area: six;
			}
		}

		&-btn {
			grid-area: btn;

			@include mediaTablet {
				margin-top: rem(40);
			}

			@include mediaMobile {
				margin-top: rem(28);
			}

			.button {
				width: 100%;
				height: 100%;

				&__text {
					margin-bottom: auto;
				}
			}
		}
	}
}

.block-company-number {
	display: flex;
	flex-direction: column;
	border: 1px solid var(--stroke-light-secondary);
	padding: rem(40);

	@include mediaBigDesktop {
		padding: big(40);
	}

	@include mediaDesktop {
		padding: rem(32);
	}

	@include mediaLaptop {
		padding: rem(24);
	}

	@include mediaMobile {
		padding: rem(20);
	}

	&__count {
		font: var(--font-h2);
		margin-bottom: rem(20);

		@include mediaBigDesktop {
			margin-bottom: big(20);
		}

		@include mediaMobile {
			margin-bottom: rem(16);
		}
	}

	&__text {
		margin-top: auto;
	}
}

.block-company-quote {
	padding: var(--section-offset);
	margin: 0;
	display: flex;
	flex-direction: column;
	background-color: var(--color-secondary);
	color: var(--color-white);
	font: var(--font-h4);
	letter-spacing: var(--letter-spacing-h4);

	@include mediaMobile {
		padding: rem(40);
	}

	&__icon {
		width: rem(32);
		height: rem(32);
		fill: rgba(255, 255, 255, 0.30);
		margin-bottom: rem(16);

		@include mediaBigDesktop {
			width: big(32);
			height: big(32);
			margin-bottom: big(16);
		}

		@include mediaDesktop {
			width: rem(24);
			height: rem(24);
		}

		@include mediaLaptop {
			margin-bottom: rem(12);
		}
	}

	&__text {
		font: var(--font-h4);
		letter-spacing: var(--letter-spacing-h4);

		p {
			&:first-child {
				margin-top: 0;
			}

			&:last-child {
				margin-bottom: 0;
			}
		}
	}
}