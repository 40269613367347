.dop-services-card {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	padding: rem(32);
	border: 1px solid var(--stroke-dark-secondary);

	@include mediaBigDesktop {
		padding: big(32);
	}

	@include mediaDesktop {
		padding: rem(26) rem(32);
	}

	@include mediaLaptop {
		padding: rem(24);
	}

	@include mediaMobile {
		padding: rem(20);
	}

	&__title {
		font: var(--font-body-M);
		min-height: rem(96);

		@include mediaBigDesktop {
			min-height: big(96);
		}

		@include mediaDesktop {
			min-height: rem(78);
		}

		@include mediaLaptop {
			min-height: rem(72);
		}

		@include mediaTablet {
			min-height: rem(60);
		}
	}

	&__footer {
		margin-top: rem(32);
		display: flex;
		align-items: flex-end;
		justify-content: space-between;

		@include mediaBigDesktop {
			margin-top: big(32);
		}

		@include mediaLaptop {
			margin-top: rem(24);
		}

		@include mediaMobile {
			margin-top: rem(20);
		}
	}

	&__price {
		font: var(--font-h5);
		letter-spacing: var(--letter-spacing-h5);
		margin-right: rem(20);

		@include mediaBigDesktop {
			margin-right: big(20);
		}
	}

	&__icon {
		margin: 0;
		font-size: 0;
		max-width: rem(48);

		@include mediaBigDesktop {
			max-width: big(48);
		}

		@include mediaLaptop {
			max-width: rem(36);
		}

		@include mediaTablet {
			max-width: rem(32);
		}

		picture {
			display: inline-block;
			width: 100%;
		}

		img {
			width: 100%;
			height: auto;
		}
	}
}
