.file-card {
	display: inline-block;
	font-size: 0;
	text-decoration: none;

	&__fig {
		display: block;
		margin: 0;
	}

	&__pic {
		display: block;
		width: 100%;
	}

	&__img {
		display: block;
		width: 100%;
		max-width: 100%;
		height: auto;
		border: 1px solid var(--stroke-dark-secondary);
	}

	&__title {
		display: block;
		font: var(--font-body-S);
		color: var(--color-text-dark-secondary);
		text-decoration: none;
		margin-top: rem(20);

		@include mediaBigDesktop {
			margin-top: big(20);
		}

		@include mediaDesktop {
			margin-top: rem(16);
		}

		@include mediaLaptop {
			margin-top: rem(12);
		}

		@include mediaMobile {
			margin-top: rem(8);
		}
	}

	&__wrap {
		position: relative;

		&:hover {
			.file-card__btns {
				opacity: 1;
			}
		}
	}

	&__btns {
		position: absolute;
		left: 50%;
		top: 50%;
		z-index: 2;
		transform: translate(-50%, -50%);
		display: flex;
		justify-content: center;
		align-items: center;
		opacity: 0;
		transition: opacity var(--anim-direction);

		@include mediaTablet {
			display: none;
		}

		.icon-button {
			&:not(:first-child) {
				margin-left: rem(12);

				@include mediaBigDesktop {
					margin-left: big(12);
				}

				@include mediaLaptop {
					margin-left: rem(8);
				}
			}
		}
	}
}