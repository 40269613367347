.sec-cta {
	position: relative;
	height: 100vh;
	min-height: rem(750);
	background-color: var(--color-gray-3);

	@include mediaBigDesktop {
		min-height: big(750);
	}

	@include mediaTablet {
		display: flex;
		align-items: flex-end;
		height: auto;
	}

	@include mediaMobile {
		min-height: rem(830);
	}

	.container {
		width: 100%;
	}

	&:before {
		content: "";
		display: block;
		width: 100%;
		height: 90%;
		position: absolute;
		left: 0;
		bottom: 0;
		background-image: url("/assets/templates/img/common/bg-cta_2.png");
		background-repeat: no-repeat;
		background-position: center top;
		background-size: cover;

		@include mediaTablet {
			height: 96%;
			background-image: url("/assets/templates/img/common/bg-cta-mobile_2.png");
		}
	}

	&__box {
		position: relative;
		z-index: 2;
		width: 100%;
		max-width: 50%;
		padding-left: rem(50);
		margin-left: auto;

		@include mediaBigDesktop {
			padding-left: big(50);
		}

		@include mediaDesktop {
			padding-left: rem(34);
		}

		@include mediaLaptop {
			padding-left: rem(24);
		}

		@include mediaTablet {
			padding-left: 0;
			max-width: 100%;
			margin-left: 0;
		}
	}
}

@supports(height: 100dvh) {
	.sec-cta {
		height: 100dvh;

		@include mediaTablet {
			height: auto;
		}
	}
}
