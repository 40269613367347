.sec-incoterms {
	position: relative;

	.sec__actions {
		@include mediaTablet {
			width: 100%;
		}

		@include mediaMobile {
			display: flex;
			flex-direction: column;
			gap: rem(8);
		}

		.labels-group {
			display: flex;
			flex-direction: column;
			gap: rem(10);
			width: 100%;

			@include mediaBigDesktop {
				gap: big(10);
			}

			@include mediaDesktop {
				gap: rem(14);
			}

			@include mediaLaptop {
				gap: rem(12);
			}

			@include mediaTablet {
				gap: rem(8);
			}
		}
	}

	&__fig {
		display: block;
		margin: 0;
		font-size: 0;
	}

	&__pic {
		display: block;
		width: 100%;
	}

	&__img {
		max-width: 100%;
		height: auto;
		width: 100%;
	}

	&_border-top {
		&:before {
			content: "";
			display: block;
			height: 1px;
			width: calc(100% - #{rem(100)});
			background-color: var(--stroke-dark-secondary);

			position: absolute;
			right: 0;
			top: 0;

			@include mediaBigDesktop {
				width: calc(100% - #{big(100)});
			}

			@include mediaLaptop {
				width: calc(100% - #{rem(72)});
			}

			@include mediaTablet {
				width: 100%;
			}
		}
	}
}

.scheme-label {
	display: flex;
	align-items: center;
	font: var(--font-body-M);
	gap: rem(16);

	@include mediaBigDesktop {
		gap: big(16);
	}

	&__icon {
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;
		min-width: rem(24);
		width: rem(24);
		height: rem(24);
		background-color: var(--color-gray);

		@include mediaBigDesktop {
			min-width: big(24);
			width: big(24);
			height: big(24);
		}

		@include mediaTablet {
			min-width: rem(20);
			width: rem(20);
			height: rem(20);
		}
	}

	&_primary {
		.scheme-label {
			&__icon {
				&:after {
					position: absolute;
					left: 50%;
					top: 50%;
					z-index: 2;
					transform: translate(-50%, -50%);
					content: "";
					display: block;
					width: rem(12);
					height: rem(12);
					background-color: var(--color-primary);

					@include mediaBigDesktop {
						width: big(12);
						height: big(12);
					}

					@include mediaTablet {
						width: rem(8);
						height: rem(8);
					}
				}
			}
		}
	}

	&_secondary {
		.scheme-label {
			&__icon {
				&:after {
					position: absolute;
					left: 50%;
					top: 50%;
					z-index: 2;
					transform: translate(-50%, -50%);
					content: "";
					display: block;
					width: rem(12);
					height: rem(12);
					background-color: var(--color-secondary);

					@include mediaBigDesktop {
						width: big(12);
						height: big(12);
					}

					@include mediaTablet {
						width: rem(8);
						height: rem(8);
					}
				}
			}
		}
	}

	&_dark {
		.scheme-label {
			&__icon {
				&:after {
					position: absolute;
					left: 50%;
					top: 50%;
					z-index: 2;
					transform: translate(-50%, -50%);
					content: "";
					display: block;
					width: rem(12);
					height: rem(12);
					background-color: var(--color-dark);

					@include mediaBigDesktop {
						width: big(12);
						height: big(12);
					}

					@include mediaTablet {
						width: rem(8);
						height: rem(8);
					}
				}
			}
		}
	}
}