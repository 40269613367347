.header {
	position: fixed;
	top: 0;
	z-index: 5;
	display: flex;
	align-items: center;
	justify-content: space-between;
	height: var(--height-menu);
	width: calc(100% - #{rem(100)});
	background: var(--color-white);
	align-self: flex-end;
	border-bottom: 1px solid var(--stroke-dark-secondary);
	border-left: 1px solid var(--stroke-dark-secondary);

	padding-left: rem(40);

	transition: top var(--anim-direction), background-color var(--anim-direction);

	@include mediaBigDesktop {
		width: calc(100% - #{big(100)});
		padding-left: big(40);
	}

	@include mediaDesktop {
		padding-left: rem(32);
	}

	@include mediaLaptop {
		width: calc(100% - #{rem(72)});
		padding-left: rem(24);
	}

	@include mediaTablet {
		padding-left: 0;
		border-left: 0;
		width: 100%;
		z-index: 15;
	}

	&__col {
		display: flex;
		align-items: center;
		height: 100%;

		&:first-child {
			@include mediaMobile {
				flex: 1;
			}
		}
	}

	&__btn-menu {
		display: none;
		margin-right: rem(24);
		//margin-bottom: -1px;

		@include mediaTablet {
			display: block;
		}

		@include mediaMobile {
			margin-right: rem(16);
		}
	}

	&__logo {
		font-size: 0;

		.logo {
			&__svg {
				height: rem(36);

				@include mediaBigDesktop {
					height: big(36);
				}

				@include mediaTablet {
					height: rem(26);
				}
			}
		}
	}

	&__lang {
		margin-left: rem(40);
		padding-left: rem(40);
		border-left: 1px solid var(--stroke-dark-secondary);

		@include mediaBigDesktop {
			margin-left: big(40);
			padding-left: big(40);
		}

		@include mediaDesktop {
			margin-left: rem(32);
			padding-left: rem(32);
		}

		@include mediaLaptop {
			margin-left: rem(24);
			padding-left: rem(24);
		}

		@include mediaMobile {
			margin-left: auto;
			margin-right: rem(16);
			padding-left: 0;
			border-left: 0;
		}

		ul {
			display: flex;
			align-items: center;
			flex-direction: row-reverse;
			list-style-type: none;
			padding: 0;
			margin: 0;

			@include mediaTablet {
				height: rem(32);
			}

			li {
				line-height: 1;

				&:not(:last-child) {
					margin-left: rem(20);

					@include mediaBigDesktop {
						margin-left: big(20);
					}

					@include mediaDesktop {
						margin-left: rem(16);
					}

					@include mediaLaptop {
						margin-left: rem(12);
					}
				}
			}

			a {
				font: var(--font-body-M-caption);
				color: var(--color-text-dark-secondary);
				text-transform: uppercase;
				text-decoration: none;
				line-height: 1.3;

				transition: color var(--anim-direction);

				&.active {
					color: var(--color-text-dark);
				}

				&:not(.active) {
					&:hover {
						color: var(--color-secondary);
					}
				}
			}
		}
	}

	&__nav {
		margin-left: rem(40);
		margin-right: rem(40);
		padding-left: rem(40);
		border-left: 1px solid var(--stroke-dark-secondary);

		@include mediaBigDesktop {
			margin-left: big(40);
			margin-right: big(40);
			padding-left: big(40);
		}

		@include mediaDesktop {
			margin-left: rem(32);
			margin-right: rem(32);
			padding-left: rem(32);
		}

		@include mediaLaptop {
			display: none;
		}
	}

	&__phone {
		font: var(--font-body-M-semibold);
		margin-right: rem(32);

		@include mediaBigDesktop {
			margin-right: big(32);
		}

		@include mediaLaptop {
			margin-right: rem(24);
		}

		@include mediaMobile {
			display: none;
		}

		a {
			color: var(--color-text-dark);
			text-decoration: none;
		}
	}

	&__social {
		display: flex;
		align-items: center;

		margin-right: rem(40);
		//padding-right: rem(40);
		//border-right: 1px solid var(--stroke-dark-secondary);

		@include mediaBigDesktop {
			margin-right: big(40);
			//padding-right: big(40);
		}

		@include mediaDesktop {
			margin-right: rem(32);
			//padding-right: rem(32);
		}

		@include mediaLaptop {
			margin-right: rem(24);
			//padding-right: rem(24);
		}

		@include mediaTablet {
			padding-right: 0;
			border-right: 0;
		}

		@include mediaMobile {
			display: none;
		}

		.social-icon {
			&:not(:last-child) {
				margin-right: rem(24);

				@include mediaBigDesktop {
					margin-right: big(24);
				}

				@include mediaDesktop {
					margin-right: rem(20);
				}
			}
		}
	}

	&__btns {
		display: flex;
		align-items: center;
		margin-bottom: -1px;
		height: calc(100% + 1px);

		position: relative;
		z-index: 2;

		@include mediaTablet {
			margin-bottom: 0;
		}

		.text-button {
			@include mediaTablet {
				display: flex;
				align-items: center;
				justify-content: center;
				width: rem(64);
				height: rem(64);
				font-size: 0;
				border-left: 1px solid var(--stroke-dark-secondary);
			}

			@include mediaMobile {
				width: rem(44);
				height: rem(44);
			}

			&__text {
				@include mediaTablet {
					display: none;
				}
			}

			&__icon {
				@include mediaTablet {
					width: rem(24);
					height: rem(24);
				}

				@include mediaMobile {
					width: rem(20);
					height: rem(20);
				}
			}
		}

		.button:not(.btn-phone) {
			height: 100%;
			margin-left: rem(40);

			@include mediaBigDesktop {
				margin-left: big(40);
			}

			@include mediaDesktop {
				margin-left: rem(32);
			}

			@include mediaLaptop {
				margin-left: rem(24);
			}

			@include mediaTablet {
				display: none;
			}
		}

		.btn-phone {
			display: none;
			align-items: center;
			justify-content: center;
			width: rem(64);
			height: rem(64);
			padding: 0;

			@include mediaTablet {
				display: inline-flex;
			}

			@include mediaMobile {
				width: rem(44);
				height: rem(44);
			}

			.button__icon {
				margin: 0;
				align-self: center;
				transform: none;

				@include mediaTablet {
					width: rem(24);
					height: rem(24);
				}

				@include mediaMobile {
					width: rem(20);
					height: rem(20);
				}
			}

			&:hover:not(:disabled):not(.disabled) {
				.button__icon {
					transform: none;
				}
			}
		}
	}

	&_opacity {
		position: absolute;
		right: 0;
		top: 0;
		z-index: 10;

		background: transparent;
		border-left: transparent;
		border-color: var(--stroke-light-secondary);

		.header {
			&__lang {
				border-color: var(--stroke-light-secondary);

				ul {
					a {
						color: var(--color-text-light-secondary);

						&.active {
							color: var(--color-white);
						}

						&:not(.active) {
							&:hover {
								color: var(--color-white);
							}
						}
					}
				}
			}

			&__nav {
				border-color: var(--stroke-light-secondary);
			}

			&__social {
				border-color: var(--stroke-light-secondary);
			}

			&__phone {
				a {
					color: var(--color-white);
				}
			}

			&__btns {
				.text-button {
					@include mediaTablet {
						border-color: var(--stroke-light-secondary);
					}
				}
			}
		}

		.text-button {
			color: var(--color-white);

			&__icon {
				fill: var(--color-white);
			}
		}

		.icon-button {
			&_stroke {
				background: transparent;
				border-color: var(--stroke-light-secondary);

				.icon-button__icon {
					fill: var(--color-white);
				}
			}
		}

		//.logo {
		//	&__svg {
		//		&-icon {
		//			fill: var(--color-white);
		//		}
		//
		//		&-name {
		//			fill: var(--color-white);
		//		}
		//	}
		//}

		.main-menu {
			&__link {
				color: var(--color-primary);

				&:hover {
					color: var(--color-primary);
				}
			}

			&__item {
				&_arrow {
					&:before,
					&:after {
						background: var(--color-primary);
					}

					&:hover {
						.main-menu__link {
							color: var(--color-primary);
						}

						&:before,
						&:after {
							background: var(--color-primary);
						}
					}
				}
			}
		}

		.social-icon {
			&__icon {
				fill: var(--color-white);
			}
		}

		.button_primary {
			.button-hover-effects {
				background: var(--color-white);
			}

			&:hover {
				color: var(--color-primary);

				svg {
					fill: var(--color-primary);

					@include mediaTablet {
						fill: var(--color-white);
					}
				}
			}
		}

		.header-btn-menu {
			border-color: var(--stroke-light-secondary);

			&:before,
			&:after {
				background: var(--color-white);
			}
		}
	}

	&_fixed {
		top: 0;
		position: fixed;
		width: 100%;
		padding-left: rem(140);
		background-color: var(--color-white);
		border-color: var(--stroke-dark-secondary);

		@include mediaBigDesktop {
			padding-left: big(140);
		}

		@include mediaDesktop {
			padding-left: rem(132);
		}

		@include mediaLaptop {
			width: 100%;
			padding-left: rem(96);
		}

		@include mediaTablet {
			padding-left: 0;
		}

		.header {
			&__lang {
				border-color: var(--stroke-dark-secondary);

				ul {
					a {
						color: var(--color-text-dark-secondary);

						&.active {
							color: var(--color-text-dark);
						}

						&:not(.active) {
							&:hover {
								color: var(--color-secondary);
							}
						}
					}
				}
			}

			&__nav {
				border-color: var(--stroke-dark-secondary);
			}

			&__social {
				border-color: var(--stroke-dark-secondary);
			}

			&__phone {
				a {
					color: var(--color-text-dark);
				}
			}

			&__btns {
				.text-button {
					border-color: var(--stroke-dark-secondary);
				}
			}
		}

		.text-button {
			color: var(--color-secondary);

			&__icon {
				fill: var(--color-secondary);
			}
		}

		.icon-button {
			background: transparent;
			border-color: var(--stroke-dark-secondary);

			&__icon {
				fill: var(--color-dark);
			}

			&_stroke .icon-button__icon {
				fill: var(--color-dark);
			}
		}

		.logo {
			&__svg {
				&-icon {
					fill: var(--color-primary);
				}

				&-name {
					fill: var(--color-dark);
				}
			}
		}

		.main-menu {
			&__link {
				color: var(--color-primary);

				&:hover {
					color: var(--color-primary);
				}
			}

			&__item {
				&_arrow {
					&:before,
					&:after {
						background: var(--color-primary);
					}

					&:hover {
						.main-menu__link {
							color: var(--color-primary);
						}

						&:before,
						&:after {
							background: var(--color-primary);
						}
					}
				}
			}
		}

		.social-icon {
			&__icon {
				fill: var(--color-text-dark);
			}
		}

		.button_primary {
			.button-hover-effects {
				background: var(--color-text-dark);
			}

			&:hover {
				color: var(--color-white);

				svg {
					fill: var(--color-white);
				}
			}
		}

		.header-btn-menu {
			border-color: var(--stroke-dark-secondary);

			&:before,
			&:after {
				background: var(--stroke-dark);
			}
		}
	}

	&_active {
		@include mediaTablet {
			z-index: 20;
			background-color: var(--color-white);
			border-color: var(--stroke-dark-secondary);

			.header {
				&__lang {
					border-color: var(--stroke-dark-secondary);

					ul {
						a {
							color: var(--color-text-dark-secondary);

							&.active {
								color: var(--color-text-dark);
							}
						}
					}
				}

				&__nav {
					border-color: var(--stroke-dark-secondary);
				}

				&__social {
					border-color: var(--stroke-dark-secondary);
				}

				&__phone {
					a {
						color: var(--color-text-dark);
					}
				}

				&__btns {
					.text-button {
						border-color: var(--stroke-dark-secondary);
					}
				}
			}

			.text-button {
				color: var(--color-secondary);

				&__icon {
					fill: var(--color-secondary);
				}
			}

			.icon-button {
				background: transparent;
				border-color: var(--stroke-dark-secondary);

				&__icon {
					fill: var(--color-dark);
				}

				&_stroke .icon-button__icon {
					fill: var(--color-dark);
				}
			}
			.logo {
				&__svg {
					&-icon {
						fill: var(--color-primary);
					}

					&-name {
						fill: var(--color-dark);
					}
				}
			}

			.main-menu {
				&__link {
					color: var(--color-text-dark);

					&:hover {
						color: var(--color-text-dark);
					}
				}

				&__item {
					&_arrow {
						&:before,
						&:after {
							background: var(--color-text-dark-secondary);
						}

						&:hover {
							.main-menu__link {
								color: var(--color-text-dark);
							}

							&:before,
							&:after {
								background: var(--color-text-dark);
							}
						}
					}
				}
			}

			.social-icon {
				&__icon {
					fill: var(--color-text-dark);
				}
			}

			.button_primary {
				.button-hover-effects {
					background: var(--color-text-dark);
				}

				&:hover {
					color: var(--color-white);

					svg {
						fill: var(--color-white);
					}
				}
			}

			.header-btn-menu {
				border-color: var(--stroke-dark-secondary);

				&:before,
				&:after {
					background: var(--stroke-dark);
				}
			}
		}
	}

	&_hidden {
		top: rem(-150);

		@include mediaBigDesktop {
			top: big(-150);
		}
	}
}

.logo {
	display: inline-block;

	&__link {
		display: inline-block;
		text-decoration: none;
		font-size: 0;
	}

	&__svg {
		width: auto;
		height: rem(34);

		@include mediaBigDesktop {
			height: big(34);
		}

		@include mediaLaptop {
			height: rem(24);
		}

		&-icon {
			fill: var(--color-primary);
		}

		&-name {
			fill: var(--color-dark);
		}
	}
}

.main-menu {
	display: flex;
	align-items: center;
	list-style-type: none;
	padding: 0;
	margin: 0;

	&__item {
		&:not(:last-child) {
			margin-right: rem(40);

			@include mediaBigDesktop {
				margin-right: big(40);
			}

			@include mediaDesktop {
				margin-right: rem(32);
			}
		}

		&:hover {
			.main-menu__sub-menu {
				opacity: 1;
				visibility: visible;
				pointer-events: all;
			}
		}

		&_arrow {
			position: relative;
			padding-right: rem(25);

			@include mediaBigDesktop {
				padding-right: big(25);
			}

			&:before,
			&:after {
				content: "";
				position: absolute;
				z-index: 2;
				top: calc(50% - #{rem(1)});
				right: 0;

				display: block;
				width: rem(7);
				height: 1px;
				background: var(--color-primary);
				transition: transform var(--anim-direction);

				@include mediaBigDesktop {
					top: big(15);
					width: big(7);
				}
			}

			&:before {
				transform: rotate(45deg);
				right: rem(4);

				@include mediaBigDesktop {
					right: big(4);
				}
			}

			&:after {
				transform: rotate(-45deg);
			}

			&:hover {
				&:before {
					transform: rotate(-45deg);
				}

				&:after {
					transform: rotate(45deg);
				}
			}
		}

		&_active {
			.main-menu__link {
				color: var(--color-secondary);
			}
		}
	}

	&__link {
		font: var(--font-body-M-caption);
		color: var(--color-primary);
		text-transform: uppercase;
		text-decoration: none;
		line-height: rem(32);

		transition: color var(--anim-direction);

		@include mediaBigDesktop {
			line-height: big(32);
		}

		@include mediaDesktop {
			line-height: rem(24);
		}

		@include mediaTablet {
			line-height: rem(20);
		}
	}

	&__sub-menu {
		display: block;
		position: absolute;
		left: rem(-48);
		top: calc(100% + #{rem(3)});
		z-index: 5;
		margin-top: rem(36);
		opacity: 0;
		visibility: hidden;
		pointer-events: none;

		transition: opacity var(--anim-direction), visibility var(--anim-direction);

		@include mediaBigDesktop {
			left: big(-48);
			margin-top: big(36);
			top: calc(100% + #{big(3)});
		}

		@include mediaDesktop {
			left: rem(-32);
			top: 100%;
		}

		&:before {
			content: "";
			display: block;
			width: 100%;
			height: rem(39);
			background: transparent;
			position: absolute;
			left: 0;
			top: rem(-39);

			@include mediaBigDesktop {
				height: big(39);
				top: big(-39);
			}
		}
	}
}

.social-icon {
	display: inline-block;
	text-decoration: none;
	font-size: 0;

	&__icon {
		width: rem(32);
		height: rem(32);
		fill: var(--color-dark);

		@include mediaBigDesktop {
			width: big(32);
			height: big(32);
		}

		@include mediaDesktop {
			width: rem(24);
			height: rem(24);
		}
	}
}

.header-btn-menu {
	position: relative;
	display: block;
	width: rem(48);
	height: rem(48);
	margin: 0;
	padding: 0;
	cursor: pointer;

	background: transparent;
	border: 0;
	border-right: 1px solid var(--stroke-dark-secondary);

	@include mediaBigDesktop {
		width: big(48);
		height: big(48);
	}

	@include mediaLaptop {
		width: rem(40);
		height: rem(40);
	}

	@include mediaTablet {
		width: rem(64);
		height: rem(64);
	}

	@include mediaMobile {
		width: rem(44);
		height: rem(44);
	}

	&:before,
	&:after {
		content: "";
		position: absolute;
		left: rem(6);
		z-index: 2;
		display: block;
		width: rem(36);
		height: rem(2);
		background: var(--color-dark);

		transition: top var(--anim-direction), transform var(--anim-direction);

		@include mediaBigDesktop {
			left: big(6);
			width: big(36);
			height: big(2);
		}

		@include mediaLaptop {
			left: rem(5);
			width: rem(30);
		}

		@include mediaTablet {
			left: rem(22);
			width: rem(20);
		}

		@include mediaMobile {
			left: rem(12);
		}
	}

	&:before {
		top: calc(50% - #{rem(5)});

		@include mediaBigDesktop {
			top: calc(50% - #{big(5)};
		}

		@include mediaDesktop {
			top: calc(50% - #{rem(5)};
		}

		@include mediaTablet {
			top: calc(50% - #{rem(3)});
		}

		@include mediaMobile {
			top: calc(50% - #{rem(4)});
		}
	}

	&:after {
		top: 50%;

		@include mediaBigDesktop {
			top: 50%;
		}

		@include mediaDesktop {
			top: calc(50% + #{rem(1)};
		}

		@include mediaTablet {
			top: calc(50% + #{rem(1)};
		}

		@include mediaMobile {
			top: calc(50% + #{rem(1)});
		}
	}

	&.js_active {
		&:before {
			top: calc(50% - 2px);
			transform: rotate(45deg);

			@include mediaMobile {
				top: calc(50% - 1px);
			}
		}

		&:after {
			top: calc(50% - 2px);
			transform: rotate(-45deg);

			@include mediaMobile {
				top: calc(50% - 1px);
			}
		}
	}
}

.sub-menu {
	list-style-type: none;
	background: var(--color-white);
	border: 1px solid var(--stroke-dark-secondary);
	border-top: 0;
	min-width: rem(410);
	padding: rem(48);

	@include mediaBigDesktop {
		min-width: big(410);
		padding: big(48);
	}

	@include mediaDesktop {
		min-width: rem(310);
		padding: rem(32);
	}

	&__item {
		&:not(:last-child) {
			margin-bottom: rem(24);

			@include mediaBigDesktop {
				margin-bottom: big(24);
			}

			@include mediaDesktop {
				margin-bottom: big(20);
			}
		}

		a {
			font: var(--font-body-M-caption);
			color: var(--color-dark);
			text-decoration: none;
			text-transform: uppercase;

			transition: color var(--anim-direction);

			&:hover {
				color: var(--color-primary);
			}
		}
	}
}

.header-scroll-panel {
	position: fixed;
	left: 0;
	top: 0;
	z-index: 15;
	width: rem(100);
	height: 100%;

	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	padding: rem(32) rem(26);
	background: transparent;
	color: var(--color-text-dark);
	border: 0;
	border-right: 1px solid var(--stroke-dark-secondary);
	cursor: pointer;

	transition: filter var(--anim-direction), color var(--anim-direction);

	filter: invert(100%);

	@include mediaBigDesktop {
		width: big(100);
		padding: big(32) big(26);
	}

	@include mediaDesktop {
		padding: rem(26);
	}

	@include mediaLaptop {
		padding: rem(20) rem(16);
		width: rem(72);
	}

	@include mediaTablet {
		display: none;
	}

	&__btns {
		position: absolute;
		left: 0;
		top: 0;
		z-index: 2;

		padding: rem(32) rem(26);
		width: 100%;

		@include mediaBigDesktop {
			padding: big(32) big(26);
		}

		@include mediaDesktop {
			padding: rem(26);
		}

		@include mediaLaptop {
			padding: rem(20) rem(16);
		}

		.header-btn-menu {
			width: rem(48);
			height: rem(48);
			border: 0;

			@include mediaBigDesktop {
				width: big(48);
				height: big(48);
			}

			@include mediaLaptop {
				width: rem(40);
				height: rem(40);
			}
		}
	}

	&__text {
		position: relative;
		opacity: 1;
		transform: rotate(-90deg) translate(rem(-35), 0);
		font: var(--font-body-M-caption);
		text-transform: uppercase;

		line-height: rem(28);
		height: rem(28);

		@include mediaBigDesktop {
			line-height: big(28);
			height: big(28);
		}

		@include mediaDesktop {
			line-height: rem(22);
			height: rem(22);
		}

		@include mediaTablet {
			line-height: rem(20);
			height: rem(20);
		}

		&-open,
		&-close {
			position: absolute;
			left: 0;
			right: 0;
			top: 0;
			z-index: 2;
			text-align: center;
		}

		&-close {
			opacity: 0;
			display: none;
		}
	}

	&__icon {
		position: absolute;
		bottom: rem(40);
		width: rem(24);
		height: rem(24);
		fill: #000;

		opacity: 1;
		transition: opacity var(--anim-direction);

		@include mediaBigDesktop {
			width: big(24);
			height: big(24);
		}
	}

	&:hover {
		.header-scroll-panel {
			&__text-open {
				&:after,
				span {
					transform: translateY(-100%);
					opacity: 1;
				}
			}

			&__text-close {
				&:after,
				span {
					transform: translateY(-100%);
					opacity: 1;
				}
			}
		}
	}
}

.manu-panel {
	position: fixed;
	left: 0;
	top: 0;
	z-index: 10;
	height: 100%;
	visibility: hidden;
	transition: visibility var(--anim-direction);

	@include mediaTablet {
		top: rem(64);
		width: rem(372);
		height: calc(100% - #{rem(64)});
	}

	@include mediaMobile {
		top: rem(44);
		width: 100%;
		height: calc(100% - #{rem(44)});
	}

	&__content {
		position: relative;
		z-index: 5;
		display: flex;
		flex-direction: column;
		width: auto;
		height: 100%;
		background-color: var(--color-white);
		color: var(--color-dark);
		clip-path: polygon(0 0, 0 0, 0 100%, 0% 100%);
		padding-left: rem(100);

		//transition: clip-path var(--anim-direction);

		@include mediaBigDesktop {
			padding-left: big(100);
		}

		@include mediaDesktop {
			padding-left: rem(100);
		}

		@include mediaLaptop {
			padding-left: rem(72);
		}

		@include mediaTablet {
			padding: rem(40);
			width: 100%;
			overflow: auto;
		}

		@include mediaMobile {
			padding: rem(40) rem(16) rem(20);
		}

		.big-menu {
			padding: var(--section-offset);
			flex: 1;
			width: rem(587);
			border-right: 1px solid var(--stroke-dark-secondary);

			@include mediaBigDesktop {
				width: big(587);
			}

			@include mediaDesktop {
				width: rem(438);
			}

			@include mediaLaptop {
				width: rem(330);
			}

			@include mediaTablet {
				width: 100%;
				padding: 0;
				border: 0;
			}

			&__item {
				opacity: 0;
			}
		}
	}

	&__footer {
		margin-top: auto;
		padding: var(--section-offset);
		opacity: 0;

		@include mediaTablet {
			padding: 0;
			padding-top: rem(40);
		}

		.contact-block {
			&:not(:last-child) {
				margin-bottom: rem(40);

				@include mediaBigDesktop {
					margin-bottom: big(40);
				}

				@include mediaDesktop {
					margin-bottom: rem(32);
				}

				@include mediaLaptop {
					margin-bottom: rem(24);
				}

				@include mediaMobile {
					margin-bottom: rem(16);
				}
			}

			&.show_mobile {
				display: none;

				@include mediaMobile {
					display: block;
				}
			}
		}
	}

	&__overlay {
		position: fixed;
		left: 0;
		top: 0;
		z-index: 2;
		width: 100%;
		height: 100%;
		background: rgba(0, 0, 0, 0.50);
		opacity: 0;
	}
}

.big-menu {
	list-style-type: none;
	padding: 0;
	margin: 0;

	&__item {
		display: block;
		width: 100%;

		&:not(:last-child) {
			margin-bottom: rem(40);

			@include mediaBigDesktop {
				margin-bottom: big(40);
			}

			@include mediaDesktop {
				margin-bottom: rem(32);
			}

			@include mediaLaptop {
				margin-bottom: rem(20);
			}

			@include mediaTablet {
				margin-bottom: rem(24);
			}

			@include mediaMobile {
				margin-bottom: rem(16);
			}
		}

		&_arrow {
			position: relative;
			padding-right: rem(50);

			@include mediaBigDesktop {
				padding-right: big(50);
			}

			@include mediaTablet {
				padding-right: 0;

				.big-menu__link {
					display: inline-block;
					margin-right: rem(50);
				}
			}

			svg {
				width: rem(40);
				height: rem(40);
				padding: rem(4);
				position: absolute;
				right: 0;
				top: 0;
				fill: var(--color-gray-2);

				@include mediaBigDesktop {
					width: big(40);
					height: big(40);
					padding: big(4);
				}

				@include mediaDesktop {
					width: rem(32);
					height: rem(32);
				}

				@include mediaLaptop {
					width: rem(24);
					height: rem(24);
					padding: 0;
				}

				@include mediaTablet {
					transform: rotate(90deg);
				}
			}

			&:after {
				content: "";
				display: block;
				width: var(--section-offset);
				height: 100%;
				position: absolute;
				right: rem(-100);
				top: 0;

				@include mediaBigDesktop {
					right: big(-100);
				}

				@include mediaDesktop {
					right: rem(-68);
				}

				@include mediaLaptop {
					right: rem(-48);
				}

				@include mediaTablet {
					content: none;
				}
			}

			@media (any-hover: hover) {
				&:hover {
					.big-menu__link {
						color: var(--color-secondary);
					}

					svg {
						fill: var(--color-secondary);
					}

					.big-sub-menu {
						visibility: visible;
						clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);

						@include mediaTablet {
							display: block;
						}
					}
				}
			}
		}

		.big-sub-menu {
			position: fixed;
			top: 0;
			left: calc(#{rem(587)} + #{rem(98)});
			z-index: 2;
			height: 100%;
			visibility: hidden;
			clip-path: polygon(0 0, 0 0, 0 100%, 0% 100%);
			border-left: 1px solid var(--stroke-dark-secondary);
			border-right: 1px solid var(--stroke-dark-secondary);

			transition: clip-path var(--anim-direction), visibility var(--anim-direction);

			@include mediaBigDesktop {
				left: calc(#{big(587)} + #{big(98)});
			}

			@include mediaDesktop {
				left: calc(#{rem(438)} + #{rem(98)});
			}

			@include mediaLaptop {
				left: calc(#{rem(330)} + #{rem(70)});
			}

			@include mediaTablet {
				position: static;
				border: 0;
				border-top: 1px solid var(--stroke-dark-secondary);
				margin-top: rem(24);
				clip-path: none;
				display: none;
				visibility: visible;
			}

			@include mediaMobile {
				margin-top: rem(16);
			}
		}

		&.js_active {
			@include mediaTablet {
				.big-menu__link {
					color: var(--color-secondary);
				}

				svg {
					fill: var(--color-secondary);
				}

				svg {
					transform: rotate(270deg);
				}

				.big-sub-menu {
					display: block;
					visibility: visible;
				}
			}
		}
	}

	&__link {
		font: var(--font-h4);
		letter-spacing: var(--letter-spacing-h4);
		color: var(--color-text-dark);
		text-decoration: none;

		transition: color var(--anim-direction);

		&:hover {
			color: var(--color-secondary);
		}
	}
}

.big-sub-menu {
	list-style-type: none;
	margin: 0;
	padding: var(--section-offset);
	background-color: var(--color-white);

	@include mediaTablet {
		background-color: transparent;
		padding: rem(24) 0;
	}

	@include mediaMobile {
		padding: rem(16) 0;
	}

	&__item {
		display: block;
		width: 100%;

		&:not(:last-child) {
			margin-bottom: rem(24);

			@include mediaBigDesktop {
				margin-bottom: big(24);
			}

			@include mediaDesktop {
				margin-bottom: rem(20);
			}

			@include mediaLaptop {
				margin-bottom: rem(16);
			}

			@include mediaMobile {
				margin-bottom: rem(12);
			}
		}

		a {
			font: var(--font-body-M);
			color: var(--color-text-dark);
			text-decoration: none;

			transition: color var(--anim-direction);

			&:hover {
				color: var(--color-secondary);
			}
		}
	}
}

.header_active,
.header_fixed {
	& + .header-scroll-panel {
		.header-scroll-panel__icon {
			opacity: 0;
		}
	}
}