.category-card {
	background: var(--color-white);
	padding: rem(40);
	width: 100%;

	@include mediaBigDesktop {
		padding: big(40);
	}

	@include mediaDesktop {
		padding: rem(32);
	}

	@include mediaLaptop {
		padding: rem(24);
	}

	@include mediaMobile {
		padding: rem(20);
	}

	&__scheme {
		&-label {
			display: flex;
			align-items: center;
			justify-content: space-between;
			font: var(--font-body-M-caption);
			color: var(--color-text-dark-secondary);
			text-transform: uppercase;
			margin-bottom: rem(16);

			@include mediaBigDesktop {
				margin-bottom: big(16);
			}

			@include mediaDesktop {
				margin-bottom: rem(12);
			}
		}

		&-flags {
			position: relative;
			display: flex;
			align-items: center;
			justify-content: space-between;
			margin-bottom: rem(16);

			@include mediaBigDesktop {
				margin-bottom: big(16);
			}

			@include mediaDesktop {
				margin-bottom: rem(12);
			}

			figure {
				position: relative;
				z-index: 2;
				margin: 0;
				font-size: 0;
				background: var(--color-white);
				padding-right: rem(12);

				@include mediaBigDesktop {
					padding-right: big(12);
				}

				&:last-child {
					padding-right: 0;
					padding-left: rem(12);

					@include mediaBigDesktop {
						padding-right: 0;
						padding-left: big(12);
					}
				}
			}

			&:after {
				content: "";
				position: absolute;
				left: 0;
				top: 50%;
				z-index: 1;

				display: block;
				width: 100%;
				height: 1px;
				border-top: 1px dotted var(--color-secondary);
			}
		}
	}

	&__city-info {
		display: flex;
		justify-content: space-between;
	}

	&__city-item {
		font: var(--font-body-M);
		color: var(--color-dark);

		span {
			display: block;
		}

		.country {
			font: var(--font-body-M-caption);
			color: var(--color-text-dark-secondary);
			text-transform: uppercase;
			margin-top: rem(4);

			@include mediaBigDesktop {
				margin-top: big(4);
			}

			@include mediaDesktop {
				margin-top: 0;
			}
		}

		&:last-child {
			text-align: right;
		}
	}

	&__footer {
		border-top: 1px solid var(--stroke-dark-secondary);
		padding-top: rem(40);
		margin-top: rem(40);

		@include mediaBigDesktop {
			padding-top: big(40);
			margin-top: big(40);
		}

		@include mediaDesktop {
			padding-top: rem(32);
			margin-top: rem(32);
		}

		@include mediaLaptop {
			padding-top: rem(24);
			margin-top: rem(24);
		}

		@include mediaTablet {
			padding-top: rem(16);
			margin-top: rem(16);
		}
	}

	&__time {
		display: flex;
		align-items: center;

		span {
			margin-left: rem(5);

			@include mediaBigDesktop {
				margin-left: big(5);
			}
		}
	}

	&__btn {
		display: none;
		margin-top: rem(40);

		@include mediaBigDesktop {
			margin-top: big(40);
		}

		@include mediaDesktop {
			margin-top: rem(32);
		}

		@include mediaLaptop {
			margin-top: rem(24);
		}

		@include mediaTablet {
			margin-top: rem(16);
		}
	}

	&:hover {
		.category-card {
			&__btn {
				display: inline-flex;
			}
		}
	}
}

.category-card-time {
	display: flex;
	align-items: center;
	font: var(--font-body-M);
	color: var(--color-text-dark-secondary);

	&__icon {
		width: rem(24);
		height: rem(24);
		margin-right: rem(8);
		fill: var(--color-text-dark-secondary);

		@include mediaBigDesktop {
			width: big(24);
			height: big(24);
			margin-right: big(8);
		}

		@include mediaDesktop {
			width: rem(20);
			height: rem(20);
		}

		@include mediaTablet {
			margin-right: rem(5);
		}
	}
}