.sec-utp-parallax {
	position: relative;
	z-index: 2;
	height: 100vh;
	overflow: hidden;
	background-color: var(--color-dark);
	color: var(--color-text-light);

	min-height: rem(600);

	@include mediaBigDesktop {
		min-height: big(600);
	}

	@include mediaTablet {
		min-height: rem(1024);
	}

	@include mediaMobile {
		min-height: rem(400);
	}

	.sec {
		&__title {
			max-width: 100%;
		}
	}

	.container {
		height: 100%;
	}

	&__btns {
		margin-top: auto;
	}

	&__row {
		height: 100%;
		display: flex;
		gap: var(--section-offset);

		@include mediaTablet {
			flex-direction: column;
			justify-content: space-between;
			gap: rem(80);
		}
	}

	&__col {
		display: flex;
		flex-direction: column;
		height: 100%;
		flex: 1;

		@include mediaTablet {
			flex: none;
			height: auto;
		}

		&_cards {
			@include mediaMobile {
				overflow: auto;
			}
		}
	}

	&__col-scroll {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;

		@include mediaTablet {
			display: grid;
			grid-template-rows: 1fr 1fr;
			grid-auto-flow: column;
			grid-auto-columns: max-content;
		}

		@include mediaMobile {
			display: flex;
			flex-wrap: nowrap;
			width: max-content;
		}

		.block-utp-2 {
			width: 50%;
			height: rem(500);
			margin-left: -1px;
			margin-top: -1px;

			@include mediaBigDesktop {
				height: big(500);
			}

			@include mediaDesktop {
				height: rem(414);
			}

			@include mediaLaptop {
				height: rem(314);
			}

			@include mediaTablet {
				width: rem(318);
				height: rem(318);
			}

			@include mediaMobile {
				width: rem(237);
				height: rem(224);
				margin-top: 0;
			}

			&:nth-child(odd) {
				margin-top: rem(-101);

				@include mediaBigDesktop {
					margin-top: big(-101);
				}

				@include mediaDesktop {
					margin-top: rem(-69);
				}

				@include mediaLaptop {
					margin-top: rem(-49);
				}

				@include mediaTablet {
					margin-top: -1px;
				}

				@include mediaMobile {
					margin-top: 0;
				}
			}

			&:nth-child(2) {
				margin-top: var(--section-offset);

				@include mediaTablet {
					margin-top: -1px;
				}

				@include mediaMobile {
					margin-top: 0;
				}
			}

			&:first-child {
				margin-top: 0;

				@include mediaTablet {
					margin-top: -1px;
				}

				@include mediaMobile {
					margin-top: 0;
					margin-left: 0;
				}
			}
		}
	}

	//&__col-list {
	//	flex: 1;
	//
	//	@include mediaTablet {
	//		display: flex;
	//	}
	//}
}

@supports(height: 100dvh) {
	.sec-utp-parallax {
		height: 100dvh;
	}
}

.block-utp-2 {
	position: relative;
	padding: rem(40);
	border: 1px solid var(--stroke-light-secondary);

	@include mediaBigDesktop {
		padding: big(40);
	}

	@include mediaDesktop {
		padding: rem(32);
	}

	@include mediaLaptop {
		padding: rem(24);
	}

	@include mediaMobile {
		padding: rem(20);
	}

	&__count {
		position: relative;
		z-index: 2;
		font: var(--font-body-M-caption);
		color: var(--color-text-light-secondary);
		margin-bottom: rem(20);

		@include mediaBigDesktop {
			margin-bottom: big(20);
		}

		@include mediaDesktop {
			margin-bottom: rem(16);
		}

		@include mediaLaptop {
			margin-bottom: rem(12);
		}

		@include mediaTablet {
			margin-bottom: rem(8);
		}
	}

	&__title {
		position: relative;
		z-index: 2;
		font: var(--font-h4);
		letter-spacing: var(--letter-spacing-h4);

		@include mediaMobile {
			font-size: rem(16);
		}
	}

	&__img {
		position: absolute;
		left: 0;
		bottom: 0;
		z-index: 1;
		margin: 0;
		font-size: 0;
		width: 100%;

		picture {
			display: block;
			width: 100%;
		}

		img {
			width: 100%;
			height: auto;
			max-width: 100%;
		}
	}
}
