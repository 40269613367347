.main-banner {
	padding-top: rem(110);
	padding-bottom: rem(100);
	position: relative;
	z-index: 2;
	color: var(--color-white);
	overflow: hidden;

	min-height: rem(800);
	height: 100vh;

	@include mediaBigDesktop {
		padding-top: big(110);
		padding-bottom: big(100);
		min-height: big(800);
	}

	@include mediaDesktop {
		padding-top: rem(95);
		padding-bottom: rem(68);
		min-height: rem(700);
	}

	@include mediaLaptop {
		padding-top: rem(80);
		padding-bottom: rem(48);
		min-height: rem(600);
	}

	@include mediaTablet {
		padding-top: rem(64);
		padding-bottom: rem(40);
		min-height: rem(400);
	}

	@include mediaMobile {
		padding-top: rem(44);
	}

	&:before {
		content: "";
		display: block;
		width: 100%;
		height: 100%;
		position: absolute;
		left: 0;
		top: 0;
		z-index: 1;
		background-color: rgba(0, 0, 0, 0.3);
	}

	.container {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		height: 100%;

		position: relative;
		z-index: 2;
	}

	&__title {
		margin-top: var(--section-offset);
		font-size: rem(115);
		line-height: 0.97;
		font-weight: 500;
		letter-spacing: -3.45px;
		max-width: rem(1285);

		color: var(--color-primary);

		@include mediaBigDesktop {
			font-size: big(115);
			max-width: big(1285);
		}

		@include mediaDesktop {
			font-size: rem(92);
			line-height: 0.91;
			letter-spacing: -2.76px;
			max-width: rem(962);
		}

		@include mediaLaptop {
			font-size: rem(68);
			line-height: 0.97;
			letter-spacing: -2.04px;
			max-width: rem(702);
		}

		@include mediaTablet {
			font-size: rem(60);
			line-height: 0.93;
			letter-spacing: -1.8px;
			max-width: 100%;
		}

		@include mediaMobile {
			margin-top: rem(40);
			font-size: rem(30);
			line-height: 1.06;
			letter-spacing: -0.9px;
		}
	}

	&__desc {
		margin: rem(40) 0;
		max-width: rem(885);
		font: var(--font-body-M);

		@include mediaBigDesktop {
			margin: big(40) 0;
			max-width: big(885);
		}

		@include mediaDesktop {
			margin: rem(32) 0;
			max-width: rem(720);
		}

		@include mediaLaptop {
			margin: rem(24) 0;
			max-width: rem(502);
		}

		@include mediaTablet {
			max-width: 100%;
		}

		@include mediaMobile {
			margin: rem(16) 0;
		}

		p {
			margin: 0;

			&:not(:last-child) {
				margin-bottom: rem(15);

				@include mediaBigDesktop {
					margin-bottom: big(15);
				}

				@include mediaTablet {
					margin-bottom: rem(8);
				}
			}
		}
	}

	&__btns {
		margin-top: auto;
	}

	&__video {
		position: absolute;
		left: 0;
		top: 0;
		z-index: 0;
		width: 100%;
		max-width: 100%;
		height: 100%;
		object-fit: cover;

		background-position: center;
		background-size: cover;
		background-repeat: no-repeat;

		picture {
			display: block;
			font-size: 0;
			width: 100%;
			height: 100%;

			img {
				width: 100%;
				max-width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}
	}
}

@supports(height: 100dvh) {
	.main-banner {
		height: 100dvh;
	}
}
