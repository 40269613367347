.label-group {
	display: block;
	width: 100%;
	margin: 0;

	.error,
	.is-invalid {
		margin-bottom: rem(5);

		@include mediaBigDesktop {
			margin-bottom: big(5);
		}
	}

	&__error {
		display: block;
		font: var(--font-body-S);
		color: var(--system-states-error);
	}
}

.input {
	position: relative;
	display: block;

	width: 100%;
	height: rem(59);
	font: var(--font-body-M);
	letter-spacing: var(--text-spacing);
	line-height: rem(59);

	padding: 0 rem(20);
	margin: 0;

	background: var(--color-white);
	color: var(--color-dark);
	border: 1px solid var(--stroke-dark-secondary);
	text-overflow: ellipsis;

	transition: border-color var(--anim-direction);

	@include mediaBigDesktop {
		height: big(59);
		line-height: big(59);

		padding: 0 big(20);
	}

	@include mediaDesktop {
		height: rem(53);
		line-height: rem(53);
	}

	@include mediaLaptop {
		height: rem(43);
		line-height: rem(43);
		padding: 0 rem(16);
	}

	&:required,
	&_required {
		background-image: url("/assets/templates/img/common/icon-required.svg");
		background-repeat: no-repeat;
		background-size: rem(32);
		background-position: calc(100% - #{rem(16)}) center;

		@include mediaBigDesktop {
			background-size: big(32);
			background-position: calc(100% - #{big(16)}) center;
		}

		@include mediaDesktop {
			background-size: rem(26);
			background-position: calc(100% - #{rem(13)}) center;
		}

		@include mediaLaptop {
			background-size: rem(24);
			background-position: calc(100% - #{rem(12)}) center;
		}

		@include mediaTablet {
			background-size: rem(20);
			background-position: calc(100% - #{rem(10)}) center;
		}
	}

	&:disabled,
	&.disabled {
		background-image: none;
		border-color: var(--stroke-dark-secondary);
		background: var(--color-gray-3);
	}

	&:hover:not(:disabled):not(.disabled) {
		border-color: var(--stroke-dark);
	}

	&:focus {
		outline: 0;
		border-color: var(--stroke-dark);
	}

	&::placeholder {
		color: var(--color-text-dark-secondary);
	}

	&.error,
	&.is-invalid {
		border-color: var(--system-states-error);
	}
}

.double-group {
	position: relative;

	&__fields {
		position: relative;
		display: flex;
		align-items: center;
		background: var(--color-white);
		border: 1px solid var(--stroke-dark-secondary);

		transition: border-color var(--anim-direction);

		&:after {
			content: "";
			display: block;

			width: 1px;
			height: 100%;

			position: absolute;
			left: 50%;
			top: 0;

			background: var(--stroke-dark-secondary);

			transition: background-color var(--anim-direction);
		}
	}

	&__input {
		flex: 1;
		border: 0;
		text-overflow: ellipsis;
	}

	&__btn {
		position: relative;
		z-index: 2;
		display: flex;
		align-items: center;
		justify-content: center;

		padding: 2px;
		background-color: var(--color-white);
		border: 1px solid transparent;
		cursor: pointer;

		transition: border-color var(--anim-direction);

		svg {
			width: rem(24);
			height: rem(24);

			transition: transform var(--anim-direction);

			@include mediaBigDesktop {
				width: big(24);
				height: big(24);
			}
		}

		&:focus-visible {
			outline: 0;
			border-color: var(--stroke-dark);
		}

		&:hover {
			svg {
				fill: var(--color-secondary);
				transform: rotate(180deg);
			}
		}
	}

	&:hover,
	&:focus-within:not(.disabled) {
		.double-group__fields {
			border-color: var(--stroke-dark);

			&:after {
				background: var(--stroke-dark);
			}
		}
	}

	&.disabled {
		.double-group {
			&__fields {
				background: var(--color-gray-3);
				border-color: var(--stroke-dark-secondary);
			}

			&__input {
				background: var(--color-gray-3);
			}

			&__btn {
				background: var(--color-gray-3);
				pointer-events: none;
				cursor: default;

				svg {
					fill: var(--color-gray-2);
				}
			}
		}
	}

	.is-invalid {
		+ .double-group__fields {
			border-color: var(--system-states-error);

			&:after {
				background: var(--system-states-error);
			}
		}

		+ .double-group__fields + .double-group {
			&__error {
				display: block;
			}
		}
	}

	&__error {
		display: none;
		font: var(--font-body-S);
		color: var(--system-states-error);
		margin-top: rem(5);

		@include mediaBigDesktop {
			margin-top: big(5);
		}
	}
}

.checkbox {
	position: relative;

	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: start;
	gap: rem(16);

	user-select: none;

	@include mediaBigDesktop {
		gap: big(16);
	}

	&:hover {
		.checkbox {
			&__item {
				border-color: var(--stroke-dark);
			}
		}
	}

	&__input {
		&:checked {
			& ~ .checkbox {
				&__item {
					background-color: var(--color-dark);
					border-color: var(--color-dark);
				}
			}
		}

		&:disabled {
			& ~ .checkbox {
				&__item {
					background-color: var(--color-gray-3);
					border-color: var(--stroke-dark-secondary);
					cursor: default;

					&:before {
						background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16' fill='none'%3E%3Cpath d='M6.66843 10.1139L12.7967 3.98566L13.7395 4.92846L6.66843 11.9995L2.42578 7.75691L3.36859 6.81411L6.66843 10.1139Z' fill='rgba(0, 0, 0, 0.08)'/%3E%3C/svg%3E");
					}
				}
			}
		}

		&:disabled:not(&:checked) {
			& ~ .checkbox {
				&__item {
					&:before {
						content: none;
					}
				}
			}
		}

		&.error,
		&.is-invalid {
			& ~ .checkbox {
				&__item {
					border-color: var(--system-states-error);
				}
			}
		}
	}

	&__item {
		position: relative;
		min-width: rem(24);
		width: rem(24);
		height: rem(24);
		display: block;

		background-color: var(--color-white);
		border: solid 1px var(--stroke-dark-secondary);
		border-radius: 0;
		cursor: pointer;

		transition: border-color var(--anim-direction), background-color var(--anim-direction);

		@include mediaBigDesktop {
			min-width: big(24);
			width: big(24);
			height: big(24);
		}

		&::before {
			content: "";
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			margin: auto;

			width: rem(16);
			height: rem(16);

			background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16' fill='none'%3E%3Cpath d='M6.66843 10.1139L12.7967 3.98566L13.7395 4.92846L6.66843 11.9995L2.42578 7.75691L3.36859 6.81411L6.66843 10.1139Z' fill='white'/%3E%3C/svg%3E");
			background-size: contain;
			background-position: center;
			background-repeat: no-repeat;

			@include mediaBigDesktop {
				width: big(16);
				height: big(16);
			}
		}
	}

	&__text {
		position: relative;
		z-index: 1;

		font: var(--font-body-M);
		color: var(--text-dark-primary);

		a {
			color: var(--text-dark-primary);
			text-decoration: underline;

			&:hover {
				color: var(--accent-color);
			}
		}
	}
}

.radio {
	position: relative;

	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: start;
	gap: rem(16);

	user-select: none;

	@include mediaBigDesktop {
		gap: big(16);
	}

	&:hover {
		.radio {
			&__item {
				border-color: var(--stroke-dark);
			}
		}
	}

	&__input {
		&:checked {
			& ~ .radio {
				&__item {
					background-color: var(--stroke-dark);
					border-color: var(--stroke-dark);
				}
			}
		}

		&:disabled {
			& ~ .radio {
				&__item {
					background-color: var(--color-gray-3);
					border-color: var(--stroke-dark-secondary);
					cursor: default;

					&:before {
						background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16' fill='none'%3E%3Cpath d='M6.66843 10.1139L12.7967 3.98566L13.7395 4.92846L6.66843 11.9995L2.42578 7.75691L3.36859 6.81411L6.66843 10.1139Z' fill='rgba(0, 0, 0, 0.08)'/%3E%3C/svg%3E");
					}
				}
			}
		}

		&:disabled:not(&:checked) {
			& ~ .radio {
				&__item {
					&:before {
						content: none;
					}
				}
			}
		}
	}

	&__item {
		position: relative;
		min-width: rem(24);
		width: rem(24);
		height: rem(24);
		display: block;

		background-color: var(--color-white);
		border: solid 1px var(--stroke-dark-secondary);
		border-radius: 50%;
		cursor: pointer;

		transition: border-color var(--anim-direction), background-color var(--anim-direction);

		@include mediaBigDesktop {
			min-width: big(24);
			width: big(24);
			height: big(24);
		}

		&::before {
			content: "";
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			margin: auto;

			width: rem(14);
			height: rem(14);

			border-radius: 50%;
			background: var(--color-white);

			transition: background-color var(--anim-direction);

			@include mediaBigDesktop {
				width: big(14);
				height: big(14);
			}
		}
	}

	&__text {
		position: relative;
		z-index: 1;

		font: var(--font-body-M);
		color: var(--text-dark-primary);

		a {
			color: var(--text-dark-primary);
			text-decoration: underline;

			&:hover {
				color: var(--accent-color);
			}
		}
	}
}

.iti {
	display: block;
	width: 100%;

	&--inline-dropdown {
		.iti {
			&__dropdown-content {
				z-index: 5;
			}
		}
	}

	&__flag {
		background-image: url("/assets/templates/img/common/flags.png?1");
	}
}

@media (min-resolution:2x) {
	.iti__flag {
		background-image: url("/assets/templates/img/common/flags.png?1");
	}
}