@mixin mediaBigDesktop {
  @media screen and (min-width: #{rem(2561)}) {
    @content;
  }
}

@mixin mediaDesktop {
  @media screen and (max-width: #{rem(2100)}) {
    @content;
  }
}

@mixin mediaLaptop {
  @media screen and (max-width: #{rem(1650)}) {
    @content;
  }
}

@mixin mediaTablet {
  @media screen and (max-width: #{rem(1128)}) {
    @content;
  }
}

@mixin mediaMobile {
  @media screen and (max-width: #{rem(720)}) {
    @content;
  }
}

@mixin media($width) {
  @media screen and (max-width: $width) {
    @content;
  }
}
