.sec-history {
	position: relative;
	height: 100vh;
	min-height: rem(980);
	background-color: var(--color-gray-3);
	padding-bottom: 0;
	overflow: hidden;

	@include mediaBigDesktop {
		min-height: big(980);
	}

	@include mediaDesktop {
		min-height: rem(700);
	}

	@include mediaTablet {
		min-height: rem(500);
		height: auto;
	}

	.sec {
		&__header {
			align-items: flex-start;
		}

		&__actions {
			flex-direction: column;
			padding-right: var(--section-offset);
			max-width: 48%;

			@include mediaTablet {
				padding-right: 0;
			}

			@include mediaTablet {
				max-width: 100%;
				width: 100%;
			}

			@include mediaMobile {
				display: block;
				width: 100%;
			}
		}
	}

	.container {
		display: flex;
		flex-direction: column;
		width: 100%;
		height: 100%;
	}

	&__slider-text {
		width: 100%;

		.swiper-wrapper {
			align-items: stretch;
		}

		.swiper-slide {
			opacity: 0;
			height: auto;
			background-color: var(--color-gray-3);

			transition: opacity var(--anim-direction);

			&.swiper-slide-active {
				opacity: 1;
			}

			.line {
				overflow: hidden;
			}

			p {
				&:first-child {
					margin-top: 0;
				}

				&:last-child {
					margin-bottom: 0;
				}
			}
		}
	}

	&__slider {
		position: relative;
		margin-top: auto;
		padding-bottom: calc(#{rem(112)} + var(--section-offset));
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: space-between;
		z-index: 3;

		@include mediaBigDesktop {
			padding-bottom: calc(#{big(112)} + var(--section-offset));
		}

		@include mediaDesktop {
			height: rem(550);
			padding-bottom: calc(#{rem(100)} + var(--section-offset));
		}

		@include mediaTablet {
			height: rem(300);
			padding-bottom: calc(#{rem(40)} + var(--section-offset));
		}

		@include mediaMobile {
			padding-bottom: 0;
			height: rem(200);
		}

		&:before {
			content: "";
			display: block;
			width: 100%;
			height: rem(150);
			background: linear-gradient(180deg, rgba(249,249,249,1) 0%, rgba(249,249,249,0) 100%);
			position: absolute;
			left: 0;
			top: -1px;
			z-index: 3;

			@include mediaBigDesktop {
				height: big(150);
			}

			@include mediaDesktop {
				height: rem(50);
			}

			@include mediaLaptop {
				height: rem(60);
			}

			@include mediaTablet {
				height: rem(40);
			}
		}

		.swiper-wrapper {
			z-index: 2;
		}

		.swiper-slide {
			opacity: 0;
			height: 100%;
			font-size: 0;
			line-height: 1;

			transition: opacity 1s;

			&.swiper-slide-active {
				opacity: 1;
			}
		}

		.history-slide {
			height: 100%;
		}
	}

	&__slider-btn-container {
		display: flex;
		align-items: center;
		gap: rem(40);

		@include mediaBigDesktop {
			gap: big(40);
		}

		@include mediaDesktop {
			gap: rem(32);
		}

		@include mediaLaptop {
			gap: rem(24);
		}

		@include mediaTablet {
			gap: rem(16);
		}

		.h5 {
			opacity: 1;
			transition: opacity var(--anim-direction);

			@include mediaMobile {
				display: none;
			}
		}

		.hide {
			opacity: 0;
		}

		.icon-button {
			position: relative;
			z-index: 4;
		}
	}

	&__slider-lines {
		position: absolute;
		left: rem(100);
		bottom: 0;
		z-index: 2;
		width: calc(100% - #{rem(100)});
		display: flex;
		align-items: flex-end;
		justify-content: space-between;

		@include mediaBigDesktop {
			left: big(100);
			width: calc(100% - #{big(100)});
		}

		@include mediaLaptop {
			left: rem(72);
			width: calc(100% - #{rem(72)});
		}

		@include mediaTablet {
			left: 0;
			width: 100%;
		}

		.slide-line {
			display: inline-block;
			background: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 100%);
			min-width: 1px;
			width: 1px;
			height: rem(112);

			transition: width 0.3s,
			height 0.3s,
			min-width 0.3s;

			@include mediaBigDesktop {
				height: big(112);
				margin: 0 big(10);
			}

			@include mediaTablet {
				height: rem(80);
			}

			@include mediaMobile {
				height: rem(40);
			}

			&:first-child {
				margin-left: 0;
			}

			&:last-child {
				margin-right: 0;
			}

			&.js_active {
				height: rem(164);
				min-width: rem(4);
				width: rem(4);
				background: linear-gradient(0deg, #175FE9 0%, rgba(23, 95, 233, 0) 100%);

				@include mediaBigDesktop {
					height: big(164);
					min-width: big(4);
					width: big(4);
				}

				@include mediaTablet {
					height: rem(120);
				}

				@include mediaMobile {
					height: rem(60);
				}
			}
		}
	}
}

@supports(height: 100dvh) {
	.sec-history {
		height: 100dvh;

		@include mediaTablet {
			height: auto;
		}
	}
}

.history-slide {
	padding: rem(2);
	display: flex;
	align-items: center;
	justify-content: center;
	line-height: 1;
	font-size: 0;

	@include mediaBigDesktop {
		padding: big(2);
	}

	&__text {
		color: var(--color-secondary);
		text-align: center;
		font-size: rem(435);
		line-height: 0.73;
		letter-spacing: -13.05px;

		@include mediaBigDesktop {
			font-size: big(435);
		}

		@include mediaDesktop {
			font-size: rem(320);
			line-height: 1;
			letter-spacing: -9.6px;
		}

		@include mediaLaptop {
			font-size: rem(240);
			letter-spacing: -7.2px;
		}

		@include mediaTablet {
			font-size: rem(120);
			line-height: 2;
			letter-spacing: -3.6px;
		}

		@include mediaMobile {
			font-size: rem(70);
			line-height: 3.42;
			letter-spacing: -2.1px;
		}
	}
}