.sec-directions {
	background-color: var(--color-gray-3);

	&__nav {
		width: 100%;
		overflow: auto hidden;

		&-scroll {
			display: flex;
			align-items: center;

			.button-tab-wrap {
				display: inline-table;
				width: max-content;
				white-space: nowrap;
				overflow: hidden;
				border-radius: rem(60);

				@include mediaBigDesktop {
					border-radius: big(60);
				}

				&:not(:last-child) {
					margin-right: rem(12);

					@include mediaBigDesktop {
						margin-right: big(12);
					}
				}
			}
		}
	}

	.sec__title {
		margin-top: var(--section-offset);

		@include mediaMobile {
			margin-top: rem(40);
		}
	}

	&__list {
		display: flex;
		align-items: flex-start;
		flex-wrap: wrap;
		margin: 0 rem(-50);

		@include mediaBigDesktop {
			margin: 0 big(-50);
		}

		@include mediaDesktop {
			margin: 0 rem(-34);
		}

		@include mediaLaptop {
			margin: 0 rem(-24);
		}

		@include mediaTablet {
			margin: 0 rem(-12);
		}

		@include mediaMobile {
			margin: 0;
		}

		.category-card {
			width: calc(33.333% - #{rem(100)});
			margin: 0 rem(50);
			margin-bottom: rem(100);

			@include mediaBigDesktop {
				width: calc(33.333% - #{big(100)});
				margin: 0 big(50);
				margin-bottom: big(100);
			}

			@include mediaDesktop {
				width: calc(33.333% - #{rem(68)});
				margin: 0 rem(34);
				margin-bottom: rem(68);
			}

			@include mediaLaptop {
				width: calc(33.333% - #{rem(48)});
				margin: 0 rem(24);
				margin-bottom: rem(48);
			}

			@include mediaTablet {
				width: calc(50% - #{rem(24)});
				margin: 0 rem(12);
				margin-bottom: rem(24);
			}

			@include mediaMobile {
				width: 100%;
				margin: 0;
				margin-bottom: rem(16);
			}
		}
	}

	&__btns {
		@include mediaTablet {
			margin-top: rem(16);
		}

		@include mediaMobile {
			margin-top: rem(12);
		}
	}
}
