.select-input {
	position: relative;

	width: 100%;
	display: flex;
	flex-direction: column;
	gap: rem(5);

	@include mediaBigDesktop {
		gap: big(5);
	}

	&_checkbox {
		& .select {
			&-answer {
				&__text {
					display: flex;
					align-items: center;
					gap: rem(10);

					transition: color var(--anim-direction);

					@include mediaBigDesktop {
						gap: big(10);
					}

					& .select-answer__checkbox {
						position: relative;

						display: flex;
						align-items: center;
						justify-content: center;
						width: rem(20);
						height: rem(20);

						background-color: var(--bg-white);
						border: solid 1px var(--stroke-dark);
						border-radius: var(--radius-secondary);

						transition: background-color var(--animation-timing) var(--cubic-bezier),
						border-color var(--animation-timing) var(--cubic-bezier);

						@include mediaBigDesktop {
							width: big(18);
							height: big(18);

							border-width: big(1);
						}

						&:before {
							content: "";
							position: absolute;
							top: 0;
							right: 0;
							bottom: 0;
							left: 0;
							margin: auto;

							display: flex;
							flex-shrink: 0;
							width: rem(16);
							height: rem(16);

							background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16' fill='none'%3E%3Cpath d='M6.66843 10.1139L12.7967 3.98566L13.7395 4.92846L6.66843 11.9995L2.42578 7.75691L3.36859 6.81411L6.66843 10.1139Z' fill='white'/%3E%3C/svg%3E");
							background-size: contain;
							background-position: center;
							background-repeat: no-repeat;

							@include mediaBigDesktop {
								width: big(16);
								height: big(16);
							}
						}
					}
				}

				&__input {
					&:checked {
						&~.select-answer__text {
							color: var(--accent-color);

							& .select-answer__checkbox {
								background-color: var(--accent-color);
								border-color: var(--accent-color);
							}
						}
					}
				}
			}
		}
	}

	&__checkbox {

		&:focus,
		&:checked {
			&~.select-current {
				& .select-current {
					&__icon {
						transform: rotate(180deg);
					}
				}
			}

			&~.select-answer {
				margin-top: rem(10);

				opacity: 1 !important;
				visibility: visible;

				@include mediaBigDesktop {
					margin-top: big(10);
				}
			}
		}

		&:disabled {
			&~.select-current {
				border-color: var(--stroke-dark-secondary);
				background: var(--color-gray-3);
				color: var(--color-text-dark-secondary);
				cursor: default;
			}
		}
	}

	&__input {
		&[value=""] {
			&~.select-current {
				& .select-current {
					&__text {
						color: var(--color-text-dark-secondary);
					}
				}
			}
		}

		&.is-invalid {
			+ .select-current {
				border-color: var(--system-states-error);
			}
		}
	}

	&__error {
		display: none;
		font: var(--font-body-S);
		color: var(--system-states-error);
	}

	&__input.is-invalid {
		+ .select-input__current {
			border-color: var(--system-states-error);

			+ .select-input__answer {
				+ .select-input__error {
					display: block;
				}
			}
		}
	}
}

.select-current {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	gap: rem(15);
	height: rem(59);
	padding: 0 rem(20);
	background-color: var(--color-white);
	border: 1px solid var(--stroke-dark-secondary);
	font: var(--font-body-M);
	color: var(--color-text-dark);

	transition: border-color var(--anim-direction);

	cursor: pointer;

	@include mediaBigDesktop {
		gap: big(15);
		height: big(59);
		padding: 0 big(20);
	}

	@include mediaDesktop {
		height: rem(53);
	}

	@include mediaLaptop {
		height: rem(43);
		padding: 0 rem(16);
	}

	&:hover {
		border-color: var(--accent-color);
	}

	&__icon {
		width: rem(24);
		height: rem(24);
		flex-shrink: 0;

		fill: var(--color-gray-2);
		user-select: none;

		transition: transform var(--anim-direction);

		@include mediaBigDesktop {
			width: big(24);
			height: big(24);
		}

		@include mediaDesktop {
			width: rem(20);
			height: rem(20);
		}
	}

	&__text {
		width: 100%;
		max-width: 100%;
		display: block;

		white-space: nowrap;
		text-overflow: ellipsis;

		overflow: hidden;
		user-select: none;
	}
}

.select-answer {
	position: absolute;
	z-index: 4;
	top: 100%;
	left: 0;
	padding: rem(12) 0;
	margin-top: rem(-10);

	width: 100%;
	min-width: rem(200);
	height: auto;
	max-height: rem(384);
	display: flex;
	flex-direction: column;

	background-color: var(--color-white);
	border: 1px solid var(--stroke-dark-secondary);
	overflow: auto;

	transition: margin-top var(--anim-direction),
	opacity var(--anim-direction),
	visibility var(--anim-direction);

	opacity: 0;
	visibility: hidden;

	@include mediaBigDesktop {
		min-width: big(200);
		max-height: big(384);
		padding: big(12) 0;
		margin-top: big(-10);
	}

	@include mediaLaptop {
		max-height: rem(288);
	}

	@include mediaTablet {

	}

	@include scrollbar();

	&__item {
		padding: rem(14) rem(20);

		width: 100%;
		display: flex;
		align-items: center;
		justify-content: start;

		color: var(--color-text-dark);
		text-align: start;
		font: var(--font-body-M);
		text-decoration: none;

		background-color: var(--color-white);
		border: none;

		transition: background-color var(--animation-timing) var(--cubic-bezier),
		color var(--animation-timing) var(--cubic-bezier);

		cursor: pointer;

		@include mediaBigDesktop {
			padding: big(14) big(20);
		}

		@include mediaLaptop {
			padding: rem(10) rem(16);
		}

		@include mediaTablet {
			padding: rem(12) rem(16);
		}

		&:hover {
			background-color: var(--color-gray-3);
		}

		&:focus,
		&:active {
			color: var(--color-text-dark);
			background-color: var(--color-gray-3);
		}
	}
}
