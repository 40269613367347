.page {
	margin: 0;
	position: relative;

	min-width: 320px;
	min-height: 100%;

	font: var(--font-body-M);
	letter-spacing: var(--text-spacing);

	background-color: var(--color-white);
	scroll-behavior: smooth;

	@include mediaBigDesktop {
		font-size: big(16);
		font: var(--font-body-M);
	}

	@include scrollbar();

	&:before {
		content: "";
		display: block;
		width: 1px;
		height: 100%;
		background-color: var(--stroke-dark-secondary);
		position: absolute;
		left: rem(100);
		top: 0;
		z-index: 1;

		@include mediaBigDesktop {
			left: big(100);
		}

		@include mediaLaptop {
			left: rem(72);
		}

		@include mediaTablet{
			content: none;
		}
	}

	&__main {
		padding-bottom: var(--section-offset);
		transform-origin: top;

		transition: transform var(--anim-direction);

		@include mediaMobile {
			padding-bottom: rem(40);
		}

		&_p-top {
			padding-top: var(--height-menu);
		}
	}

	&__breadcrumbs {
		padding: rem(32) 0;
		overflow: auto;

		@include mediaBigDesktop {
			padding: big(32) 0;
		}

		@include mediaLaptop {
			padding: rem(24) 0;
		}

		@include mediaMobile {
			padding: rem(14) 0;
		}
	}

	&__title {
		display: block;
		font: var(--font-h3);
		letter-spacing: var(--letter-spacing-h3);
		margin-bottom: var(--section-offset);

		@include mediaMobile {
			margin-bottom: rem(16);
		}
	}

	&__row {
		display: flex;
		width: 100%;
		gap: var(--section-offset);
		padding: var(--section-padding);

		&_pt-min {
			padding-top: rem(68);

			@include mediaBigDesktop {
				padding-top: big(68);
			}

			@include mediaDesktop {
				padding-top: rem(48);
			}

			@include mediaLaptop {
				padding-top: rem(24);
			}

			@include mediaTablet {
				padding-top: rem(16);
			}
		}
	}

	&__content {
		flex: 1;
	}

	&__aside {
		flex-basis: rem(688);

		@include mediaBigDesktop {
			flex-basis: big(688);
		}

		@include mediaDesktop {
			flex-basis: rem(516);
		}

		@include mediaLaptop {
			flex-basis: rem(392);
		}

		@include mediaTablet {
			display: none;
		}

		.cta-form {
			position: sticky;
			top: 0;
			right: 0;
		}
	}

	&__bottom {
		padding-top: var(--section-offset);
		margin-top: var(--section-offset);
		border-top: 1px solid var(--stroke-dark-secondary);
	}

	&__pagination {
		padding-top: var(--section-offset);
		display: flex;
		align-items: center;
		justify-content: center;
	}

	&.scale_min {
		.page__main {
			transform: scale(0.9);
		}
	}
}

.page-404 {
	.page {
		&__row {
			padding-top: 0;
			align-items: center;

			@include mediaTablet {
				flex-direction: column-reverse;
				gap: 0;
			}
		}

		&__title {
			max-width: rem(560);
			margin: 0;

			@include mediaBigDesktop {
				max-width: big(560);
			}

			@include mediaDesktop {
				max-width: rem(480);
			}

			@include mediaTablet {
				max-width: 100%;
			}

			strong {
				font-weight: inherit;
				color: var(--color-secondary);
			}
		}

		&__content {
			flex: none;
			flex-basis: rem(1082);
			padding-top: rem(240);
			padding-right: var(--section-offset);

			@include mediaBigDesktop {
				flex-basis: big(1082);
				padding-top: big(240);
			}

			@include mediaDesktop {
				flex-basis: rem(808);
				padding-top: rem(140);
			}

			@include mediaLaptop {
				flex-basis: rem(612);
				padding-top: rem(75);
			}

			@include mediaTablet {
				flex-basis: 100%;
				padding-right: 0;
				padding-top: rem(40);
			}

			.text-editor {
				margin: rem(40) 0;

				@include mediaBigDesktop {
					margin: big(40) 0;
				}

				@include mediaDesktop {
					margin: rem(32) 0;
				}

				@include mediaLaptop {
					margin: rem(24) 0;
				}

				@include mediaMobile {
					margin: rem(16) 0;
				}
			}
		}
	}

	&__img {
		flex: 1;
		margin: 0;
		padding: 0;
		align-self: flex-start;
		display: block;
		opacity: 0;

		@include mediaTablet {
			align-self: center;
		}

		picture {
			display: block;
			padding: 0;
			width: 100%;
		}

		img {
			max-width: 100%;
			width: 100%;
			height: auto;

			transform-origin: top;
			animation: anim_img_404 3s ease-in-out infinite;

			@include mediaTablet {
				max-width: rem(400);
			}

			@include mediaMobile {
				max-width: rem(270);
			}
		}
	}
}

.page-price {
	.page__title {
		@include mediaMobile {
			margin-bottom: rem(28);
		}
	}

	&__accordion-list {
		.block-accordion {
			&:first-child {
				.block-accordion__header {
					padding-top: 0;
				}
			}
		}
	}
}

.page-news {
	.page {
		&__title {
			margin-bottom: rem(40);

			@include mediaBigDesktop {
				margin-bottom: big(40);
			}

			@include mediaDesktop {
				margin-bottom: rem(32);
			}

			@include mediaLaptop {
				margin-bottom: rem(24);
			}

			@include mediaMobile {
				margin-bottom: rem(16);
			}
		}
	}

	&__post-info {
		display: flex;
		align-items: center;
		gap: rem(20);
		margin-bottom: var(--section-offset);

		@include mediaBigDesktop {
			gap: big(20);
		}

		@include mediaLaptop {
			gap: rem(16);
		}

		@include mediaTablet {
			gap: rem(12);
		}

		@include mediaMobile {
			flex-direction: column;
			align-items: flex-start;
		}
	}

	&__post-date {
		font: var(--font-body-M-caption);
		text-transform: uppercase;
		color: var(--color-text-dark-secondary);
		letter-spacing: -0.54px;

		@include mediaDesktop {
			letter-spacing: -0.42px;
		}

		@include mediaLaptop {
			letter-spacing: -0.36px;
		}
	}

	&__post-tags {
		display: flex;
		align-items: center;

		gap: rem(5);

		@include mediaBigDesktop {
			gap: big(5);
		}
	}

	&__post-link-source {
		margin-left: auto;
		color: var(--color-text-dark);
		text-underline-offset: rem(2);
		font: var(--font-link);

		@include mediaBigDesktop {
			text-underline-offset: big(2);
		}

		@include mediaMobile {
			margin-left: 0;
			margin-top: rem(8);
		}
	}

	&__post-image {
		display: block;
		font-size: 0;
		margin: var(--section-offset) 0;

		picture {
			display: block;
			width: 100%;
		}

		img {
			width: 100%;
			max-width: 100%;
			height: rem(500);
			object-fit: cover;

			@include mediaBigDesktop {
				height: big(500);
			}

			@include mediaLaptop {
				height: rem(400);
			}

			@include mediaTablet {
				height: rem(350);
			}

			@include mediaMobile {
				height: rem(250);
			}
		}
	}
}

.page-faq {
	.page {
		&__bottom {
			margin-top: 0;
			border: 0;
		}
	}

	#mse2_results {
		margin-bottom: 0;
	}

	&__accordion-list {
		.block-accordion {
			&:first-child {
				.block-accordion__header {
					padding-top: 0;
				}
			}
		}

		.block-accordion {
			&__content {
				max-width: rem(1078);

				@include mediaBigDesktop {
					max-width: big(1078);
				}

				@include mediaDesktop {
					max-width: rem(808);
				}

				@include mediaLaptop {
					max-width: rem(612);
				}

				@include mediaTablet {
					max-width: 100%;
				}
			}
		}
	}
}

.page-projects {
	&__list {
		.project-card {
			&:not(:last-child) {
				margin-bottom: rem(68);

				@include mediaBigDesktop {
					margin-bottom: big(68);
				}

				@include mediaTablet {
					margin-bottom: rem(24);
				}

				@include mediaMobile {
					margin-bottom: rem(16);
				}
			}

			&:nth-child(even) {
				flex-direction: row-reverse;

				@include mediaMobile {
					flex-direction: column;
				}
			}
		}
	}
}

.page-news-list {
	.page {
		&__row {
			padding-bottom: 0;

			@include mediaTablet {
				padding-bottom: rem(16);
			}

			@include mediaMobile {
				padding-bottom: rem(12);
			}
		}

		&__pagination {
			padding-top: 0;
		}
	}

	#mse2_results {
		margin-bottom: 0;
	}

	&__list {
		display: flex;
		flex-wrap: wrap;
		margin: 0 rem(-50);

		@include mediaBigDesktop {
			margin: 0 big(-50);
		}

		@include mediaDesktop {
			margin: 0 rem(-34);
		}

		@include mediaLaptop {
			margin: 0 rem(-24);
		}

		@include mediaTablet {
			margin: 0 rem(-12);
		}

		@include mediaMobile {
			margin: 0;
		}

		.news-card {
			width: calc(50% - #{rem(100)});
			margin: 0 rem(50);
			margin-bottom: var(--section-offset);

			@include mediaBigDesktop {
				width: calc(50% - #{big(100)});
				margin: 0 big(50);
				margin-bottom: var(--section-offset);
			}

			@include mediaDesktop {
				width: calc(50% - #{rem(68)});
				margin: 0 rem(34);
				margin-bottom: var(--section-offset);
			}

			@include mediaLaptop {
				width: calc(50% - #{rem(48)});
				margin: 0 rem(24);
				margin-bottom: var(--section-offset);
			}

			@include mediaTablet {
				width: calc(50% - #{rem(24)});
				margin: 0 rem(12);
				margin-bottom: rem(24);
			}

			@include mediaMobile {
				width: 100%;
				margin: 0;
				margin-bottom: rem(16);
			}
		}
	}
}

.page-reviews-list {
	background-color: var(--color-gray-3);
	padding-bottom: 0;

	.page {
		&__row {
			padding-bottom: 0;

			@include mediaTablet {
				padding-bottom: rem(16);
			}

			@include mediaMobile {
				padding-bottom: rem(12);
			}
		}

		&__pagination {
			padding-top: 0;
		}
	}

	&__list {
		display: flex;
		flex-wrap: wrap;
		margin: 0 rem(-50);

		@include mediaBigDesktop {
			margin: 0 big(-50);
		}

		@include mediaDesktop {
			margin: 0 rem(-34);
		}

		@include mediaLaptop {
			margin: 0 rem(-24);
		}

		@include mediaTablet {
			margin: 0;
		}

		.review-card {
			width: calc(50% - #{rem(100)});
			margin: 0 rem(50);
			margin-bottom: var(--section-offset);

			@include mediaBigDesktop {
				width: calc(50% - #{big(100)});
				margin: 0 big(50);
				margin-bottom: var(--section-offset);
			}

			@include mediaDesktop {
				width: calc(50% - #{rem(68)});
				margin: 0 rem(34);
				margin-bottom: var(--section-offset);
			}

			@include mediaLaptop {
				width: calc(50% - #{rem(48)});
				margin: 0 rem(24);
				margin-bottom: var(--section-offset);
			}

			@include mediaTablet {
				width: 100%;
				margin: 0;
				margin-bottom: rem(24);
			}
		}
	}
}

.page-contacts {
	.page__content {
		width: 100%;
	}

	&__tabs {
		width: 100%;
		overflow: hidden;
		padding-bottom: var(--section-offset);

		@include mediaMobile {
			padding-bottom: rem(40);
		}

		&-list {
			width: 100%;
			overflow: auto hidden;
			border-bottom: 1px solid var(--stroke-dark-secondary);

			&-wrap-scroll {
				display: flex;
				align-items: flex-end;
				gap: rem(40);

				@include mediaBigDesktop {
					gap: big(40);
				}

				@include mediaDesktop {
					gap: rem(32);
				}

				label {
					position: relative;
				}
			}

			.button-tab-line {
				margin-bottom: -1px;
				width: max-content;
			}
		}

		&-content {
			padding-top: var(--section-offset);

			@include mediaTablet {
				padding-top: rem(30);
			}

			.block-contact-full {
				display: none;

				&.js_active {
					display: flex;
				}
			}
		}
	}

	&__row {
		display: flex;
		padding-top: var(--section-offset);
		gap: var(--section-offset);

		@include mediaLaptop {
			padding-top: rem(48);
			gap: rem(68);
		}

		@include mediaTablet {
			padding-top: rem(40);
			flex-direction: column;
		}
	}

	&__information {
		flex: 1;

		@include mediaTablet {
			width: 100%;
		}

		.table-information {
			margin-top: var(--section-offset);
			width: 100%;
			border-collapse: collapse;
			font: var(--font-body-M);

			tr {
				border-bottom: 1px solid var(--stroke-dark-secondary);

				@include mediaMobile {
					display: flex;
					flex-direction: column;
					padding: rem(16) 0;
				}

				td {
					&:first-child {
						padding-left: 0;
						color: var(--color-text-dark-secondary);
						min-width: rem(290);

						@include mediaBigDesktop {
							min-width: big(290);
						}

						@include mediaDesktop {
							min-width: rem(225);
						}

						@include mediaLaptop {
							min-width: rem(170);
						}

						@include mediaTablet {
							min-width: rem(210);
						}

						@include mediaMobile {
							min-width: 100%;
							margin-bottom: rem(8);
						}
					}

					&:last-child {
						padding-right: 0;
					}
				}

				&:first-child {
					@include mediaMobile {
						padding-top: 0;
					}

					td {
						padding-top: 0;
					}
				}
			}

			td {
				padding: rem(40) rem(100);
				padding-right: 0;

				transition: background-color var(--anim-direction);

				@include mediaBigDesktop {
					padding: big(40) big(100);
					padding-right: 0;
				}

				@include mediaDesktop {
					padding: rem(32) rem(68);
					padding-right: 0;
				}

				@include mediaLaptop {
					padding: rem(24) rem(48);
					padding-right: 0;
				}

				@include mediaMobile {
					padding: 0;
				}
			}
		}

		.file-button {
			margin-top: rem(40);

			@include mediaBigDesktop {
				margin-top: big(40);
			}

			@include mediaDesktop {
				margin-top: rem(32);
			}

			@include mediaLaptop {
				margin-top: rem(24);
			}
		}
	}

	&__cta {
		flex: 1;

		@include mediaTablet {
			display: none;
		}

		.cta-form-light {
			position: sticky;
			top: 0;
			left: 0;
			background-color: var(--color-gray-3);
		}
	}
}

.page-services {
	.page-banner {
		background-color: var(--color-gray-3);
	}
}

.page-services-list {
	padding-bottom: 0;

	.page {
		&__row {
			padding-bottom: 0;
		}
	}

	&__list {
		display: flex;
		flex-wrap: wrap;
		margin: 0 rem(-50);

		@include mediaBigDesktop {
			margin: 0 big(-50);
		}

		@include mediaDesktop {
			margin: 0 rem(-34);
		}

		@include mediaLaptop {
			margin: 0 rem(-24);
		}

		@include mediaTablet {
			margin: 0 rem(-12);
		}

		@include mediaMobile {
			margin: 0;
		}

		.services-card {
			width: calc(25% - #{rem(100)});
			margin: 0 rem(50);
			margin-bottom: rem(100);

			@include mediaBigDesktop {
				width: calc(25% - #{big(100)});
				margin: 0 big(50);
				margin-bottom: big(100);
			}

			@include mediaDesktop {
				width: calc(25% - #{rem(68)});
				margin: 0 rem(34);
				margin-bottom: rem(68);
			}

			@include mediaLaptop {
				width: calc(25% - #{rem(48)});
				margin: 0 rem(24);
				margin-bottom: rem(48);
			}

			@include mediaTablet {
				width: calc(50% - #{rem(24)});
				margin: 0 rem(12);
				margin-bottom: rem(24);
			}

			@include mediaMobile {
				width: 100%;
				margin: 0;
				margin-bottom: rem(16);
			}
		}
	}

	.sec-cta {
		background-color: transparent;

		.cta-form-light {
			background-color: var(--color-gray-3);
		}
	}

	.sec-incoterms {
		@include mediaMobile {
			display: none;
		}
	}
}

.page-about {
	padding-bottom: 0;
}
