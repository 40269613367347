@font-face {
  font-family: 'Mulish';
  src: url("../fonts/Mulish/Mulish-Regular/Mulish-Regular.woff2") format("woff2");
  src: url("../fonts/Mulish/Mulish-Regular/Mulish-Regular.woff") format("woff");
  src: url("../fonts/Mulish/Mulish-Regular/Mulish-Regular.ttf") format("ttf");
  font-weight: 400;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Mulish';
  src: url("../fonts/Mulish/Mulish-Medium/Mulish-Medium.woff2") format("woff2");
  src: url("../fonts/Mulish/Mulish-Medium/Mulish-Medium.woff") format("woff");
  src: url("../fonts/Mulish/Mulish-Medium/Mulish-Medium.ttf") format("ttf");
  font-weight: 500;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Mulish';
  src: url("../fonts/Mulish/Mulish-SemiBold/Mulish-SemiBold.woff2") format("woff2");
  src: url("../fonts/Mulish/Mulish-SemiBold/Mulish-SemiBold.woff") format("woff");
  src: url("../fonts/Mulish/Mulish-SemiBold/Mulish-SemiBold.ttf") format("ttf");
  font-weight: 600;
  font-style: normal;
  font-display: swap; }

:root {
  --font-primary: "Mulish", sans-serif;
  --font-h1: 500 9.375rem/0.88 var(--font-primary);
  --font-h2: 500 5.3125rem/1.1 var(--font-primary);
  --font-h3: 500 3.5625rem/1.12 var(--font-primary);
  --font-h4: 500 2.1875rem/1.14 var(--font-primary);
  --font-h5: 500 1.6875rem/1.18 var(--font-primary);
  --font-body-M: 400 1.375rem/1.45 var(--font-primary);
  --font-body-M-semibold: 600 1.375rem/1.45 var(--font-primary);
  --font-body-M-caption: 400 1.125rem/1.33 var(--font-primary);
  --font-body-S: 400 1.125rem/1.33 var(--font-primary);
  --font-link: 400 1.375rem/1.45 var(--font-primary);
  --letter-spacing-h1: -4.5px;
  --letter-spacing-h2: -2.55px;
  --letter-spacing-h3: -1.71px;
  --letter-spacing-h4: -1.05px;
  --letter-spacing-h5: -0.81px;
  --text-spacing: -0.66px;
  --color-primary: #ae2631;
  --color-secondary: #2A67DD;
  --color-white: #fff;
  --color-dark: #363636;
  --color-gray: #F2F2F2;
  --color-gray-2: #AFAFAF;
  --color-gray-3: #F9F9F9;
  --color-gray-7: #EFEFEF;
  --color-text-dark: #363636;
  --color-text-dark-secondary: rgba(54, 54, 54, 0.40);
  --color-text-dark-disable: rgba(54, 54, 54, 0.20);
  --color-text-light: #FFFFFF;
  --color-text-light-secondary: rgba(255, 255, 255, 0.60);
  --color-text-light-disable: rgba(255, 255, 255, 0.40);
  --stroke-dark: #363636;
  --stroke-dark-secondary: rgba(0, 0, 0, 0.08);
  --stroke-light: #FFFFFF;
  --stroke-light-secondary: rgba(255, 255, 255, 0.15);
  --system-states-success: #40D157;
  --system-states-validation: #FFC700;
  --system-states-error: #D6252C;
  --anim-direction: 0.5s;
  --anim-cubic: cubic-bezier(.36, .3, 0, 1);
  --section-padding: 6.25rem 0;
  --section-offset: 6.25rem;
  --height-menu: 6.25rem; }
  @media screen and (min-width: 160.0625rem) {
    :root {
      --font-h1: 500 5.85938vw/0.88 var(--font-primary);
      --font-h2: 500 3.32031vw/1.1 var(--font-primary);
      --font-h3: 500 2.22656vw/1.12 var(--font-primary);
      --font-h4: 500 1.36719vw/1.14 var(--font-primary);
      --font-h5: 500 1.05469vw/1.18 var(--font-primary);
      --font-body-M: 400 0.85938vw/1.45 var(--font-primary);
      --font-body-M-semibold: 600 0.85938vw/1.45 var(--font-primary);
      --font-body-M-caption: 400 0.70312vw/1.33 var(--font-primary);
      --font-body-S: 400 0.70312vw/1.33 var(--font-primary);
      --font-link: 400 0.85938vw/1.45 var(--font-primary); } }
  @media screen and (max-width: 131.25rem) {
    :root {
      --font-h1: 500 7.5rem/0.93 var(--font-primary);
      --font-h2: 500 4.25rem/0.94 var(--font-primary);
      --font-h3: 500 2.875rem/1.13 var(--font-primary);
      --font-h4: 500 1.75rem/1.14 var(--font-primary);
      --font-h5: 500 1.375rem/1.27 var(--font-primary);
      --font-body-M: 400 1.125rem/1.44 var(--font-primary);
      --font-body-M-semibold: 600 1.125rem/1.44 var(--font-primary);
      --font-body-M-caption: 400 0.875rem/1.42 var(--font-primary);
      --font-body-S: 400 1.125rem/1.33 var(--font-primary);
      --font-link: 400 1.125rem/1.44 var(--font-primary);
      --letter-spacing-h1: -3.6px;
      --letter-spacing-h2: -2.04px;
      --letter-spacing-h3: -1.38px;
      --letter-spacing-h4: -0.84px;
      --letter-spacing-h5: -0.66px;
      --text-spacing: -0.54px; } }
  @media screen and (max-width: 103.125rem) {
    :root {
      --font-h1: 500 5rem/1.0 var(--font-primary);
      --font-h2: 500 3rem/0.91 var(--font-primary);
      --font-h3: 500 2.125rem/1.11 var(--font-primary);
      --font-h4: 500 1.25rem/1.2 var(--font-primary);
      --font-h5: 500 1.125rem/1.33 var(--font-primary);
      --font-body-M: 400 1rem/1.5 var(--font-primary);
      --font-body-M-semibold: 600 1rem/1.5 var(--font-primary);
      --font-body-M-caption: 400 0.75rem/1.33 var(--font-primary);
      --font-body-S: 400 0.875rem/1.28 var(--font-primary);
      --font-link: 400 1rem/1.5 var(--font-primary);
      --letter-spacing-h1: -2.4px;
      --letter-spacing-h2: -1.44px;
      --letter-spacing-h3: -1.02px;
      --letter-spacing-h4: -0.6px;
      --letter-spacing-h5: -0.54px;
      --text-spacing: -0.48px; } }
  @media screen and (max-width: 70.5rem) {
    :root {
      --font-h1: 500 3.25rem/1.0 var(--font-primary);
      --font-h2: 500 2.5rem/1.0 var(--font-primary);
      --font-h3: 500 1.75rem/1.14 var(--font-primary);
      --font-h4: 500 1.125rem/1.33 var(--font-primary);
      --font-h5: 500 1rem/1.25 var(--font-primary);
      --font-body-M: 400 0.875rem/1.42 var(--font-primary);
      --font-body-M-semibold: 600 0.875rem/1.42 var(--font-primary);
      --font-body-M-caption: 400 0.75rem/1.33 var(--font-primary);
      --font-body-S: 400 0.75rem/1.33 var(--font-primary);
      --font-link: 400 0.875rem/1.42 var(--font-primary);
      --letter-spacing-h1: -1.56px;
      --letter-spacing-h2: -1.2px;
      --letter-spacing-h3: -0.84px;
      --letter-spacing-h4: -0.54px;
      --letter-spacing-h5: -0.48px;
      --text-spacing: -0.42px; } }
  @media screen and (max-width: 45rem) {
    :root {
      --font-h1: 500 1.875rem/1.06 var(--font-primary);
      --font-h2: 500 1.5rem/1.08 var(--font-primary);
      --font-h3: 500 1.25rem/1.2 var(--font-primary);
      --font-h4: 500 1.125rem/1.33 var(--font-primary);
      --font-h5: 500 1rem/1.25 var(--font-primary);
      --font-body-M: 400 0.875rem/1.42 var(--font-primary);
      --font-body-M-semibold: 600 0.875rem/1.42 var(--font-primary);
      --font-body-M-caption: 400 0.75rem/1.33 var(--font-primary);
      --font-body-S: 400 0.75rem/1.33 var(--font-primary);
      --font-link: 400 0.875rem/1.42 var(--font-primary);
      --letter-spacing-h1: -0.9px;
      --letter-spacing-h2: -0.72px;
      --letter-spacing-h3: -0.6px;
      --letter-spacing-h4: -0.54px;
      --letter-spacing-h5: -0.48px; } }
  @media screen and (min-width: 160.0625rem) {
    :root {
      --section-padding: 3.90625vw 0;
      --section-offset: 3.90625vw; } }
  @media screen and (max-width: 131.25rem) {
    :root {
      --section-padding: 4.25rem 0;
      --section-offset: 4.25rem; } }
  @media screen and (max-width: 103.125rem) {
    :root {
      --section-padding: 3rem 0;
      --section-offset: 3rem; } }
  @media screen and (max-width: 70.5rem) {
    :root {
      --section-padding: 2.5rem 0;
      --section-offset: 2.5rem; } }
  @media screen and (max-width: 45rem) {
    :root {
      --section-padding: 2.5rem 0;
      --section-offset: 1.75rem; } }
  @media screen and (min-width: 160.0625rem) {
    :root {
      --height-menu: 3.90625vw; } }
  @media screen and (max-width: 131.25rem) {
    :root {
      --height-menu: 6rem; } }
  @media screen and (max-width: 103.125rem) {
    :root {
      --height-menu: 5rem; } }
  @media screen and (max-width: 70.5rem) {
    :root {
      --height-menu: 4rem; } }
  @media screen and (max-width: 45rem) {
    :root {
      --height-menu: 2.75rem; } }

* {
  box-sizing: border-box; }

::selection {
  background: var(--color-secondary);
  color: var(--color-text-light); }

::-moz-selection {
  background: var(--color-secondary);
  color: var(--color-text-light); }

img {
  max-width: 100%;
  height: auto; }

figure {
  margin: 0; }

label {
  display: inline-block;
  line-height: 0; }

.html {
  height: 100%;
  font-family: var(--font-primary);
  color: var(--text-primary);
  font-style: normal;
  font-weight: 400;
  -webkit-text-size-adjust: 100%; }

.main {
  height: 100%;
  display: flex;
  flex-direction: column; }

.disable-scroll {
  width: 100%;
  height: 100vh;
  overflow: hidden; }

.wrapper {
  min-height: 100vh;
  display: flex;
  align-items: stretch;
  flex-direction: column; }

.container {
  padding-right: 6.25rem;
  padding-left: 12.5rem; }
  @media screen and (min-width: 160.0625rem) {
    .container {
      padding-right: 3.90625vw;
      padding-left: 7.8125vw; } }
  @media screen and (max-width: 131.25rem) {
    .container {
      padding-right: 4.25rem;
      padding-left: 10.5rem; } }
  @media screen and (max-width: 103.125rem) {
    .container {
      padding-right: 3rem;
      padding-left: 7.5rem; } }
  @media screen and (max-width: 70.5rem) {
    .container {
      padding-right: 2.5rem;
      padding-left: 2.5rem; } }
  @media screen and (max-width: 45rem) {
    .container {
      padding-right: 1rem;
      padding-left: 1rem; } }

.footer {
  margin-top: auto; }

.input {
  -webkit-appearance: none;
  appearance: none;
  border: none;
  border-radius: 0;
  background-color: var(--color-white); }
  .input::-webkit-search-decoration, .input::-webkit-search-cancel-button, .input::-webkit-search-results-button, .input::-webkit-search-results-decoration {
    display: none; }
  .input::-webkit-outer-spin-button, .input::-webkit-inner-spin-button {
    -webkit-appearance: none; }
  .input[type=number] {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: textfield; }

.visually-hidden {
  position: absolute;
  overflow: hidden;
  margin: -1px;
  border: 0;
  padding: 0;
  width: 1px;
  height: 1px;
  clip: rect(0 0 0 0); }

.reset-styles {
  padding: 0;
  margin: 0; }

.text-underline {
  text-decoration: underline !important;
  text-underline-offset: 2px; }

@media screen and (max-width: 45rem) {
  table.bt tbody td {
    flex-direction: column; }
    table.bt tbody td:before {
      width: 100%;
      display: block;
      color: var(--color-text-dark-secondary);
      margin-bottom: 0.25rem; }
    table.bt tbody td .bt-content {
      width: 100%;
      display: block; }
  table.bt tbody tr td:not(:first-child) {
    padding-top: 0; } }

h1, .h1 {
  display: block;
  margin: 0;
  padding: 0;
  font: var(--font-h1);
  letter-spacing: var(--letter-spacing-h1); }

h2, .h2 {
  display: block;
  margin: 0;
  padding: 0;
  font: var(--font-h2);
  letter-spacing: var(--letter-spacing-h2); }

h3, .h3 {
  display: block;
  margin: 0;
  padding: 0;
  font: var(--font-h3);
  letter-spacing: var(--letter-spacing-h3); }

h4, .h4 {
  display: block;
  margin: 0;
  padding: 0;
  font: var(--font-h4);
  letter-spacing: var(--letter-spacing-h4); }

h5, .h5,
h6, .h6 {
  display: block;
  margin: 0;
  padding: 0;
  font: var(--font-h5);
  letter-spacing: var(--letter-spacing-h5); }

.header {
  position: fixed;
  top: 0;
  z-index: 5;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: var(--height-menu);
  width: calc(100% - 6.25rem);
  background: var(--color-white);
  align-self: flex-end;
  border-bottom: 1px solid var(--stroke-dark-secondary);
  border-left: 1px solid var(--stroke-dark-secondary);
  padding-left: 2.5rem;
  transition: top var(--anim-direction), background-color var(--anim-direction); }
  @media screen and (min-width: 160.0625rem) {
    .header {
      width: calc(100% - 3.90625vw);
      padding-left: 1.5625vw; } }
  @media screen and (max-width: 131.25rem) {
    .header {
      padding-left: 2rem; } }
  @media screen and (max-width: 103.125rem) {
    .header {
      width: calc(100% - 4.5rem);
      padding-left: 1.5rem; } }
  @media screen and (max-width: 70.5rem) {
    .header {
      padding-left: 0;
      border-left: 0;
      width: 100%;
      z-index: 15; } }
  .header__col {
    display: flex;
    align-items: center;
    height: 100%; }
    @media screen and (max-width: 45rem) {
      .header__col:first-child {
        flex: 1; } }
  .header__btn-menu {
    display: none;
    margin-right: 1.5rem; }
    @media screen and (max-width: 70.5rem) {
      .header__btn-menu {
        display: block; } }
    @media screen and (max-width: 45rem) {
      .header__btn-menu {
        margin-right: 1rem; } }
  .header__logo {
    font-size: 0; }
    .header__logo .logo__svg {
      height: 2.25rem; }
      @media screen and (min-width: 160.0625rem) {
        .header__logo .logo__svg {
          height: 1.40625vw; } }
      @media screen and (max-width: 70.5rem) {
        .header__logo .logo__svg {
          height: 1.625rem; } }
  .header__lang {
    margin-left: 2.5rem;
    padding-left: 2.5rem;
    border-left: 1px solid var(--stroke-dark-secondary); }
    @media screen and (min-width: 160.0625rem) {
      .header__lang {
        margin-left: 1.5625vw;
        padding-left: 1.5625vw; } }
    @media screen and (max-width: 131.25rem) {
      .header__lang {
        margin-left: 2rem;
        padding-left: 2rem; } }
    @media screen and (max-width: 103.125rem) {
      .header__lang {
        margin-left: 1.5rem;
        padding-left: 1.5rem; } }
    @media screen and (max-width: 45rem) {
      .header__lang {
        margin-left: auto;
        margin-right: 1rem;
        padding-left: 0;
        border-left: 0; } }
    .header__lang ul {
      display: flex;
      align-items: center;
      flex-direction: row-reverse;
      list-style-type: none;
      padding: 0;
      margin: 0; }
      @media screen and (max-width: 70.5rem) {
        .header__lang ul {
          height: 2rem; } }
      .header__lang ul li {
        line-height: 1; }
        .header__lang ul li:not(:last-child) {
          margin-left: 1.25rem; }
          @media screen and (min-width: 160.0625rem) {
            .header__lang ul li:not(:last-child) {
              margin-left: 0.78125vw; } }
          @media screen and (max-width: 131.25rem) {
            .header__lang ul li:not(:last-child) {
              margin-left: 1rem; } }
          @media screen and (max-width: 103.125rem) {
            .header__lang ul li:not(:last-child) {
              margin-left: 0.75rem; } }
      .header__lang ul a {
        font: var(--font-body-M-caption);
        color: var(--color-text-dark-secondary);
        text-transform: uppercase;
        text-decoration: none;
        line-height: 1.3;
        transition: color var(--anim-direction); }
        .header__lang ul a.active {
          color: var(--color-text-dark); }
        .header__lang ul a:not(.active):hover {
          color: var(--color-secondary); }
  .header__nav {
    margin-left: 2.5rem;
    margin-right: 2.5rem;
    padding-left: 2.5rem;
    border-left: 1px solid var(--stroke-dark-secondary); }
    @media screen and (min-width: 160.0625rem) {
      .header__nav {
        margin-left: 1.5625vw;
        margin-right: 1.5625vw;
        padding-left: 1.5625vw; } }
    @media screen and (max-width: 131.25rem) {
      .header__nav {
        margin-left: 2rem;
        margin-right: 2rem;
        padding-left: 2rem; } }
    @media screen and (max-width: 103.125rem) {
      .header__nav {
        display: none; } }
  .header__phone {
    font: var(--font-body-M-semibold);
    margin-right: 2rem; }
    @media screen and (min-width: 160.0625rem) {
      .header__phone {
        margin-right: 1.25vw; } }
    @media screen and (max-width: 103.125rem) {
      .header__phone {
        margin-right: 1.5rem; } }
    @media screen and (max-width: 45rem) {
      .header__phone {
        display: none; } }
    .header__phone a {
      color: var(--color-text-dark);
      text-decoration: none; }
  .header__social {
    display: flex;
    align-items: center;
    margin-right: 2.5rem; }
    @media screen and (min-width: 160.0625rem) {
      .header__social {
        margin-right: 1.5625vw; } }
    @media screen and (max-width: 131.25rem) {
      .header__social {
        margin-right: 2rem; } }
    @media screen and (max-width: 103.125rem) {
      .header__social {
        margin-right: 1.5rem; } }
    @media screen and (max-width: 70.5rem) {
      .header__social {
        padding-right: 0;
        border-right: 0; } }
    @media screen and (max-width: 45rem) {
      .header__social {
        display: none; } }
    .header__social .social-icon:not(:last-child) {
      margin-right: 1.5rem; }
      @media screen and (min-width: 160.0625rem) {
        .header__social .social-icon:not(:last-child) {
          margin-right: 0.9375vw; } }
      @media screen and (max-width: 131.25rem) {
        .header__social .social-icon:not(:last-child) {
          margin-right: 1.25rem; } }
  .header__btns {
    display: flex;
    align-items: center;
    margin-bottom: -1px;
    height: calc(100% + 1px);
    position: relative;
    z-index: 2; }
    @media screen and (max-width: 70.5rem) {
      .header__btns {
        margin-bottom: 0; } }
    @media screen and (max-width: 70.5rem) {
      .header__btns .text-button {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 4rem;
        height: 4rem;
        font-size: 0;
        border-left: 1px solid var(--stroke-dark-secondary); } }
    @media screen and (max-width: 45rem) {
      .header__btns .text-button {
        width: 2.75rem;
        height: 2.75rem; } }
    @media screen and (max-width: 70.5rem) {
      .header__btns .text-button__text {
        display: none; } }
    @media screen and (max-width: 70.5rem) {
      .header__btns .text-button__icon {
        width: 1.5rem;
        height: 1.5rem; } }
    @media screen and (max-width: 45rem) {
      .header__btns .text-button__icon {
        width: 1.25rem;
        height: 1.25rem; } }
    .header__btns .button:not(.btn-phone) {
      height: 100%;
      margin-left: 2.5rem; }
      @media screen and (min-width: 160.0625rem) {
        .header__btns .button:not(.btn-phone) {
          margin-left: 1.5625vw; } }
      @media screen and (max-width: 131.25rem) {
        .header__btns .button:not(.btn-phone) {
          margin-left: 2rem; } }
      @media screen and (max-width: 103.125rem) {
        .header__btns .button:not(.btn-phone) {
          margin-left: 1.5rem; } }
      @media screen and (max-width: 70.5rem) {
        .header__btns .button:not(.btn-phone) {
          display: none; } }
    .header__btns .btn-phone {
      display: none;
      align-items: center;
      justify-content: center;
      width: 4rem;
      height: 4rem;
      padding: 0; }
      @media screen and (max-width: 70.5rem) {
        .header__btns .btn-phone {
          display: inline-flex; } }
      @media screen and (max-width: 45rem) {
        .header__btns .btn-phone {
          width: 2.75rem;
          height: 2.75rem; } }
      .header__btns .btn-phone .button__icon {
        margin: 0;
        align-self: center;
        transform: none; }
        @media screen and (max-width: 70.5rem) {
          .header__btns .btn-phone .button__icon {
            width: 1.5rem;
            height: 1.5rem; } }
        @media screen and (max-width: 45rem) {
          .header__btns .btn-phone .button__icon {
            width: 1.25rem;
            height: 1.25rem; } }
      .header__btns .btn-phone:hover:not(:disabled):not(.disabled) .button__icon {
        transform: none; }
  .header_opacity {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 10;
    background: transparent;
    border-left: transparent;
    border-color: var(--stroke-light-secondary); }
    .header_opacity .header__lang {
      border-color: var(--stroke-light-secondary); }
      .header_opacity .header__lang ul a {
        color: var(--color-text-light-secondary); }
        .header_opacity .header__lang ul a.active {
          color: var(--color-white); }
        .header_opacity .header__lang ul a:not(.active):hover {
          color: var(--color-white); }
    .header_opacity .header__nav {
      border-color: var(--stroke-light-secondary); }
    .header_opacity .header__social {
      border-color: var(--stroke-light-secondary); }
    .header_opacity .header__phone a {
      color: var(--color-white); }
    @media screen and (max-width: 70.5rem) {
      .header_opacity .header__btns .text-button {
        border-color: var(--stroke-light-secondary); } }
    .header_opacity .text-button {
      color: var(--color-white); }
      .header_opacity .text-button__icon {
        fill: var(--color-white); }
    .header_opacity .icon-button_stroke {
      background: transparent;
      border-color: var(--stroke-light-secondary); }
      .header_opacity .icon-button_stroke .icon-button__icon {
        fill: var(--color-white); }
    .header_opacity .main-menu__link {
      color: var(--color-primary); }
      .header_opacity .main-menu__link:hover {
        color: var(--color-primary); }
    .header_opacity .main-menu__item_arrow:before, .header_opacity .main-menu__item_arrow:after {
      background: var(--color-primary); }
    .header_opacity .main-menu__item_arrow:hover .main-menu__link {
      color: var(--color-primary); }
    .header_opacity .main-menu__item_arrow:hover:before, .header_opacity .main-menu__item_arrow:hover:after {
      background: var(--color-primary); }
    .header_opacity .social-icon__icon {
      fill: var(--color-white); }
    .header_opacity .button_primary .button-hover-effects {
      background: var(--color-white); }
    .header_opacity .button_primary:hover {
      color: var(--color-primary); }
      .header_opacity .button_primary:hover svg {
        fill: var(--color-primary); }
        @media screen and (max-width: 70.5rem) {
          .header_opacity .button_primary:hover svg {
            fill: var(--color-white); } }
    .header_opacity .header-btn-menu {
      border-color: var(--stroke-light-secondary); }
      .header_opacity .header-btn-menu:before, .header_opacity .header-btn-menu:after {
        background: var(--color-white); }
  .header_fixed {
    top: 0;
    position: fixed;
    width: 100%;
    padding-left: 8.75rem;
    background-color: var(--color-white);
    border-color: var(--stroke-dark-secondary); }
    @media screen and (min-width: 160.0625rem) {
      .header_fixed {
        padding-left: 5.46875vw; } }
    @media screen and (max-width: 131.25rem) {
      .header_fixed {
        padding-left: 8.25rem; } }
    @media screen and (max-width: 103.125rem) {
      .header_fixed {
        width: 100%;
        padding-left: 6rem; } }
    @media screen and (max-width: 70.5rem) {
      .header_fixed {
        padding-left: 0; } }
    .header_fixed .header__lang {
      border-color: var(--stroke-dark-secondary); }
      .header_fixed .header__lang ul a {
        color: var(--color-text-dark-secondary); }
        .header_fixed .header__lang ul a.active {
          color: var(--color-text-dark); }
        .header_fixed .header__lang ul a:not(.active):hover {
          color: var(--color-secondary); }
    .header_fixed .header__nav {
      border-color: var(--stroke-dark-secondary); }
    .header_fixed .header__social {
      border-color: var(--stroke-dark-secondary); }
    .header_fixed .header__phone a {
      color: var(--color-text-dark); }
    .header_fixed .header__btns .text-button {
      border-color: var(--stroke-dark-secondary); }
    .header_fixed .text-button {
      color: var(--color-secondary); }
      .header_fixed .text-button__icon {
        fill: var(--color-secondary); }
    .header_fixed .icon-button {
      background: transparent;
      border-color: var(--stroke-dark-secondary); }
      .header_fixed .icon-button__icon {
        fill: var(--color-dark); }
      .header_fixed .icon-button_stroke .icon-button__icon {
        fill: var(--color-dark); }
    .header_fixed .logo__svg-icon {
      fill: var(--color-primary); }
    .header_fixed .logo__svg-name {
      fill: var(--color-dark); }
    .header_fixed .main-menu__link {
      color: var(--color-primary); }
      .header_fixed .main-menu__link:hover {
        color: var(--color-primary); }
    .header_fixed .main-menu__item_arrow:before, .header_fixed .main-menu__item_arrow:after {
      background: var(--color-primary); }
    .header_fixed .main-menu__item_arrow:hover .main-menu__link {
      color: var(--color-primary); }
    .header_fixed .main-menu__item_arrow:hover:before, .header_fixed .main-menu__item_arrow:hover:after {
      background: var(--color-primary); }
    .header_fixed .social-icon__icon {
      fill: var(--color-text-dark); }
    .header_fixed .button_primary .button-hover-effects {
      background: var(--color-text-dark); }
    .header_fixed .button_primary:hover {
      color: var(--color-white); }
      .header_fixed .button_primary:hover svg {
        fill: var(--color-white); }
    .header_fixed .header-btn-menu {
      border-color: var(--stroke-dark-secondary); }
      .header_fixed .header-btn-menu:before, .header_fixed .header-btn-menu:after {
        background: var(--stroke-dark); }
  @media screen and (max-width: 70.5rem) {
    .header_active {
      z-index: 20;
      background-color: var(--color-white);
      border-color: var(--stroke-dark-secondary); }
      .header_active .header__lang {
        border-color: var(--stroke-dark-secondary); }
        .header_active .header__lang ul a {
          color: var(--color-text-dark-secondary); }
          .header_active .header__lang ul a.active {
            color: var(--color-text-dark); }
      .header_active .header__nav {
        border-color: var(--stroke-dark-secondary); }
      .header_active .header__social {
        border-color: var(--stroke-dark-secondary); }
      .header_active .header__phone a {
        color: var(--color-text-dark); }
      .header_active .header__btns .text-button {
        border-color: var(--stroke-dark-secondary); }
      .header_active .text-button {
        color: var(--color-secondary); }
        .header_active .text-button__icon {
          fill: var(--color-secondary); }
      .header_active .icon-button {
        background: transparent;
        border-color: var(--stroke-dark-secondary); }
        .header_active .icon-button__icon {
          fill: var(--color-dark); }
        .header_active .icon-button_stroke .icon-button__icon {
          fill: var(--color-dark); }
      .header_active .logo__svg-icon {
        fill: var(--color-primary); }
      .header_active .logo__svg-name {
        fill: var(--color-dark); }
      .header_active .main-menu__link {
        color: var(--color-text-dark); }
        .header_active .main-menu__link:hover {
          color: var(--color-text-dark); }
      .header_active .main-menu__item_arrow:before, .header_active .main-menu__item_arrow:after {
        background: var(--color-text-dark-secondary); }
      .header_active .main-menu__item_arrow:hover .main-menu__link {
        color: var(--color-text-dark); }
      .header_active .main-menu__item_arrow:hover:before, .header_active .main-menu__item_arrow:hover:after {
        background: var(--color-text-dark); }
      .header_active .social-icon__icon {
        fill: var(--color-text-dark); }
      .header_active .button_primary .button-hover-effects {
        background: var(--color-text-dark); }
      .header_active .button_primary:hover {
        color: var(--color-white); }
        .header_active .button_primary:hover svg {
          fill: var(--color-white); }
      .header_active .header-btn-menu {
        border-color: var(--stroke-dark-secondary); }
        .header_active .header-btn-menu:before, .header_active .header-btn-menu:after {
          background: var(--stroke-dark); } }
  .header_hidden {
    top: -9.375rem; }
    @media screen and (min-width: 160.0625rem) {
      .header_hidden {
        top: -5.85938vw; } }

.logo {
  display: inline-block; }
  .logo__link {
    display: inline-block;
    text-decoration: none;
    font-size: 0; }
  .logo__svg {
    width: auto;
    height: 2.125rem; }
    @media screen and (min-width: 160.0625rem) {
      .logo__svg {
        height: 1.32812vw; } }
    @media screen and (max-width: 103.125rem) {
      .logo__svg {
        height: 1.5rem; } }
    .logo__svg-icon {
      fill: var(--color-primary); }
    .logo__svg-name {
      fill: var(--color-dark); }

.main-menu {
  display: flex;
  align-items: center;
  list-style-type: none;
  padding: 0;
  margin: 0; }
  .main-menu__item:not(:last-child) {
    margin-right: 2.5rem; }
    @media screen and (min-width: 160.0625rem) {
      .main-menu__item:not(:last-child) {
        margin-right: 1.5625vw; } }
    @media screen and (max-width: 131.25rem) {
      .main-menu__item:not(:last-child) {
        margin-right: 2rem; } }
  .main-menu__item:hover .main-menu__sub-menu {
    opacity: 1;
    visibility: visible;
    pointer-events: all; }
  .main-menu__item_arrow {
    position: relative;
    padding-right: 1.5625rem; }
    @media screen and (min-width: 160.0625rem) {
      .main-menu__item_arrow {
        padding-right: 0.97656vw; } }
    .main-menu__item_arrow:before, .main-menu__item_arrow:after {
      content: "";
      position: absolute;
      z-index: 2;
      top: calc(50% - 0.0625rem);
      right: 0;
      display: block;
      width: 0.4375rem;
      height: 1px;
      background: var(--color-primary);
      transition: transform var(--anim-direction); }
      @media screen and (min-width: 160.0625rem) {
        .main-menu__item_arrow:before, .main-menu__item_arrow:after {
          top: 0.58594vw;
          width: 0.27344vw; } }
    .main-menu__item_arrow:before {
      transform: rotate(45deg);
      right: 0.25rem; }
      @media screen and (min-width: 160.0625rem) {
        .main-menu__item_arrow:before {
          right: 0.15625vw; } }
    .main-menu__item_arrow:after {
      transform: rotate(-45deg); }
    .main-menu__item_arrow:hover:before {
      transform: rotate(-45deg); }
    .main-menu__item_arrow:hover:after {
      transform: rotate(45deg); }
  .main-menu__item_active .main-menu__link {
    color: var(--color-secondary); }
  .main-menu__link {
    font: var(--font-body-M-caption);
    color: var(--color-primary);
    text-transform: uppercase;
    text-decoration: none;
    line-height: 2rem;
    transition: color var(--anim-direction); }
    @media screen and (min-width: 160.0625rem) {
      .main-menu__link {
        line-height: 1.25vw; } }
    @media screen and (max-width: 131.25rem) {
      .main-menu__link {
        line-height: 1.5rem; } }
    @media screen and (max-width: 70.5rem) {
      .main-menu__link {
        line-height: 1.25rem; } }
  .main-menu__sub-menu {
    display: block;
    position: absolute;
    left: -3rem;
    top: calc(100% + 0.1875rem);
    z-index: 5;
    margin-top: 2.25rem;
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    transition: opacity var(--anim-direction), visibility var(--anim-direction); }
    @media screen and (min-width: 160.0625rem) {
      .main-menu__sub-menu {
        left: -1.875vw;
        margin-top: 1.40625vw;
        top: calc(100% + 0.11719vw); } }
    @media screen and (max-width: 131.25rem) {
      .main-menu__sub-menu {
        left: -2rem;
        top: 100%; } }
    .main-menu__sub-menu:before {
      content: "";
      display: block;
      width: 100%;
      height: 2.4375rem;
      background: transparent;
      position: absolute;
      left: 0;
      top: -2.4375rem; }
      @media screen and (min-width: 160.0625rem) {
        .main-menu__sub-menu:before {
          height: 1.52344vw;
          top: -1.52344vw; } }

.social-icon {
  display: inline-block;
  text-decoration: none;
  font-size: 0; }
  .social-icon__icon {
    width: 2rem;
    height: 2rem;
    fill: var(--color-dark); }
    @media screen and (min-width: 160.0625rem) {
      .social-icon__icon {
        width: 1.25vw;
        height: 1.25vw; } }
    @media screen and (max-width: 131.25rem) {
      .social-icon__icon {
        width: 1.5rem;
        height: 1.5rem; } }

.header-btn-menu {
  position: relative;
  display: block;
  width: 3rem;
  height: 3rem;
  margin: 0;
  padding: 0;
  cursor: pointer;
  background: transparent;
  border: 0;
  border-right: 1px solid var(--stroke-dark-secondary); }
  @media screen and (min-width: 160.0625rem) {
    .header-btn-menu {
      width: 1.875vw;
      height: 1.875vw; } }
  @media screen and (max-width: 103.125rem) {
    .header-btn-menu {
      width: 2.5rem;
      height: 2.5rem; } }
  @media screen and (max-width: 70.5rem) {
    .header-btn-menu {
      width: 4rem;
      height: 4rem; } }
  @media screen and (max-width: 45rem) {
    .header-btn-menu {
      width: 2.75rem;
      height: 2.75rem; } }
  .header-btn-menu:before, .header-btn-menu:after {
    content: "";
    position: absolute;
    left: 0.375rem;
    z-index: 2;
    display: block;
    width: 2.25rem;
    height: 0.125rem;
    background: var(--color-dark);
    transition: top var(--anim-direction), transform var(--anim-direction); }
    @media screen and (min-width: 160.0625rem) {
      .header-btn-menu:before, .header-btn-menu:after {
        left: 0.23438vw;
        width: 1.40625vw;
        height: 0.07812vw; } }
    @media screen and (max-width: 103.125rem) {
      .header-btn-menu:before, .header-btn-menu:after {
        left: 0.3125rem;
        width: 1.875rem; } }
    @media screen and (max-width: 70.5rem) {
      .header-btn-menu:before, .header-btn-menu:after {
        left: 1.375rem;
        width: 1.25rem; } }
    @media screen and (max-width: 45rem) {
      .header-btn-menu:before, .header-btn-menu:after {
        left: 0.75rem; } }
  .header-btn-menu:before {
    top: calc(50% - 0.3125rem); }
    @media screen and (min-width: 160.0625rem) {
      .header-btn-menu:before {
        top: calc(50% - 0.19531vw); } }
    @media screen and (max-width: 131.25rem) {
      .header-btn-menu:before {
        top: calc(50% - 0.3125rem); } }
    @media screen and (max-width: 70.5rem) {
      .header-btn-menu:before {
        top: calc(50% - 0.1875rem); } }
    @media screen and (max-width: 45rem) {
      .header-btn-menu:before {
        top: calc(50% - 0.25rem); } }
  .header-btn-menu:after {
    top: 50%; }
    @media screen and (min-width: 160.0625rem) {
      .header-btn-menu:after {
        top: 50%; } }
    @media screen and (max-width: 131.25rem) {
      .header-btn-menu:after {
        top: calc(50% + 0.0625rem); } }
    @media screen and (max-width: 70.5rem) {
      .header-btn-menu:after {
        top: calc(50% + 0.0625rem); } }
    @media screen and (max-width: 45rem) {
      .header-btn-menu:after {
        top: calc(50% + 0.0625rem); } }
  .header-btn-menu.js_active:before {
    top: calc(50% - 2px);
    transform: rotate(45deg); }
    @media screen and (max-width: 45rem) {
      .header-btn-menu.js_active:before {
        top: calc(50% - 1px); } }
  .header-btn-menu.js_active:after {
    top: calc(50% - 2px);
    transform: rotate(-45deg); }
    @media screen and (max-width: 45rem) {
      .header-btn-menu.js_active:after {
        top: calc(50% - 1px); } }

.sub-menu {
  list-style-type: none;
  background: var(--color-white);
  border: 1px solid var(--stroke-dark-secondary);
  border-top: 0;
  min-width: 25.625rem;
  padding: 3rem; }
  @media screen and (min-width: 160.0625rem) {
    .sub-menu {
      min-width: 16.01562vw;
      padding: 1.875vw; } }
  @media screen and (max-width: 131.25rem) {
    .sub-menu {
      min-width: 19.375rem;
      padding: 2rem; } }
  .sub-menu__item:not(:last-child) {
    margin-bottom: 1.5rem; }
    @media screen and (min-width: 160.0625rem) {
      .sub-menu__item:not(:last-child) {
        margin-bottom: 0.9375vw; } }
    @media screen and (max-width: 131.25rem) {
      .sub-menu__item:not(:last-child) {
        margin-bottom: 0.78125vw; } }
  .sub-menu__item a {
    font: var(--font-body-M-caption);
    color: var(--color-dark);
    text-decoration: none;
    text-transform: uppercase;
    transition: color var(--anim-direction); }
    .sub-menu__item a:hover {
      color: var(--color-primary); }

.header-scroll-panel {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 15;
  width: 6.25rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem 1.625rem;
  background: transparent;
  color: var(--color-text-dark);
  border: 0;
  border-right: 1px solid var(--stroke-dark-secondary);
  cursor: pointer;
  transition: filter var(--anim-direction), color var(--anim-direction);
  filter: invert(100%); }
  @media screen and (min-width: 160.0625rem) {
    .header-scroll-panel {
      width: 3.90625vw;
      padding: 1.25vw 1.01562vw; } }
  @media screen and (max-width: 131.25rem) {
    .header-scroll-panel {
      padding: 1.625rem; } }
  @media screen and (max-width: 103.125rem) {
    .header-scroll-panel {
      padding: 1.25rem 1rem;
      width: 4.5rem; } }
  @media screen and (max-width: 70.5rem) {
    .header-scroll-panel {
      display: none; } }
  .header-scroll-panel__btns {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 2;
    padding: 2rem 1.625rem;
    width: 100%; }
    @media screen and (min-width: 160.0625rem) {
      .header-scroll-panel__btns {
        padding: 1.25vw 1.01562vw; } }
    @media screen and (max-width: 131.25rem) {
      .header-scroll-panel__btns {
        padding: 1.625rem; } }
    @media screen and (max-width: 103.125rem) {
      .header-scroll-panel__btns {
        padding: 1.25rem 1rem; } }
    .header-scroll-panel__btns .header-btn-menu {
      width: 3rem;
      height: 3rem;
      border: 0; }
      @media screen and (min-width: 160.0625rem) {
        .header-scroll-panel__btns .header-btn-menu {
          width: 1.875vw;
          height: 1.875vw; } }
      @media screen and (max-width: 103.125rem) {
        .header-scroll-panel__btns .header-btn-menu {
          width: 2.5rem;
          height: 2.5rem; } }
  .header-scroll-panel__text {
    position: relative;
    opacity: 1;
    transform: rotate(-90deg) translate(-2.1875rem, 0);
    font: var(--font-body-M-caption);
    text-transform: uppercase;
    line-height: 1.75rem;
    height: 1.75rem; }
    @media screen and (min-width: 160.0625rem) {
      .header-scroll-panel__text {
        line-height: 1.09375vw;
        height: 1.09375vw; } }
    @media screen and (max-width: 131.25rem) {
      .header-scroll-panel__text {
        line-height: 1.375rem;
        height: 1.375rem; } }
    @media screen and (max-width: 70.5rem) {
      .header-scroll-panel__text {
        line-height: 1.25rem;
        height: 1.25rem; } }
    .header-scroll-panel__text-open, .header-scroll-panel__text-close {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      z-index: 2;
      text-align: center; }
    .header-scroll-panel__text-close {
      opacity: 0;
      display: none; }
  .header-scroll-panel__icon {
    position: absolute;
    bottom: 2.5rem;
    width: 1.5rem;
    height: 1.5rem;
    fill: #000;
    opacity: 1;
    transition: opacity var(--anim-direction); }
    @media screen and (min-width: 160.0625rem) {
      .header-scroll-panel__icon {
        width: 0.9375vw;
        height: 0.9375vw; } }
  .header-scroll-panel:hover .header-scroll-panel__text-open:after,
  .header-scroll-panel:hover .header-scroll-panel__text-open span {
    transform: translateY(-100%);
    opacity: 1; }
  .header-scroll-panel:hover .header-scroll-panel__text-close:after,
  .header-scroll-panel:hover .header-scroll-panel__text-close span {
    transform: translateY(-100%);
    opacity: 1; }

.manu-panel {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 10;
  height: 100%;
  visibility: hidden;
  transition: visibility var(--anim-direction); }
  @media screen and (max-width: 70.5rem) {
    .manu-panel {
      top: 4rem;
      width: 23.25rem;
      height: calc(100% - 4rem); } }
  @media screen and (max-width: 45rem) {
    .manu-panel {
      top: 2.75rem;
      width: 100%;
      height: calc(100% - 2.75rem); } }
  .manu-panel__content {
    position: relative;
    z-index: 5;
    display: flex;
    flex-direction: column;
    width: auto;
    height: 100%;
    background-color: var(--color-white);
    color: var(--color-dark);
    clip-path: polygon(0 0, 0 0, 0 100%, 0% 100%);
    padding-left: 6.25rem; }
    @media screen and (min-width: 160.0625rem) {
      .manu-panel__content {
        padding-left: 3.90625vw; } }
    @media screen and (max-width: 131.25rem) {
      .manu-panel__content {
        padding-left: 6.25rem; } }
    @media screen and (max-width: 103.125rem) {
      .manu-panel__content {
        padding-left: 4.5rem; } }
    @media screen and (max-width: 70.5rem) {
      .manu-panel__content {
        padding: 2.5rem;
        width: 100%;
        overflow: auto; } }
    @media screen and (max-width: 45rem) {
      .manu-panel__content {
        padding: 2.5rem 1rem 1.25rem; } }
    .manu-panel__content .big-menu {
      padding: var(--section-offset);
      flex: 1;
      width: 36.6875rem;
      border-right: 1px solid var(--stroke-dark-secondary); }
      @media screen and (min-width: 160.0625rem) {
        .manu-panel__content .big-menu {
          width: 22.92969vw; } }
      @media screen and (max-width: 131.25rem) {
        .manu-panel__content .big-menu {
          width: 27.375rem; } }
      @media screen and (max-width: 103.125rem) {
        .manu-panel__content .big-menu {
          width: 20.625rem; } }
      @media screen and (max-width: 70.5rem) {
        .manu-panel__content .big-menu {
          width: 100%;
          padding: 0;
          border: 0; } }
      .manu-panel__content .big-menu__item {
        opacity: 0; }
  .manu-panel__footer {
    margin-top: auto;
    padding: var(--section-offset);
    opacity: 0; }
    @media screen and (max-width: 70.5rem) {
      .manu-panel__footer {
        padding: 0;
        padding-top: 2.5rem; } }
    .manu-panel__footer .contact-block:not(:last-child) {
      margin-bottom: 2.5rem; }
      @media screen and (min-width: 160.0625rem) {
        .manu-panel__footer .contact-block:not(:last-child) {
          margin-bottom: 1.5625vw; } }
      @media screen and (max-width: 131.25rem) {
        .manu-panel__footer .contact-block:not(:last-child) {
          margin-bottom: 2rem; } }
      @media screen and (max-width: 103.125rem) {
        .manu-panel__footer .contact-block:not(:last-child) {
          margin-bottom: 1.5rem; } }
      @media screen and (max-width: 45rem) {
        .manu-panel__footer .contact-block:not(:last-child) {
          margin-bottom: 1rem; } }
    .manu-panel__footer .contact-block.show_mobile {
      display: none; }
      @media screen and (max-width: 45rem) {
        .manu-panel__footer .contact-block.show_mobile {
          display: block; } }
  .manu-panel__overlay {
    position: fixed;
    left: 0;
    top: 0;
    z-index: 2;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    opacity: 0; }

.big-menu {
  list-style-type: none;
  padding: 0;
  margin: 0; }
  .big-menu__item {
    display: block;
    width: 100%; }
    .big-menu__item:not(:last-child) {
      margin-bottom: 2.5rem; }
      @media screen and (min-width: 160.0625rem) {
        .big-menu__item:not(:last-child) {
          margin-bottom: 1.5625vw; } }
      @media screen and (max-width: 131.25rem) {
        .big-menu__item:not(:last-child) {
          margin-bottom: 2rem; } }
      @media screen and (max-width: 103.125rem) {
        .big-menu__item:not(:last-child) {
          margin-bottom: 1.25rem; } }
      @media screen and (max-width: 70.5rem) {
        .big-menu__item:not(:last-child) {
          margin-bottom: 1.5rem; } }
      @media screen and (max-width: 45rem) {
        .big-menu__item:not(:last-child) {
          margin-bottom: 1rem; } }
    .big-menu__item_arrow {
      position: relative;
      padding-right: 3.125rem; }
      @media screen and (min-width: 160.0625rem) {
        .big-menu__item_arrow {
          padding-right: 1.95312vw; } }
      @media screen and (max-width: 70.5rem) {
        .big-menu__item_arrow {
          padding-right: 0; }
          .big-menu__item_arrow .big-menu__link {
            display: inline-block;
            margin-right: 3.125rem; } }
      .big-menu__item_arrow svg {
        width: 2.5rem;
        height: 2.5rem;
        padding: 0.25rem;
        position: absolute;
        right: 0;
        top: 0;
        fill: var(--color-gray-2); }
        @media screen and (min-width: 160.0625rem) {
          .big-menu__item_arrow svg {
            width: 1.5625vw;
            height: 1.5625vw;
            padding: 0.15625vw; } }
        @media screen and (max-width: 131.25rem) {
          .big-menu__item_arrow svg {
            width: 2rem;
            height: 2rem; } }
        @media screen and (max-width: 103.125rem) {
          .big-menu__item_arrow svg {
            width: 1.5rem;
            height: 1.5rem;
            padding: 0; } }
        @media screen and (max-width: 70.5rem) {
          .big-menu__item_arrow svg {
            transform: rotate(90deg); } }
      .big-menu__item_arrow:after {
        content: "";
        display: block;
        width: var(--section-offset);
        height: 100%;
        position: absolute;
        right: -6.25rem;
        top: 0; }
        @media screen and (min-width: 160.0625rem) {
          .big-menu__item_arrow:after {
            right: -3.90625vw; } }
        @media screen and (max-width: 131.25rem) {
          .big-menu__item_arrow:after {
            right: -4.25rem; } }
        @media screen and (max-width: 103.125rem) {
          .big-menu__item_arrow:after {
            right: -3rem; } }
        @media screen and (max-width: 70.5rem) {
          .big-menu__item_arrow:after {
            content: none; } }
      @media (any-hover: hover) {
        .big-menu__item_arrow:hover .big-menu__link {
          color: var(--color-secondary); }
        .big-menu__item_arrow:hover svg {
          fill: var(--color-secondary); }
        .big-menu__item_arrow:hover .big-sub-menu {
          visibility: visible;
          clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%); } }
  @media screen and (any-hover: hover) and (max-width: 70.5rem) {
    .big-menu__item_arrow:hover .big-sub-menu {
      display: block; } }
    .big-menu__item .big-sub-menu {
      position: fixed;
      top: 0;
      left: calc(36.6875rem + 6.125rem);
      z-index: 2;
      height: 100%;
      visibility: hidden;
      clip-path: polygon(0 0, 0 0, 0 100%, 0% 100%);
      border-left: 1px solid var(--stroke-dark-secondary);
      border-right: 1px solid var(--stroke-dark-secondary);
      transition: clip-path var(--anim-direction), visibility var(--anim-direction); }
      @media screen and (min-width: 160.0625rem) {
        .big-menu__item .big-sub-menu {
          left: calc(22.92969vw + 3.82813vw); } }
      @media screen and (max-width: 131.25rem) {
        .big-menu__item .big-sub-menu {
          left: calc(27.375rem + 6.125rem); } }
      @media screen and (max-width: 103.125rem) {
        .big-menu__item .big-sub-menu {
          left: calc(20.625rem + 4.375rem); } }
      @media screen and (max-width: 70.5rem) {
        .big-menu__item .big-sub-menu {
          position: static;
          border: 0;
          border-top: 1px solid var(--stroke-dark-secondary);
          margin-top: 1.5rem;
          clip-path: none;
          display: none;
          visibility: visible; } }
      @media screen and (max-width: 45rem) {
        .big-menu__item .big-sub-menu {
          margin-top: 1rem; } }
    @media screen and (max-width: 70.5rem) {
      .big-menu__item.js_active .big-menu__link {
        color: var(--color-secondary); }
      .big-menu__item.js_active svg {
        fill: var(--color-secondary); }
      .big-menu__item.js_active svg {
        transform: rotate(270deg); }
      .big-menu__item.js_active .big-sub-menu {
        display: block;
        visibility: visible; } }
  .big-menu__link {
    font: var(--font-h4);
    letter-spacing: var(--letter-spacing-h4);
    color: var(--color-text-dark);
    text-decoration: none;
    transition: color var(--anim-direction); }
    .big-menu__link:hover {
      color: var(--color-secondary); }

.big-sub-menu {
  list-style-type: none;
  margin: 0;
  padding: var(--section-offset);
  background-color: var(--color-white); }
  @media screen and (max-width: 70.5rem) {
    .big-sub-menu {
      background-color: transparent;
      padding: 1.5rem 0; } }
  @media screen and (max-width: 45rem) {
    .big-sub-menu {
      padding: 1rem 0; } }
  .big-sub-menu__item {
    display: block;
    width: 100%; }
    .big-sub-menu__item:not(:last-child) {
      margin-bottom: 1.5rem; }
      @media screen and (min-width: 160.0625rem) {
        .big-sub-menu__item:not(:last-child) {
          margin-bottom: 0.9375vw; } }
      @media screen and (max-width: 131.25rem) {
        .big-sub-menu__item:not(:last-child) {
          margin-bottom: 1.25rem; } }
      @media screen and (max-width: 103.125rem) {
        .big-sub-menu__item:not(:last-child) {
          margin-bottom: 1rem; } }
      @media screen and (max-width: 45rem) {
        .big-sub-menu__item:not(:last-child) {
          margin-bottom: 0.75rem; } }
    .big-sub-menu__item a {
      font: var(--font-body-M);
      color: var(--color-text-dark);
      text-decoration: none;
      transition: color var(--anim-direction); }
      .big-sub-menu__item a:hover {
        color: var(--color-secondary); }

.header_active + .header-scroll-panel .header-scroll-panel__icon,
.header_fixed + .header-scroll-panel .header-scroll-panel__icon {
  opacity: 0; }

.footer {
  --footer-gap: 2.5rem;
  position: relative;
  display: block;
  width: 100%;
  background: var(--color-gray-3);
  padding-top: 6.25rem; }
  @media screen and (min-width: 160.0625rem) {
    .footer {
      --footer-gap: 1.5625vw;
      padding-top: 3.90625vw; } }
  @media screen and (max-width: 131.25rem) {
    .footer {
      --footer-gap: 2rem;
      padding-top: 4.25rem; } }
  @media screen and (max-width: 103.125rem) {
    .footer {
      --footer-gap: 1.5rem;
      padding-top: 3rem; } }
  @media screen and (max-width: 70.5rem) {
    .footer {
      padding-top: 2.5rem; } }
  @media screen and (max-width: 45rem) {
    .footer {
      --footer-gap: 1rem;
      padding-top: 2.5rem;
      padding-bottom: 1rem; } }
  .footer__main {
    display: flex;
    flex-wrap: wrap;
    gap: 6.25rem; }
    @media screen and (min-width: 160.0625rem) {
      .footer__main {
        gap: 3.90625vw; } }
    @media screen and (max-width: 131.25rem) {
      .footer__main {
        gap: 4.25rem; } }
    @media screen and (max-width: 103.125rem) {
      .footer__main {
        gap: 3rem; } }
    @media screen and (max-width: 70.5rem) {
      .footer__main {
        gap: 1.5rem; } }
    @media screen and (max-width: 45rem) {
      .footer__main {
        flex-direction: column;
        gap: 0; } }
  .footer__col_info {
    flex-basis: 18.375rem; }
    @media screen and (min-width: 160.0625rem) {
      .footer__col_info {
        flex-basis: 11.48438vw; } }
    @media screen and (max-width: 131.25rem) {
      .footer__col_info {
        flex-basis: 14rem; } }
    @media screen and (max-width: 103.125rem) {
      .footer__col_info {
        flex-basis: 10.75rem; } }
    @media screen and (max-width: 70.5rem) {
      .footer__col_info {
        flex-basis: 9.625rem; } }
    @media screen and (max-width: 45rem) {
      .footer__col_info {
        flex-basis: 100%;
        margin-bottom: 1.25rem; } }
  .footer__col_nav {
    flex-basis: 43rem; }
    @media screen and (min-width: 160.0625rem) {
      .footer__col_nav {
        flex-basis: 26.875vw; } }
    @media screen and (max-width: 131.25rem) {
      .footer__col_nav {
        flex-basis: 32.25rem; } }
    @media screen and (max-width: 103.125rem) {
      .footer__col_nav {
        flex-basis: 24.5rem; } }
    @media screen and (max-width: 70.5rem) {
      .footer__col_nav {
        flex-basis: 13.25rem; } }
    @media screen and (max-width: 45rem) {
      .footer__col_nav {
        flex-basis: 100%; } }
  .footer__col_contacts {
    flex: 1; }
    .footer__col_contacts .footer-toggle__title .select-input {
      width: auto; }
      @media screen and (max-width: 45rem) {
        .footer__col_contacts .footer-toggle__title .select-input {
          width: 100%; } }
    .footer__col_contacts .footer-toggle__title .select-current {
      padding: 0;
      background: 0;
      margin: 0;
      border: 0;
      height: auto;
      font: var(--font-body-M-semibold);
      gap: 0.5rem; }
    .footer__col_contacts .contact-block {
      max-width: 49.375rem; }
      @media screen and (min-width: 160.0625rem) {
        .footer__col_contacts .contact-block {
          max-width: 30.85938vw; } }
      @media screen and (max-width: 131.25rem) {
        .footer__col_contacts .contact-block {
          max-width: 32.3125rem; } }
      @media screen and (max-width: 103.125rem) {
        .footer__col_contacts .contact-block {
          max-width: 24.6875rem; } }
      @media screen and (max-width: 70.5rem) {
        .footer__col_contacts .contact-block {
          max-width: 100%; } }
      .footer__col_contacts .contact-block:not(:last-child) {
        margin-bottom: 2rem; }
        @media screen and (min-width: 160.0625rem) {
          .footer__col_contacts .contact-block:not(:last-child) {
            margin-bottom: 1.25vw; } }
        @media screen and (max-width: 131.25rem) {
          .footer__col_contacts .contact-block:not(:last-child) {
            margin-bottom: 1.5rem; } }
        @media screen and (max-width: 70.5rem) {
          .footer__col_contacts .contact-block:not(:last-child) {
            margin-bottom: 1rem; } }
  .footer__logo {
    font-size: 0;
    padding-bottom: var(--footer-gap);
    margin-bottom: var(--footer-gap);
    border-bottom: 1px solid var(--stroke-dark-secondary); }
    @media screen and (max-width: 45rem) {
      .footer__logo {
        padding-bottom: 0;
        border-bottom: none; } }
    .footer__logo-link {
      display: inline-block;
      font-size: 0;
      text-decoration: none; }
    .footer__logo-img {
      width: auto;
      height: 2.5625rem; }
      @media screen and (min-width: 160.0625rem) {
        .footer__logo-img {
          height: 1.60156vw; } }
      @media screen and (min-width: 160.0625rem) {
        .footer__logo-img {
          height: 1.60156vw; } }
      @media screen and (max-width: 131.25rem) {
        .footer__logo-img {
          height: 2.0625rem; } }
  .footer__partners {
    margin-bottom: var(--footer-gap); }
  .footer__social {
    display: flex;
    align-items: center;
    gap: 0.75rem;
    padding: 0;
    margin: 0;
    list-style-type: none; }
    @media screen and (min-width: 160.0625rem) {
      .footer__social {
        gap: 0.46875vw; } }
    @media screen and (max-width: 103.125rem) {
      .footer__social {
        gap: 0.5rem; } }
  .footer__contacts-accordion .footer-toggle__title {
    display: none; }
    @media screen and (max-width: 45rem) {
      .footer__contacts-accordion .footer-toggle__title {
        display: flex; } }
  .footer__contacts-accordion .footer-toggle:not(.active) {
    display: none; }
    @media screen and (max-width: 45rem) {
      .footer__contacts-accordion .footer-toggle:not(.active) {
        display: block; } }
  .footer__bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 6.25rem;
    padding-bottom: 2.5rem;
    font: 400 1rem/1.25 var(--font-primary);
    letter-spacing: -0.48px;
    text-transform: uppercase; }
    @media screen and (min-width: 160.0625rem) {
      .footer__bottom {
        padding-top: 3.90625vw;
        padding-bottom: 1.5625vw;
        font: 400 0.625vw/1.25 var(--font-primary); } }
    @media screen and (max-width: 131.25rem) {
      .footer__bottom {
        padding-top: 4.25rem;
        padding-bottom: 2rem;
        font: 400 0.875rem/1.42 var(--font-primary);
        letter-spacing: -0.42px; } }
    @media screen and (max-width: 103.125rem) {
      .footer__bottom {
        padding-top: 3.125rem;
        padding-bottom: 1.625rem;
        font: 400 0.75rem/1.33 var(--font-primary);
        letter-spacing: -0.36px; } }
    @media screen and (max-width: 70.5rem) {
      .footer__bottom {
        flex-direction: column;
        align-items: flex-start;
        padding-top: 2.5rem;
        padding-bottom: 2.625rem; } }
    @media screen and (max-width: 45rem) {
      .footer__bottom {
        padding-top: 1.25rem;
        padding-bottom: 1rem;
        border-top: 1px solid var(--stroke-dark-secondary); } }
    .footer__bottom-text {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      gap: 2rem; }
      @media screen and (min-width: 160.0625rem) {
        .footer__bottom-text {
          gap: 1.25vw; } }
      @media screen and (max-width: 103.125rem) {
        .footer__bottom-text {
          gap: 1.5rem; } }
      @media screen and (max-width: 70.5rem) {
        .footer__bottom-text {
          margin-bottom: 1.5rem; } }
      @media screen and (max-width: 45rem) {
        .footer__bottom-text {
          flex-direction: column;
          align-items: flex-start;
          gap: 0.75rem;
          margin-bottom: 0.75rem; } }
      .footer__bottom-text p {
        margin: 0;
        line-height: 1.25rem; }
      .footer__bottom-text a {
        color: var(--color-dark);
        text-decoration: underline;
        text-underline-offset: 2px; }
  .footer__btn-top {
    position: absolute;
    right: 6.25rem;
    bottom: 10rem;
    z-index: 2; }
    @media screen and (min-width: 160.0625rem) {
      .footer__btn-top {
        right: 3.90625vw;
        bottom: 6.25vw; } }
    @media screen and (max-width: 131.25rem) {
      .footer__btn-top {
        right: 4.25rem;
        bottom: 7.5rem; } }
    @media screen and (max-width: 103.125rem) {
      .footer__btn-top {
        right: 3.0625rem;
        bottom: 5.75rem; } }
    @media screen and (max-width: 70.5rem) {
      .footer__btn-top {
        right: 2.5rem;
        bottom: 2.5rem; } }
    @media screen and (max-width: 45rem) {
      .footer__btn-top {
        right: 1rem;
        bottom: 1rem; } }

.partner-block {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  background: var(--color-gray);
  padding: 1.25rem;
  font: var(--font-body-S);
  color: var(--color-secondary); }
  @media screen and (min-width: 160.0625rem) {
    .partner-block {
      padding: 0.78125vw; } }
  @media screen and (max-width: 70.5rem) {
    .partner-block {
      padding: 0.75rem; } }
  @media screen and (max-width: 45rem) {
    .partner-block {
      flex-direction: row;
      align-items: center; } }
  .partner-block__icon {
    width: 1.5rem;
    height: 1.5rem;
    fill: var(--color-secondary);
    margin-bottom: 0.75rem; }
    @media screen and (min-width: 160.0625rem) {
      .partner-block__icon {
        width: 0.9375vw;
        height: 0.9375vw;
        margin-bottom: 0.46875vw; } }
    @media screen and (max-width: 103.125rem) {
      .partner-block__icon {
        margin-bottom: 0.3125vw; } }
    @media screen and (max-width: 45rem) {
      .partner-block__icon {
        margin-bottom: 0;
        margin-right: 0.5rem; } }

.footer-toggle__title {
  display: flex;
  align-items: center;
  padding-bottom: var(--footer-gap);
  margin-bottom: var(--footer-gap);
  border-bottom: 1px solid var(--stroke-dark-secondary);
  font: var(--font-body-M-semibold); }
  @media screen and (max-width: 45rem) {
    .footer-toggle__title {
      justify-content: space-between;
      padding-top: 1.25rem;
      padding-bottom: 0;
      margin-bottom: 1.25rem;
      border-top: 1px solid var(--stroke-dark-secondary);
      border-bottom: 0; } }
  .footer-toggle__title-icon {
    display: none;
    width: 1.5rem;
    height: 1.5rem;
    fill: var(--color-gray-2);
    margin-left: 0.5rem;
    transition: transform var(--anim-direction); }
    @media screen and (max-width: 70.5rem) {
      .footer-toggle__title-icon {
        width: 1.25rem;
        height: 1.25rem; } }
    @media screen and (max-width: 45rem) {
      .footer-toggle__title-icon {
        display: block; } }

@media screen and (max-width: 45rem) {
  .footer-toggle__content {
    display: none;
    padding-bottom: 1.25rem; } }

@media screen and (max-width: 45rem) {
  .footer-toggle.toggle_active .footer-toggle__title {
    margin-bottom: 1rem; } }

.footer-toggle.toggle_active .footer-toggle__title-icon {
  transform: rotate(180deg); }

.footer-toggle.toggle_active .footer-toggle__content {
  display: block; }

.footer-menu {
  list-style-type: none;
  padding: 0;
  margin: 0;
  font: var(--font-body-M); }
  .footer-menu li:not(:last-child) {
    margin-bottom: 1rem; }
    @media screen and (min-width: 160.0625rem) {
      .footer-menu li:not(:last-child) {
        margin-bottom: 0.625vw; } }
    @media screen and (max-width: 131.25rem) {
      .footer-menu li:not(:last-child) {
        margin-bottom: 0.75rem; } }
    @media screen and (max-width: 103.125rem) {
      .footer-menu li:not(:last-child) {
        margin-bottom: 0.5rem; } }
    @media screen and (max-width: 70.5rem) {
      .footer-menu li:not(:last-child) {
        margin-bottom: 0.75rem; } }
  .footer-menu li a {
    color: var(--color-dark);
    text-decoration: none; }

.site-dev {
  display: flex;
  align-items: center; }
  .site-dev__img {
    width: 1.25rem;
    height: auto;
    margin-right: 0.9375rem; }
    @media screen and (min-width: 160.0625rem) {
      .site-dev__img {
        width: 0.78125vw;
        margin-right: 0.58594vw; } }
  .site-dev__text {
    display: inline-flex;
    align-items: center;
    line-height: 2rem; }
    @media screen and (min-width: 160.0625rem) {
      .site-dev__text {
        line-height: 1.25vw; } }
    @media screen and (max-width: 131.25rem) {
      .site-dev__text {
        line-height: 1.5rem; } }
    @media screen and (max-width: 70.5rem) {
      .site-dev__text {
        line-height: 1.25rem; } }
    .site-dev__text a {
      color: var(--color-dark);
      text-decoration: underline;
      text-underline-offset: 2px;
      margin-left: 0.3125rem; }
      @media screen and (min-width: 160.0625rem) {
        .site-dev__text a {
          margin-left: 0.19531vw; } }

.model {
  --anim-direction-model: 1s;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 20;
  width: 100%;
  height: 100%;
  visibility: hidden;
  pointer-events: none;
  transition: visibility var(--anim-direction-model) var(--anim-cubic); }
  .model__btn-close {
    position: absolute;
    right: 2.5rem;
    top: 2.5rem;
    z-index: 3;
    opacity: 0;
    transition: opacity var(--anim-direction-model) var(--anim-cubic); }
    @media screen and (min-width: 160.0625rem) {
      .model__btn-close {
        right: 1.5625vw;
        top: 1.5625vw; } }
    @media screen and (max-width: 131.25rem) {
      .model__btn-close {
        right: 2rem;
        top: 2rem; } }
    @media screen and (max-width: 70.5rem) {
      .model__btn-close {
        right: 2.5rem;
        top: 2.5rem; } }
    @media screen and (max-width: 45rem) {
      .model__btn-close {
        right: 1rem;
        top: 1rem; } }
  .model__bg {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 2;
    width: 50%;
    height: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    transform: translateX(-100%);
    transition: transform var(--anim-direction-model) var(--anim-cubic); }
    @media screen and (max-width: 70.5rem) {
      .model__bg {
        display: none; } }
  .model__content {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 2;
    width: 50%;
    height: 100%;
    background-color: var(--color-white);
    color: var(--color-dark);
    padding: 12.5rem 18.4375rem;
    min-width: 43.75rem;
    overflow: auto;
    transform: translateX(100%);
    transition: transform var(--anim-direction-model) var(--anim-cubic), opacity var(--anim-direction-model) var(--anim-cubic); }
    .model__content::-webkit-scrollbar {
      width: 0.25rem; }
      @media screen and (min-width: 160.0625rem) {
        .model__content::-webkit-scrollbar {
          width: 0.15625vw; } }
    .model__content::-webkit-scrollbar-track {
      background: var(--color-white); }
    .model__content::-webkit-scrollbar-thumb {
      background-color: var(--color-dark); }
    @media screen and (min-width: 160.0625rem) {
      .model__content {
        padding: 7.8125vw 11.52344vw; } }
    @media screen and (max-width: 131.25rem) {
      .model__content {
        padding: 8.5rem 14.375rem; } }
    @media screen and (max-width: 103.125rem) {
      .model__content {
        padding: 6rem 10rem; } }
    @media screen and (max-width: 70.5rem) {
      .model__content {
        width: 100%;
        min-width: auto;
        padding: 5rem 2.5rem; } }
    @media screen and (max-width: 45rem) {
      .model__content {
        padding: 2.5rem 1rem; } }
    .model__content .icon-success {
      margin-bottom: 3rem; }
      @media screen and (min-width: 160.0625rem) {
        .model__content .icon-success {
          margin-bottom: 1.875vw; } }
      @media screen and (max-width: 103.125rem) {
        .model__content .icon-success {
          margin-bottom: 2rem; } }
      @media screen and (max-width: 70.5rem) {
        .model__content .icon-success {
          margin-bottom: 1.5rem; } }
      @media screen and (max-width: 45rem) {
        .model__content .icon-success {
          margin-bottom: 1rem; } }
  .model__title {
    display: block; }
  .model__text {
    margin-top: 1.25rem;
    margin-bottom: 0; }
    @media screen and (min-width: 160.0625rem) {
      .model__text {
        margin-top: 0.78125vw; } }
    @media screen and (max-width: 131.25rem) {
      .model__text {
        margin-top: 1rem; } }
    @media screen and (max-width: 103.125rem) {
      .model__text {
        margin-top: 0.75rem; } }
    @media screen and (max-width: 45rem) {
      .model__text {
        margin-top: 0.5rem; } }
  .model__form {
    margin-top: 3rem; }
    @media screen and (min-width: 160.0625rem) {
      .model__form {
        margin-top: 1.875vw; } }
    @media screen and (max-width: 103.125rem) {
      .model__form {
        margin-top: 2rem; } }
    @media screen and (max-width: 45rem) {
      .model__form {
        margin-top: 1.25rem; } }
    .model__form .label-group,
    .model__form .double-group,
    .model__form .select-input {
      margin-bottom: 2.5rem; }
      @media screen and (min-width: 160.0625rem) {
        .model__form .label-group,
        .model__form .double-group,
        .model__form .select-input {
          margin-bottom: 1.5625vw; } }
      @media screen and (max-width: 131.25rem) {
        .model__form .label-group,
        .model__form .double-group,
        .model__form .select-input {
          margin-bottom: 2rem; } }
      @media screen and (max-width: 103.125rem) {
        .model__form .label-group,
        .model__form .double-group,
        .model__form .select-input {
          margin-bottom: 1.5rem; } }
      @media screen and (max-width: 45rem) {
        .model__form .label-group,
        .model__form .double-group,
        .model__form .select-input {
          margin-bottom: 1.125rem; } }
    .model__form .checkbox {
      margin-top: 3rem; }
      @media screen and (min-width: 160.0625rem) {
        .model__form .checkbox {
          margin-top: 1.875vw; } }
      @media screen and (max-width: 103.125rem) {
        .model__form .checkbox {
          margin-top: 2rem; } }
      @media screen and (max-width: 45rem) {
        .model__form .checkbox {
          margin-top: 1.25rem; } }
    .model__form .button {
      margin-top: 3rem; }
      @media screen and (min-width: 160.0625rem) {
        .model__form .button {
          margin-top: 1.875vw; } }
      @media screen and (max-width: 103.125rem) {
        .model__form .button {
          margin-top: 2rem; } }
      @media screen and (max-width: 45rem) {
        .model__form .button {
          margin-top: 1.25rem; } }
  .model__overlay {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    opacity: 0;
    transition: opacity var(--anim-direction-model) var(--anim-cubic); }
  .model.js_active {
    visibility: visible;
    pointer-events: all; }
    .model.js_active .model__btn-close {
      opacity: 1; }
    .model.js_active .model__bg {
      transform: translateX(0); }
    .model.js_active .model__content {
      transform: translateX(0); }
    .model.js_active .model__overlay {
      opacity: 1; }
  @media screen and (max-width: 70.5rem) {
    .model_mobile-win .model__content {
      max-width: 450px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      height: auto;
      padding: 2.5rem; } }
  @media screen and (max-width: 45rem) {
    .model_mobile-win .model__content {
      max-width: 90%;
      padding: 1.25rem; } }
  @media screen and (max-width: 70.5rem) {
    .model_mobile-win.js_active .model__content {
      transform: translate(-50%, -50%); } }
  .model_win .model__title {
    margin-bottom: 3rem; }
    @media screen and (min-width: 160.0625rem) {
      .model_win .model__title {
        margin-bottom: 1.875vw; } }
    @media screen and (max-width: 103.125rem) {
      .model_win .model__title {
        margin-bottom: 2rem; } }
    @media screen and (max-width: 45rem) {
      .model_win .model__title {
        margin-bottom: 1.25rem; } }
  .model_win .model__content {
    max-width: 50rem;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: auto;
    padding: 6.25rem;
    opacity: 0; }
    @media screen and (min-width: 160.0625rem) {
      .model_win .model__content {
        max-width: 31.25vw;
        padding: 3.90625vw; } }
    @media screen and (max-width: 131.25rem) {
      .model_win .model__content {
        padding: 4.6875rem; } }
    @media screen and (max-width: 103.125rem) {
      .model_win .model__content {
        max-width: 37.5rem;
        padding: 3.125rem; } }
    @media screen and (max-width: 70.5rem) {
      .model_win .model__content {
        max-width: 28.125rem;
        padding: 2.5rem; } }
    @media screen and (max-width: 45rem) {
      .model_win .model__content {
        max-width: 90%;
        padding: 1.25rem;
        padding-right: 3.125rem; } }
  .model_win.js_active .model__content {
    opacity: 1;
    transform: translate(-50%, -50%); }

.model-close-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.625rem;
  width: 3rem;
  height: 3rem;
  background-color: var(--color-white);
  border: 0;
  border-radius: 0;
  cursor: pointer;
  transform-origin: center;
  transition: transform 0.5s; }
  @media screen and (min-width: 160.0625rem) {
    .model-close-btn {
      padding: 0.39062vw;
      width: 1.875vw;
      height: 1.875vw; } }
  @media screen and (max-width: 70.5rem) {
    .model-close-btn {
      padding: 0.1875rem;
      width: 1.5rem;
      height: 1.5rem; } }
  .model-close-btn svg {
    margin: 0;
    width: 100%;
    height: 100%;
    fill: var(--stroke-dark); }
  .model-close-btn:hover {
    transform: rotate(180deg); }

.model-content-review .review-card {
  flex-direction: column;
  padding: 0; }
  .model-content-review .review-card__header {
    padding-bottom: 3rem; }
    @media screen and (min-width: 160.0625rem) {
      .model-content-review .review-card__header {
        padding-bottom: 1.875vw; } }
    @media screen and (max-width: 103.125rem) {
      .model-content-review .review-card__header {
        padding-bottom: 2rem; } }
    @media screen and (max-width: 45rem) {
      .model-content-review .review-card__header {
        padding-bottom: 1.25rem; } }
  .model-content-review .review-card__text {
    padding-top: 3rem;
    overflow: visible;
    display: block;
    -webkit-line-clamp: initial; }
    @media screen and (min-width: 160.0625rem) {
      .model-content-review .review-card__text {
        padding-top: 1.875vw; } }
    @media screen and (max-width: 103.125rem) {
      .model-content-review .review-card__text {
        padding-top: 2rem; } }
    @media screen and (max-width: 45rem) {
      .model-content-review .review-card__text {
        padding-top: 1.25rem; } }
  .model-content-review .review-card__btn {
    display: none; }
  .model-content-review .review-card__media {
    margin-left: 0;
    margin-top: 3rem; }
    @media screen and (min-width: 160.0625rem) {
      .model-content-review .review-card__media {
        margin-top: 1.875vw; } }
    @media screen and (max-width: 103.125rem) {
      .model-content-review .review-card__media {
        margin-top: 2rem; } }
    @media screen and (max-width: 45rem) {
      .model-content-review .review-card__media {
        margin-top: 1.25rem; } }
    .model-content-review .review-card__media .icon-button .button-hover-effects {
      display: none; }

.icon-success {
  display: flex;
  align-items: center;
  justify-content: center;
  width: var(--section-offset);
  min-width: var(--section-offset);
  height: var(--section-offset);
  border-radius: 50%;
  background-color: var(--system-states-success); }
  @media screen and (max-width: 45rem) {
    .icon-success {
      width: 2.5rem;
      min-width: 2.5rem;
      height: 2.5rem; } }
  .icon-success svg {
    fill: var(--color-white);
    width: 2rem;
    height: 2rem; }
    @media screen and (min-width: 160.0625rem) {
      .icon-success svg {
        width: 1.25vw;
        height: 1.25vw; } }
    @media screen and (max-width: 131.25rem) {
      .icon-success svg {
        width: 1.5rem;
        height: 1.5rem; } }
    @media screen and (max-width: 70.5rem) {
      .icon-success svg {
        width: 1.25rem;
        height: 1.25rem; } }

.button {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 1rem 1.25rem;
  margin: 0;
  width: fit-content;
  font: var(--font-body-M-semibold);
  letter-spacing: var(--text-spacing);
  font-style: normal;
  text-align: left;
  text-decoration: none;
  background: transparent;
  border: none;
  border-radius: 0;
  cursor: pointer;
  user-select: none;
  overflow: hidden;
  transition: color var(--anim-direction); }
  @media screen and (min-width: 160.0625rem) {
    .button {
      padding: 0.625vw 0.78125vw; } }
  @media screen and (max-width: 131.25rem) {
    .button {
      padding: 0.75rem 1rem; } }
  @media screen and (max-width: 70.5rem) {
    .button {
      padding: 0.5rem 0.75rem; } }
  .button__text {
    position: relative;
    padding-right: 3.25rem;
    z-index: 2; }
    @media screen and (min-width: 160.0625rem) {
      .button__text {
        padding-right: 2.03125vw; } }
    @media screen and (max-width: 131.25rem) {
      .button__text {
        padding-right: 3.25rem; } }
    @media screen and (max-width: 103.125rem) {
      .button__text {
        padding-right: 2rem; } }
  .button__icon {
    position: relative;
    margin-top: 0.875rem;
    align-self: flex-end;
    width: 2rem;
    height: 2rem;
    fill: var(--color-white);
    z-index: 2;
    transition: transform var(--anim-direction), fill var(--anim-direction); }
    @media screen and (min-width: 160.0625rem) {
      .button__icon {
        margin-top: 0.54688vw;
        width: 1.25vw;
        height: 1.25vw; } }
    @media screen and (max-width: 131.25rem) {
      .button__icon {
        width: 1.5rem;
        height: 1.5rem;
        margin-top: 1.375rem; } }
    @media screen and (max-width: 103.125rem) {
      .button__icon {
        margin-top: 0.5rem; } }
    @media screen and (max-width: 70.5rem) {
      .button__icon {
        width: 1.25rem;
        height: 1.25rem;
        margin-top: 0.75rem; } }
  .button .button-hover-effects {
    position: absolute;
    top: 0;
    right: -50px;
    display: block;
    width: 0;
    height: 0;
    background-color: var(--color-dark);
    transition: width var(--anim-direction);
    z-index: 1; }
    .button .button-hover-effects::before {
      content: "";
      padding-top: 100%;
      width: 100%;
      float: left;
      border-radius: 50%;
      transform: translate(-50%, -50%);
      background-color: inherit;
      transition: width var(--anim-direction), transform var(--anim-direction); }
  .button:disabled, .button.disabled {
    cursor: default;
    color: var(--color-text-light-disable); }
    .button:disabled .button__icon, .button.disabled .button__icon {
      fill: var(--color-text-light-disable); }
  .button:hover:not(:disabled):not(.disabled) .button__icon {
    transform: rotate(-45deg); }
  .button:hover:not(:disabled):not(.disabled) .button-hover-effects {
    width: 250%; }
  .button:focus-visible {
    outline: 0;
    box-shadow: 0 0 0 3px var(--color-secondary); }
  .button_primary {
    background-color: var(--color-primary);
    color: var(--color-white); }
  .button_secondary {
    background-color: var(--color-secondary);
    color: var(--color-white); }
  .button_tertiary {
    padding: 0.9375rem 1.1875rem;
    background-color: transparent;
    color: var(--color-secondary);
    border: 1px solid var(--stroke-dark-secondary);
    transition: border-color var(--anim-direction), color var(--anim-direction); }
    @media screen and (min-width: 160.0625rem) {
      .button_tertiary {
        padding: 0.58594vw 0.74219vw; } }
    @media screen and (max-width: 131.25rem) {
      .button_tertiary {
        padding: 0.6875rem 0.9375rem; } }
    .button_tertiary .button__icon {
      fill: var(--color-secondary); }
    .button_tertiary .button-hover-effects {
      background-color: var(--color-secondary); }
    .button_tertiary:hover {
      color: var(--color-white);
      border-color: var(--color-secondary); }
      .button_tertiary:hover .button__icon {
        fill: var(--color-white); }
    .button_tertiary:disabled,
    .button_tertiary .disabled {
      border-color: var(--stroke-dark-secondary);
      color: var(--color-text-dark-disable); }
      .button_tertiary:disabled .button__icon,
      .button_tertiary .disabled .button__icon {
        fill: var(--color-text-dark-disable); }
  .button_h-light .button-hover-effects {
    background-color: var(--color-white); }
  .button_h-light.button_tertiary {
    border-color: var(--stroke-light-secondary);
    color: var(--color-white); }
    .button_h-light.button_tertiary .button__icon {
      fill: var(--color-white); }
    .button_h-light.button_tertiary:disabled, .button_h-light.button_tertiary.disabled {
      border-color: var(--stroke-light-secondary);
      color: var(--color-text-light-disable); }
      .button_h-light.button_tertiary:disabled .button__icon, .button_h-light.button_tertiary.disabled .button__icon {
        fill: var(--color-text-light-disable); }
  .button_h-light:hover:not(:disabled):not(.disabled).button_primary {
    color: var(--color-primary); }
    .button_h-light:hover:not(:disabled):not(.disabled).button_primary .button__icon {
      fill: var(--color-primary); }
  .button_h-light:hover:not(:disabled):not(.disabled).button_secondary {
    color: var(--color-secondary); }
    .button_h-light:hover:not(:disabled):not(.disabled).button_secondary .button__icon {
      fill: var(--color-secondary); }
  .button_h-light:hover:not(:disabled):not(.disabled).button_tertiary {
    border-color: var(--color-white);
    color: var(--color-secondary); }
    .button_h-light:hover:not(:disabled):not(.disabled).button_tertiary .button__icon {
      fill: var(--color-secondary); }

.file-button {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 1rem 1.25rem;
  margin: 0;
  width: fit-content;
  font: var(--font-body-M-semibold);
  letter-spacing: var(--text-spacing);
  text-align: left;
  text-decoration: none;
  background: transparent;
  border: 1px solid var(--stroke-dark-secondary);
  border-radius: 0;
  cursor: pointer;
  user-select: none;
  overflow: hidden;
  transition: color var(--anim-direction), border-color var(--anim-direction); }
  @media screen and (min-width: 160.0625rem) {
    .file-button {
      padding: 0.625vw 0.78125vw; } }
  @media screen and (max-width: 131.25rem) {
    .file-button {
      padding: 0.75rem 1rem; } }
  @media screen and (max-width: 70.5rem) {
    .file-button {
      padding: 0.5rem 0.75rem; } }
  .file-button__text {
    position: relative;
    display: block;
    color: var(--color-secondary);
    transition: color var(--anim-direction);
    padding-right: 3.25rem;
    z-index: 2; }
    @media screen and (min-width: 160.0625rem) {
      .file-button__text {
        padding-right: 2.03125vw; } }
    @media screen and (max-width: 131.25rem) {
      .file-button__text {
        padding-right: 2.5rem; } }
    @media screen and (max-width: 103.125rem) {
      .file-button__text {
        padding-right: 2rem; } }
  .file-button__desc {
    position: relative;
    display: block;
    color: var(--color-text-dark-secondary);
    font: var(--font-body-M-caption);
    text-transform: uppercase;
    padding-right: 3.25rem;
    z-index: 2;
    transition: color var(--anim-direction); }
    @media screen and (min-width: 160.0625rem) {
      .file-button__desc {
        padding-right: 2.03125vw; } }
    @media screen and (max-width: 131.25rem) {
      .file-button__desc {
        padding-right: 2.5rem; } }
    @media screen and (max-width: 103.125rem) {
      .file-button__desc {
        padding-right: 2rem; } }
  .file-button__icon {
    position: relative;
    align-self: flex-end;
    width: 2rem;
    height: 2rem;
    fill: var(--color-secondary);
    z-index: 2;
    margin-top: -0.75rem;
    transition: transform var(--anim-direction), fill var(--anim-direction); }
    @media screen and (min-width: 160.0625rem) {
      .file-button__icon {
        width: 0.9375vw;
        height: 0.9375vw;
        margin-top: -0.46875vw; } }
    @media screen and (max-width: 131.25rem) {
      .file-button__icon {
        width: 1.5rem;
        height: 1.5rem;
        margin-top: 0.125rem; } }
    @media screen and (max-width: 103.125rem) {
      .file-button__icon {
        margin-top: -0.5rem; } }
    @media screen and (max-width: 70.5rem) {
      .file-button__icon {
        width: 1.25rem;
        height: 1.25rem;
        margin-top: -0.125rem; } }
  .file-button .button-hover-effects {
    position: absolute;
    top: 0;
    right: -50px;
    display: block;
    width: 0;
    height: 0;
    background-color: var(--color-secondary);
    transition: width var(--anim-direction);
    z-index: 1; }
    .file-button .button-hover-effects::before {
      content: "";
      padding-top: 100%;
      width: 100%;
      float: left;
      border-radius: 50%;
      transform: translate(-50%, -50%);
      background-color: inherit;
      transition: width var(--anim-direction), transform var(--anim-direction); }
  .file-button:disabled, .file-button.disabled {
    cursor: default;
    border-color: var(--stroke-dark-secondary);
    color: var(--color-text-dark-disable); }
    .file-button:disabled .file-button__text, .file-button:disabled .file-button__desc, .file-button.disabled .file-button__text, .file-button.disabled .file-button__desc {
      color: var(--color-text-dark-disable); }
    .file-button:disabled .file-button__icon, .file-button.disabled .file-button__icon {
      fill: var(--color-text-dark-disable); }
  .file-button:hover:not(:disabled):not(.disabled) {
    border-color: var(--color-secondary);
    color: var(--color-white); }
    .file-button:hover:not(:disabled):not(.disabled) .file-button__text {
      color: var(--color-white); }
    .file-button:hover:not(:disabled):not(.disabled) .file-button__desc {
      color: var(--color-text-light-secondary); }
    .file-button:hover:not(:disabled):not(.disabled) .file-button__icon {
      fill: var(--color-white); }
    .file-button:hover:not(:disabled):not(.disabled) .button-hover-effects {
      width: 250%; }
  .file-button:focus-visible {
    outline: 0;
    box-shadow: 0 0 0 3px var(--color-secondary); }
  .file-button_light {
    color: var(--color-white);
    border-color: var(--stroke-light-secondary); }
    .file-button_light .file-button__text {
      color: var(--color-white); }
    .file-button_light .file-button__desc {
      color: var(--color-text-light-secondary); }
    .file-button_light .file-button__icon {
      fill: var(--color-white); }
    .file-button_light .button-hover-effects {
      background-color: var(--color-white); }
    .file-button_light:hover:not(:disabled):not(.disabled) {
      color: var(--color-secondary);
      border-color: var(--color-white); }
      .file-button_light:hover:not(:disabled):not(.disabled) .file-button__text {
        color: var(--color-secondary); }
      .file-button_light:hover:not(:disabled):not(.disabled) .file-button__desc {
        color: var(--color-text-dark-secondary); }
      .file-button_light:hover:not(:disabled):not(.disabled) .file-button__icon {
        fill: var(--color-secondary); }
    .file-button_light:disabled, .file-button_light.disabled {
      border-color: var(--stroke-light-secondary);
      color: var(--color-text-light-disable); }
      .file-button_light:disabled .file-button__text, .file-button_light:disabled .file-button__desc, .file-button_light.disabled .file-button__text, .file-button_light.disabled .file-button__desc {
        color: var(--color-text-light-disable); }
      .file-button_light:disabled .file-button__icon, .file-button_light.disabled .file-button__icon {
        fill: var(--color-text-light-disable); }

.text-button {
  position: relative;
  display: flex;
  gap: 0.5rem;
  align-items: center;
  padding: 0;
  margin: 0;
  width: fit-content;
  font: var(--font-body-M-semibold);
  letter-spacing: var(--text-spacing);
  text-align: left;
  text-decoration: none;
  color: var(--color-secondary);
  background: transparent;
  border: none;
  border-radius: 0;
  cursor: pointer;
  user-select: none;
  overflow: hidden; }
  @media screen and (min-width: 160.0625rem) {
    .text-button {
      font-size: 0.85938vw;
      gap: 0.3125vw; } }
  @media screen and (max-width: 131.25rem) {
    .text-button {
      font-size: 1.125rem;
      gap: 0.375rem; } }
  @media screen and (max-width: 103.125rem) {
    .text-button {
      font-size: 1rem;
      gap: 0.25rem; } }
  @media screen and (max-width: 70.5rem) {
    .text-button {
      font-size: 0.875rem; } }
  .text-button__text {
    display: block; }
  .text-button__icon {
    min-width: 2rem;
    width: 2rem;
    height: 2rem;
    fill: var(--color-secondary);
    transition: transform var(--anim-direction), fill var(--anim-direction); }
    @media screen and (min-width: 160.0625rem) {
      .text-button__icon {
        min-width: 1.25vw;
        width: 1.25vw;
        height: 1.25vw; } }
    @media screen and (max-width: 131.25rem) {
      .text-button__icon {
        min-width: 1.5rem;
        width: 1.5rem;
        height: 1.5rem; } }
    @media screen and (max-width: 70.5rem) {
      .text-button__icon {
        min-width: 1.25rem;
        width: 1.25rem;
        height: 1.25rem; } }
  .text-button:disabled, .text-button.disabled {
    cursor: default;
    color: var(--color-text-dark-disable);
    pointer-events: none; }
    .text-button:disabled .text-button__icon, .text-button.disabled .text-button__icon {
      fill: var(--color-text-dark-disable); }
  .text-button:focus-visible {
    outline: 0;
    box-shadow: 0 0 0 3px var(--color-secondary); }
  .text-button_secondary {
    color: var(--color-text-dark-secondary); }
    .text-button_secondary .text-button__icon {
      fill: var(--color-text-dark-secondary); }

.icon-button {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 0;
  margin: 0;
  min-width: 3.75rem;
  width: 3.75rem;
  height: 3.75rem;
  font-size: 0;
  text-decoration: none;
  background: var(--color-dark);
  border: 0;
  border-radius: 50%;
  cursor: pointer;
  user-select: none;
  overflow: hidden;
  transition: border-color var(--anim-direction), background var(--anim-direction); }
  @media screen and (min-width: 160.0625rem) {
    .icon-button {
      min-width: 2.34375vw;
      width: 2.34375vw;
      height: 2.34375vw; } }
  @media screen and (max-width: 131.25rem) {
    .icon-button {
      min-width: 3.375rem;
      width: 3.375rem;
      height: 3.375rem; } }
  @media screen and (max-width: 103.125rem) {
    .icon-button {
      min-width: 2.75rem;
      width: 2.75rem;
      height: 2.75rem; } }
  .icon-button__icon {
    position: relative;
    z-index: 2;
    width: 1.5rem;
    height: 1.5rem;
    fill: var(--color-white);
    transition: transform var(--anim-direction), fill var(--anim-direction); }
    @media screen and (min-width: 160.0625rem) {
      .icon-button__icon {
        width: 0.9375vw;
        height: 0.9375vw; } }
    @media screen and (max-width: 131.25rem) {
      .icon-button__icon {
        width: 1.25rem;
        height: 1.25rem; } }
  .icon-button .button-hover-effects {
    position: absolute;
    top: 0;
    right: -50%;
    display: block;
    width: 0;
    height: 0;
    background-color: var(--color-secondary);
    transition: width var(--anim-direction);
    z-index: 1; }
    .icon-button .button-hover-effects::before {
      content: "";
      padding-top: 100%;
      width: 100%;
      float: left;
      border-radius: 50%;
      transform: translate(-50%, -50%);
      background-color: inherit;
      transition: width var(--anim-direction), transform var(--anim-direction); }
  .icon-button:disabled, .icon-button.disabled {
    cursor: default; }
    .icon-button:disabled .icon-button__icon, .icon-button.disabled .icon-button__icon {
      fill: var(--stroke-light-secondary); }
  .icon-button:hover:not(:disabled):not(.disabled) {
    border-color: var(--color-secondary); }
    @media screen and (max-width: 45rem) {
      .icon-button:hover:not(:disabled):not(.disabled) {
        background-color: var(--color-secondary); } }
    .icon-button:hover:not(:disabled):not(.disabled) .icon-button__icon {
      fill: var(--color-white); }
    .icon-button:hover:not(:disabled):not(.disabled) .button-hover-effects {
      width: 250%; }
  .icon-button:focus-visible {
    outline: 0;
    box-shadow: 0 0 0 3px var(--color-secondary); }
  .icon-button_stroke {
    min-width: 3.6875rem;
    width: 3.6875rem;
    height: 3.6875rem;
    background: transparent;
    border: 1px solid var(--stroke-dark-secondary); }
    @media screen and (min-width: 160.0625rem) {
      .icon-button_stroke {
        min-width: 2.30469vw;
        width: 2.30469vw;
        height: 2.30469vw; } }
    @media screen and (max-width: 131.25rem) {
      .icon-button_stroke {
        min-width: 3.3125rem;
        width: 3.3125rem;
        height: 3.3125rem; } }
    @media screen and (max-width: 103.125rem) {
      .icon-button_stroke {
        min-width: 2.6875rem;
        width: 2.6875rem;
        height: 2.6875rem; } }
    .icon-button_stroke .icon-button__icon {
      fill: var(--color-dark); }
    .icon-button_stroke:disabled, .icon-button_stroke.disabled {
      border-color: var(--stroke-dark-secondary); }
      .icon-button_stroke:disabled .icon-button__icon, .icon-button_stroke.disabled .icon-button__icon {
        fill: var(--color-text-dark-disable); }
  .icon-button_light {
    background: var(--color-white); }
    .icon-button_light .icon-button__icon {
      fill: var(--color-dark); }
    .icon-button_light:disabled .icon-button__icon, .icon-button_light.disabled .icon-button__icon {
      fill: var(--color-text-dark-disable); }
    .icon-button_light.icon-button_stroke {
      background: transparent;
      border-color: var(--stroke-light-secondary); }
      .icon-button_light.icon-button_stroke .icon-button__icon {
        fill: var(--color-white); }
      .icon-button_light.icon-button_stroke:disabled .icon-button__icon, .icon-button_light.icon-button_stroke.disabled .icon-button__icon {
        fill: var(--color-text-light-disable); }

.icon-text-button {
  position: relative;
  display: flex;
  gap: 0.75rem;
  align-items: center;
  padding: 0;
  margin: 0;
  width: fit-content;
  font: var(--font-body-M-semibold);
  letter-spacing: var(--text-spacing);
  text-align: left;
  text-decoration: none;
  color: var(--color-white);
  background: transparent;
  border: none;
  border-radius: 0;
  cursor: pointer;
  user-select: none;
  overflow: hidden; }
  @media screen and (min-width: 160.0625rem) {
    .icon-text-button {
      gap: 0.46875vw; } }
  .icon-text-button__circle {
    min-width: 3.75rem;
    width: 3.75rem;
    height: 3.75rem;
    border-radius: 50%;
    background: var(--color-white); }
    @media screen and (min-width: 160.0625rem) {
      .icon-text-button__circle {
        min-width: 2.34375vw;
        width: 2.34375vw;
        height: 2.34375vw; } }
    @media screen and (max-width: 131.25rem) {
      .icon-text-button__circle {
        min-width: 3.375rem;
        width: 3.375rem;
        height: 3.375rem; } }
    @media screen and (max-width: 103.125rem) {
      .icon-text-button__circle {
        min-width: 2.75rem;
        width: 2.75rem;
        height: 2.75rem; } }
  .icon-text-button__icon {
    position: relative;
    z-index: 2;
    width: 1.5rem;
    height: 1.5rem;
    fill: var(--color-secondary);
    transition: transform var(--anim-direction), fill var(--anim-direction); }
    @media screen and (min-width: 160.0625rem) {
      .icon-text-button__icon {
        width: 0.9375vw;
        height: 0.9375vw; } }
    @media screen and (max-width: 131.25rem) {
      .icon-text-button__icon {
        width: 1.25rem;
        height: 1.25rem; } }
  .icon-text-button__text {
    text-decoration: none; }
  .icon-text-button .button-hover-effects {
    position: absolute;
    top: 0;
    right: -50px;
    display: block;
    width: 0;
    height: 0;
    background-color: var(--color-secondary);
    transition: width var(--anim-direction), transform var(--anim-direction);
    z-index: 1; }
    .icon-text-button .button-hover-effects::before {
      content: "";
      padding-top: 100%;
      width: 100%;
      float: left;
      border-radius: 50%;
      transform: translate(-50%, -50%);
      background-color: inherit;
      transition: width var(--anim-direction), transform var(--anim-direction); }
  .icon-text-button:disabled, .icon-text-button.disabled {
    cursor: default;
    color: var(--color-white); }
    .icon-text-button:disabled .icon-text-button__icon-button, .icon-text-button.disabled .icon-text-button__icon-button {
      background: var(--color-white); }
      .icon-text-button:disabled .icon-text-button__icon-button .icon-button__icon, .icon-text-button.disabled .icon-text-button__icon-button .icon-button__icon {
        fill: var(--color-text-dark-disable); }
      .icon-text-button:disabled .icon-text-button__icon-button .button-hover-effects, .icon-text-button.disabled .icon-text-button__icon-button .button-hover-effects {
        display: none; }
  .icon-text-button:hover:not(:disabled):not(.disabled) .icon-text-button__circle {
    background: var(--color-secondary); }
  .icon-text-button:hover:not(:disabled):not(.disabled) .icon-text-button__icon {
    fill: var(--color-white); }
  .icon-text-button:hover:not(:disabled):not(.disabled) .button-hover-effects {
    width: 250%;
    height: 250%; }
  .icon-text-button:focus-visible {
    outline: 0;
    box-shadow: 0 0 0 3px var(--color-secondary); }

[data-text-hover] {
  display: block;
  overflow: hidden;
  white-space: nowrap;
  width: max-content;
  text-overflow: ellipsis;
  line-height: 2rem;
  height: 2rem; }
  @media screen and (min-width: 160.0625rem) {
    [data-text-hover] {
      line-height: 1.25vw;
      height: 1.25vw; } }
  @media screen and (max-width: 131.25rem) {
    [data-text-hover] {
      line-height: 1.5rem;
      height: 1.5rem; } }
  @media screen and (max-width: 70.5rem) {
    [data-text-hover] {
      line-height: 1.25rem;
      height: 1.25rem; } }
  [data-text-hover]:after {
    content: attr(data-text-hover);
    display: block;
    pointer-events: none;
    transition: transform var(--anim-direction), opacity var(--anim-direction), color var(--anim-direction); }
  [data-text-hover] span {
    display: block;
    transition: transform var(--anim-direction), opacity var(--anim-direction), color var(--anim-direction); }
  [data-text-hover]:hover:after,
  [data-text-hover]:hover span {
    transform: translateY(-100%);
    opacity: 1; }
  [data-text-hover].h4 {
    line-height: 2rem;
    height: 2rem; }
    @media screen and (min-width: 160.0625rem) {
      [data-text-hover].h4 {
        line-height: 1.25vw;
        height: 1.25vw; } }
    @media screen and (max-width: 70.5rem) {
      [data-text-hover].h4 {
        line-height: 1.5rem;
        height: 1.5rem; } }

.label-group {
  display: block;
  width: 100%;
  margin: 0; }
  .label-group .error,
  .label-group .is-invalid {
    margin-bottom: 0.3125rem; }
    @media screen and (min-width: 160.0625rem) {
      .label-group .error,
      .label-group .is-invalid {
        margin-bottom: 0.19531vw; } }
  .label-group__error {
    display: block;
    font: var(--font-body-S);
    color: var(--system-states-error); }

.input {
  position: relative;
  display: block;
  width: 100%;
  height: 3.6875rem;
  font: var(--font-body-M);
  letter-spacing: var(--text-spacing);
  line-height: 3.6875rem;
  padding: 0 1.25rem;
  margin: 0;
  background: var(--color-white);
  color: var(--color-dark);
  border: 1px solid var(--stroke-dark-secondary);
  text-overflow: ellipsis;
  transition: border-color var(--anim-direction); }
  @media screen and (min-width: 160.0625rem) {
    .input {
      height: 2.30469vw;
      line-height: 2.30469vw;
      padding: 0 0.78125vw; } }
  @media screen and (max-width: 131.25rem) {
    .input {
      height: 3.3125rem;
      line-height: 3.3125rem; } }
  @media screen and (max-width: 103.125rem) {
    .input {
      height: 2.6875rem;
      line-height: 2.6875rem;
      padding: 0 1rem; } }
  .input:required, .input_required {
    background-image: url("/assets/templates/img/common/icon-required.svg");
    background-repeat: no-repeat;
    background-size: 2rem;
    background-position: calc(100% - 1rem) center; }
    @media screen and (min-width: 160.0625rem) {
      .input:required, .input_required {
        background-size: 1.25vw;
        background-position: calc(100% - 0.625vw) center; } }
    @media screen and (max-width: 131.25rem) {
      .input:required, .input_required {
        background-size: 1.625rem;
        background-position: calc(100% - 0.8125rem) center; } }
    @media screen and (max-width: 103.125rem) {
      .input:required, .input_required {
        background-size: 1.5rem;
        background-position: calc(100% - 0.75rem) center; } }
    @media screen and (max-width: 70.5rem) {
      .input:required, .input_required {
        background-size: 1.25rem;
        background-position: calc(100% - 0.625rem) center; } }
  .input:disabled, .input.disabled {
    background-image: none;
    border-color: var(--stroke-dark-secondary);
    background: var(--color-gray-3); }
  .input:hover:not(:disabled):not(.disabled) {
    border-color: var(--stroke-dark); }
  .input:focus {
    outline: 0;
    border-color: var(--stroke-dark); }
  .input::placeholder {
    color: var(--color-text-dark-secondary); }
  .input.error, .input.is-invalid {
    border-color: var(--system-states-error); }

.double-group {
  position: relative; }
  .double-group__fields {
    position: relative;
    display: flex;
    align-items: center;
    background: var(--color-white);
    border: 1px solid var(--stroke-dark-secondary);
    transition: border-color var(--anim-direction); }
    .double-group__fields:after {
      content: "";
      display: block;
      width: 1px;
      height: 100%;
      position: absolute;
      left: 50%;
      top: 0;
      background: var(--stroke-dark-secondary);
      transition: background-color var(--anim-direction); }
  .double-group__input {
    flex: 1;
    border: 0;
    text-overflow: ellipsis; }
  .double-group__btn {
    position: relative;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2px;
    background-color: var(--color-white);
    border: 1px solid transparent;
    cursor: pointer;
    transition: border-color var(--anim-direction); }
    .double-group__btn svg {
      width: 1.5rem;
      height: 1.5rem;
      transition: transform var(--anim-direction); }
      @media screen and (min-width: 160.0625rem) {
        .double-group__btn svg {
          width: 0.9375vw;
          height: 0.9375vw; } }
    .double-group__btn:focus-visible {
      outline: 0;
      border-color: var(--stroke-dark); }
    .double-group__btn:hover svg {
      fill: var(--color-secondary);
      transform: rotate(180deg); }
  .double-group:hover .double-group__fields, .double-group:focus-within:not(.disabled) .double-group__fields {
    border-color: var(--stroke-dark); }
    .double-group:hover .double-group__fields:after, .double-group:focus-within:not(.disabled) .double-group__fields:after {
      background: var(--stroke-dark); }
  .double-group.disabled .double-group__fields {
    background: var(--color-gray-3);
    border-color: var(--stroke-dark-secondary); }
  .double-group.disabled .double-group__input {
    background: var(--color-gray-3); }
  .double-group.disabled .double-group__btn {
    background: var(--color-gray-3);
    pointer-events: none;
    cursor: default; }
    .double-group.disabled .double-group__btn svg {
      fill: var(--color-gray-2); }
  .double-group .is-invalid + .double-group__fields {
    border-color: var(--system-states-error); }
    .double-group .is-invalid + .double-group__fields:after {
      background: var(--system-states-error); }
  .double-group .is-invalid + .double-group__fields + .double-group__error {
    display: block; }
  .double-group__error {
    display: none;
    font: var(--font-body-S);
    color: var(--system-states-error);
    margin-top: 0.3125rem; }
    @media screen and (min-width: 160.0625rem) {
      .double-group__error {
        margin-top: 0.19531vw; } }

.checkbox {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  gap: 1rem;
  user-select: none; }
  @media screen and (min-width: 160.0625rem) {
    .checkbox {
      gap: 0.625vw; } }
  .checkbox:hover .checkbox__item {
    border-color: var(--stroke-dark); }
  .checkbox__input:checked ~ .checkbox__item {
    background-color: var(--color-dark);
    border-color: var(--color-dark); }
  .checkbox__input:disabled ~ .checkbox__item {
    background-color: var(--color-gray-3);
    border-color: var(--stroke-dark-secondary);
    cursor: default; }
    .checkbox__input:disabled ~ .checkbox__item:before {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16' fill='none'%3E%3Cpath d='M6.66843 10.1139L12.7967 3.98566L13.7395 4.92846L6.66843 11.9995L2.42578 7.75691L3.36859 6.81411L6.66843 10.1139Z' fill='rgba(0, 0, 0, 0.08)'/%3E%3C/svg%3E"); }
  .checkbox__input:disabled:not(.checkbox__input:checked) ~ .checkbox__item:before {
    content: none; }
  .checkbox__input.error ~ .checkbox__item, .checkbox__input.is-invalid ~ .checkbox__item {
    border-color: var(--system-states-error); }
  .checkbox__item {
    position: relative;
    min-width: 1.5rem;
    width: 1.5rem;
    height: 1.5rem;
    display: block;
    background-color: var(--color-white);
    border: solid 1px var(--stroke-dark-secondary);
    border-radius: 0;
    cursor: pointer;
    transition: border-color var(--anim-direction), background-color var(--anim-direction); }
    @media screen and (min-width: 160.0625rem) {
      .checkbox__item {
        min-width: 0.9375vw;
        width: 0.9375vw;
        height: 0.9375vw; } }
    .checkbox__item::before {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      margin: auto;
      width: 1rem;
      height: 1rem;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16' fill='none'%3E%3Cpath d='M6.66843 10.1139L12.7967 3.98566L13.7395 4.92846L6.66843 11.9995L2.42578 7.75691L3.36859 6.81411L6.66843 10.1139Z' fill='white'/%3E%3C/svg%3E");
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat; }
      @media screen and (min-width: 160.0625rem) {
        .checkbox__item::before {
          width: 0.625vw;
          height: 0.625vw; } }
  .checkbox__text {
    position: relative;
    z-index: 1;
    font: var(--font-body-M);
    color: var(--text-dark-primary); }
    .checkbox__text a {
      color: var(--text-dark-primary);
      text-decoration: underline; }
      .checkbox__text a:hover {
        color: var(--accent-color); }

.radio {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  gap: 1rem;
  user-select: none; }
  @media screen and (min-width: 160.0625rem) {
    .radio {
      gap: 0.625vw; } }
  .radio:hover .radio__item {
    border-color: var(--stroke-dark); }
  .radio__input:checked ~ .radio__item {
    background-color: var(--stroke-dark);
    border-color: var(--stroke-dark); }
  .radio__input:disabled ~ .radio__item {
    background-color: var(--color-gray-3);
    border-color: var(--stroke-dark-secondary);
    cursor: default; }
    .radio__input:disabled ~ .radio__item:before {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16' fill='none'%3E%3Cpath d='M6.66843 10.1139L12.7967 3.98566L13.7395 4.92846L6.66843 11.9995L2.42578 7.75691L3.36859 6.81411L6.66843 10.1139Z' fill='rgba(0, 0, 0, 0.08)'/%3E%3C/svg%3E"); }
  .radio__input:disabled:not(.radio__input:checked) ~ .radio__item:before {
    content: none; }
  .radio__item {
    position: relative;
    min-width: 1.5rem;
    width: 1.5rem;
    height: 1.5rem;
    display: block;
    background-color: var(--color-white);
    border: solid 1px var(--stroke-dark-secondary);
    border-radius: 50%;
    cursor: pointer;
    transition: border-color var(--anim-direction), background-color var(--anim-direction); }
    @media screen and (min-width: 160.0625rem) {
      .radio__item {
        min-width: 0.9375vw;
        width: 0.9375vw;
        height: 0.9375vw; } }
    .radio__item::before {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      margin: auto;
      width: 0.875rem;
      height: 0.875rem;
      border-radius: 50%;
      background: var(--color-white);
      transition: background-color var(--anim-direction); }
      @media screen and (min-width: 160.0625rem) {
        .radio__item::before {
          width: 0.54688vw;
          height: 0.54688vw; } }
  .radio__text {
    position: relative;
    z-index: 1;
    font: var(--font-body-M);
    color: var(--text-dark-primary); }
    .radio__text a {
      color: var(--text-dark-primary);
      text-decoration: underline; }
      .radio__text a:hover {
        color: var(--accent-color); }

.iti {
  display: block;
  width: 100%; }
  .iti--inline-dropdown .iti__dropdown-content {
    z-index: 5; }
  .iti__flag {
    background-image: url("/assets/templates/img/common/flags.png?1"); }

@media (min-resolution: 2x) {
  .iti__flag {
    background-image: url("/assets/templates/img/common/flags.png?1"); } }

.select-input {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.3125rem; }
  @media screen and (min-width: 160.0625rem) {
    .select-input {
      gap: 0.19531vw; } }
  .select-input_checkbox .select-answer__text {
    display: flex;
    align-items: center;
    gap: 0.625rem;
    transition: color var(--anim-direction); }
    @media screen and (min-width: 160.0625rem) {
      .select-input_checkbox .select-answer__text {
        gap: 0.39062vw; } }
    .select-input_checkbox .select-answer__text .select-answer__checkbox {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 1.25rem;
      height: 1.25rem;
      background-color: var(--bg-white);
      border: solid 1px var(--stroke-dark);
      border-radius: var(--radius-secondary);
      transition: background-color var(--animation-timing) var(--cubic-bezier), border-color var(--animation-timing) var(--cubic-bezier); }
      @media screen and (min-width: 160.0625rem) {
        .select-input_checkbox .select-answer__text .select-answer__checkbox {
          width: 0.70312vw;
          height: 0.70312vw;
          border-width: 0.03906vw; } }
      .select-input_checkbox .select-answer__text .select-answer__checkbox:before {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        margin: auto;
        display: flex;
        flex-shrink: 0;
        width: 1rem;
        height: 1rem;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16' fill='none'%3E%3Cpath d='M6.66843 10.1139L12.7967 3.98566L13.7395 4.92846L6.66843 11.9995L2.42578 7.75691L3.36859 6.81411L6.66843 10.1139Z' fill='white'/%3E%3C/svg%3E");
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat; }
        @media screen and (min-width: 160.0625rem) {
          .select-input_checkbox .select-answer__text .select-answer__checkbox:before {
            width: 0.625vw;
            height: 0.625vw; } }
  .select-input_checkbox .select-answer__input:checked ~ .select-answer__text {
    color: var(--accent-color); }
    .select-input_checkbox .select-answer__input:checked ~ .select-answer__text .select-answer__checkbox {
      background-color: var(--accent-color);
      border-color: var(--accent-color); }
  .select-input__checkbox:focus ~ .select-current .select-current__icon, .select-input__checkbox:checked ~ .select-current .select-current__icon {
    transform: rotate(180deg); }
  .select-input__checkbox:focus ~ .select-answer, .select-input__checkbox:checked ~ .select-answer {
    margin-top: 0.625rem;
    opacity: 1 !important;
    visibility: visible; }
    @media screen and (min-width: 160.0625rem) {
      .select-input__checkbox:focus ~ .select-answer, .select-input__checkbox:checked ~ .select-answer {
        margin-top: 0.39062vw; } }
  .select-input__checkbox:disabled ~ .select-current {
    border-color: var(--stroke-dark-secondary);
    background: var(--color-gray-3);
    color: var(--color-text-dark-secondary);
    cursor: default; }
  .select-input__input[value=""] ~ .select-current .select-current__text {
    color: var(--color-text-dark-secondary); }
  .select-input__input.is-invalid + .select-current {
    border-color: var(--system-states-error); }
  .select-input__error {
    display: none;
    font: var(--font-body-S);
    color: var(--system-states-error); }
  .select-input__input.is-invalid + .select-input__current {
    border-color: var(--system-states-error); }
    .select-input__input.is-invalid + .select-input__current + .select-input__answer + .select-input__error {
      display: block; }

.select-current {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 0.9375rem;
  height: 3.6875rem;
  padding: 0 1.25rem;
  background-color: var(--color-white);
  border: 1px solid var(--stroke-dark-secondary);
  font: var(--font-body-M);
  color: var(--color-text-dark);
  transition: border-color var(--anim-direction);
  cursor: pointer; }
  @media screen and (min-width: 160.0625rem) {
    .select-current {
      gap: 0.58594vw;
      height: 2.30469vw;
      padding: 0 0.78125vw; } }
  @media screen and (max-width: 131.25rem) {
    .select-current {
      height: 3.3125rem; } }
  @media screen and (max-width: 103.125rem) {
    .select-current {
      height: 2.6875rem;
      padding: 0 1rem; } }
  .select-current:hover {
    border-color: var(--accent-color); }
  .select-current__icon {
    width: 1.5rem;
    height: 1.5rem;
    flex-shrink: 0;
    fill: var(--color-gray-2);
    user-select: none;
    transition: transform var(--anim-direction); }
    @media screen and (min-width: 160.0625rem) {
      .select-current__icon {
        width: 0.9375vw;
        height: 0.9375vw; } }
    @media screen and (max-width: 131.25rem) {
      .select-current__icon {
        width: 1.25rem;
        height: 1.25rem; } }
  .select-current__text {
    width: 100%;
    max-width: 100%;
    display: block;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    user-select: none; }

.select-answer {
  position: absolute;
  z-index: 4;
  top: 100%;
  left: 0;
  padding: 0.75rem 0;
  margin-top: -0.625rem;
  width: 100%;
  min-width: 12.5rem;
  height: auto;
  max-height: 24rem;
  display: flex;
  flex-direction: column;
  background-color: var(--color-white);
  border: 1px solid var(--stroke-dark-secondary);
  overflow: auto;
  transition: margin-top var(--anim-direction), opacity var(--anim-direction), visibility var(--anim-direction);
  opacity: 0;
  visibility: hidden; }
  @media screen and (min-width: 160.0625rem) {
    .select-answer {
      min-width: 7.8125vw;
      max-height: 15vw;
      padding: 0.46875vw 0;
      margin-top: -0.39062vw; } }
  @media screen and (max-width: 103.125rem) {
    .select-answer {
      max-height: 18rem; } }
  .select-answer::-webkit-scrollbar {
    width: 0.25rem; }
    @media screen and (min-width: 160.0625rem) {
      .select-answer::-webkit-scrollbar {
        width: 0.15625vw; } }
  .select-answer::-webkit-scrollbar-track {
    background: var(--color-white); }
  .select-answer::-webkit-scrollbar-thumb {
    background-color: var(--color-dark); }
  .select-answer__item {
    padding: 0.875rem 1.25rem;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: start;
    color: var(--color-text-dark);
    text-align: start;
    font: var(--font-body-M);
    text-decoration: none;
    background-color: var(--color-white);
    border: none;
    transition: background-color var(--animation-timing) var(--cubic-bezier), color var(--animation-timing) var(--cubic-bezier);
    cursor: pointer; }
    @media screen and (min-width: 160.0625rem) {
      .select-answer__item {
        padding: 0.54688vw 0.78125vw; } }
    @media screen and (max-width: 103.125rem) {
      .select-answer__item {
        padding: 0.625rem 1rem; } }
    @media screen and (max-width: 70.5rem) {
      .select-answer__item {
        padding: 0.75rem 1rem; } }
    .select-answer__item:hover {
      background-color: var(--color-gray-3); }
    .select-answer__item:focus, .select-answer__item:active {
      color: var(--color-text-dark);
      background-color: var(--color-gray-3); }

.breadcrumbs {
  display: flex;
  list-style-type: none;
  padding: 0;
  margin: 0; }
  .breadcrumbs__item {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: var(--color-text-dark);
    font: var(--font-body-M-caption);
    letter-spacing: -0.54px;
    text-transform: uppercase; }
    @media screen and (max-width: 131.25rem) {
      .breadcrumbs__item {
        letter-spacing: -0.42px; } }
    @media screen and (max-width: 103.125rem) {
      .breadcrumbs__item {
        letter-spacing: -0.36px; } }
    .breadcrumbs__item:not(:last-child):after {
      content: "";
      margin: 0 1rem;
      width: 0.125rem;
      height: 0.125rem;
      display: inline-block;
      min-width: 0.125rem;
      background: var(--color-text-dark-secondary); }
      @media screen and (min-width: 160.0625rem) {
        .breadcrumbs__item:not(:last-child):after {
          min-width: 0.07812vw;
          width: 0.07812vw;
          height: 0.07812vw;
          margin: 0 0.625vw; } }
      @media screen and (max-width: 131.25rem) {
        .breadcrumbs__item:not(:last-child):after {
          margin: 0 0.75rem; } }
      @media screen and (max-width: 45rem) {
        .breadcrumbs__item:not(:last-child):after {
          margin: 0 0.375rem; } }
    .breadcrumbs__item a {
      text-decoration: none;
      color: var(--color-text-dark-secondary); }
    .breadcrumbs__item_active {
      display: inline-flex;
      line-height: 1.5rem;
      height: 1.5rem; }
      @media screen and (min-width: 160.0625rem) {
        .breadcrumbs__item_active {
          line-height: 0.9375vw;
          height: 0.9375vw; } }
      @media screen and (max-width: 131.25rem) {
        .breadcrumbs__item_active {
          line-height: 1.25rem;
          height: 1.25rem; } }
      @media screen and (max-width: 103.125rem) {
        .breadcrumbs__item_active {
          line-height: 1rem;
          height: 1rem; } }
      .breadcrumbs__item_active span {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis; }
  .breadcrumbs__item-text {
    display: block;
    line-height: 1.5rem;
    height: 1.5rem; }
    @media screen and (min-width: 160.0625rem) {
      .breadcrumbs__item-text {
        line-height: 0.9375vw;
        height: 0.9375vw; } }
    @media screen and (max-width: 131.25rem) {
      .breadcrumbs__item-text {
        line-height: 1.25rem;
        height: 1.25rem; } }
    @media screen and (max-width: 103.125rem) {
      .breadcrumbs__item-text {
        line-height: 1rem;
        height: 1rem; } }
  .breadcrumbs_dark .breadcrumbs__item {
    color: var(--color-text-light); }
    .breadcrumbs_dark .breadcrumbs__item:after {
      background: #D9D9D9; }
    .breadcrumbs_dark .breadcrumbs__item a {
      color: var(--color-text-light-secondary); }

.pagination {
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  gap: 0.75rem;
  list-style-type: none; }
  @media screen and (min-width: 160.0625rem) {
    .pagination {
      gap: 0.46875vw; } }
  @media screen and (max-width: 70.5rem) {
    .pagination {
      gap: 0.5rem; } }
  @media screen and (max-width: 45rem) {
    .pagination {
      flex-wrap: wrap;
      gap: 0.25rem; } }
  .pagination__item .icon-button {
    color: var(--color-dark); }
    .pagination__item .icon-button span:not(.button-hover-effects) {
      position: relative;
      z-index: 2;
      font: var(--font-body-M);
      letter-spacing: var(--text-spacing);
      text-decoration: none;
      line-height: 1.5rem;
      transition: color var(--anim-direction); }
      @media screen and (min-width: 160.0625rem) {
        .pagination__item .icon-button span:not(.button-hover-effects) {
          gap: 0.9375vw; } }
    .pagination__item .icon-button:hover span {
      color: var(--color-white); }
  .pagination__item_active .icon-button {
    color: var(--color-white);
    background: var(--color-secondary);
    cursor: default; }

.button-tab {
  position: relative;
  display: inline-table;
  padding: 0 2.5rem;
  margin: 0;
  width: fit-content;
  font: var(--font-body-M);
  letter-spacing: var(--text-spacing);
  line-height: 3.625rem;
  color: var(--color-dark);
  font-style: normal;
  text-decoration: none;
  background: transparent;
  border: 1px solid var(--stroke-dark-secondary);
  border-radius: 3.75rem;
  cursor: pointer;
  user-select: none;
  overflow: hidden;
  transition: color var(--anim-direction), border-color var(--anim-direction), background-color var(--anim-direction); }
  @media screen and (min-width: 160.0625rem) {
    .button-tab {
      padding: 0 1.5625vw;
      line-height: 2.26562vw;
      border-radius: 2.34375vw; } }
  @media screen and (max-width: 131.25rem) {
    .button-tab {
      padding: 0 2rem;
      line-height: 3.25rem; } }
  @media screen and (max-width: 103.125rem) {
    .button-tab {
      padding: 0 1.5rem;
      line-height: 2.625rem; } }
  .button-tab__text {
    position: relative;
    z-index: 2; }
  .button-tab .button-hover-effects {
    position: absolute;
    top: 0;
    right: -50px;
    display: block;
    width: 0;
    height: 0;
    background-color: var(--color-dark);
    transition: width var(--anim-direction);
    z-index: 1; }
    .button-tab .button-hover-effects::before {
      content: "";
      padding-top: 100%;
      width: 100%;
      float: left;
      border-radius: 50%;
      transform: translate(-50%, -50%);
      background-color: inherit;
      transition: width var(--anim-direction), transform var(--anim-direction); }
  .button-tab:hover {
    color: var(--color-white);
    border-color: var(--color-dark); }
    .button-tab:hover .button-hover-effects {
      width: 250%; }
  .button-tab_active {
    background: var(--color-dark);
    border-color: var(--color-dark);
    color: var(--color-white); }
  .button-tab_dark {
    border-color: var(--color-text-light-secondary);
    color: var(--color-white); }
    .button-tab_dark .button-hover-effects {
      background: var(--color-white); }
    .button-tab_dark:hover {
      border-color: var(--color-white);
      color: var(--color-dark); }
    .button-tab_dark.button-tab_active {
      border-color: var(--color-white);
      background: var(--color-white);
      color: var(--color-dark); }

.button-tab-line {
  position: relative;
  display: inline-flex;
  align-items: center;
  padding: 0;
  padding-bottom: 1.25rem;
  margin: 0;
  width: fit-content;
  font: var(--font-body-M);
  letter-spacing: var(--text-spacing);
  color: var(--color-dark);
  font-style: normal;
  text-decoration: none;
  background: transparent;
  border: 0;
  border-radius: 0;
  cursor: pointer;
  user-select: none;
  overflow: hidden;
  transition: color var(--anim-direction); }
  @media screen and (min-width: 160.0625rem) {
    .button-tab-line {
      padding-bottom: 0.78125vw; } }
  @media screen and (max-width: 103.125rem) {
    .button-tab-line {
      padding-bottom: 1rem; } }
  @media screen and (max-width: 45rem) {
    .button-tab-line {
      padding-bottom: 0.5rem; } }
  .button-tab-line:after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
    display: block;
    width: 0;
    height: 0.1875rem;
    margin: 0 auto;
    background: var(--color-secondary);
    transition: width var(--anim-direction); }
    @media screen and (min-width: 160.0625rem) {
      .button-tab-line:after {
        height: 0.11719vw; } }
  .button-tab-line_active .button-tab-line__count {
    background-color: var(--color-secondary); }
  .button-tab-line_active:after {
    width: 100%; }
  .button-tab-line:hover:after {
    width: 100%; }
  .button-tab-line__count {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    line-height: 2.75rem;
    height: 2.75rem;
    width: 2.75rem;
    min-width: 2.75rem;
    padding: 0;
    background-color: var(--color-dark);
    color: var(--color-white);
    border-radius: 50%;
    margin-right: 0.9375rem;
    font: var(--font-body-M-caption);
    transition: background-color var(--anim-direction), color var(--anim-direction); }
    @media screen and (min-width: 160.0625rem) {
      .button-tab-line__count {
        line-height: 1.71875vw;
        height: 1.71875vw;
        width: 1.71875vw;
        min-width: 1.71875vw;
        margin-right: 0.58594vw; } }
    @media screen and (max-width: 131.25rem) {
      .button-tab-line__count {
        line-height: 2.25rem;
        height: 2.25rem;
        width: 2.25rem;
        min-width: 2.25rem; } }
    @media screen and (max-width: 103.125rem) {
      .button-tab-line__count {
        line-height: 2rem;
        height: 2rem;
        width: 2rem;
        min-width: 2rem;
        margin-right: 0.75rem; } }
    @media screen and (max-width: 70.5rem) {
      .button-tab-line__count {
        line-height: 1.5rem;
        height: 1.5rem;
        width: 1.5rem;
        min-width: 1.5rem;
        margin-right: 0.5rem; } }
  .button-tab-line_light {
    color: var(--color-white); }
    .button-tab-line_light .button-tab-line__count {
      background-color: var(--color-white);
      color: var(--color-text-dark); }

.visually-hidden:checked + .button-tab {
  background: var(--color-dark);
  border-color: var(--color-dark);
  color: var(--color-white); }
  .visually-hidden:checked + .button-tab_dark {
    border-color: var(--color-white);
    background: var(--color-white);
    color: var(--color-dark); }

.visually-hidden:checked + .button-tab-line:after {
  width: 100%; }

.visually-hidden:disabled + .button-tab {
  cursor: default;
  background: transparent;
  border-color: var(--stroke-dark-secondary);
  color: var(--color-text-light-disable); }
  .visually-hidden:disabled + .button-tab .button-hover-effects {
    display: none; }
  .visually-hidden:disabled + .button-tab_dark {
    background: transparent;
    border-color: var(--stroke-light-secondary);
    color: var(--color-text-light-disable); }

.tag {
  display: inline-block;
  padding: 0 1rem;
  margin: 0;
  width: fit-content;
  font: var(--font-body-M-caption);
  letter-spacing: var(--text-spacing);
  line-height: 2.5rem;
  text-decoration: none;
  text-transform: uppercase;
  color: var(--color-secondary);
  background: var(--color-gray-3);
  border: none;
  border-radius: 2.5rem;
  cursor: pointer;
  user-select: none;
  overflow: hidden;
  transition: color var(--anim-direction), background-color var(--anim-direction); }
  @media screen and (min-width: 160.0625rem) {
    .tag {
      padding: 0 0.625vw;
      line-height: 1.5625vw;
      border-radius: 1.5625vw; } }
  @media screen and (max-width: 131.25rem) {
    .tag {
      padding: 0 0.75rem;
      line-height: 1.75rem;
      border-radius: 1.75rem; } }
  @media screen and (max-width: 103.125rem) {
    .tag {
      line-height: 1.5rem;
      border-radius: 1.5rem; } }
  .tag:hover {
    background: var(--color-secondary);
    color: var(--color-white); }
  .tag:disabled, .tag.disabled {
    cursor: default;
    background: var(--color-gray-3);
    color: var(--color-text-dark-disable);
    pointer-events: none; }
  .tag_light {
    background: var(--color-white); }

.main-banner {
  padding-top: 6.875rem;
  padding-bottom: 6.25rem;
  position: relative;
  z-index: 2;
  color: var(--color-white);
  overflow: hidden;
  min-height: 50rem;
  height: 100vh; }
  @media screen and (min-width: 160.0625rem) {
    .main-banner {
      padding-top: 4.29688vw;
      padding-bottom: 3.90625vw;
      min-height: 31.25vw; } }
  @media screen and (max-width: 131.25rem) {
    .main-banner {
      padding-top: 5.9375rem;
      padding-bottom: 4.25rem;
      min-height: 43.75rem; } }
  @media screen and (max-width: 103.125rem) {
    .main-banner {
      padding-top: 5rem;
      padding-bottom: 3rem;
      min-height: 37.5rem; } }
  @media screen and (max-width: 70.5rem) {
    .main-banner {
      padding-top: 4rem;
      padding-bottom: 2.5rem;
      min-height: 25rem; } }
  @media screen and (max-width: 45rem) {
    .main-banner {
      padding-top: 2.75rem; } }
  .main-banner:before {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
    background-color: rgba(0, 0, 0, 0.3); }
  .main-banner .container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: 100%;
    position: relative;
    z-index: 2; }
  .main-banner__title {
    margin-top: var(--section-offset);
    font-size: 7.1875rem;
    line-height: 0.97;
    font-weight: 500;
    letter-spacing: -3.45px;
    max-width: 80.3125rem;
    color: var(--color-primary); }
    @media screen and (min-width: 160.0625rem) {
      .main-banner__title {
        font-size: 4.49219vw;
        max-width: 50.19531vw; } }
    @media screen and (max-width: 131.25rem) {
      .main-banner__title {
        font-size: 5.75rem;
        line-height: 0.91;
        letter-spacing: -2.76px;
        max-width: 60.125rem; } }
    @media screen and (max-width: 103.125rem) {
      .main-banner__title {
        font-size: 4.25rem;
        line-height: 0.97;
        letter-spacing: -2.04px;
        max-width: 43.875rem; } }
    @media screen and (max-width: 70.5rem) {
      .main-banner__title {
        font-size: 3.75rem;
        line-height: 0.93;
        letter-spacing: -1.8px;
        max-width: 100%; } }
    @media screen and (max-width: 45rem) {
      .main-banner__title {
        margin-top: 2.5rem;
        font-size: 1.875rem;
        line-height: 1.06;
        letter-spacing: -0.9px; } }
  .main-banner__desc {
    margin: 2.5rem 0;
    max-width: 55.3125rem;
    font: var(--font-body-M); }
    @media screen and (min-width: 160.0625rem) {
      .main-banner__desc {
        margin: 1.5625vw 0;
        max-width: 34.57031vw; } }
    @media screen and (max-width: 131.25rem) {
      .main-banner__desc {
        margin: 2rem 0;
        max-width: 45rem; } }
    @media screen and (max-width: 103.125rem) {
      .main-banner__desc {
        margin: 1.5rem 0;
        max-width: 31.375rem; } }
    @media screen and (max-width: 70.5rem) {
      .main-banner__desc {
        max-width: 100%; } }
    @media screen and (max-width: 45rem) {
      .main-banner__desc {
        margin: 1rem 0; } }
    .main-banner__desc p {
      margin: 0; }
      .main-banner__desc p:not(:last-child) {
        margin-bottom: 0.9375rem; }
        @media screen and (min-width: 160.0625rem) {
          .main-banner__desc p:not(:last-child) {
            margin-bottom: 0.58594vw; } }
        @media screen and (max-width: 70.5rem) {
          .main-banner__desc p:not(:last-child) {
            margin-bottom: 0.5rem; } }
  .main-banner__btns {
    margin-top: auto; }
  .main-banner__video {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 0;
    width: 100%;
    max-width: 100%;
    height: 100%;
    object-fit: cover;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat; }
    .main-banner__video picture {
      display: block;
      font-size: 0;
      width: 100%;
      height: 100%; }
      .main-banner__video picture img {
        width: 100%;
        max-width: 100%;
        height: 100%;
        object-fit: cover; }

@supports (height: 100dvh) {
  .main-banner {
    height: 100dvh; } }

.page-banner {
  padding-top: 6.875rem;
  padding-bottom: 6.25rem;
  position: relative;
  z-index: 2;
  color: var(--color-white);
  background-image: url("/assets/templates/img/banners/projects-bg.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  overflow: hidden; }
  @media screen and (min-width: 160.0625rem) {
    .page-banner {
      padding-top: 4.29688vw;
      padding-bottom: 3.90625vw; } }
  @media screen and (max-width: 131.25rem) {
    .page-banner {
      padding-top: 5.9375rem;
      padding-bottom: 4.25rem; } }
  @media screen and (max-width: 103.125rem) {
    .page-banner {
      padding-top: 5rem;
      padding-bottom: 3rem; } }
  @media screen and (max-width: 70.5rem) {
    .page-banner {
      padding-top: 4rem;
      padding-bottom: 2.5rem; } }
  @media screen and (max-width: 45rem) {
    .page-banner {
      padding-top: 2.75rem; } }
  .page-banner:before {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
    background-color: rgba(0, 0, 0, 0.5); }
  .page-banner .container {
    position: relative;
    z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    min-height: 39.375rem; }
    @media screen and (min-width: 160.0625rem) {
      .page-banner .container {
        min-height: 24.60938vw; } }
    @media screen and (max-width: 131.25rem) {
      .page-banner .container {
        min-height: 30.875rem; } }
    @media screen and (max-width: 103.125rem) {
      .page-banner .container {
        min-height: 23.25rem; } }
    @media screen and (max-width: 70.5rem) {
      .page-banner .container {
        min-height: 24.75rem; } }
    @media screen and (max-width: 45rem) {
      .page-banner .container {
        min-height: 28.5rem; } }
  .page-banner__breadcrumbs {
    padding: 2rem 0; }
    @media screen and (min-width: 160.0625rem) {
      .page-banner__breadcrumbs {
        padding: 1.25vw 0; } }
    @media screen and (max-width: 103.125rem) {
      .page-banner__breadcrumbs {
        padding: 1.5rem 0; } }
    @media screen and (max-width: 45rem) {
      .page-banner__breadcrumbs {
        padding: 0.875rem 0; } }
  .page-banner__row {
    flex: 1;
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: 6.25rem; }
    @media screen and (min-width: 160.0625rem) {
      .page-banner__row {
        gap: 3.90625vw; } }
    @media screen and (max-width: 131.25rem) {
      .page-banner__row {
        gap: 4.25rem; } }
    @media screen and (max-width: 103.125rem) {
      .page-banner__row {
        gap: 3rem; } }
    @media screen and (max-width: 70.5rem) {
      .page-banner__row {
        gap: 0;
        flex-direction: column; } }
    @media screen and (max-width: 81.25rem) {
      .page-banner__row .page-banner__utp {
        flex-direction: column; }
        .page-banner__row .page-banner__utp .block-banner-utp:not(:first-child) {
          margin-left: 0; } }
    @media screen and (max-width: 70.5rem) {
      .page-banner__row .page-banner__utp {
        flex-direction: row;
        margin-top: 3.75rem; }
        .page-banner__row .page-banner__utp .block-banner-utp:not(:first-child) {
          margin-left: -1px; } }
    @media screen and (max-width: 45rem) {
      .page-banner__row .page-banner__utp {
        flex-direction: column;
        margin-top: 1.25rem; }
        .page-banner__row .page-banner__utp .block-banner-utp {
          flex-direction: row; }
          .page-banner__row .page-banner__utp .block-banner-utp:not(:first-child) {
            margin-left: 0; } }
  .page-banner__col {
    display: flex;
    flex-direction: column; }
  .page-banner__title {
    margin-top: 4.25rem; }
    @media screen and (min-width: 160.0625rem) {
      .page-banner__title {
        margin-top: 2.65625vw; } }
    @media screen and (max-width: 131.25rem) {
      .page-banner__title {
        margin-top: 3rem; } }
    @media screen and (max-width: 103.125rem) {
      .page-banner__title {
        margin-top: 1.5rem; } }
    @media screen and (max-width: 70.5rem) {
      .page-banner__title {
        margin-top: 1rem; } }
  .page-banner__desc {
    margin: 2.5rem 0;
    max-width: 55.3125rem;
    font: var(--font-body-M); }
    @media screen and (min-width: 160.0625rem) {
      .page-banner__desc {
        margin: 1.5625vw 0;
        max-width: 34.57031vw; } }
    @media screen and (max-width: 131.25rem) {
      .page-banner__desc {
        margin: 2rem 0;
        max-width: 41.375rem; } }
    @media screen and (max-width: 103.125rem) {
      .page-banner__desc {
        margin: 1.5rem 0;
        max-width: 38.25rem; } }
    @media screen and (max-width: 70.5rem) {
      .page-banner__desc {
        max-width: 20.75rem; } }
    @media screen and (max-width: 45rem) {
      .page-banner__desc {
        margin: 1rem 0;
        max-width: 100%; } }
    .page-banner__desc p {
      margin-bottom: 0; }
      .page-banner__desc p:first-child {
        margin-top: 0; }
  .page-banner__nav {
    margin-top: auto;
    max-width: 91.875rem; }
    @media screen and (min-width: 160.0625rem) {
      .page-banner__nav {
        max-width: 57.42188vw; } }
    @media screen and (max-width: 131.25rem) {
      .page-banner__nav {
        max-width: 77.8125rem; } }
    @media screen and (max-width: 103.125rem) {
      .page-banner__nav {
        max-width: 58.75rem; } }
    @media screen and (max-width: 70.5rem) {
      .page-banner__nav {
        max-width: 100%;
        overflow: auto; } }
    .page-banner__nav-wrap-scroll {
      display: flex;
      flex-wrap: wrap;
      gap: 0.75rem; }
      @media screen and (min-width: 160.0625rem) {
        .page-banner__nav-wrap-scroll {
          gap: 0.46875vw; } }
      @media screen and (max-width: 103.125rem) {
        .page-banner__nav-wrap-scroll {
          gap: 0.5rem; } }
      @media screen and (max-width: 45rem) {
        .page-banner__nav-wrap-scroll {
          flex-wrap: nowrap;
          width: max-content; } }
      .page-banner__nav-wrap-scroll label {
        position: relative;
        overflow: hidden;
        border-radius: 3.75rem; }
        @media screen and (min-width: 160.0625rem) {
          .page-banner__nav-wrap-scroll label {
            border-radius: 2.34375vw; } }
  .page-banner__utp {
    display: flex;
    margin-top: auto;
    max-width: 92.3125rem; }
    @media screen and (min-width: 160.0625rem) {
      .page-banner__utp {
        max-width: 57.69531vw; } }
    @media screen and (max-width: 131.25rem) {
      .page-banner__utp {
        max-width: 68.75rem; } }
    @media screen and (max-width: 103.125rem) {
      .page-banner__utp {
        max-width: 100%;
        width: 100%; } }
    @media screen and (max-width: 45rem) {
      .page-banner__utp {
        flex-direction: column;
        padding-top: 0.75rem; } }
    .page-banner__utp .block-banner-utp {
      flex: 1; }
      .page-banner__utp .block-banner-utp:not(:first-child) {
        margin-left: -1px; }
        @media screen and (max-width: 45rem) {
          .page-banner__utp .block-banner-utp:not(:first-child) {
            margin-left: 0;
            margin-top: -1px; } }
  .page-banner__form {
    position: relative;
    width: 43rem;
    margin-top: 3.75rem; }
    @media screen and (min-width: 160.0625rem) {
      .page-banner__form {
        width: 26.875vw;
        margin-top: 2.34375vw; } }
    @media screen and (max-width: 131.25rem) {
      .page-banner__form {
        width: 32.25rem;
        margin-top: auto; } }
    @media screen and (max-width: 103.125rem) {
      .page-banner__form {
        width: 24.5rem; } }
    @media screen and (max-width: 70.5rem) {
      .page-banner__form {
        width: 100%;
        margin-top: 3.125rem;
        border: 1px solid var(--stroke-dark-secondary); } }
    @media screen and (max-width: 45rem) {
      .page-banner__form {
        margin-top: 1.75rem; } }
  @media screen and (max-width: 70.5rem) {
    .page-banner_full {
      background-size: auto 100vh;
      background-position: top; }
      .page-banner_full:before {
        min-height: 100vh; } }
  .page-banner_full .container {
    min-height: calc(100vh - 13.125rem); }
    @media screen and (min-width: 160.0625rem) {
      .page-banner_full .container {
        min-height: calc(100vh - 8.20312vw); } }
    @media screen and (max-width: 131.25rem) {
      .page-banner_full .container {
        min-height: calc(100vh - 10.1875rem); } }
    @media screen and (max-width: 103.125rem) {
      .page-banner_full .container {
        min-height: calc(100vh - 8rem); } }
    @media screen and (max-width: 70.5rem) {
      .page-banner_full .container {
        height: auto;
        min-height: auto; } }

@supports (height: 100dvh) {
  @media screen and (max-width: 70.5rem) {
    .page-banner_full:before {
      min-height: 100dvh; } }
  .page-banner_full .container {
    min-height: calc(100dvh - 13.125rem); }
    @media screen and (min-width: 160.0625rem) {
      .page-banner_full .container {
        min-height: calc(100dvh - 8.20312vw); } }
    @media screen and (max-width: 131.25rem) {
      .page-banner_full .container {
        min-height: calc(100dvh - 10.1875rem); } }
    @media screen and (max-width: 103.125rem) {
      .page-banner_full .container {
        min-height: calc(100dvh - 8rem); } }
    @media screen and (max-width: 70.5rem) {
      .page-banner_full .container {
        height: auto;
        min-height: auto; } } }

.block-banner-utp {
  display: flex;
  align-items: center;
  padding: 2.5rem;
  color: var(--color-white);
  border: 1px solid var(--stroke-light-secondary);
  gap: 1.5rem;
  width: 100%; }
  @media screen and (min-width: 160.0625rem) {
    .block-banner-utp {
      padding: 1.5625vw;
      gap: 0.9375vw; } }
  @media screen and (max-width: 131.25rem) {
    .block-banner-utp {
      padding: 2rem;
      gap: 1.25rem; } }
  @media screen and (max-width: 103.125rem) {
    .block-banner-utp {
      padding: 1.5rem;
      gap: 1rem; } }
  @media screen and (max-width: 70.5rem) {
    .block-banner-utp {
      gap: 0.75rem;
      flex-direction: column;
      align-items: flex-start; } }
  @media screen and (max-width: 45rem) {
    .block-banner-utp {
      padding: 1.25rem;
      gap: 0.75rem; } }
  .block-banner-utp__icon {
    min-width: 3rem;
    width: 3rem;
    height: 3rem;
    display: block;
    fill: currentColor; }
    @media screen and (min-width: 160.0625rem) {
      .block-banner-utp__icon {
        min-width: 1.875vw;
        width: 1.875vw;
        height: 1.875vw; } }
    @media screen and (max-width: 70.5rem) {
      .block-banner-utp__icon {
        min-width: 2rem;
        width: 2rem;
        height: 2rem; } }
    .block-banner-utp__icon img {
      width: 100%;
      height: 100%;
      object-fit: contain; }
  .block-banner-utp__text {
    font: var(--font-body-M-semibold); }

.cta-form {
  width: 100%; }
  .cta-form__title {
    display: block;
    font: var(--font-h4);
    letter-spacing: var(--letter-spacing-h4);
    color: var(--color-text-dark);
    margin-bottom: 2.5rem; }
    @media screen and (min-width: 160.0625rem) {
      .cta-form__title {
        margin-bottom: 1.5625vw; } }
    @media screen and (max-width: 131.25rem) {
      .cta-form__title {
        margin-bottom: 1.875rem; } }
    @media screen and (max-width: 103.125rem) {
      .cta-form__title {
        margin-bottom: 1.5rem; } }
    .cta-form__title span {
      color: var(--color-secondary); }
  .cta-form__content {
    padding: 6.25rem;
    color: var(--color-text-dark);
    background-color: var(--color-gray-3); }
    @media screen and (min-width: 160.0625rem) {
      .cta-form__content {
        padding: 3.90625vw; } }
    @media screen and (max-width: 131.25rem) {
      .cta-form__content {
        padding: 4.25rem; } }
    @media screen and (max-width: 103.125rem) {
      .cta-form__content {
        padding: 3rem; } }
    .cta-form__content form .label-group {
      margin-bottom: 1.5rem; }
      @media screen and (min-width: 160.0625rem) {
        .cta-form__content form .label-group {
          margin-bottom: 0.9375vw; } }
      @media screen and (max-width: 131.25rem) {
        .cta-form__content form .label-group {
          margin-bottom: 1.25rem; } }
    .cta-form__content form .checkbox {
      margin-top: 2.5rem; }
      @media screen and (min-width: 160.0625rem) {
        .cta-form__content form .checkbox {
          margin-top: 1.5625vw; } }
      @media screen and (max-width: 131.25rem) {
        .cta-form__content form .checkbox {
          margin-top: 2rem; } }
      @media screen and (max-width: 103.125rem) {
        .cta-form__content form .checkbox {
          margin-top: 1.5rem; } }
    .cta-form__content form .button {
      width: 100%;
      margin-top: 2.5rem; }
      @media screen and (min-width: 160.0625rem) {
        .cta-form__content form .button {
          margin-top: 1.5625vw; } }
      @media screen and (max-width: 131.25rem) {
        .cta-form__content form .button {
          margin-top: 2rem; } }
      @media screen and (max-width: 103.125rem) {
        .cta-form__content form .button {
          margin-top: 1.5rem; } }
  .cta-form__footer {
    padding: 6.25rem;
    background-color: var(--color-gray-7);
    font: var(--font-body-M); }
    @media screen and (min-width: 160.0625rem) {
      .cta-form__footer {
        padding: 3.90625vw; } }
    @media screen and (max-width: 131.25rem) {
      .cta-form__footer {
        padding: 4.25rem; } }
    @media screen and (max-width: 103.125rem) {
      .cta-form__footer {
        padding: 3rem; } }
    .cta-form__footer .icon-text-button {
      gap: 1.25rem;
      color: var(--color-secondary);
      margin-bottom: 1.5rem; }
      @media screen and (min-width: 160.0625rem) {
        .cta-form__footer .icon-text-button {
          gap: 0.78125vw;
          margin-bottom: 0.9375vw; } }
      @media screen and (max-width: 131.25rem) {
        .cta-form__footer .icon-text-button {
          margin-bottom: 1.25rem; } }
      @media screen and (max-width: 103.125rem) {
        .cta-form__footer .icon-text-button {
          margin-bottom: 1rem; } }
    .cta-form__footer p {
      margin: 0; }

.cta-call {
  padding: 2.5rem;
  gap: 2.5rem;
  display: flex;
  background-color: var(--color-gray-3);
  color: var(--color-text-dark); }
  @media screen and (min-width: 160.0625rem) {
    .cta-call {
      padding: 1.5625vw;
      gap: 1.5625vw; } }
  @media screen and (max-width: 131.25rem) {
    .cta-call {
      padding: 2rem;
      gap: 2rem; } }
  @media screen and (max-width: 103.125rem) {
    .cta-call {
      padding: 1.5rem; } }
  @media screen and (max-width: 45rem) {
    .cta-call {
      padding: 1.25rem;
      gap: 1.25rem;
      flex-direction: column; } }
  .cta-call__content {
    font: var(--font-body-M);
    flex: 1; }
    .cta-call__content .icon-text-button {
      gap: 1.25rem;
      color: var(--color-secondary); }
      @media screen and (min-width: 160.0625rem) {
        .cta-call__content .icon-text-button {
          gap: 0.78125vw; } }
    .cta-call__content p {
      margin: 0;
      margin-top: 1.25rem; }
      @media screen and (min-width: 160.0625rem) {
        .cta-call__content p {
          margin-top: 0.78125vw; } }
      @media screen and (max-width: 103.125rem) {
        .cta-call__content p {
          margin-top: 1rem; } }
  .cta-call__btns {
    align-self: flex-end;
    flex-basis: 16.6875rem; }
    @media screen and (min-width: 160.0625rem) {
      .cta-call__btns {
        flex-basis: 10.42969vw; } }
    @media screen and (max-width: 131.25rem) {
      .cta-call__btns {
        flex-basis: 14.1875rem; } }
    @media screen and (max-width: 103.125rem) {
      .cta-call__btns {
        flex-basis: 11.9375rem; } }
    @media screen and (max-width: 70.5rem) {
      .cta-call__btns {
        flex-basis: 10.5rem; } }
    @media screen and (max-width: 45rem) {
      .cta-call__btns {
        flex-basis: 100%;
        width: 100%; } }
    .cta-call__btns .button {
      width: 100%; }

.cta-form-light {
  display: block;
  padding: 6.25rem;
  background-color: var(--color-white);
  color: var(--color-text-dark); }
  @media screen and (min-width: 160.0625rem) {
    .cta-form-light {
      padding: 3.90625vw; } }
  @media screen and (max-width: 131.25rem) {
    .cta-form-light {
      padding: 4.25rem; } }
  @media screen and (max-width: 103.125rem) {
    .cta-form-light {
      padding: 3rem; } }
  @media screen and (max-width: 70.5rem) {
    .cta-form-light {
      padding: 2.5rem; } }
  @media screen and (max-width: 45rem) {
    .cta-form-light {
      padding: 1.25rem; } }
  .cta-form-light__title {
    color: var(--color-text-dark);
    font: var(--font-h3);
    letter-spacing: var(--letter-spacing-h3); }
    .cta-form-light__title span {
      color: var(--color-secondary); }
  .cta-form-light__text {
    font: var(--font-body-M);
    margin-top: 1.5rem; }
    @media screen and (min-width: 160.0625rem) {
      .cta-form-light__text {
        margin-top: 0.9375vw; } }
    @media screen and (max-width: 131.25rem) {
      .cta-form-light__text {
        margin-top: 1.25rem; } }
    @media screen and (max-width: 70.5rem) {
      .cta-form-light__text {
        margin-top: 1rem; } }
    @media screen and (max-width: 70.5rem) {
      .cta-form-light__text {
        margin-top: 0.75rem; } }
  .cta-form-light__form {
    margin-top: 3rem; }
    @media screen and (min-width: 160.0625rem) {
      .cta-form-light__form {
        margin-top: 1.875vw; } }
    @media screen and (max-width: 131.25rem) {
      .cta-form-light__form {
        margin-top: 2rem; } }
    @media screen and (max-width: 45rem) {
      .cta-form-light__form {
        margin-top: 1.25rem; } }
    .cta-form-light__form form {
      display: flex;
      flex-direction: column;
      gap: 3rem; }
      @media screen and (min-width: 160.0625rem) {
        .cta-form-light__form form {
          gap: 1.875vw; } }
      @media screen and (max-width: 131.25rem) {
        .cta-form-light__form form {
          gap: 2.5rem; } }
      @media screen and (max-width: 103.125rem) {
        .cta-form-light__form form {
          gap: 2rem; } }
      @media screen and (max-width: 70.5rem) {
        .cta-form-light__form form {
          gap: 1.5rem; } }
      @media screen and (max-width: 45rem) {
        .cta-form-light__form form {
          gap: 1.25rem; } }
    .cta-form-light__form .label-form-variable {
      display: flex;
      align-items: center;
      width: 100%; }
      .cta-form-light__form .label-form-variable .label-variable-btn {
        width: 100%; }
    .cta-form-light__form .label-row {
      display: flex;
      align-items: flex-start;
      gap: 2rem; }
      @media screen and (min-width: 160.0625rem) {
        .cta-form-light__form .label-row {
          gap: 1.25vw; } }
      @media screen and (max-width: 70.5rem) {
        .cta-form-light__form .label-row {
          gap: 1.5rem; } }
      @media screen and (max-width: 45rem) {
        .cta-form-light__form .label-row {
          flex-direction: column;
          gap: 1rem; } }
    .cta-form-light__form .label-group {
      display: block; }
    .cta-form-light__form .form-footer {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 2rem; }
      @media screen and (min-width: 160.0625rem) {
        .cta-form-light__form .form-footer {
          gap: 1.25vw; } }
      @media screen and (max-width: 131.25rem) {
        .cta-form-light__form .form-footer .checkbox {
          width: 50%; } }
      @media screen and (max-width: 70.5rem) {
        .cta-form-light__form .form-footer {
          gap: 1.5rem; }
          .cta-form-light__form .form-footer .button,
          .cta-form-light__form .form-footer .checkbox {
            width: 100%; }
          .cta-form-light__form .form-footer .button {
            width: calc(100% - 1.5rem); } }
      @media screen and (max-width: 45rem) {
        .cta-form-light__form .form-footer {
          flex-direction: column;
          align-items: flex-start; }
          .cta-form-light__form .form-footer .button {
            width: 100%;
            margin-top: 0.25rem; } }

.cta-form-calc {
  padding: 3.75rem;
  background-color: var(--color-white);
  color: var(--color-text-dark); }
  @media screen and (min-width: 160.0625rem) {
    .cta-form-calc {
      padding: 2.34375vw; } }
  @media screen and (max-width: 131.25rem) {
    .cta-form-calc {
      padding: 3rem; } }
  @media screen and (max-width: 103.125rem) {
    .cta-form-calc {
      padding: 2.25rem; } }
  @media screen and (max-width: 70.5rem) {
    .cta-form-calc {
      padding: 2.5rem; } }
  @media screen and (max-width: 45rem) {
    .cta-form-calc {
      padding: 1.25rem; } }
  .cta-form-calc__title {
    display: block;
    font: var(--font-h4);
    letter-spacing: var(--letter-spacing-h4);
    margin-bottom: 2.5rem; }
    @media screen and (min-width: 160.0625rem) {
      .cta-form-calc__title {
        margin-bottom: 1.5625vw; } }
    @media screen and (max-width: 131.25rem) {
      .cta-form-calc__title {
        margin-bottom: 2rem; } }
    @media screen and (max-width: 103.125rem) {
      .cta-form-calc__title {
        margin-bottom: 1.5rem; } }
    @media screen and (max-width: 70.5rem) {
      .cta-form-calc__title {
        font: var(--font-h3);
        letter-spacing: var(--letter-spacing-h3); } }
    @media screen and (max-width: 45rem) {
      .cta-form-calc__title {
        margin-bottom: 1.25rem; } }
    .cta-form-calc__title span {
      color: var(--color-secondary); }
  .cta-form-calc__form {
    display: flex;
    flex-direction: column;
    gap: 1.25rem; }
    @media screen and (min-width: 160.0625rem) {
      .cta-form-calc__form {
        gap: 0.78125vw; } }
    @media screen and (max-width: 103.125rem) {
      .cta-form-calc__form {
        gap: 1rem; } }
    @media screen and (max-width: 70.5rem) {
      .cta-form-calc__form {
        gap: 1.5rem; } }
    @media screen and (max-width: 45rem) {
      .cta-form-calc__form {
        gap: 1rem; } }
    .cta-form-calc__form .double-group {
      margin-bottom: 0.25rem; }
      @media screen and (min-width: 160.0625rem) {
        .cta-form-calc__form .double-group {
          margin-bottom: 0.15625vw; } }
      @media screen and (max-width: 131.25rem) {
        .cta-form-calc__form .double-group {
          margin-bottom: 0; } }
    .cta-form-calc__form .label-row {
      display: flex;
      align-items: flex-start;
      gap: 1.5rem;
      margin-bottom: 0.25rem; }
      @media screen and (min-width: 160.0625rem) {
        .cta-form-calc__form .label-row {
          gap: 0.9375vw;
          margin-bottom: 0.15625vw; } }
      @media screen and (max-width: 131.25rem) {
        .cta-form-calc__form .label-row {
          gap: 1.25rem;
          margin-bottom: 0; } }
      @media screen and (max-width: 103.125rem) {
        .cta-form-calc__form .label-row {
          gap: 1rem; } }
      @media screen and (max-width: 70.5rem) {
        .cta-form-calc__form .label-row {
          gap: 1.5rem; } }
      @media screen and (max-width: 45rem) {
        .cta-form-calc__form .label-row {
          flex-direction: column;
          gap: 1rem; } }
      .cta-form-calc__form .label-row .select-input, .cta-form-calc__form .label-row .label-group {
        max-width: 50%; }
        @media screen and (max-width: 45rem) {
          .cta-form-calc__form .label-row .select-input, .cta-form-calc__form .label-row .label-group {
            max-width: 100%; } }
    .cta-form-calc__form .label-row-tablet {
      display: flex;
      flex-direction: column;
      gap: 1.25rem; }
      @media screen and (min-width: 160.0625rem) {
        .cta-form-calc__form .label-row-tablet {
          gap: 0.78125vw; } }
      @media screen and (max-width: 103.125rem) {
        .cta-form-calc__form .label-row-tablet {
          gap: 1rem; } }
      @media screen and (max-width: 70.5rem) {
        .cta-form-calc__form .label-row-tablet {
          flex-direction: row;
          align-items: center;
          gap: 1.5rem; } }
      @media screen and (max-width: 45rem) {
        .cta-form-calc__form .label-row-tablet {
          flex-direction: column;
          gap: 1rem; } }
    .cta-form-calc__form-footer {
      display: flex;
      flex-direction: column;
      gap: 1.25rem; }
      @media screen and (min-width: 160.0625rem) {
        .cta-form-calc__form-footer {
          gap: 0.78125vw; } }
      @media screen and (max-width: 103.125rem) {
        .cta-form-calc__form-footer {
          gap: 1rem; } }
      @media screen and (max-width: 70.5rem) {
        .cta-form-calc__form-footer {
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          gap: 1.5rem; } }
      @media screen and (max-width: 45rem) {
        .cta-form-calc__form-footer {
          flex-direction: column;
          align-items: flex-start;
          gap: 1.25rem;
          margin-top: 0.25rem; } }
    .cta-form-calc__form .checkbox {
      margin-top: 1.25rem; }
      @media screen and (min-width: 160.0625rem) {
        .cta-form-calc__form .checkbox {
          margin-top: 0.78125vw; } }
      @media screen and (max-width: 131.25rem) {
        .cta-form-calc__form .checkbox {
          margin-top: 0.25rem; } }
      @media screen and (max-width: 103.125rem) {
        .cta-form-calc__form .checkbox {
          margin-top: 0.5rem; } }
      @media screen and (max-width: 70.5rem) {
        .cta-form-calc__form .checkbox {
          margin-top: 0; } }
      .cta-form-calc__form .checkbox .checkbox__text {
        font: var(--font-body-S);
        font-size: 1rem; }
        @media screen and (min-width: 160.0625rem) {
          .cta-form-calc__form .checkbox .checkbox__text {
            font-size: 0.625vw; } }
        @media screen and (max-width: 103.125rem) {
          .cta-form-calc__form .checkbox .checkbox__text {
            font-size: 0.875rem; } }
        @media screen and (max-width: 70.5rem) {
          .cta-form-calc__form .checkbox .checkbox__text {
            font-size: 1rem; } }
        @media screen and (max-width: 45rem) {
          .cta-form-calc__form .checkbox .checkbox__text {
            font-size: 0.875rem; } }
    .cta-form-calc__form .button {
      margin-top: 0.75rem;
      width: 100%; }
      @media screen and (min-width: 160.0625rem) {
        .cta-form-calc__form .button {
          margin-top: 0.46875vw; } }
      @media screen and (max-width: 131.25rem) {
        .cta-form-calc__form .button {
          margin-top: 0.25rem; } }
      @media screen and (max-width: 103.125rem) {
        .cta-form-calc__form .button {
          margin-top: 0.5rem; } }
      @media screen and (max-width: 70.5rem) {
        .cta-form-calc__form .button {
          margin-top: 0;
          width: auto; } }
      @media screen and (max-width: 45rem) {
        .cta-form-calc__form .button {
          width: 100%; } }

.cta-form-calc-2 {
  padding: var(--section-offset);
  background-color: var(--color-white);
  color: var(--color-text-dark); }
  @media screen and (max-width: 45rem) {
    .cta-form-calc-2 {
      padding: 1.25rem; } }
  .cta-form-calc-2__title {
    display: block;
    font: var(--font-h3);
    letter-spacing: var(--letter-spacing-h3);
    margin-bottom: 2.5rem; }
    @media screen and (min-width: 160.0625rem) {
      .cta-form-calc-2__title {
        margin-bottom: 1.5625vw; } }
    @media screen and (max-width: 103.125rem) {
      .cta-form-calc-2__title {
        margin-bottom: 2rem; } }
    @media screen and (max-width: 70.5rem) {
      .cta-form-calc-2__title {
        margin-bottom: 1.5rem; } }
    @media screen and (max-width: 45rem) {
      .cta-form-calc-2__title {
        margin-bottom: 1.25rem; } }
    .cta-form-calc-2__title span {
      color: var(--color-secondary); }
  .cta-form-calc-2__form {
    display: flex;
    flex-direction: column;
    gap: 2rem; }
    @media screen and (min-width: 160.0625rem) {
      .cta-form-calc-2__form {
        gap: 1.25vw; } }
    @media screen and (max-width: 103.125rem) {
      .cta-form-calc-2__form {
        gap: 1.5rem; } }
    @media screen and (max-width: 45rem) {
      .cta-form-calc-2__form {
        gap: 1rem; } }
    .cta-form-calc-2__form .label-row {
      display: flex;
      align-items: flex-start;
      gap: 2rem; }
      @media screen and (min-width: 160.0625rem) {
        .cta-form-calc-2__form .label-row {
          gap: 1.25vw; } }
      @media screen and (max-width: 103.125rem) {
        .cta-form-calc-2__form .label-row {
          gap: 1.5rem;
          margin-bottom: 0; } }
      @media screen and (max-width: 70.5rem) {
        .cta-form-calc-2__form .label-row {
          gap: 1.5rem; } }
      @media screen and (max-width: 45rem) {
        .cta-form-calc-2__form .label-row {
          flex-direction: column;
          gap: 1rem; } }
    .cta-form-calc-2__form .label-row-tablet {
      display: flex;
      flex-direction: column;
      gap: 2rem; }
      @media screen and (min-width: 160.0625rem) {
        .cta-form-calc-2__form .label-row-tablet {
          gap: 1.25vw; } }
      @media screen and (max-width: 103.125rem) {
        .cta-form-calc-2__form .label-row-tablet {
          gap: 1.5rem; } }
      @media screen and (max-width: 70.5rem) {
        .cta-form-calc-2__form .label-row-tablet {
          flex-direction: row;
          align-items: center; } }
      @media screen and (max-width: 45rem) {
        .cta-form-calc-2__form .label-row-tablet {
          flex-direction: column;
          gap: 1rem; } }
    .cta-form-calc-2__form-footer {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 2rem;
      margin-top: 1rem; }
      @media screen and (min-width: 160.0625rem) {
        .cta-form-calc-2__form-footer {
          gap: 1.25vw;
          margin-top: 0.625vw; } }
      @media screen and (max-width: 131.25rem) {
        .cta-form-calc-2__form-footer {
          margin-top: 0.5rem; } }
      @media screen and (max-width: 103.125rem) {
        .cta-form-calc-2__form-footer {
          gap: 1rem; } }
      @media screen and (max-width: 70.5rem) {
        .cta-form-calc-2__form-footer {
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          gap: 1.5rem; } }
      @media screen and (max-width: 45rem) {
        .cta-form-calc-2__form-footer {
          flex-direction: column;
          align-items: flex-start;
          gap: 1.25rem;
          margin-top: 0.25rem; } }
    @media screen and (max-width: 45rem) {
      .cta-form-calc-2__form .button {
        width: 100%; } }

.label-variable-btn {
  display: inline-flex; }
  .label-variable-btn__input:checked + .label-variable-btn__content {
    background-color: var(--color-dark);
    border-color: var(--stroke-dark);
    color: var(--color-white); }
  .label-variable-btn__content {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    border: 1px solid var(--stroke-dark-secondary);
    color: var(--color-text-dark);
    font: var(--font-body-M-caption);
    text-transform: uppercase;
    height: 3.6875rem;
    width: 100%;
    padding: 0 1.25rem;
    gap: 0.75rem;
    cursor: pointer;
    transition: background-color var(--anim-direction), color var(--anim-direction), border-color var(--anim-direction); }
    @media screen and (min-width: 160.0625rem) {
      .label-variable-btn__content {
        height: 2.30469vw;
        padding: 0 0.78125vw;
        gap: 0.46875vw; } }
    @media screen and (max-width: 131.25rem) {
      .label-variable-btn__content {
        height: 3.3125rem; } }
    @media screen and (max-width: 103.125rem) {
      .label-variable-btn__content {
        height: 2.6875rem; } }
    @media screen and (max-width: 70.5rem) {
      .label-variable-btn__content {
        height: 2.9375rem; } }
    @media screen and (max-width: 45rem) {
      .label-variable-btn__content {
        height: 2.6875rem;
        gap: 0.5rem; } }
  .label-variable-btn__icon {
    width: 1.5rem;
    height: 1.5rem;
    fill: currentColor;
    transition: fill var(--anim-direction); }
    @media screen and (min-width: 160.0625rem) {
      .label-variable-btn__icon {
        width: 0.9375vw;
        height: 0.9375vw; } }
    @media screen and (max-width: 45rem) {
      .label-variable-btn__icon {
        width: 1.25rem;
        height: 1.25rem; } }

.form-tabs {
  display: block; }
  .form-tabs__tab {
    margin-bottom: 3rem; }
    @media screen and (min-width: 160.0625rem) {
      .form-tabs__tab {
        margin-bottom: 1.875vw; } }
    @media screen and (max-width: 131.25rem) {
      .form-tabs__tab {
        margin-bottom: 2.5rem; } }
    @media screen and (max-width: 103.125rem) {
      .form-tabs__tab {
        margin-bottom: 2rem; } }
    @media screen and (max-width: 70.5rem) {
      .form-tabs__tab {
        margin-bottom: 1.5rem; } }
    @media screen and (max-width: 45rem) {
      .form-tabs__tab {
        margin-bottom: 1.25rem; } }
  .form-tabs__content {
    display: none; }
    .form-tabs__content.js_active {
      display: block; }

.block-accordion {
  position: relative; }
  .block-accordion__header {
    padding: 2.5rem 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 6.25rem;
    cursor: pointer;
    border-bottom: 1px solid var(--stroke-dark-secondary);
    transition: border-color var(--anim-direction); }
    @media screen and (min-width: 160.0625rem) {
      .block-accordion__header {
        padding: 1.5625vw 0;
        gap: 3.90625vw; } }
    @media screen and (max-width: 131.25rem) {
      .block-accordion__header {
        padding: 2rem 0;
        gap: 4.25rem; } }
    @media screen and (max-width: 103.125rem) {
      .block-accordion__header {
        padding: 1.5rem 0;
        gap: 3rem; } }
    @media screen and (max-width: 45rem) {
      .block-accordion__header {
        padding: 1rem 0;
        gap: 1rem; } }
  .block-accordion__title {
    font: var(--font-h4);
    letter-spacing: var(--letter-spacing-h4); }
  .block-accordion__content {
    height: 0;
    overflow: hidden;
    opacity: 0;
    visibility: hidden;
    transition: opacity var(--anim-direction), visibility var(--anim-direction); }
    .block-accordion__content p:first-child {
      margin-top: 0; }
    .block-accordion__content p:last-child {
      margin-bottom: 0; }
  .block-accordion.toggle_active .block-accordion__header {
    border-color: var(--color-secondary); }
  .block-accordion.toggle_active .block-accordion__content {
    padding: 2.5rem 0;
    height: auto;
    opacity: 1;
    visibility: visible; }
    @media screen and (min-width: 160.0625rem) {
      .block-accordion.toggle_active .block-accordion__content {
        padding: 1.5625vw 0; } }
    @media screen and (max-width: 131.25rem) {
      .block-accordion.toggle_active .block-accordion__content {
        padding: 2rem 0; } }
    @media screen and (max-width: 103.125rem) {
      .block-accordion.toggle_active .block-accordion__content {
        padding: 1.5rem 0; } }
    @media screen and (max-width: 45rem) {
      .block-accordion.toggle_active .block-accordion__content {
        padding: 1rem 0; } }
  .block-accordion.toggle_active .block-accordion__btn .block-accordion-btn {
    background-color: var(--color-dark); }
    .block-accordion.toggle_active .block-accordion__btn .block-accordion-btn:before, .block-accordion.toggle_active .block-accordion__btn .block-accordion-btn:after {
      background-color: var(--color-white); }
    .block-accordion.toggle_active .block-accordion__btn .block-accordion-btn:after {
      transform: translate(-50%, -50%) rotate(0); }

.block-accordion-btn {
  position: relative; }
  .block-accordion-btn:before, .block-accordion-btn:after {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
    content: "";
    display: block;
    width: 0.875rem;
    height: 0.125rem;
    background-color: var(--color-dark);
    transition: background-color var(--anim-direction), transform var(--anim-direction); }
    @media screen and (min-width: 160.0625rem) {
      .block-accordion-btn:before, .block-accordion-btn:after {
        width: 0.54688vw;
        height: 0.07812vw; } }
    @media screen and (max-width: 103.125rem) {
      .block-accordion-btn:before, .block-accordion-btn:after {
        width: 0.75rem; } }
  .block-accordion-btn:after {
    transform: translate(-50%, -50%) rotate(90deg); }
  .block-accordion-btn:hover:before, .block-accordion-btn:hover:after {
    background-color: var(--color-white); }

.contact-block__label {
  display: block;
  font: var(--font-body-M-caption);
  color: var(--color-text-dark-secondary);
  text-transform: uppercase;
  margin-bottom: 0.5rem; }
  @media screen and (min-width: 160.0625rem) {
    .contact-block__label {
      margin-bottom: 0.3125vw; } }
  @media screen and (max-width: 131.25rem) {
    .contact-block__label {
      margin-bottom: 0.25rem; } }

.contact-block__content {
  font: var(--font-body-M); }
  .contact-block__content a:not(.text-button) {
    color: var(--color-text-dark);
    text-decoration: none; }
  .contact-block__content p {
    margin: 0; }
  .contact-block__content .text-button {
    margin-top: 1.25rem; }
    @media screen and (min-width: 160.0625rem) {
      .contact-block__content .text-button {
        margin-top: 0.78125vw; } }
    @media screen and (max-width: 131.25rem) {
      .contact-block__content .text-button {
        margin-top: 1rem; } }
    @media screen and (max-width: 103.125rem) {
      .contact-block__content .text-button {
        margin-top: 0.75rem; } }
  .contact-block__content .icon-button {
    margin-top: 0.75rem; }
    @media screen and (min-width: 160.0625rem) {
      .contact-block__content .icon-button {
        margin-top: 0.46875vw; } }

.news-card {
  display: flex;
  overflow: hidden;
  background: var(--color-gray-3); }
  @media screen and (max-width: 70.5rem) {
    .news-card {
      flex-direction: column; } }
  .news-card__link {
    display: inline-flex;
    font-size: 0; }
  .news-card__fig {
    display: block;
    margin: 0;
    padding: 0;
    overflow: hidden;
    width: 24.6875rem; }
    @media screen and (min-width: 160.0625rem) {
      .news-card__fig {
        width: 15.42969vw; } }
    @media screen and (max-width: 131.25rem) {
      .news-card__fig {
        width: 18.125rem; } }
    @media screen and (max-width: 103.125rem) {
      .news-card__fig {
        width: 13.75rem; } }
    @media screen and (max-width: 70.5rem) {
      .news-card__fig {
        width: 100%;
        height: 15rem; } }
  .news-card__pic {
    display: block;
    width: 100%;
    height: 100%;
    font-size: 0; }
  .news-card__img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform var(--anim-direction); }
  .news-card__content {
    position: relative;
    flex: 1;
    padding: 6.25rem; }
    @media screen and (min-width: 160.0625rem) {
      .news-card__content {
        padding: 3.90625vw; } }
    @media screen and (max-width: 131.25rem) {
      .news-card__content {
        padding: 4.25rem; } }
    @media screen and (max-width: 103.125rem) {
      .news-card__content {
        padding: 3rem; } }
    @media screen and (max-width: 70.5rem) {
      .news-card__content {
        padding: 1.5rem; } }
    @media screen and (max-width: 45rem) {
      .news-card__content {
        padding: 1.25rem; } }
  .news-card__tags {
    position: relative;
    z-index: 2;
    display: inline-flex;
    flex-wrap: wrap;
    gap: 0.3125rem;
    margin-bottom: 1.5rem; }
    @media screen and (min-width: 160.0625rem) {
      .news-card__tags {
        gap: 0.19531vw;
        margin-bottom: 0.9375vw; } }
    @media screen and (max-width: 131.25rem) {
      .news-card__tags {
        margin-bottom: 1.25rem; } }
    @media screen and (max-width: 103.125rem) {
      .news-card__tags {
        margin-bottom: 1rem; } }
    @media screen and (max-width: 70.5rem) {
      .news-card__tags {
        margin-bottom: 0.75rem; } }
  .news-card__title {
    display: block;
    font: var(--font-h5);
    color: var(--color-dark);
    text-decoration: none;
    transition: color var(--anim-direction); }
    .news-card__title:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      display: block;
      width: 100%;
      height: 100%; }
  .news-card__date {
    display: block;
    font: var(--font-body-M-caption);
    text-transform: uppercase;
    color: var(--color-text-dark-secondary);
    margin-top: 1.5rem; }
    @media screen and (min-width: 160.0625rem) {
      .news-card__date {
        margin-top: 0.9375vw; } }
    @media screen and (max-width: 131.25rem) {
      .news-card__date {
        margin-top: 1.25rem; } }
    @media screen and (max-width: 103.125rem) {
      .news-card__date {
        margin-top: 1rem; } }
    @media screen and (max-width: 70.5rem) {
      .news-card__date {
        margin-top: 0.75rem; } }
  .news-card:hover .news-card__img {
    transform: scale(1.1); }
  .news-card:hover .news-card__title {
    color: var(--color-secondary); }

.dop-services-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 2rem;
  border: 1px solid var(--stroke-dark-secondary); }
  @media screen and (min-width: 160.0625rem) {
    .dop-services-card {
      padding: 1.25vw; } }
  @media screen and (max-width: 131.25rem) {
    .dop-services-card {
      padding: 1.625rem 2rem; } }
  @media screen and (max-width: 103.125rem) {
    .dop-services-card {
      padding: 1.5rem; } }
  @media screen and (max-width: 45rem) {
    .dop-services-card {
      padding: 1.25rem; } }
  .dop-services-card__title {
    font: var(--font-body-M);
    min-height: 6rem; }
    @media screen and (min-width: 160.0625rem) {
      .dop-services-card__title {
        min-height: 3.75vw; } }
    @media screen and (max-width: 131.25rem) {
      .dop-services-card__title {
        min-height: 4.875rem; } }
    @media screen and (max-width: 103.125rem) {
      .dop-services-card__title {
        min-height: 4.5rem; } }
    @media screen and (max-width: 70.5rem) {
      .dop-services-card__title {
        min-height: 3.75rem; } }
  .dop-services-card__footer {
    margin-top: 2rem;
    display: flex;
    align-items: flex-end;
    justify-content: space-between; }
    @media screen and (min-width: 160.0625rem) {
      .dop-services-card__footer {
        margin-top: 1.25vw; } }
    @media screen and (max-width: 103.125rem) {
      .dop-services-card__footer {
        margin-top: 1.5rem; } }
    @media screen and (max-width: 45rem) {
      .dop-services-card__footer {
        margin-top: 1.25rem; } }
  .dop-services-card__price {
    font: var(--font-h5);
    letter-spacing: var(--letter-spacing-h5);
    margin-right: 1.25rem; }
    @media screen and (min-width: 160.0625rem) {
      .dop-services-card__price {
        margin-right: 0.78125vw; } }
  .dop-services-card__icon {
    margin: 0;
    font-size: 0;
    max-width: 3rem; }
    @media screen and (min-width: 160.0625rem) {
      .dop-services-card__icon {
        max-width: 1.875vw; } }
    @media screen and (max-width: 103.125rem) {
      .dop-services-card__icon {
        max-width: 2.25rem; } }
    @media screen and (max-width: 70.5rem) {
      .dop-services-card__icon {
        max-width: 2rem; } }
    .dop-services-card__icon picture {
      display: inline-block;
      width: 100%; }
    .dop-services-card__icon img {
      width: 100%;
      height: auto; }

.file-card {
  display: inline-block;
  font-size: 0;
  text-decoration: none; }
  .file-card__fig {
    display: block;
    margin: 0; }
  .file-card__pic {
    display: block;
    width: 100%; }
  .file-card__img {
    display: block;
    width: 100%;
    max-width: 100%;
    height: auto;
    border: 1px solid var(--stroke-dark-secondary); }
  .file-card__title {
    display: block;
    font: var(--font-body-S);
    color: var(--color-text-dark-secondary);
    text-decoration: none;
    margin-top: 1.25rem; }
    @media screen and (min-width: 160.0625rem) {
      .file-card__title {
        margin-top: 0.78125vw; } }
    @media screen and (max-width: 131.25rem) {
      .file-card__title {
        margin-top: 1rem; } }
    @media screen and (max-width: 103.125rem) {
      .file-card__title {
        margin-top: 0.75rem; } }
    @media screen and (max-width: 45rem) {
      .file-card__title {
        margin-top: 0.5rem; } }
  .file-card__wrap {
    position: relative; }
    .file-card__wrap:hover .file-card__btns {
      opacity: 1; }
  .file-card__btns {
    position: absolute;
    left: 50%;
    top: 50%;
    z-index: 2;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    transition: opacity var(--anim-direction); }
    @media screen and (max-width: 70.5rem) {
      .file-card__btns {
        display: none; } }
    .file-card__btns .icon-button:not(:first-child) {
      margin-left: 0.75rem; }
      @media screen and (min-width: 160.0625rem) {
        .file-card__btns .icon-button:not(:first-child) {
          margin-left: 0.46875vw; } }
      @media screen and (max-width: 103.125rem) {
        .file-card__btns .icon-button:not(:first-child) {
          margin-left: 0.5rem; } }

.category-card {
  background: var(--color-white);
  padding: 2.5rem;
  width: 100%; }
  @media screen and (min-width: 160.0625rem) {
    .category-card {
      padding: 1.5625vw; } }
  @media screen and (max-width: 131.25rem) {
    .category-card {
      padding: 2rem; } }
  @media screen and (max-width: 103.125rem) {
    .category-card {
      padding: 1.5rem; } }
  @media screen and (max-width: 45rem) {
    .category-card {
      padding: 1.25rem; } }
  .category-card__scheme-label {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font: var(--font-body-M-caption);
    color: var(--color-text-dark-secondary);
    text-transform: uppercase;
    margin-bottom: 1rem; }
    @media screen and (min-width: 160.0625rem) {
      .category-card__scheme-label {
        margin-bottom: 0.625vw; } }
    @media screen and (max-width: 131.25rem) {
      .category-card__scheme-label {
        margin-bottom: 0.75rem; } }
  .category-card__scheme-flags {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1rem; }
    @media screen and (min-width: 160.0625rem) {
      .category-card__scheme-flags {
        margin-bottom: 0.625vw; } }
    @media screen and (max-width: 131.25rem) {
      .category-card__scheme-flags {
        margin-bottom: 0.75rem; } }
    .category-card__scheme-flags figure {
      position: relative;
      z-index: 2;
      margin: 0;
      font-size: 0;
      background: var(--color-white);
      padding-right: 0.75rem; }
      @media screen and (min-width: 160.0625rem) {
        .category-card__scheme-flags figure {
          padding-right: 0.46875vw; } }
      .category-card__scheme-flags figure:last-child {
        padding-right: 0;
        padding-left: 0.75rem; }
        @media screen and (min-width: 160.0625rem) {
          .category-card__scheme-flags figure:last-child {
            padding-right: 0;
            padding-left: 0.46875vw; } }
    .category-card__scheme-flags:after {
      content: "";
      position: absolute;
      left: 0;
      top: 50%;
      z-index: 1;
      display: block;
      width: 100%;
      height: 1px;
      border-top: 1px dotted var(--color-secondary); }
  .category-card__city-info {
    display: flex;
    justify-content: space-between; }
  .category-card__city-item {
    font: var(--font-body-M);
    color: var(--color-dark); }
    .category-card__city-item span {
      display: block; }
    .category-card__city-item .country {
      font: var(--font-body-M-caption);
      color: var(--color-text-dark-secondary);
      text-transform: uppercase;
      margin-top: 0.25rem; }
      @media screen and (min-width: 160.0625rem) {
        .category-card__city-item .country {
          margin-top: 0.15625vw; } }
      @media screen and (max-width: 131.25rem) {
        .category-card__city-item .country {
          margin-top: 0; } }
    .category-card__city-item:last-child {
      text-align: right; }
  .category-card__footer {
    border-top: 1px solid var(--stroke-dark-secondary);
    padding-top: 2.5rem;
    margin-top: 2.5rem; }
    @media screen and (min-width: 160.0625rem) {
      .category-card__footer {
        padding-top: 1.5625vw;
        margin-top: 1.5625vw; } }
    @media screen and (max-width: 131.25rem) {
      .category-card__footer {
        padding-top: 2rem;
        margin-top: 2rem; } }
    @media screen and (max-width: 103.125rem) {
      .category-card__footer {
        padding-top: 1.5rem;
        margin-top: 1.5rem; } }
    @media screen and (max-width: 70.5rem) {
      .category-card__footer {
        padding-top: 1rem;
        margin-top: 1rem; } }
  .category-card__time {
    display: flex;
    align-items: center; }
    .category-card__time span {
      margin-left: 0.3125rem; }
      @media screen and (min-width: 160.0625rem) {
        .category-card__time span {
          margin-left: 0.19531vw; } }
  .category-card__btn {
    display: none;
    margin-top: 2.5rem; }
    @media screen and (min-width: 160.0625rem) {
      .category-card__btn {
        margin-top: 1.5625vw; } }
    @media screen and (max-width: 131.25rem) {
      .category-card__btn {
        margin-top: 2rem; } }
    @media screen and (max-width: 103.125rem) {
      .category-card__btn {
        margin-top: 1.5rem; } }
    @media screen and (max-width: 70.5rem) {
      .category-card__btn {
        margin-top: 1rem; } }
  .category-card:hover .category-card__btn {
    display: inline-flex; }

.category-card-time {
  display: flex;
  align-items: center;
  font: var(--font-body-M);
  color: var(--color-text-dark-secondary); }
  .category-card-time__icon {
    width: 1.5rem;
    height: 1.5rem;
    margin-right: 0.5rem;
    fill: var(--color-text-dark-secondary); }
    @media screen and (min-width: 160.0625rem) {
      .category-card-time__icon {
        width: 0.9375vw;
        height: 0.9375vw;
        margin-right: 0.3125vw; } }
    @media screen and (max-width: 131.25rem) {
      .category-card-time__icon {
        width: 1.25rem;
        height: 1.25rem; } }
    @media screen and (max-width: 70.5rem) {
      .category-card-time__icon {
        margin-right: 0.3125rem; } }

.project-card {
  display: flex;
  width: 100%;
  min-height: 50rem;
  background: var(--color-gray-3); }
  @media screen and (min-width: 160.0625rem) {
    .project-card {
      min-height: 31.25vw; } }
  @media screen and (max-width: 131.25rem) {
    .project-card {
      min-height: 37.5rem; } }
  @media screen and (max-width: 103.125rem) {
    .project-card {
      min-height: 33.125rem; } }
  @media screen and (max-width: 70.5rem) {
    .project-card {
      min-height: 21.875rem; } }
  @media screen and (max-width: 45rem) {
    .project-card {
      min-height: auto;
      flex-direction: column; } }
  .project-card__slider {
    width: 50%; }
    @media screen and (max-width: 45rem) {
      .project-card__slider {
        width: 100%;
        height: 15rem; } }
  .project-card__content {
    position: relative;
    width: 50%;
    padding: 6.25rem 6.25rem 9.5rem; }
    @media screen and (min-width: 160.0625rem) {
      .project-card__content {
        padding: 3.90625vw 3.90625vw 5.9375vw; } }
    @media screen and (max-width: 131.25rem) {
      .project-card__content {
        padding: 4.25rem 4.25rem 7.5rem; } }
    @media screen and (max-width: 103.125rem) {
      .project-card__content {
        padding: 3rem 3rem 6rem; } }
    @media screen and (max-width: 70.5rem) {
      .project-card__content {
        padding: 2.5rem; } }
    @media screen and (max-width: 45rem) {
      .project-card__content {
        width: 100%;
        padding: 1.25rem; } }
  .project-card__tags {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 1.5rem; }
    @media screen and (min-width: 160.0625rem) {
      .project-card__tags {
        margin-bottom: 0.9375vw; } }
    @media screen and (max-width: 131.25rem) {
      .project-card__tags {
        margin-bottom: 1.25rem; } }
    @media screen and (max-width: 70.5rem) {
      .project-card__tags {
        margin-bottom: 1rem; } }
    @media screen and (max-width: 45rem) {
      .project-card__tags {
        margin-bottom: 0.75rem; } }
  .project-card__title {
    display: block;
    font: var(--font-h3);
    color: var(--stroke-dark);
    text-decoration: none;
    transition: color var(--anim-direction); }
  .project-card__text {
    margin-top: 1.25rem;
    font: var(--font-body-M); }
    @media screen and (min-width: 160.0625rem) {
      .project-card__text {
        margin-top: 0.78125vw; } }
    @media screen and (max-width: 70.5rem) {
      .project-card__text {
        margin-top: 1rem;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-box-orient: vertical;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 5; } }
    @media screen and (max-width: 45rem) {
      .project-card__text {
        margin-top: 0.75rem; } }
    .project-card__text p {
      margin: 0; }
  .project-card__btn {
    position: absolute;
    left: 6.25rem;
    bottom: 6.25rem;
    opacity: 0;
    transition: opacity var(--anim-direction); }
    @media screen and (min-width: 160.0625rem) {
      .project-card__btn {
        left: 3.90625vw;
        bottom: 3.90625vw; } }
    @media screen and (max-width: 131.25rem) {
      .project-card__btn {
        left: 4.25rem;
        bottom: 4.25rem; } }
    @media screen and (max-width: 103.125rem) {
      .project-card__btn {
        left: 3rem;
        bottom: 3rem; } }
    @media screen and (max-width: 70.5rem) {
      .project-card__btn {
        position: static;
        opacity: 1;
        margin-top: 1rem; } }
    @media screen and (max-width: 45rem) {
      .project-card__btn {
        margin-top: 0.75rem; } }
  .project-card:hover .project-card__btn {
    opacity: 1; }
  .project-card_reverse {
    flex-direction: row-reverse; }
    @media screen and (max-width: 45rem) {
      .project-card_reverse {
        flex-direction: column; } }

.slider-hover {
  position: relative;
  width: 100%;
  height: 100%; }
  .slider-hover__list {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 2;
    width: 100%;
    height: 100%;
    display: flex; }
  .slider-hover__img {
    position: absolute;
    left: 50%;
    top: 0;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    margin: 0;
    pointer-events: none;
    background-color: #fff;
    transform: translateX(-50%);
    opacity: 0;
    transition: opacity var(--anim-direction); }
    .slider-hover__img:before {
      content: "";
      position: absolute;
      left: 0;
      bottom: 0;
      display: block;
      width: 100%;
      height: 100%;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0) 79.5%, rgba(0, 0, 0, 0.5) 100%); }
    .slider-hover__img img {
      display: block;
      width: 100%;
      max-width: 100%;
      height: 100%;
      object-fit: cover; }
  .slider-hover__item {
    flex-grow: 1; }
    .slider-hover__item:first-child .slider-hover__img {
      opacity: 1;
      z-index: -1; }
    .slider-hover__item:hover .slider-hover__img {
      opacity: 1;
      z-index: -1; }
  .slider-hover__nav {
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 3;
    display: flex;
    align-items: center;
    gap: 0.75rem;
    width: 100%;
    padding: 2.5rem; }
    @media screen and (min-width: 160.0625rem) {
      .slider-hover__nav {
        gap: 0.46875vw;
        padding: 1.5625vw; } }
    @media screen and (max-width: 131.25rem) {
      .slider-hover__nav {
        padding: 2rem; } }
    @media screen and (max-width: 103.125rem) {
      .slider-hover__nav {
        padding: 1.5625rem 1.4375rem; } }
    @media screen and (max-width: 70.5rem) {
      .slider-hover__nav {
        gap: 0.5rem;
        padding: 1.25rem; } }
  .slider-hover__nav-item {
    display: inline-block;
    width: 100%;
    height: 0.25rem;
    background: var(--color-white);
    opacity: 0.5;
    transition: opacity var(--anim-direction); }
    @media screen and (min-width: 160.0625rem) {
      .slider-hover__nav-item {
        height: 0.15625vw; } }
    @media screen and (max-width: 70.5rem) {
      .slider-hover__nav-item {
        height: 0.1875rem; } }
    @media screen and (max-width: 45rem) {
      .slider-hover__nav-item {
        height: 0.125rem; } }
    .slider-hover__nav-item_active {
      opacity: 1; }

.review-card {
  position: relative;
  display: flex;
  overflow: hidden;
  padding: 2.5rem;
  background: var(--color-white); }
  @media screen and (min-width: 160.0625rem) {
    .review-card {
      padding: 1.5625vw; } }
  @media screen and (max-width: 131.25rem) {
    .review-card {
      padding: 2rem; } }
  @media screen and (max-width: 103.125rem) {
    .review-card {
      padding: 1.5rem; } }
  @media screen and (max-width: 45rem) {
    .review-card {
      flex-direction: column;
      padding: 1.25rem; } }
  .review-card__content {
    flex: 1; }
  .review-card__header {
    display: flex;
    align-items: center;
    padding-bottom: 2rem;
    border-bottom: 1px solid var(--stroke-dark-secondary); }
    @media screen and (min-width: 160.0625rem) {
      .review-card__header {
        padding-bottom: 1.25vw; } }
    @media screen and (max-width: 131.25rem) {
      .review-card__header {
        padding-bottom: 1.5rem; } }
    @media screen and (max-width: 45rem) {
      .review-card__header {
        padding-bottom: 0.75rem; } }
  .review-card__avatar {
    display: block;
    width: 100%;
    max-width: 3.75rem;
    margin: 0;
    padding: 0;
    margin-right: 1rem; }
    @media screen and (min-width: 160.0625rem) {
      .review-card__avatar {
        max-width: 2.34375vw;
        margin-right: 0.625vw; } }
    @media screen and (max-width: 131.25rem) {
      .review-card__avatar {
        max-width: 2.75rem;
        margin-right: 1rem; } }
    @media screen and (max-width: 45rem) {
      .review-card__avatar {
        margin-right: 0.75rem; } }
    .review-card__avatar-pic {
      display: block;
      width: 100%;
      font-size: 0; }
    .review-card__avatar-img {
      width: 100%;
      max-width: 100%;
      height: auto; }
  .review-card__title {
    font: var(--font-body-M-semibold); }
  .review-card__subtitle {
    font: var(--font-body-M-caption);
    color: var(--color-text-dark-secondary);
    text-transform: uppercase;
    margin-top: 0.25rem; }
    @media screen and (min-width: 160.0625rem) {
      .review-card__subtitle {
        margin-top: 0.15625vw; } }
    @media screen and (max-width: 45rem) {
      .review-card__subtitle {
        margin-top: 0; } }
  .review-card__text {
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 6;
    padding-top: 1.5rem; }
    @media screen and (min-width: 160.0625rem) {
      .review-card__text {
        padding-top: 0.9375vw; } }
    @media screen and (max-width: 103.125rem) {
      .review-card__text {
        padding-top: 1rem; } }
    @media screen and (max-width: 45rem) {
      .review-card__text {
        padding-top: 0.75rem; } }
    .review-card__text p {
      margin-top: 0; }
      .review-card__text p:last-child {
        margin-bottom: 0; }
  .review-card__btn {
    margin-top: 1rem; }
    @media screen and (min-width: 160.0625rem) {
      .review-card__btn {
        margin-top: 0.625vw; } }
    @media screen and (max-width: 103.125rem) {
      .review-card__btn {
        margin-top: 0.75rem; } }
  .review-card__media {
    position: relative;
    margin-left: 6.25rem;
    width: 16.5625rem; }
    @media screen and (min-width: 160.0625rem) {
      .review-card__media {
        margin-left: 3.90625vw;
        width: 10.35156vw; } }
    @media screen and (max-width: 131.25rem) {
      .review-card__media {
        margin-left: 4.25rem;
        width: 13.6875rem; } }
    @media screen and (max-width: 103.125rem) {
      .review-card__media {
        margin-left: 3rem;
        width: 12.375rem; } }
    @media screen and (max-width: 70.5rem) {
      .review-card__media {
        margin-left: 2.5rem;
        width: 11rem; } }
    @media screen and (max-width: 45rem) {
      .review-card__media {
        margin-left: 0;
        margin-top: 1.25rem;
        width: 5rem; } }
    .review-card__media-fig {
      margin: 0;
      padding: 0; }
    .review-card__media-pic {
      display: block;
      width: 100%;
      font-size: 0; }
    .review-card__media-img {
      width: 100%;
      max-width: 100%;
      height: auto;
      border: 1px solid var(--stroke-dark-secondary); }
    .review-card__media .icon-text-button,
    .review-card__media .icon-button:not(.icon-text-button__circle) {
      position: absolute;
      left: 50%;
      top: 50%;
      z-index: 2;
      transform: translate(-50%, -50%); }
    .review-card__media .icon-button:not(.icon-text-button__circle) {
      opacity: 0;
      transition: opacity var(--anim-direction); }
    .review-card__media:hover .icon-button {
      opacity: 1; }

.services-card {
  position: relative;
  display: block;
  overflow: hidden;
  background: var(--color-gray-3); }
  @media screen and (max-width: 70.5rem) {
    .services-card {
      display: flex;
      flex-direction: column; } }
  .services-card__content {
    position: relative;
    overflow: hidden;
    padding: 2.5rem;
    min-height: 12.5rem; }
    @media screen and (min-width: 160.0625rem) {
      .services-card__content {
        padding: 1.5625vw;
        min-height: 7.8125vw; } }
    @media screen and (max-width: 131.25rem) {
      .services-card__content {
        padding: 2rem;
        min-height: 10rem; } }
    @media screen and (max-width: 103.125rem) {
      .services-card__content {
        padding: 1.5rem;
        min-height: 7.5rem; } }
    @media screen and (max-width: 70.5rem) {
      .services-card__content {
        min-height: auto;
        order: 1; } }
    @media screen and (max-width: 45rem) {
      .services-card__content {
        padding: 1.25rem; } }
  .services-card__title {
    display: block;
    font: var(--font-h4);
    color: var(--color-dark);
    text-decoration: none;
    transition: color var(--anim-direction); }
    .services-card__title:after {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 2; }
  .services-card__link {
    display: block;
    font-size: 0; }
  .services-card__fig {
    display: block;
    margin: 0;
    opacity: 1;
    overflow: hidden;
    transition: opacity var(--anim-direction); }
  .services-card__pic {
    display: block;
    width: 100%;
    height: 100%;
    font-size: 0; }
  .services-card__img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform var(--anim-direction); }
  .services-card__hidden-content {
    position: absolute;
    bottom: -100%;
    left: 0;
    opacity: 0;
    width: 100%;
    z-index: 3;
    transition: bottom var(--anim-direction), opacity var(--anim-direction); }
    @media screen and (max-width: 70.5rem) {
      .services-card__hidden-content {
        position: static;
        opacity: 1;
        order: 1; } }
  .services-card:hover .services-card__title {
    color: var(--color-secondary); }
  .services-card:hover .services-card__img {
    transform: scale(1.1); }
  .services-card_full:hover .services-card__title {
    color: var(--color-dark); }
  .services-card_full:hover .services-card__fig {
    opacity: 0; }
    @media screen and (max-width: 70.5rem) {
      .services-card_full:hover .services-card__fig {
        opacity: 1; } }
  .services-card_full:hover .services-card__img {
    transform: none; }
    @media screen and (max-width: 70.5rem) {
      .services-card_full:hover .services-card__img {
        transform: scale(1.1); } }
  .services-card_full:hover .services-card__hidden-content {
    opacity: 1;
    bottom: 0; }

.services-card-content {
  padding: 2.5rem;
  background: var(--color-gray-3); }
  @media screen and (min-width: 160.0625rem) {
    .services-card-content {
      padding: 1.5625vw; } }
  @media screen and (max-width: 131.25rem) {
    .services-card-content {
      padding: 2rem; } }
  @media screen and (max-width: 103.125rem) {
    .services-card-content {
      padding: 1.5rem; } }
  @media screen and (max-width: 70.5rem) {
    .services-card-content {
      padding: 1.5rem;
      padding-top: 0; } }
  @media screen and (max-width: 45rem) {
    .services-card-content {
      padding: 1.25rem;
      padding-top: 0; } }
  .services-card-content__title {
    display: block;
    font: var(--font-body-S);
    color: var(--color-text-dark-secondary);
    margin: 0;
    margin-bottom: 1.25rem; }
    @media screen and (min-width: 160.0625rem) {
      .services-card-content__title {
        margin-bottom: 0.78125vw; } }
    @media screen and (max-width: 131.25rem) {
      .services-card-content__title {
        margin-bottom: 1rem; } }
    @media screen and (max-width: 103.125rem) {
      .services-card-content__title {
        margin-bottom: 0.5rem; } }
  .services-card-content__list {
    list-style-type: none;
    padding: 0;
    margin: 0;
    color: var(--color-dark); }
    .services-card-content__list li:not(:first-child) {
      margin-top: 1.25rem; }
      @media screen and (min-width: 160.0625rem) {
        .services-card-content__list li:not(:first-child) {
          margin-top: 0.78125vw; } }
      @media screen and (max-width: 131.25rem) {
        .services-card-content__list li:not(:first-child) {
          margin-top: 1rem; } }
      @media screen and (max-width: 103.125rem) {
        .services-card-content__list li:not(:first-child) {
          margin-top: 0.5rem; } }
    .services-card-content__list li a {
      text-decoration: none;
      color: var(--color-dark);
      transition: color var(--anim-direction); }
      .services-card-content__list li a:hover {
        color: var(--color-secondary); }
  .services-card-content__btn {
    margin-top: 2rem; }
    @media screen and (min-width: 160.0625rem) {
      .services-card-content__btn {
        margin-top: 1.25vw; } }
    @media screen and (max-width: 131.25rem) {
      .services-card-content__btn {
        margin-top: 1.5rem; } }
    @media screen and (max-width: 103.125rem) {
      .services-card-content__btn {
        margin-top: 0.5rem; } }
    @media screen and (max-width: 70.5rem) {
      .services-card-content__btn {
        margin-top: 1rem; } }

.user-card__fig {
  display: block;
  margin: 0 0 2.5rem 0;
  padding: 0; }
  @media screen and (min-width: 160.0625rem) {
    .user-card__fig {
      margin: 0 0 1.5625vw 0; } }
  @media screen and (max-width: 131.25rem) {
    .user-card__fig {
      margin: 0 0 2rem 0; } }
  @media screen and (max-width: 103.125rem) {
    .user-card__fig {
      margin: 0 0 1.5rem 0; } }
  @media screen and (max-width: 70.5rem) {
    .user-card__fig {
      margin: 0 0 1rem 0; } }

.user-card__pic {
  display: block;
  width: 100%;
  height: 100%;
  font-size: 0; }

.user-card__img {
  width: 100%;
  height: auto;
  min-width: 100%; }

.user-card__subtitle {
  font: var(--font-body-M-caption);
  color: var(--color-text-dark-secondary);
  text-transform: uppercase;
  margin-bottom: 1rem; }
  @media screen and (min-width: 160.0625rem) {
    .user-card__subtitle {
      margin-bottom: 0.625vw; } }
  @media screen and (max-width: 131.25rem) {
    .user-card__subtitle {
      margin-bottom: 0.75rem; } }
  @media screen and (max-width: 103.125rem) {
    .user-card__subtitle {
      margin-bottom: 0.5rem; } }

.user-card__title {
  font: var(--font-h5);
  color: var(--stroke-dark); }

.user-card__contacts {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 1rem; }
  @media screen and (min-width: 160.0625rem) {
    .user-card__contacts {
      margin-top: 0.625vw; } }
  @media screen and (max-width: 131.25rem) {
    .user-card__contacts {
      margin-top: 0.75rem; } }
  @media screen and (max-width: 103.125rem) {
    .user-card__contacts {
      margin-top: 0.5rem; } }
  .user-card__contacts .user-card-contact:not(:first-child) {
    margin-top: 1rem; }
    @media screen and (min-width: 160.0625rem) {
      .user-card__contacts .user-card-contact:not(:first-child) {
        margin-top: 0.625vw; } }
    @media screen and (max-width: 131.25rem) {
      .user-card__contacts .user-card-contact:not(:first-child) {
        margin-top: 0.75rem; } }
    @media screen and (max-width: 103.125rem) {
      .user-card__contacts .user-card-contact:not(:first-child) {
        margin-top: 0.5rem; } }

.user-card-contact {
  display: inline-flex;
  align-items: center;
  font: var(--font-body-M);
  color: var(--color-dark);
  text-decoration: none;
  transition: color var(--anim-direction); }
  .user-card-contact__icon {
    width: 2rem;
    height: 2rem;
    margin-right: 0.75rem;
    fill: var(--color-gray-2); }
    @media screen and (min-width: 160.0625rem) {
      .user-card-contact__icon {
        width: 1.25vw;
        height: 1.25vw;
        margin-right: 0.46875vw; } }
    @media screen and (max-width: 131.25rem) {
      .user-card-contact__icon {
        width: 1.5rem;
        height: 1.5rem;
        margin-right: 0.75rem; } }
    @media screen and (max-width: 70.5rem) {
      .user-card-contact__icon {
        width: 1.25rem;
        height: 1.25rem;
        margin-right: 0.5rem; } }
  .user-card-contact:hover {
    color: var(--color-secondary); }

.sec {
  padding: var(--section-padding); }
  .sec__header {
    display: flex;
    align-items: center;
    margin-bottom: var(--section-offset); }
    @media screen and (max-width: 70.5rem) {
      .sec__header {
        flex-direction: column;
        align-items: flex-start; } }
    .sec__header .sec__title {
      flex: 1;
      margin-bottom: 0;
      margin-right: var(--section-offset); }
      @media screen and (max-width: 70.5rem) {
        .sec__header .sec__title {
          margin-right: 0; } }
    .sec__header .sec__actions {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin-left: calc(var(--section-offset) / 2); }
      @media screen and (max-width: 70.5rem) {
        .sec__header .sec__actions {
          margin-left: 0;
          margin-top: 1.5rem; } }
      @media screen and (max-width: 45rem) {
        .sec__header .sec__actions {
          display: none;
          margin-top: 1.25rem; } }
      .sec__header .sec__actions .icon-button {
        margin-left: 0.75rem; }
        @media screen and (min-width: 160.0625rem) {
          .sec__header .sec__actions .icon-button {
            margin-left: 0.46875vw; } }
        @media screen and (max-width: 103.125rem) {
          .sec__header .sec__actions .icon-button {
            margin-left: 0.5rem; } }
        @media screen and (max-width: 70.5rem) {
          .sec__header .sec__actions .icon-button {
            display: none; } }
  .sec__title {
    margin-bottom: var(--section-offset);
    max-width: calc(50% - var(--section-offset));
    overflow: hidden; }
    @media screen and (max-width: 70.5rem) {
      .sec__title {
        max-width: 100%; } }
    .sec__title .line {
      overflow: hidden; }
    .sec__title strong {
      font-weight: inherit;
      color: var(--color-secondary); }
  .sec__row {
    display: flex;
    gap: 6.25rem; }
    @media screen and (min-width: 160.0625rem) {
      .sec__row {
        gap: 3.90625vw; } }
    @media screen and (max-width: 131.25rem) {
      .sec__row {
        gap: 4.25rem; } }
    @media screen and (max-width: 103.125rem) {
      .sec__row {
        gap: 3rem; } }
  .sec__content {
    flex: 1; }
  .sec__aside {
    flex-basis: 43rem; }
    @media screen and (min-width: 160.0625rem) {
      .sec__aside {
        flex-basis: 26.875vw; } }
    @media screen and (max-width: 131.25rem) {
      .sec__aside {
        flex-basis: 32.25rem; } }
    @media screen and (max-width: 103.125rem) {
      .sec__aside {
        flex-basis: 24.5rem; } }
    @media screen and (max-width: 70.5rem) {
      .sec__aside {
        display: none; } }
    .sec__aside .cta-form {
      position: sticky;
      top: 0;
      right: 0; }

.sec-about-services {
  background-color: var(--color-dark);
  color: var(--color-white); }
  .sec-about-services .sec__title {
    max-width: 45%; }
    @media screen and (max-width: 70.5rem) {
      .sec-about-services .sec__title {
        max-width: 100%; } }
  .sec-about-services__row {
    display: flex;
    border-top: 1px solid var(--color-white);
    padding-top: var(--section-offset); }
    @media screen and (max-width: 70.5rem) {
      .sec-about-services__row {
        flex-direction: column; } }
    .sec-about-services__row .sec-about-services__text:first-child {
      margin-right: var(--section-offset); }
      @media screen and (max-width: 70.5rem) {
        .sec-about-services__row .sec-about-services__text:first-child {
          margin-right: 0;
          margin-bottom: 2.5rem; } }
      @media screen and (max-width: 45rem) {
        .sec-about-services__row .sec-about-services__text:first-child {
          margin-bottom: 2rem; } }
  .sec-about-services__text {
    flex: 1; }
    .sec-about-services__text p:last-child {
      margin-bottom: 0; }
    .sec-about-services__text.text-editor blockquote {
      margin-bottom: 0; }
    .sec-about-services__text blockquote {
      max-width: 43rem; }
      @media screen and (min-width: 160.0625rem) {
        .sec-about-services__text blockquote {
          max-width: 26.875vw; } }
      @media screen and (max-width: 131.25rem) {
        .sec-about-services__text blockquote {
          max-width: 41.375rem; } }
      @media screen and (max-width: 103.125rem) {
        .sec-about-services__text blockquote {
          max-width: 31.375rem; } }
      @media screen and (max-width: 70.5rem) {
        .sec-about-services__text blockquote {
          max-width: 100%; } }

.sec-about {
  background-color: var(--color-gray-3); }
  .sec-about .sec__title {
    margin-bottom: 3.75rem; }
    @media screen and (min-width: 160.0625rem) {
      .sec-about .sec__title {
        margin-bottom: 2.34375vw; } }
    @media screen and (max-width: 131.25rem) {
      .sec-about .sec__title {
        margin-bottom: 3.25rem; } }
    @media screen and (max-width: 103.125rem) {
      .sec-about .sec__title {
        margin-bottom: 2.25rem; } }
    @media screen and (max-width: 70.5rem) {
      .sec-about .sec__title {
        margin-bottom: 1.5rem; } }
    @media screen and (max-width: 45rem) {
      .sec-about .sec__title {
        margin-bottom: 1.25rem; } }
  .sec-about__row {
    display: flex;
    gap: var(--section-offset);
    border-bottom: 1px solid var(--color-dark);
    padding-bottom: var(--section-offset); }
    @media screen and (max-width: 70.5rem) {
      .sec-about__row {
        flex-direction: column;
        gap: 1.5rem; } }
    @media screen and (max-width: 45rem) {
      .sec-about__row {
        gap: 1.25rem; } }
  .sec-about__text {
    flex: 1;
    padding-right: var(--section-offset); }
    .sec-about__text p:last-child {
      margin-bottom: 0; }
  .sec-about__row-counts {
    margin: 0 -3.125rem;
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap; }
    @media screen and (min-width: 160.0625rem) {
      .sec-about__row-counts {
        margin: 0 -1.95312vw; } }
    @media screen and (max-width: 131.25rem) {
      .sec-about__row-counts {
        margin: 0 -1.5rem; } }
    @media screen and (max-width: 45rem) {
      .sec-about__row-counts {
        flex-direction: column;
        margin: 0; } }
    .sec-about__row-counts .block-count {
      width: calc(33.333% - 6.25rem);
      margin: 0 3.125rem;
      margin-top: var(--section-offset); }
      @media screen and (min-width: 160.0625rem) {
        .sec-about__row-counts .block-count {
          width: calc(33.333% - 3.90625vw);
          margin: 0 1.95312vw;
          margin-top: var(--section-offset); } }
      @media screen and (max-width: 131.25rem) {
        .sec-about__row-counts .block-count {
          width: calc(33.333% - 3rem);
          margin: 0 1.5rem;
          margin-top: var(--section-offset); } }
      @media screen and (max-width: 45rem) {
        .sec-about__row-counts .block-count {
          width: 100%;
          margin: 0;
          margin-top: var(--section-offset); } }

.block-count__image {
  font-size: 0; }
  .block-count__image picture {
    display: block; }
  .block-count__image img {
    object-fit: contain;
    width: auto;
    height: 8.5625rem; }
    @media screen and (min-width: 160.0625rem) {
      .block-count__image img {
        height: 5.35156vw; } }
    @media screen and (max-width: 131.25rem) {
      .block-count__image img {
        height: 6.875rem; } }
    @media screen and (max-width: 103.125rem) {
      .block-count__image img {
        height: 3.75rem; } }
    @media screen and (max-width: 70.5rem) {
      .block-count__image img {
        height: 2.5rem; } }

.block-count__text {
  margin-top: 1.5rem;
  font: var(--font-body-M); }
  @media screen and (min-width: 160.0625rem) {
    .block-count__text {
      margin-top: 0.9375vw;
      padding-top: 0.9375vw; } }
  @media screen and (max-width: 131.25rem) {
    .block-count__text {
      margin-top: 1.25rem;
      padding-top: 1.25rem; } }
  @media screen and (max-width: 103.125rem) {
    .block-count__text {
      margin-top: 1rem;
      padding-top: 1rem; } }
  @media screen and (max-width: 70.5rem) {
    .block-count__text {
      margin-top: 0.75rem;
      padding-top: 0.75rem; } }

.sec-bg-video {
  position: relative;
  z-index: 2;
  height: 100vh;
  color: var(--color-text-light);
  min-height: 31.25rem; }
  @media screen and (min-width: 160.0625rem) {
    .sec-bg-video {
      min-height: 19.53125vw; } }
  @media screen and (max-width: 45rem) {
    .sec-bg-video {
      min-height: 25rem; } }
  .sec-bg-video:before {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 0;
    background: rgba(0, 0, 0, 0.5); }
  .sec-bg-video .sec__header {
    align-items: flex-start; }
  .sec-bg-video .sec__actions {
    display: flex;
    flex-direction: column;
    align-items: flex-start; }
    .sec-bg-video .sec__actions p {
      margin: 0; }
    .sec-bg-video .sec__actions .button {
      margin-top: 2.5rem; }
      @media screen and (min-width: 160.0625rem) {
        .sec-bg-video .sec__actions .button {
          margin-top: 1.5625vw; } }
      @media screen and (max-width: 131.25rem) {
        .sec-bg-video .sec__actions .button {
          margin-top: 2.25rem; } }
      @media screen and (max-width: 103.125rem) {
        .sec-bg-video .sec__actions .button {
          margin-top: 1.5rem; } }
      @media screen and (max-width: 45rem) {
        .sec-bg-video .sec__actions .button {
          margin-top: 1.25rem; } }
  .sec-bg-video .container {
    position: relative;
    z-index: 3;
    height: 100%;
    display: flex;
    flex-direction: column; }
  .sec-bg-video__btns {
    margin-top: auto; }
    @media screen and (max-width: 70.5rem) {
      .sec-bg-video__btns {
        overflow: auto hidden; } }
    .sec-bg-video__btns-scroll {
      display: flex;
      flex-wrap: wrap;
      border-top: 1px solid var(--stroke-light-secondary);
      border-left: 1px solid var(--stroke-light-secondary); }
      @media screen and (max-width: 70.5rem) {
        .sec-bg-video__btns-scroll {
          flex-wrap: nowrap;
          border-top: 0; } }
    .sec-bg-video__btns label {
      position: relative;
      display: block;
      width: 25%;
      border-bottom: 1px solid var(--stroke-light-secondary);
      border-right: 1px solid var(--stroke-light-secondary); }
      @media screen and (max-width: 70.5rem) {
        .sec-bg-video__btns label {
          width: auto;
          min-width: 14.375rem;
          border-top: 1px solid var(--stroke-light-secondary); } }
      .sec-bg-video__btns label .bg-video-btn {
        height: 100%; }
  .sec-bg-video__dynamic-content {
    display: none; }
    .sec-bg-video__dynamic-content.js_active {
      display: block; }
  .sec-bg-video__bg {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 1; }
  .sec-bg-video:before {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 2;
    background-color: rgba(0, 0, 0, 0.5); }

@supports (height: 100dvh) {
  .sec-bg-video {
    height: 100dvh; } }

.bg-video-btn {
  position: relative;
  display: flex;
  background: transparent;
  border: 0;
  border-radius: 0;
  padding: 2.5rem;
  color: var(--color-text-light);
  gap: 2.5rem;
  text-align: left;
  cursor: pointer; }
  @media screen and (min-width: 160.0625rem) {
    .bg-video-btn {
      padding: 1.5625vw;
      gap: 1.5625vw; } }
  @media screen and (max-width: 131.25rem) {
    .bg-video-btn {
      padding: 2rem;
      gap: 2rem; } }
  @media screen and (max-width: 103.125rem) {
    .bg-video-btn {
      padding: 1.5rem;
      gap: 1.5rem; } }
  @media screen and (max-width: 45rem) {
    .bg-video-btn {
      padding: 1.25rem;
      gap: 1rem; } }
  .bg-video-btn:before {
    content: "";
    display: block;
    width: 0;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background-color: var(--color-secondary);
    transition: width var(--anim-direction); }
  .bg-video-btn__count {
    position: relative;
    font: var(--font-body-M-caption);
    color: var(--color-text-light-secondary);
    text-transform: uppercase; }
  .bg-video-btn__text {
    position: relative;
    font: var(--font-h4);
    letter-spacing: var(--letter-spacing-h4); }
  .bg-video-btn:hover:before {
    width: 100%; }
  .bg-video-btn:focus-visible {
    outline: 0;
    box-shadow: 0 0 0 1px var(--color-secondary); }

.visually-hidden:checked + .bg-video-btn:before {
  width: 100%; }

.sec-company {
  position: relative;
  z-index: 2;
  background-color: var(--color-dark);
  color: var(--color-white);
  padding: 0;
  overflow: hidden; }
  .sec-company__container {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 2;
    width: 100%;
    padding-top: var(--section-offset); }
    @media screen and (max-width: 45rem) {
      .sec-company__container {
        padding-top: 2.5rem; } }
  .sec-company__image {
    margin: 0;
    font-size: 0;
    display: block;
    width: 100%; }
    .sec-company__image picture {
      display: block;
      width: 100%; }
    .sec-company__image img {
      width: 100%;
      height: auto;
      max-width: 100%;
      object-fit: cover; }
      @media screen and (max-width: 70.5rem) {
        .sec-company__image img {
          height: 18.75rem; } }
  .sec-company__numbers {
    padding-top: var(--section-offset);
    padding-bottom: var(--section-offset);
    display: grid;
    grid-template-columns: 30.7% 1fr 1fr 1fr 1fr;
    grid-template-rows: repeat(3, 1fr);
    grid-template-areas: "quote . one . two" ". tree . four ." "five . six . btn"; }
    @media screen and (max-width: 70.5rem) {
      .sec-company__numbers {
        display: flex;
        flex-wrap: wrap; } }
    @media screen and (max-width: 45rem) {
      .sec-company__numbers {
        padding-top: 2.5rem;
        padding-bottom: 2.5rem; } }
    .sec-company__numbers-col {
      grid-area: quote;
      position: relative; }
      @media screen and (max-width: 70.5rem) {
        .sec-company__numbers-col {
          width: 100%;
          margin-bottom: 2.5rem; } }
      @media screen and (max-width: 45rem) {
        .sec-company__numbers-col {
          margin-bottom: 1.75rem; } }
      .sec-company__numbers-col .block-company-quote {
        position: absolute;
        left: 0;
        bottom: 0;
        z-index: 2;
        width: 100%; }
        @media screen and (max-width: 70.5rem) {
          .sec-company__numbers-col .block-company-quote {
            position: static;
            margin-top: -5rem; } }
    .sec-company__numbers .block-company-number {
      height: 21.5625rem;
      margin-left: -1px;
      margin-top: -1px; }
      @media screen and (min-width: 160.0625rem) {
        .sec-company__numbers .block-company-number {
          height: 13.47656vw; } }
      @media screen and (max-width: 131.25rem) {
        .sec-company__numbers .block-company-number {
          height: 16rem; } }
      @media screen and (max-width: 103.125rem) {
        .sec-company__numbers .block-company-number {
          height: 14.75rem; } }
      @media screen and (max-width: 70.5rem) {
        .sec-company__numbers .block-company-number {
          height: 14.375rem;
          width: 33.333%; } }
      @media screen and (max-width: 45rem) {
        .sec-company__numbers .block-company-number {
          height: auto;
          width: 100%;
          margin-left: 0;
          min-height: 9.125rem; } }
      .sec-company__numbers .block-company-number.item1 {
        grid-area: one; }
      .sec-company__numbers .block-company-number.item2 {
        grid-area: two; }
      .sec-company__numbers .block-company-number.item3 {
        grid-area: tree; }
      .sec-company__numbers .block-company-number.item4 {
        grid-area: four; }
      .sec-company__numbers .block-company-number.item5 {
        grid-area: five; }
      .sec-company__numbers .block-company-number.item6 {
        grid-area: six; }
    .sec-company__numbers-btn {
      grid-area: btn; }
      @media screen and (max-width: 70.5rem) {
        .sec-company__numbers-btn {
          margin-top: 2.5rem; } }
      @media screen and (max-width: 45rem) {
        .sec-company__numbers-btn {
          margin-top: 1.75rem; } }
      .sec-company__numbers-btn .button {
        width: 100%;
        height: 100%; }
        .sec-company__numbers-btn .button__text {
          margin-bottom: auto; }

.block-company-number {
  display: flex;
  flex-direction: column;
  border: 1px solid var(--stroke-light-secondary);
  padding: 2.5rem; }
  @media screen and (min-width: 160.0625rem) {
    .block-company-number {
      padding: 1.5625vw; } }
  @media screen and (max-width: 131.25rem) {
    .block-company-number {
      padding: 2rem; } }
  @media screen and (max-width: 103.125rem) {
    .block-company-number {
      padding: 1.5rem; } }
  @media screen and (max-width: 45rem) {
    .block-company-number {
      padding: 1.25rem; } }
  .block-company-number__count {
    font: var(--font-h2);
    margin-bottom: 1.25rem; }
    @media screen and (min-width: 160.0625rem) {
      .block-company-number__count {
        margin-bottom: 0.78125vw; } }
    @media screen and (max-width: 45rem) {
      .block-company-number__count {
        margin-bottom: 1rem; } }
  .block-company-number__text {
    margin-top: auto; }

.block-company-quote {
  padding: var(--section-offset);
  margin: 0;
  display: flex;
  flex-direction: column;
  background-color: var(--color-secondary);
  color: var(--color-white);
  font: var(--font-h4);
  letter-spacing: var(--letter-spacing-h4); }
  @media screen and (max-width: 45rem) {
    .block-company-quote {
      padding: 2.5rem; } }
  .block-company-quote__icon {
    width: 2rem;
    height: 2rem;
    fill: rgba(255, 255, 255, 0.3);
    margin-bottom: 1rem; }
    @media screen and (min-width: 160.0625rem) {
      .block-company-quote__icon {
        width: 1.25vw;
        height: 1.25vw;
        margin-bottom: 0.625vw; } }
    @media screen and (max-width: 131.25rem) {
      .block-company-quote__icon {
        width: 1.5rem;
        height: 1.5rem; } }
    @media screen and (max-width: 103.125rem) {
      .block-company-quote__icon {
        margin-bottom: 0.75rem; } }
  .block-company-quote__text {
    font: var(--font-h4);
    letter-spacing: var(--letter-spacing-h4); }
    .block-company-quote__text p:first-child {
      margin-top: 0; }
    .block-company-quote__text p:last-child {
      margin-bottom: 0; }

.sec-cta-image {
  position: relative;
  z-index: 2;
  background-color: var(--color-secondary);
  overflow: hidden;
  height: 100vh; }
  @media screen and (max-width: 70.5rem) {
    .sec-cta-image {
      height: auto; } }
  .sec-cta-image .container {
    height: 100%; }
  .sec-cta-image__row {
    position: relative;
    display: flex;
    justify-content: flex-end;
    height: 100%; }
    @media screen and (max-width: 70.5rem) {
      .sec-cta-image__row {
        flex-direction: column;
        height: 100%; } }
  .sec-cta-image__img {
    position: absolute;
    top: -6.25rem;
    left: 0;
    z-index: 1;
    max-width: 81.75rem;
    width: 100%;
    font-size: 0; }
    @media screen and (min-width: 160.0625rem) {
      .sec-cta-image__img {
        top: -3.90625vw;
        max-width: 51.09375vw; } }
    @media screen and (max-width: 131.25rem) {
      .sec-cta-image__img {
        top: -4.25rem;
        max-width: 59.6875rem; } }
    @media screen and (max-width: 103.125rem) {
      .sec-cta-image__img {
        top: -3rem;
        max-width: 45.125rem; } }
    @media screen and (max-width: 70.5rem) {
      .sec-cta-image__img {
        top: -2.5rem;
        right: 0;
        margin: 0 auto;
        max-width: 35.625rem; } }
    @media screen and (max-width: 45rem) {
      .sec-cta-image__img {
        max-width: 100%; } }
    .sec-cta-image__img picture {
      display: block;
      width: 100%; }
    .sec-cta-image__img img {
      max-width: 100%;
      width: 100%;
      height: auto; }
  .sec-cta-image__form {
    position: relative;
    z-index: 2;
    height: 100%;
    max-width: 67.625rem;
    display: flex;
    align-items: center; }
    @media screen and (min-width: 160.0625rem) {
      .sec-cta-image__form {
        max-width: 42.26562vw; } }
    @media screen and (max-width: 131.25rem) {
      .sec-cta-image__form {
        max-width: 50.5rem; } }
    @media screen and (max-width: 103.125rem) {
      .sec-cta-image__form {
        max-width: 38.25rem; } }
    @media screen and (max-width: 70.5rem) {
      .sec-cta-image__form {
        max-width: 100%;
        display: block;
        padding-top: 48vw; } }
    @media screen and (max-width: 45rem) {
      .sec-cta-image__form {
        padding-top: 50vw; } }

@supports (height: 100dvh) {
  .sec-cta-image {
    height: 100dvh; }
    @media screen and (max-width: 70.5rem) {
      .sec-cta-image {
        height: auto; } } }

.sec-cta {
  position: relative;
  height: 100vh;
  min-height: 46.875rem;
  background-color: var(--color-gray-3); }
  @media screen and (min-width: 160.0625rem) {
    .sec-cta {
      min-height: 29.29688vw; } }
  @media screen and (max-width: 70.5rem) {
    .sec-cta {
      display: flex;
      align-items: flex-end;
      height: auto; } }
  @media screen and (max-width: 45rem) {
    .sec-cta {
      min-height: 51.875rem; } }
  .sec-cta .container {
    width: 100%; }
  .sec-cta:before {
    content: "";
    display: block;
    width: 100%;
    height: 90%;
    position: absolute;
    left: 0;
    bottom: 0;
    background-image: url("/assets/templates/img/common/bg-cta_2.png");
    background-repeat: no-repeat;
    background-position: center top;
    background-size: cover; }
    @media screen and (max-width: 70.5rem) {
      .sec-cta:before {
        height: 96%;
        background-image: url("/assets/templates/img/common/bg-cta-mobile_2.png"); } }
  .sec-cta__box {
    position: relative;
    z-index: 2;
    width: 100%;
    max-width: 50%;
    padding-left: 3.125rem;
    margin-left: auto; }
    @media screen and (min-width: 160.0625rem) {
      .sec-cta__box {
        padding-left: 1.95312vw; } }
    @media screen and (max-width: 131.25rem) {
      .sec-cta__box {
        padding-left: 2.125rem; } }
    @media screen and (max-width: 103.125rem) {
      .sec-cta__box {
        padding-left: 1.5rem; } }
    @media screen and (max-width: 70.5rem) {
      .sec-cta__box {
        padding-left: 0;
        max-width: 100%;
        margin-left: 0; } }

@supports (height: 100dvh) {
  .sec-cta {
    height: 100dvh; }
    @media screen and (max-width: 70.5rem) {
      .sec-cta {
        height: auto; } } }

.sec-directions {
  background-color: var(--color-gray-3); }
  .sec-directions__nav {
    width: 100%;
    overflow: auto hidden; }
    .sec-directions__nav-scroll {
      display: flex;
      align-items: center; }
      .sec-directions__nav-scroll .button-tab-wrap {
        display: inline-table;
        width: max-content;
        white-space: nowrap;
        overflow: hidden;
        border-radius: 3.75rem; }
        @media screen and (min-width: 160.0625rem) {
          .sec-directions__nav-scroll .button-tab-wrap {
            border-radius: 2.34375vw; } }
        .sec-directions__nav-scroll .button-tab-wrap:not(:last-child) {
          margin-right: 0.75rem; }
          @media screen and (min-width: 160.0625rem) {
            .sec-directions__nav-scroll .button-tab-wrap:not(:last-child) {
              margin-right: 0.46875vw; } }
  .sec-directions .sec__title {
    margin-top: var(--section-offset); }
    @media screen and (max-width: 45rem) {
      .sec-directions .sec__title {
        margin-top: 2.5rem; } }
  .sec-directions__list {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    margin: 0 -3.125rem; }
    @media screen and (min-width: 160.0625rem) {
      .sec-directions__list {
        margin: 0 -1.95312vw; } }
    @media screen and (max-width: 131.25rem) {
      .sec-directions__list {
        margin: 0 -2.125rem; } }
    @media screen and (max-width: 103.125rem) {
      .sec-directions__list {
        margin: 0 -1.5rem; } }
    @media screen and (max-width: 70.5rem) {
      .sec-directions__list {
        margin: 0 -0.75rem; } }
    @media screen and (max-width: 45rem) {
      .sec-directions__list {
        margin: 0; } }
    .sec-directions__list .category-card {
      width: calc(33.333% - 6.25rem);
      margin: 0 3.125rem;
      margin-bottom: 6.25rem; }
      @media screen and (min-width: 160.0625rem) {
        .sec-directions__list .category-card {
          width: calc(33.333% - 3.90625vw);
          margin: 0 1.95312vw;
          margin-bottom: 3.90625vw; } }
      @media screen and (max-width: 131.25rem) {
        .sec-directions__list .category-card {
          width: calc(33.333% - 4.25rem);
          margin: 0 2.125rem;
          margin-bottom: 4.25rem; } }
      @media screen and (max-width: 103.125rem) {
        .sec-directions__list .category-card {
          width: calc(33.333% - 3rem);
          margin: 0 1.5rem;
          margin-bottom: 3rem; } }
      @media screen and (max-width: 70.5rem) {
        .sec-directions__list .category-card {
          width: calc(50% - 1.5rem);
          margin: 0 0.75rem;
          margin-bottom: 1.5rem; } }
      @media screen and (max-width: 45rem) {
        .sec-directions__list .category-card {
          width: 100%;
          margin: 0;
          margin-bottom: 1rem; } }
  @media screen and (max-width: 70.5rem) {
    .sec-directions__btns {
      margin-top: 1rem; } }
  @media screen and (max-width: 45rem) {
    .sec-directions__btns {
      margin-top: 0.75rem; } }

.sec-docs__slider {
  margin-right: -6.25rem; }
  @media screen and (min-width: 160.0625rem) {
    .sec-docs__slider {
      margin-right: -3.90625vw; } }
  @media screen and (max-width: 131.25rem) {
    .sec-docs__slider {
      margin-right: 0; } }
  @media screen and (max-width: 103.125rem) {
    .sec-docs__slider {
      margin-right: 0; } }
  @media screen and (max-width: 70.5rem) {
    .sec-docs__slider {
      margin-right: -2.5rem; } }
  @media screen and (max-width: 45rem) {
    .sec-docs__slider {
      margin-right: -1rem; } }

.sec-dop-services__slider {
  padding-bottom: 1px; }
  @media screen and (max-width: 70.5rem) {
    .sec-dop-services__slider {
      margin-right: -2.5rem; } }
  @media screen and (max-width: 45rem) {
    .sec-dop-services__slider {
      margin-right: -1rem; } }

.sec-faq__accordion-list .block-accordion:first-child .block-accordion__header {
  padding-top: 0; }

.sec-faq__accordion-list .block-accordion__content {
  max-width: 67.375rem; }
  @media screen and (min-width: 160.0625rem) {
    .sec-faq__accordion-list .block-accordion__content {
      max-width: 42.10938vw; } }
  @media screen and (max-width: 131.25rem) {
    .sec-faq__accordion-list .block-accordion__content {
      max-width: 50.5rem; } }
  @media screen and (max-width: 103.125rem) {
    .sec-faq__accordion-list .block-accordion__content {
      max-width: 38.25rem; } }
  @media screen and (max-width: 70.5rem) {
    .sec-faq__accordion-list .block-accordion__content {
      max-width: 100%; } }

.sec-history {
  position: relative;
  height: 100vh;
  min-height: 61.25rem;
  background-color: var(--color-gray-3);
  padding-bottom: 0;
  overflow: hidden; }
  @media screen and (min-width: 160.0625rem) {
    .sec-history {
      min-height: 38.28125vw; } }
  @media screen and (max-width: 131.25rem) {
    .sec-history {
      min-height: 43.75rem; } }
  @media screen and (max-width: 70.5rem) {
    .sec-history {
      min-height: 31.25rem;
      height: auto; } }
  .sec-history .sec__header {
    align-items: flex-start; }
  .sec-history .sec__actions {
    flex-direction: column;
    padding-right: var(--section-offset);
    max-width: 48%; }
    @media screen and (max-width: 70.5rem) {
      .sec-history .sec__actions {
        padding-right: 0; } }
    @media screen and (max-width: 70.5rem) {
      .sec-history .sec__actions {
        max-width: 100%;
        width: 100%; } }
    @media screen and (max-width: 45rem) {
      .sec-history .sec__actions {
        display: block;
        width: 100%; } }
  .sec-history .container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%; }
  .sec-history__slider-text {
    width: 100%; }
    .sec-history__slider-text .swiper-wrapper {
      align-items: stretch; }
    .sec-history__slider-text .swiper-slide {
      opacity: 0;
      height: auto;
      background-color: var(--color-gray-3);
      transition: opacity var(--anim-direction); }
      .sec-history__slider-text .swiper-slide.swiper-slide-active {
        opacity: 1; }
      .sec-history__slider-text .swiper-slide .line {
        overflow: hidden; }
      .sec-history__slider-text .swiper-slide p:first-child {
        margin-top: 0; }
      .sec-history__slider-text .swiper-slide p:last-child {
        margin-bottom: 0; }
  .sec-history__slider {
    position: relative;
    margin-top: auto;
    padding-bottom: calc(7rem + var(--section-offset));
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 3; }
    @media screen and (min-width: 160.0625rem) {
      .sec-history__slider {
        padding-bottom: calc(4.375vw + var(--section-offset)); } }
    @media screen and (max-width: 131.25rem) {
      .sec-history__slider {
        height: 34.375rem;
        padding-bottom: calc(6.25rem + var(--section-offset)); } }
    @media screen and (max-width: 70.5rem) {
      .sec-history__slider {
        height: 18.75rem;
        padding-bottom: calc(2.5rem + var(--section-offset)); } }
    @media screen and (max-width: 45rem) {
      .sec-history__slider {
        padding-bottom: 0;
        height: 12.5rem; } }
    .sec-history__slider:before {
      content: "";
      display: block;
      width: 100%;
      height: 9.375rem;
      background: linear-gradient(180deg, #f9f9f9 0%, rgba(249, 249, 249, 0) 100%);
      position: absolute;
      left: 0;
      top: -1px;
      z-index: 3; }
      @media screen and (min-width: 160.0625rem) {
        .sec-history__slider:before {
          height: 5.85938vw; } }
      @media screen and (max-width: 131.25rem) {
        .sec-history__slider:before {
          height: 3.125rem; } }
      @media screen and (max-width: 103.125rem) {
        .sec-history__slider:before {
          height: 3.75rem; } }
      @media screen and (max-width: 70.5rem) {
        .sec-history__slider:before {
          height: 2.5rem; } }
    .sec-history__slider .swiper-wrapper {
      z-index: 2; }
    .sec-history__slider .swiper-slide {
      opacity: 0;
      height: 100%;
      font-size: 0;
      line-height: 1;
      transition: opacity 1s; }
      .sec-history__slider .swiper-slide.swiper-slide-active {
        opacity: 1; }
    .sec-history__slider .history-slide {
      height: 100%; }
  .sec-history__slider-btn-container {
    display: flex;
    align-items: center;
    gap: 2.5rem; }
    @media screen and (min-width: 160.0625rem) {
      .sec-history__slider-btn-container {
        gap: 1.5625vw; } }
    @media screen and (max-width: 131.25rem) {
      .sec-history__slider-btn-container {
        gap: 2rem; } }
    @media screen and (max-width: 103.125rem) {
      .sec-history__slider-btn-container {
        gap: 1.5rem; } }
    @media screen and (max-width: 70.5rem) {
      .sec-history__slider-btn-container {
        gap: 1rem; } }
    .sec-history__slider-btn-container .h5 {
      opacity: 1;
      transition: opacity var(--anim-direction); }
      @media screen and (max-width: 45rem) {
        .sec-history__slider-btn-container .h5 {
          display: none; } }
    .sec-history__slider-btn-container .hide {
      opacity: 0; }
    .sec-history__slider-btn-container .icon-button {
      position: relative;
      z-index: 4; }
  .sec-history__slider-lines {
    position: absolute;
    left: 6.25rem;
    bottom: 0;
    z-index: 2;
    width: calc(100% - 6.25rem);
    display: flex;
    align-items: flex-end;
    justify-content: space-between; }
    @media screen and (min-width: 160.0625rem) {
      .sec-history__slider-lines {
        left: 3.90625vw;
        width: calc(100% - 3.90625vw); } }
    @media screen and (max-width: 103.125rem) {
      .sec-history__slider-lines {
        left: 4.5rem;
        width: calc(100% - 4.5rem); } }
    @media screen and (max-width: 70.5rem) {
      .sec-history__slider-lines {
        left: 0;
        width: 100%; } }
    .sec-history__slider-lines .slide-line {
      display: inline-block;
      background: linear-gradient(0deg, black 0%, rgba(0, 0, 0, 0) 100%);
      min-width: 1px;
      width: 1px;
      height: 7rem;
      transition: width 0.3s, height 0.3s, min-width 0.3s; }
      @media screen and (min-width: 160.0625rem) {
        .sec-history__slider-lines .slide-line {
          height: 4.375vw;
          margin: 0 0.39062vw; } }
      @media screen and (max-width: 70.5rem) {
        .sec-history__slider-lines .slide-line {
          height: 5rem; } }
      @media screen and (max-width: 45rem) {
        .sec-history__slider-lines .slide-line {
          height: 2.5rem; } }
      .sec-history__slider-lines .slide-line:first-child {
        margin-left: 0; }
      .sec-history__slider-lines .slide-line:last-child {
        margin-right: 0; }
      .sec-history__slider-lines .slide-line.js_active {
        height: 10.25rem;
        min-width: 0.25rem;
        width: 0.25rem;
        background: linear-gradient(0deg, #175FE9 0%, rgba(23, 95, 233, 0) 100%); }
        @media screen and (min-width: 160.0625rem) {
          .sec-history__slider-lines .slide-line.js_active {
            height: 6.40625vw;
            min-width: 0.15625vw;
            width: 0.15625vw; } }
        @media screen and (max-width: 70.5rem) {
          .sec-history__slider-lines .slide-line.js_active {
            height: 7.5rem; } }
        @media screen and (max-width: 45rem) {
          .sec-history__slider-lines .slide-line.js_active {
            height: 3.75rem; } }

@supports (height: 100dvh) {
  .sec-history {
    height: 100dvh; }
    @media screen and (max-width: 70.5rem) {
      .sec-history {
        height: auto; } } }

.history-slide {
  padding: 0.125rem;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1;
  font-size: 0; }
  @media screen and (min-width: 160.0625rem) {
    .history-slide {
      padding: 0.07812vw; } }
  .history-slide__text {
    color: var(--color-secondary);
    text-align: center;
    font-size: 27.1875rem;
    line-height: 0.73;
    letter-spacing: -13.05px; }
    @media screen and (min-width: 160.0625rem) {
      .history-slide__text {
        font-size: 16.99219vw; } }
    @media screen and (max-width: 131.25rem) {
      .history-slide__text {
        font-size: 20rem;
        line-height: 1;
        letter-spacing: -9.6px; } }
    @media screen and (max-width: 103.125rem) {
      .history-slide__text {
        font-size: 15rem;
        letter-spacing: -7.2px; } }
    @media screen and (max-width: 70.5rem) {
      .history-slide__text {
        font-size: 7.5rem;
        line-height: 2;
        letter-spacing: -3.6px; } }
    @media screen and (max-width: 45rem) {
      .history-slide__text {
        font-size: 4.375rem;
        line-height: 3.42;
        letter-spacing: -2.1px; } }

.sec-incoterms {
  position: relative; }
  @media screen and (max-width: 70.5rem) {
    .sec-incoterms .sec__actions {
      width: 100%; } }
  @media screen and (max-width: 45rem) {
    .sec-incoterms .sec__actions {
      display: flex;
      flex-direction: column;
      gap: 0.5rem; } }
  .sec-incoterms .sec__actions .labels-group {
    display: flex;
    flex-direction: column;
    gap: 0.625rem;
    width: 100%; }
    @media screen and (min-width: 160.0625rem) {
      .sec-incoterms .sec__actions .labels-group {
        gap: 0.39062vw; } }
    @media screen and (max-width: 131.25rem) {
      .sec-incoterms .sec__actions .labels-group {
        gap: 0.875rem; } }
    @media screen and (max-width: 103.125rem) {
      .sec-incoterms .sec__actions .labels-group {
        gap: 0.75rem; } }
    @media screen and (max-width: 70.5rem) {
      .sec-incoterms .sec__actions .labels-group {
        gap: 0.5rem; } }
  .sec-incoterms__fig {
    display: block;
    margin: 0;
    font-size: 0; }
  .sec-incoterms__pic {
    display: block;
    width: 100%; }
  .sec-incoterms__img {
    max-width: 100%;
    height: auto;
    width: 100%; }
  .sec-incoterms_border-top:before {
    content: "";
    display: block;
    height: 1px;
    width: calc(100% - 6.25rem);
    background-color: var(--stroke-dark-secondary);
    position: absolute;
    right: 0;
    top: 0; }
    @media screen and (min-width: 160.0625rem) {
      .sec-incoterms_border-top:before {
        width: calc(100% - 3.90625vw); } }
    @media screen and (max-width: 103.125rem) {
      .sec-incoterms_border-top:before {
        width: calc(100% - 4.5rem); } }
    @media screen and (max-width: 70.5rem) {
      .sec-incoterms_border-top:before {
        width: 100%; } }

.scheme-label {
  display: flex;
  align-items: center;
  font: var(--font-body-M);
  gap: 1rem; }
  @media screen and (min-width: 160.0625rem) {
    .scheme-label {
      gap: 0.625vw; } }
  .scheme-label__icon {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 1.5rem;
    width: 1.5rem;
    height: 1.5rem;
    background-color: var(--color-gray); }
    @media screen and (min-width: 160.0625rem) {
      .scheme-label__icon {
        min-width: 0.9375vw;
        width: 0.9375vw;
        height: 0.9375vw; } }
    @media screen and (max-width: 70.5rem) {
      .scheme-label__icon {
        min-width: 1.25rem;
        width: 1.25rem;
        height: 1.25rem; } }
  .scheme-label_primary .scheme-label__icon:after {
    position: absolute;
    left: 50%;
    top: 50%;
    z-index: 2;
    transform: translate(-50%, -50%);
    content: "";
    display: block;
    width: 0.75rem;
    height: 0.75rem;
    background-color: var(--color-primary); }
    @media screen and (min-width: 160.0625rem) {
      .scheme-label_primary .scheme-label__icon:after {
        width: 0.46875vw;
        height: 0.46875vw; } }
    @media screen and (max-width: 70.5rem) {
      .scheme-label_primary .scheme-label__icon:after {
        width: 0.5rem;
        height: 0.5rem; } }
  .scheme-label_secondary .scheme-label__icon:after {
    position: absolute;
    left: 50%;
    top: 50%;
    z-index: 2;
    transform: translate(-50%, -50%);
    content: "";
    display: block;
    width: 0.75rem;
    height: 0.75rem;
    background-color: var(--color-secondary); }
    @media screen and (min-width: 160.0625rem) {
      .scheme-label_secondary .scheme-label__icon:after {
        width: 0.46875vw;
        height: 0.46875vw; } }
    @media screen and (max-width: 70.5rem) {
      .scheme-label_secondary .scheme-label__icon:after {
        width: 0.5rem;
        height: 0.5rem; } }
  .scheme-label_dark .scheme-label__icon:after {
    position: absolute;
    left: 50%;
    top: 50%;
    z-index: 2;
    transform: translate(-50%, -50%);
    content: "";
    display: block;
    width: 0.75rem;
    height: 0.75rem;
    background-color: var(--color-dark); }
    @media screen and (min-width: 160.0625rem) {
      .scheme-label_dark .scheme-label__icon:after {
        width: 0.46875vw;
        height: 0.46875vw; } }
    @media screen and (max-width: 70.5rem) {
      .scheme-label_dark .scheme-label__icon:after {
        width: 0.5rem;
        height: 0.5rem; } }

.sec-logos__list {
  margin-top: var(--section-offset);
  display: flex;
  flex-wrap: wrap;
  border-left: 1px solid var(--stroke-dark-secondary);
  border-top: 1px solid var(--stroke-dark-secondary); }
  .sec-logos__list .block-logo {
    width: 10%; }
    @media screen and (max-width: 70.5rem) {
      .sec-logos__list .block-logo {
        width: 16.666%; } }
    @media screen and (max-width: 45rem) {
      .sec-logos__list .block-logo {
        width: 25%; } }

.block-logo {
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid var(--stroke-dark-secondary);
  border-right: 1px solid var(--stroke-dark-secondary);
  font-size: 0;
  padding: 1.5625rem; }
  @media screen and (min-width: 160.0625rem) {
    .block-logo {
      padding: 0.97656vw; } }
  @media screen and (max-width: 45rem) {
    .block-logo {
      padding: 0.9375rem; } }
  .block-logo picture {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%; }
  .block-logo__img {
    width: 100%;
    height: auto;
    object-fit: cover; }

.sec-map .sec__header {
  align-items: flex-start; }

@media screen and (max-width: 70.5rem) {
  .sec-map .sec__title br {
    display: none; } }

.sec-map .sec__actions {
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start; }
  @media screen and (max-width: 45rem) {
    .sec-map .sec__actions {
      display: flex; } }
  .sec-map .sec__actions .scheme-label:not(:last-child) {
    margin-bottom: 1.25rem; }
    @media screen and (min-width: 160.0625rem) {
      .sec-map .sec__actions .scheme-label:not(:last-child) {
        margin-bottom: 0.78125vw; } }
    @media screen and (max-width: 131.25rem) {
      .sec-map .sec__actions .scheme-label:not(:last-child) {
        margin-bottom: 1rem; } }
    @media screen and (max-width: 103.125rem) {
      .sec-map .sec__actions .scheme-label:not(:last-child) {
        margin-bottom: 0.75rem; } }
    @media screen and (max-width: 70.5rem) {
      .sec-map .sec__actions .scheme-label:not(:last-child) {
        margin-bottom: 0.5rem; } }

.sec-map__main {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  margin: 0 auto;
  max-width: 116.875rem; }
  @media screen and (min-width: 160.0625rem) {
    .sec-map__main {
      max-width: 73.04688vw; } }
  @media screen and (max-width: 131.25rem) {
    .sec-map__main {
      max-width: 87rem; } }
  @media screen and (max-width: 103.125rem) {
    .sec-map__main {
      max-width: 65.625rem; } }
  @media screen and (max-width: 70.5rem) {
    .sec-map__main {
      max-width: 35.625rem; } }
  @media screen and (max-width: 45rem) {
    .sec-map__main {
      max-width: 100%; } }

.sec-map__video {
  width: 100%;
  height: auto;
  transform: scale(1.005); }

@media screen and (max-width: 70.5rem) {
  .sec-news-slider__list {
    margin-right: -2.5rem; } }

@media screen and (max-width: 45rem) {
  .sec-news-slider__list {
    margin-right: -1rem; } }

.sec-quote {
  height: 100vh;
  background-image: url("/assets/templates/img/common/bg_quote.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top center;
  min-height: 56.25rem; }
  @media screen and (min-width: 160.0625rem) {
    .sec-quote {
      min-height: 35.15625vw; } }
  @media screen and (max-width: 70.5rem) {
    .sec-quote {
      background-image: url("/assets/templates/img/common/bg_quote_mobile.jpg");
      height: auto;
      min-height: 31.25rem;
      padding-top: 37.5rem; } }
  @media screen and (max-width: 45rem) {
    .sec-quote {
      padding-top: 25rem; } }
  .sec-quote .container {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    height: 100%;
    gap: 6rem; }
    @media screen and (min-width: 160.0625rem) {
      .sec-quote .container {
        gap: 3.75vw; } }
    @media screen and (max-width: 131.25rem) {
      .sec-quote .container {
        gap: var(--section-offset); } }
    @media screen and (max-width: 70.5rem) {
      .sec-quote .container {
        align-items: flex-start;
        flex-direction: column; } }
  .sec-quote__box {
    width: 100%;
    max-width: 50%;
    padding-left: 3.125rem; }
    @media screen and (min-width: 160.0625rem) {
      .sec-quote__box {
        padding-left: 1.95312vw; } }
    @media screen and (max-width: 131.25rem) {
      .sec-quote__box {
        padding-left: 2.125rem; } }
    @media screen and (max-width: 103.125rem) {
      .sec-quote__box {
        padding-left: 1.5rem; } }
    @media screen and (max-width: 70.5rem) {
      .sec-quote__box {
        padding-left: 0;
        max-width: 100%;
        margin-left: 0; } }

@supports (height: 100dvh) {
  .sec-quote {
    height: 100dvh; }
    @media screen and (max-width: 70.5rem) {
      .sec-quote {
        height: auto; } } }

.block-quote {
  padding: var(--section-offset);
  background-color: var(--color-white);
  color: var(--color-text-dark);
  margin: 0; }
  .block-quote__header {
    display: flex;
    justify-content: space-between;
    gap: 2rem;
    padding-bottom: 2.5rem;
    margin-bottom: 2.5rem;
    border-bottom: 1px solid var(--stroke-dark-secondary); }
    @media screen and (min-width: 160.0625rem) {
      .block-quote__header {
        gap: 1.25vw;
        padding-bottom: 1.5625vw;
        margin-bottom: 1.5625vw; } }
    @media screen and (max-width: 131.25rem) {
      .block-quote__header {
        padding-bottom: 2rem;
        margin-bottom: 2rem; } }
    @media screen and (max-width: 103.125rem) {
      .block-quote__header {
        padding-bottom: 1.5rem;
        margin-bottom: 1.5rem; } }
    @media screen and (max-width: 45rem) {
      .block-quote__header {
        padding-bottom: 1rem;
        margin-bottom: 1rem; } }
    .block-quote__header-label {
      font: var(--font-body-M-caption);
      color: var(--color-text-dark-secondary);
      text-transform: uppercase;
      margin-bottom: 0.5rem; }
      @media screen and (min-width: 160.0625rem) {
        .block-quote__header-label {
          margin-bottom: 0.3125vw; } }
      @media screen and (max-width: 103.125rem) {
        .block-quote__header-label {
          margin-bottom: 0.25rem; } }
    .block-quote__header-name {
      font: var(--font-body-M-semibold); }
    .block-quote__header-icon {
      width: 1.5rem;
      height: 1.5rem;
      fill: var(--color-secondary); }
      @media screen and (min-width: 160.0625rem) {
        .block-quote__header-icon {
          width: 0.9375vw;
          height: 0.9375vw; } }
  .block-quote__body {
    font: var(--font-h3);
    letter-spacing: var(--letter-spacing-h3); }
    .block-quote__body p:last-child {
      margin-bottom: 0; }
    .block-quote__body p span,
    .block-quote__body p strong {
      color: var(--color-secondary); }

.sec-reviews {
  background-color: var(--color-gray-3); }
  @media screen and (max-width: 70.5rem) {
    .sec-reviews__slider {
      margin-right: -2.5rem; } }
  @media screen and (max-width: 45rem) {
    .sec-reviews__slider {
      margin-right: -1rem; } }

.sec-slider-utp {
  position: relative;
  padding-top: 0; }
  .sec-slider-utp .sec__title {
    max-width: 45%; }
    @media screen and (max-width: 45rem) {
      .sec-slider-utp .sec__title {
        max-width: 100%; } }
  .sec-slider-utp__wrap {
    position: relative; }
    .sec-slider-utp__wrap .container {
      padding-top: var(--section-offset);
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      z-index: 2; }
      @media screen and (max-width: 45rem) {
        .sec-slider-utp__wrap .container {
          padding-top: 2.5rem; } }
  .sec-slider-utp__bg {
    width: 100%;
    z-index: 1;
    font-size: 0; }
    .sec-slider-utp__bg picture {
      display: block;
      width: 100%; }
    .sec-slider-utp__bg img {
      max-width: 100%;
      width: 100%;
      height: auto; }
      @media screen and (max-width: 70.5rem) {
        .sec-slider-utp__bg img {
          height: 25rem;
          object-fit: cover; } }
  .sec-slider-utp__slider {
    margin-top: -6.25rem; }
    @media screen and (min-width: 160.0625rem) {
      .sec-slider-utp__slider {
        margin-top: -3.90625vw; } }
    @media screen and (max-width: 131.25rem) {
      .sec-slider-utp__slider {
        margin-top: -7.1875rem; } }
    @media screen and (max-width: 103.125rem) {
      .sec-slider-utp__slider {
        margin-top: -6.25rem; } }
    @media screen and (max-width: 70.5rem) {
      .sec-slider-utp__slider {
        margin-top: -9.375rem;
        margin-right: -2.5rem; } }
    @media screen and (max-width: 45rem) {
      .sec-slider-utp__slider {
        margin-top: -5rem;
        margin-right: -1rem; } }
    .sec-slider-utp__slider .swiper-slide {
      height: auto; }
    .sec-slider-utp__slider .utp-card {
      height: 100%; }
    .sec-slider-utp__slider-btns {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin-top: var(--section-offset); }
      @media screen and (max-width: 70.5rem) {
        .sec-slider-utp__slider-btns {
          display: none; } }
      .sec-slider-utp__slider-btns .icon-button {
        margin-left: 0.75rem; }
        @media screen and (min-width: 160.0625rem) {
          .sec-slider-utp__slider-btns .icon-button {
            margin-left: 0.46875vw; } }
        @media screen and (max-width: 103.125rem) {
          .sec-slider-utp__slider-btns .icon-button {
            margin-left: 0.5rem; } }

.utp-card {
  overflow: hidden;
  position: relative;
  background-color: var(--color-gray-3);
  color: var(--color-dark);
  padding: 2.5rem;
  padding-bottom: 25rem; }
  @media screen and (min-width: 160.0625rem) {
    .utp-card {
      padding: 1.5625vw;
      padding-bottom: 15.625vw; } }
  @media screen and (max-width: 131.25rem) {
    .utp-card {
      padding: 2rem;
      padding-bottom: 19.375rem; } }
  @media screen and (max-width: 103.125rem) {
    .utp-card {
      padding: 1.5rem;
      padding-bottom: 14.8125rem; } }
  @media screen and (max-width: 45rem) {
    .utp-card {
      padding: 1.25rem;
      padding-bottom: 11.25rem; } }
  @media screen and (max-width: 30rem) {
    .utp-card {
      padding-bottom: 6.875rem; } }
  .utp-card__count {
    display: block;
    font: var(--font-body-M-caption);
    color: var(--color-secondary);
    margin-bottom: 1.5625rem; }
    @media screen and (min-width: 160.0625rem) {
      .utp-card__count {
        margin-bottom: 0.97656vw; } }
    @media screen and (max-width: 131.25rem) {
      .utp-card__count {
        margin-bottom: 1rem; } }
    @media screen and (max-width: 103.125rem) {
      .utp-card__count {
        margin-bottom: 0.75rem; } }
    @media screen and (max-width: 70.5rem) {
      .utp-card__count {
        margin-bottom: 0.5rem; } }
  .utp-card__title {
    position: relative;
    z-index: 2;
    font: var(--font-h5);
    letter-spacing: var(--letter-spacing-h5); }
  .utp-card__fig {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
    font-size: 0;
    text-align: center; }
    .utp-card__fig picture {
      display: block;
      width: 100%; }
    .utp-card__fig img {
      max-width: 100%;
      height: auto; }

.sec-steps__slider-nav {
  border-bottom: 1px solid var(--stroke-dark-secondary); }
  .sec-steps__slider-nav .swiper-wrapper {
    display: flex;
    justify-content: space-between;
    min-width: 100%; }
  .sec-steps__slider-nav .swiper-slide {
    font-size: 0;
    width: auto; }
  .sec-steps__slider-nav .swiper-slide-thumb-active .button-tab-line__count {
    background-color: var(--color-secondary);
    color: var(--color-white); }
  .sec-steps__slider-nav .swiper-slide-thumb-active .button-tab-line:after {
    width: 100%; }
  .sec-steps__slider-nav .button-tab-line {
    margin-bottom: -1px; }
    @media (any-hover: hover) {
      .sec-steps__slider-nav .button-tab-line:hover:after {
        width: 100%; } }

.sec-steps__slider .swiper-slide {
  height: auto; }

.sec-steps__slider .step-slide {
  height: 100%; }

.sec-steps_dark {
  background-color: var(--color-dark);
  color: var(--color-white); }
  .sec-steps_dark .sec-steps__slider-nav {
    border-color: var(--stroke-light-secondary); }
    @media (any-hover: hover) {
      .sec-steps_dark .sec-steps__slider-nav .button-tab-line:hover:after {
        width: 100%; } }
  .sec-steps_dark .step-slide {
    background-color: var(--color-dark);
    color: var(--color-white); }
    .sec-steps_dark .step-slide__label {
      color: var(--color-text-light-secondary); }

.step-slide {
  display: flex;
  gap: var(--section-offset);
  padding-top: var(--section-offset);
  background-color: var(--color-white); }
  @media screen and (max-width: 45rem) {
    .step-slide {
      flex-direction: column;
      padding-top: 1.75rem;
      gap: 1.75rem; } }
  .step-slide__img {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0;
    flex: 1;
    padding: 2.5rem 0; }
    @media screen and (min-width: 160.0625rem) {
      .step-slide__img {
        padding: 1.5625vw 0; } }
    @media screen and (max-width: 131.25rem) {
      .step-slide__img {
        padding: 1.875rem 0; } }
    @media screen and (max-width: 103.125rem) {
      .step-slide__img {
        padding: 0; } }
    @media screen and (max-width: 70.5rem) {
      .step-slide__img {
        justify-content: flex-start;
        align-items: flex-start;
        flex: 0.3; } }
    @media screen and (max-width: 45rem) {
      .step-slide__img {
        flex: none;
        width: 100%; } }
    .step-slide__img picture {
      display: inline-block;
      width: 100%;
      text-align: center; }
      @media screen and (max-width: 70.5rem) {
        .step-slide__img picture {
          text-align: left; } }
    .step-slide__img img {
      max-width: 41rem;
      width: 100%;
      height: auto; }
      @media screen and (min-width: 160.0625rem) {
        .step-slide__img img {
          max-width: 25.625vw; } }
      @media screen and (max-width: 131.25rem) {
        .step-slide__img img {
          max-width: 28.125rem; } }
      @media screen and (max-width: 103.125rem) {
        .step-slide__img img {
          max-width: 25.5rem; } }
      @media screen and (max-width: 70.5rem) {
        .step-slide__img img {
          max-width: 9.6875rem; } }
      @media screen and (max-width: 45rem) {
        .step-slide__img img {
          max-width: 6.25rem; } }
  .step-slide__content {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    flex: 1; }
    @media screen and (min-width: 160.0625rem) {
      .step-slide__content {
        gap: 0.9375vw; } }
    @media screen and (max-width: 131.25rem) {
      .step-slide__content {
        gap: 1.25rem; } }
    @media screen and (max-width: 103.125rem) {
      .step-slide__content {
        gap: 1rem; } }
    @media screen and (max-width: 70.5rem) {
      .step-slide__content {
        gap: 0.75rem;
        flex: 0.7; } }
    @media screen and (max-width: 45rem) {
      .step-slide__content {
        gap: 0.5rem;
        flex: 1; } }
  .step-slide__label {
    font: var(--font-body-M-caption);
    color: var(--color-secondary);
    text-transform: uppercase; }
  .step-slide__text {
    display: block;
    font: var(--font-h3);
    letter-spacing: var(--letter-spacing-h3);
    margin-right: 6.25rem; }
    @media screen and (min-width: 160.0625rem) {
      .step-slide__text {
        margin-right: 3.90625vw; } }
    @media screen and (max-width: 131.25rem) {
      .step-slide__text {
        margin-right: 4.75rem; } }
    @media screen and (max-width: 103.125rem) {
      .step-slide__text {
        margin-right: 3rem; } }
    @media screen and (max-width: 70.5rem) {
      .step-slide__text {
        margin-right: 0; } }
    .step-slide__text .line {
      overflow: hidden; }
  .step-slide__btns {
    display: flex;
    align-items: center;
    gap: 0.75rem;
    margin-top: auto; }
    @media screen and (min-width: 160.0625rem) {
      .step-slide__btns {
        gap: 0.46875vw; } }
    @media screen and (max-width: 103.125rem) {
      .step-slide__btns {
        gap: 0.5rem; } }
    @media screen and (max-width: 70.5rem) {
      .step-slide__btns {
        display: none; } }

.sec-team__slider {
  margin-right: -6.25rem; }
  @media screen and (min-width: 160.0625rem) {
    .sec-team__slider {
      margin-right: -3.90625vw; } }
  @media screen and (max-width: 131.25rem) {
    .sec-team__slider {
      margin-right: 0; } }
  @media screen and (max-width: 103.125rem) {
    .sec-team__slider {
      margin-right: 0; } }
  @media screen and (max-width: 70.5rem) {
    .sec-team__slider {
      margin-right: -2.5rem; } }
  @media screen and (max-width: 45rem) {
    .sec-team__slider {
      margin-right: -1rem; } }

.sec-types {
  height: 100vh;
  overflow: hidden; }
  @media screen and (max-width: 70.5rem) {
    .sec-types {
      height: auto; } }
  .sec-types__row {
    display: flex;
    gap: var(--section-offset); }
    @media screen and (max-width: 70.5rem) {
      .sec-types__row {
        gap: 1.5rem; } }
    @media screen and (max-width: 45rem) {
      .sec-types__row {
        flex-direction: column;
        gap: 0; } }
  .sec-types__col {
    flex: 1; }
    .sec-types__col .block-accordion-icon:not(:last-child) {
      border-bottom: 1px solid var(--stroke-dark-secondary); }
    @media screen and (max-width: 45rem) {
      .sec-types__col_right .block-accordion-icon:not(:first-child) {
        border-bottom: 0;
        border-top: 1px solid var(--stroke-dark-secondary); }
      .sec-types__col_right .block-accordion-icon:first-child {
        border-bottom: 0; } }
    @media screen and (max-width: 45rem) {
      .sec-types__col_left .block-accordion-icon:first-child .block-accordion-icon__header {
        padding-top: 0; } }
    .sec-types__col_image {
      text-align: center;
      margin-bottom: -6.25rem; }
      @media screen and (min-width: 160.0625rem) {
        .sec-types__col_image {
          margin-bottom: -3.90625vw; } }
      @media screen and (max-width: 131.25rem) {
        .sec-types__col_image {
          margin-bottom: -4.25rem; } }
      @media screen and (max-width: 103.125rem) {
        .sec-types__col_image {
          margin-bottom: -3rem; } }
      @media screen and (max-width: 70.5rem) {
        .sec-types__col_image {
          display: none; } }
      .sec-types__col_image figure {
        font-size: 0;
        margin: 0; }
      .sec-types__col_image picture {
        display: inline-block;
        width: 100%; }
      .sec-types__col_image img {
        max-width: 19.125rem;
        width: 100%;
        height: auto; }
        @media screen and (min-width: 160.0625rem) {
          .sec-types__col_image img {
            max-width: 11.95312vw; } }
        @media screen and (max-width: 131.25rem) {
          .sec-types__col_image img {
            max-width: 14.25rem; } }
        @media screen and (max-width: 103.125rem) {
          .sec-types__col_image img {
            max-width: 10.8125rem; } }
    .sec-types__col_right .block-accordion-icon__header {
      flex-direction: row-reverse; }
    .sec-types__col_right .block-accordion-icon__body {
      text-align: right; }
    @media screen and (max-width: 70.5rem) {
      .sec-types__col_right .block-accordion-icon__header {
        flex-direction: row; }
      .sec-types__col_right .block-accordion-icon__body {
        text-align: left; } }
    @media screen and (max-width: 45rem) {
      .sec-types__col_right {
        border-top: 1px solid var(--stroke-dark-secondary); } }
    .sec-types__col .file-button {
      margin-top: 2.5rem;
      margin-left: auto; }
      @media screen and (min-width: 160.0625rem) {
        .sec-types__col .file-button {
          margin-top: 1.5625vw; } }
      @media screen and (max-width: 131.25rem) {
        .sec-types__col .file-button {
          margin-top: 2rem; } }
      @media screen and (max-width: 70.5rem) {
        .sec-types__col .file-button {
          margin-top: 1.75rem; } }
      @media screen and (max-width: 45rem) {
        .sec-types__col .file-button {
          margin-top: 0;
          margin-left: 0; } }

@supports (height: 100dvh) {
  .sec-types {
    height: 100dvh; }
    @media screen and (max-width: 70.5rem) {
      .sec-types {
        height: auto; } } }

.block-accordion-icon label {
  display: block;
  position: relative; }

.block-accordion-icon__header {
  display: flex;
  align-items: center;
  gap: 2rem;
  padding: 2.5rem 0;
  width: 100%;
  cursor: pointer; }
  @media screen and (min-width: 160.0625rem) {
    .block-accordion-icon__header {
      gap: 1.25vw;
      padding: 1.5625vw 0; } }
  @media screen and (max-width: 131.25rem) {
    .block-accordion-icon__header {
      padding: 2rem 0; } }
  @media screen and (max-width: 103.125rem) {
    .block-accordion-icon__header {
      gap: 1.5rem;
      padding: 1.5rem 0; } }
  @media screen and (max-width: 70.5rem) {
    .block-accordion-icon__header {
      gap: 1rem;
      padding: 1.5rem 0 1rem 0; } }
  @media screen and (max-width: 45rem) {
    .block-accordion-icon__header {
      padding-top: 1.25rem; } }

.block-accordion-icon__arrow {
  width: 1.5rem;
  height: 1.5rem;
  fill: var(--color-text-dark-secondary);
  transition: transform var(--anim-direction); }
  @media screen and (min-width: 160.0625rem) {
    .block-accordion-icon__arrow {
      width: 0.9375vw;
      height: 0.9375vw; } }
  @media screen and (max-width: 70.5rem) {
    .block-accordion-icon__arrow {
      display: none; } }

.block-accordion-icon__icon {
  width: 3rem;
  margin: 0;
  font-size: 0; }
  @media screen and (min-width: 160.0625rem) {
    .block-accordion-icon__icon {
      width: 1.875vw; } }
  @media screen and (max-width: 103.125rem) {
    .block-accordion-icon__icon {
      width: 2.25rem; } }
  @media screen and (max-width: 70.5rem) {
    .block-accordion-icon__icon {
      width: 2rem; } }
  .block-accordion-icon__icon picture {
    display: inline-block;
    width: 100%; }
  .block-accordion-icon__icon img {
    width: 100%;
    max-width: 100%;
    height: auto; }

.block-accordion-icon__title {
  font: var(--font-h5);
  letter-spacing: var(--letter-spacing-h5);
  transition: color var(--anim-direction); }

.block-accordion-icon__body {
  visibility: hidden;
  opacity: 0;
  max-height: 0;
  padding-bottom: 0;
  color: var(--color-text-dark-secondary);
  transition: visibility var(--anim-direction), opacity var(--anim-direction), max-height var(--anim-direction), padding-bottom var(--anim-direction); }
  @media screen and (max-width: 70.5rem) {
    .block-accordion-icon__body {
      visibility: visible;
      opacity: 1;
      max-height: none;
      padding-bottom: 1.5rem; } }
  .block-accordion-icon__body p:first-child {
    margin-top: 0; }
  .block-accordion-icon__body p:last-child {
    margin-bottom: 0; }
  .block-accordion-icon__body.js_active {
    visibility: visible;
    opacity: 1;
    max-height: 62.4375rem;
    padding-bottom: 2.5rem; }
    @media screen and (min-width: 160.0625rem) {
      .block-accordion-icon__body.js_active {
        max-height: 39.02344vw;
        padding-bottom: 1.5625vw; } }
    @media screen and (max-width: 131.25rem) {
      .block-accordion-icon__body.js_active {
        padding-bottom: 2rem; } }
    @media screen and (max-width: 103.125rem) {
      .block-accordion-icon__body.js_active {
        padding-bottom: 1.5rem; } }

.visually-hidden:checked + .block-accordion-icon__header {
  padding-bottom: 1.5rem; }
  @media screen and (min-width: 160.0625rem) {
    .visually-hidden:checked + .block-accordion-icon__header {
      padding-bottom: 0.9375vw; } }
  @media screen and (max-width: 131.25rem) {
    .visually-hidden:checked + .block-accordion-icon__header {
      padding-bottom: 1.25rem; } }
  @media screen and (max-width: 103.125rem) {
    .visually-hidden:checked + .block-accordion-icon__header {
      padding-bottom: 1rem; } }
  .visually-hidden:checked + .block-accordion-icon__header .block-accordion-icon__arrow {
    transform: rotate(180deg); }
  .visually-hidden:checked + .block-accordion-icon__header .block-accordion-icon__title {
    color: var(--color-secondary); }
    @media screen and (max-width: 70.5rem) {
      .visually-hidden:checked + .block-accordion-icon__header .block-accordion-icon__title {
        color: var(--color-text-dark); } }

.sec-utp-parallax {
  position: relative;
  z-index: 2;
  height: 100vh;
  overflow: hidden;
  background-color: var(--color-dark);
  color: var(--color-text-light);
  min-height: 37.5rem; }
  @media screen and (min-width: 160.0625rem) {
    .sec-utp-parallax {
      min-height: 23.4375vw; } }
  @media screen and (max-width: 70.5rem) {
    .sec-utp-parallax {
      min-height: 64rem; } }
  @media screen and (max-width: 45rem) {
    .sec-utp-parallax {
      min-height: 25rem; } }
  .sec-utp-parallax .sec__title {
    max-width: 100%; }
  .sec-utp-parallax .container {
    height: 100%; }
  .sec-utp-parallax__btns {
    margin-top: auto; }
  .sec-utp-parallax__row {
    height: 100%;
    display: flex;
    gap: var(--section-offset); }
    @media screen and (max-width: 70.5rem) {
      .sec-utp-parallax__row {
        flex-direction: column;
        justify-content: space-between;
        gap: 5rem; } }
  .sec-utp-parallax__col {
    display: flex;
    flex-direction: column;
    height: 100%;
    flex: 1; }
    @media screen and (max-width: 70.5rem) {
      .sec-utp-parallax__col {
        flex: none;
        height: auto; } }
    @media screen and (max-width: 45rem) {
      .sec-utp-parallax__col_cards {
        overflow: auto; } }
  .sec-utp-parallax__col-scroll {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap; }
    @media screen and (max-width: 70.5rem) {
      .sec-utp-parallax__col-scroll {
        display: grid;
        grid-template-rows: 1fr 1fr;
        grid-auto-flow: column;
        grid-auto-columns: max-content; } }
    @media screen and (max-width: 45rem) {
      .sec-utp-parallax__col-scroll {
        display: flex;
        flex-wrap: nowrap;
        width: max-content; } }
    .sec-utp-parallax__col-scroll .block-utp-2 {
      width: 50%;
      height: 31.25rem;
      margin-left: -1px;
      margin-top: -1px; }
      @media screen and (min-width: 160.0625rem) {
        .sec-utp-parallax__col-scroll .block-utp-2 {
          height: 19.53125vw; } }
      @media screen and (max-width: 131.25rem) {
        .sec-utp-parallax__col-scroll .block-utp-2 {
          height: 25.875rem; } }
      @media screen and (max-width: 103.125rem) {
        .sec-utp-parallax__col-scroll .block-utp-2 {
          height: 19.625rem; } }
      @media screen and (max-width: 70.5rem) {
        .sec-utp-parallax__col-scroll .block-utp-2 {
          width: 19.875rem;
          height: 19.875rem; } }
      @media screen and (max-width: 45rem) {
        .sec-utp-parallax__col-scroll .block-utp-2 {
          width: 14.8125rem;
          height: 14rem;
          margin-top: 0; } }
      .sec-utp-parallax__col-scroll .block-utp-2:nth-child(odd) {
        margin-top: -6.3125rem; }
        @media screen and (min-width: 160.0625rem) {
          .sec-utp-parallax__col-scroll .block-utp-2:nth-child(odd) {
            margin-top: -3.94531vw; } }
        @media screen and (max-width: 131.25rem) {
          .sec-utp-parallax__col-scroll .block-utp-2:nth-child(odd) {
            margin-top: -4.3125rem; } }
        @media screen and (max-width: 103.125rem) {
          .sec-utp-parallax__col-scroll .block-utp-2:nth-child(odd) {
            margin-top: -3.0625rem; } }
        @media screen and (max-width: 70.5rem) {
          .sec-utp-parallax__col-scroll .block-utp-2:nth-child(odd) {
            margin-top: -1px; } }
        @media screen and (max-width: 45rem) {
          .sec-utp-parallax__col-scroll .block-utp-2:nth-child(odd) {
            margin-top: 0; } }
      .sec-utp-parallax__col-scroll .block-utp-2:nth-child(2) {
        margin-top: var(--section-offset); }
        @media screen and (max-width: 70.5rem) {
          .sec-utp-parallax__col-scroll .block-utp-2:nth-child(2) {
            margin-top: -1px; } }
        @media screen and (max-width: 45rem) {
          .sec-utp-parallax__col-scroll .block-utp-2:nth-child(2) {
            margin-top: 0; } }
      .sec-utp-parallax__col-scroll .block-utp-2:first-child {
        margin-top: 0; }
        @media screen and (max-width: 70.5rem) {
          .sec-utp-parallax__col-scroll .block-utp-2:first-child {
            margin-top: -1px; } }
        @media screen and (max-width: 45rem) {
          .sec-utp-parallax__col-scroll .block-utp-2:first-child {
            margin-top: 0;
            margin-left: 0; } }

@supports (height: 100dvh) {
  .sec-utp-parallax {
    height: 100dvh; } }

.block-utp-2 {
  position: relative;
  padding: 2.5rem;
  border: 1px solid var(--stroke-light-secondary); }
  @media screen and (min-width: 160.0625rem) {
    .block-utp-2 {
      padding: 1.5625vw; } }
  @media screen and (max-width: 131.25rem) {
    .block-utp-2 {
      padding: 2rem; } }
  @media screen and (max-width: 103.125rem) {
    .block-utp-2 {
      padding: 1.5rem; } }
  @media screen and (max-width: 45rem) {
    .block-utp-2 {
      padding: 1.25rem; } }
  .block-utp-2__count {
    position: relative;
    z-index: 2;
    font: var(--font-body-M-caption);
    color: var(--color-text-light-secondary);
    margin-bottom: 1.25rem; }
    @media screen and (min-width: 160.0625rem) {
      .block-utp-2__count {
        margin-bottom: 0.78125vw; } }
    @media screen and (max-width: 131.25rem) {
      .block-utp-2__count {
        margin-bottom: 1rem; } }
    @media screen and (max-width: 103.125rem) {
      .block-utp-2__count {
        margin-bottom: 0.75rem; } }
    @media screen and (max-width: 70.5rem) {
      .block-utp-2__count {
        margin-bottom: 0.5rem; } }
  .block-utp-2__title {
    position: relative;
    z-index: 2;
    font: var(--font-h4);
    letter-spacing: var(--letter-spacing-h4); }
    @media screen and (max-width: 45rem) {
      .block-utp-2__title {
        font-size: 1rem; } }
  .block-utp-2__img {
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 1;
    margin: 0;
    font-size: 0;
    width: 100%; }
    .block-utp-2__img picture {
      display: block;
      width: 100%; }
    .block-utp-2__img img {
      width: 100%;
      height: auto;
      max-width: 100%; }

.block-contact-full {
  display: flex;
  gap: 6.25rem; }
  @media screen and (min-width: 160.0625rem) {
    .block-contact-full {
      gap: 3.90625vw; } }
  @media screen and (max-width: 131.25rem) {
    .block-contact-full {
      gap: 4.25rem; } }
  @media screen and (max-width: 70.5rem) {
    .block-contact-full {
      gap: 2.5rem;
      flex-direction: column; } }
  @media screen and (max-width: 45rem) {
    .block-contact-full {
      gap: 1.75rem; } }
  .block-contact-full__content {
    display: flex;
    flex-direction: column;
    gap: 2.5rem;
    flex: 1; }
    @media screen and (min-width: 160.0625rem) {
      .block-contact-full__content {
        gap: 1.5625vw; } }
    @media screen and (max-width: 131.25rem) {
      .block-contact-full__content {
        gap: 2rem; } }
    @media screen and (max-width: 103.125rem) {
      .block-contact-full__content {
        gap: 1.5rem; } }
    @media screen and (max-width: 45rem) {
      .block-contact-full__content {
        gap: 1.25rem; } }
    .block-contact-full__content .contact-block {
      padding-right: 6.25rem; }
      @media screen and (min-width: 160.0625rem) {
        .block-contact-full__content .contact-block {
          padding-right: 3.90625vw; } }
      @media screen and (max-width: 131.25rem) {
        .block-contact-full__content .contact-block {
          padding-right: 4.25rem; } }
      @media screen and (max-width: 103.125rem) {
        .block-contact-full__content .contact-block {
          padding-right: 3rem; } }
      @media screen and (max-width: 70.5rem) {
        .block-contact-full__content .contact-block {
          padding-right: 0; } }
  .block-contact-full__map {
    flex: 1;
    background-color: var(--color-gray-3); }
    .block-contact-full__map .ya-map {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      min-height: 25rem; }
      @media screen and (min-width: 160.0625rem) {
        .block-contact-full__map .ya-map {
          min-height: 15.625vw; } }
      @media screen and (max-width: 70.5rem) {
        .block-contact-full__map .ya-map {
          height: 25rem; } }
      @media screen and (max-width: 45rem) {
        .block-contact-full__map .ya-map {
          min-height: 18.75rem;
          height: 18.75rem; } }

.page {
  margin: 0;
  position: relative;
  min-width: 320px;
  min-height: 100%;
  font: var(--font-body-M);
  letter-spacing: var(--text-spacing);
  background-color: var(--color-white);
  scroll-behavior: smooth; }
  @media screen and (min-width: 160.0625rem) {
    .page {
      font-size: 0.625vw;
      font: var(--font-body-M); } }
  .page::-webkit-scrollbar {
    width: 0.25rem; }
    @media screen and (min-width: 160.0625rem) {
      .page::-webkit-scrollbar {
        width: 0.15625vw; } }
  .page::-webkit-scrollbar-track {
    background: var(--color-white); }
  .page::-webkit-scrollbar-thumb {
    background-color: var(--color-dark); }
  .page:before {
    content: "";
    display: block;
    width: 1px;
    height: 100%;
    background-color: var(--stroke-dark-secondary);
    position: absolute;
    left: 6.25rem;
    top: 0;
    z-index: 1; }
    @media screen and (min-width: 160.0625rem) {
      .page:before {
        left: 3.90625vw; } }
    @media screen and (max-width: 103.125rem) {
      .page:before {
        left: 4.5rem; } }
    @media screen and (max-width: 70.5rem) {
      .page:before {
        content: none; } }
  .page__main {
    padding-bottom: var(--section-offset);
    transform-origin: top;
    transition: transform var(--anim-direction); }
    @media screen and (max-width: 45rem) {
      .page__main {
        padding-bottom: 2.5rem; } }
    .page__main_p-top {
      padding-top: var(--height-menu); }
  .page__breadcrumbs {
    padding: 2rem 0;
    overflow: auto; }
    @media screen and (min-width: 160.0625rem) {
      .page__breadcrumbs {
        padding: 1.25vw 0; } }
    @media screen and (max-width: 103.125rem) {
      .page__breadcrumbs {
        padding: 1.5rem 0; } }
    @media screen and (max-width: 45rem) {
      .page__breadcrumbs {
        padding: 0.875rem 0; } }
  .page__title {
    display: block;
    font: var(--font-h3);
    letter-spacing: var(--letter-spacing-h3);
    margin-bottom: var(--section-offset); }
    @media screen and (max-width: 45rem) {
      .page__title {
        margin-bottom: 1rem; } }
  .page__row {
    display: flex;
    width: 100%;
    gap: var(--section-offset);
    padding: var(--section-padding); }
    .page__row_pt-min {
      padding-top: 4.25rem; }
      @media screen and (min-width: 160.0625rem) {
        .page__row_pt-min {
          padding-top: 2.65625vw; } }
      @media screen and (max-width: 131.25rem) {
        .page__row_pt-min {
          padding-top: 3rem; } }
      @media screen and (max-width: 103.125rem) {
        .page__row_pt-min {
          padding-top: 1.5rem; } }
      @media screen and (max-width: 70.5rem) {
        .page__row_pt-min {
          padding-top: 1rem; } }
  .page__content {
    flex: 1; }
  .page__aside {
    flex-basis: 43rem; }
    @media screen and (min-width: 160.0625rem) {
      .page__aside {
        flex-basis: 26.875vw; } }
    @media screen and (max-width: 131.25rem) {
      .page__aside {
        flex-basis: 32.25rem; } }
    @media screen and (max-width: 103.125rem) {
      .page__aside {
        flex-basis: 24.5rem; } }
    @media screen and (max-width: 70.5rem) {
      .page__aside {
        display: none; } }
    .page__aside .cta-form {
      position: sticky;
      top: 0;
      right: 0; }
  .page__bottom {
    padding-top: var(--section-offset);
    margin-top: var(--section-offset);
    border-top: 1px solid var(--stroke-dark-secondary); }
  .page__pagination {
    padding-top: var(--section-offset);
    display: flex;
    align-items: center;
    justify-content: center; }
  .page.scale_min .page__main {
    transform: scale(0.9); }

.page-404 .page__row {
  padding-top: 0;
  align-items: center; }
  @media screen and (max-width: 70.5rem) {
    .page-404 .page__row {
      flex-direction: column-reverse;
      gap: 0; } }

.page-404 .page__title {
  max-width: 35rem;
  margin: 0; }
  @media screen and (min-width: 160.0625rem) {
    .page-404 .page__title {
      max-width: 21.875vw; } }
  @media screen and (max-width: 131.25rem) {
    .page-404 .page__title {
      max-width: 30rem; } }
  @media screen and (max-width: 70.5rem) {
    .page-404 .page__title {
      max-width: 100%; } }
  .page-404 .page__title strong {
    font-weight: inherit;
    color: var(--color-secondary); }

.page-404 .page__content {
  flex: none;
  flex-basis: 67.625rem;
  padding-top: 15rem;
  padding-right: var(--section-offset); }
  @media screen and (min-width: 160.0625rem) {
    .page-404 .page__content {
      flex-basis: 42.26562vw;
      padding-top: 9.375vw; } }
  @media screen and (max-width: 131.25rem) {
    .page-404 .page__content {
      flex-basis: 50.5rem;
      padding-top: 8.75rem; } }
  @media screen and (max-width: 103.125rem) {
    .page-404 .page__content {
      flex-basis: 38.25rem;
      padding-top: 4.6875rem; } }
  @media screen and (max-width: 70.5rem) {
    .page-404 .page__content {
      flex-basis: 100%;
      padding-right: 0;
      padding-top: 2.5rem; } }
  .page-404 .page__content .text-editor {
    margin: 2.5rem 0; }
    @media screen and (min-width: 160.0625rem) {
      .page-404 .page__content .text-editor {
        margin: 1.5625vw 0; } }
    @media screen and (max-width: 131.25rem) {
      .page-404 .page__content .text-editor {
        margin: 2rem 0; } }
    @media screen and (max-width: 103.125rem) {
      .page-404 .page__content .text-editor {
        margin: 1.5rem 0; } }
    @media screen and (max-width: 45rem) {
      .page-404 .page__content .text-editor {
        margin: 1rem 0; } }

.page-404__img {
  flex: 1;
  margin: 0;
  padding: 0;
  align-self: flex-start;
  display: block;
  opacity: 0; }
  @media screen and (max-width: 70.5rem) {
    .page-404__img {
      align-self: center; } }
  .page-404__img picture {
    display: block;
    padding: 0;
    width: 100%; }
  .page-404__img img {
    max-width: 100%;
    width: 100%;
    height: auto;
    transform-origin: top;
    animation: anim_img_404 3s ease-in-out infinite; }
    @media screen and (max-width: 70.5rem) {
      .page-404__img img {
        max-width: 25rem; } }
    @media screen and (max-width: 45rem) {
      .page-404__img img {
        max-width: 16.875rem; } }

@media screen and (max-width: 45rem) {
  .page-price .page__title {
    margin-bottom: 1.75rem; } }

.page-price__accordion-list .block-accordion:first-child .block-accordion__header {
  padding-top: 0; }

.page-news .page__title {
  margin-bottom: 2.5rem; }
  @media screen and (min-width: 160.0625rem) {
    .page-news .page__title {
      margin-bottom: 1.5625vw; } }
  @media screen and (max-width: 131.25rem) {
    .page-news .page__title {
      margin-bottom: 2rem; } }
  @media screen and (max-width: 103.125rem) {
    .page-news .page__title {
      margin-bottom: 1.5rem; } }
  @media screen and (max-width: 45rem) {
    .page-news .page__title {
      margin-bottom: 1rem; } }

.page-news__post-info {
  display: flex;
  align-items: center;
  gap: 1.25rem;
  margin-bottom: var(--section-offset); }
  @media screen and (min-width: 160.0625rem) {
    .page-news__post-info {
      gap: 0.78125vw; } }
  @media screen and (max-width: 103.125rem) {
    .page-news__post-info {
      gap: 1rem; } }
  @media screen and (max-width: 70.5rem) {
    .page-news__post-info {
      gap: 0.75rem; } }
  @media screen and (max-width: 45rem) {
    .page-news__post-info {
      flex-direction: column;
      align-items: flex-start; } }

.page-news__post-date {
  font: var(--font-body-M-caption);
  text-transform: uppercase;
  color: var(--color-text-dark-secondary);
  letter-spacing: -0.54px; }
  @media screen and (max-width: 131.25rem) {
    .page-news__post-date {
      letter-spacing: -0.42px; } }
  @media screen and (max-width: 103.125rem) {
    .page-news__post-date {
      letter-spacing: -0.36px; } }

.page-news__post-tags {
  display: flex;
  align-items: center;
  gap: 0.3125rem; }
  @media screen and (min-width: 160.0625rem) {
    .page-news__post-tags {
      gap: 0.19531vw; } }

.page-news__post-link-source {
  margin-left: auto;
  color: var(--color-text-dark);
  text-underline-offset: 0.125rem;
  font: var(--font-link); }
  @media screen and (min-width: 160.0625rem) {
    .page-news__post-link-source {
      text-underline-offset: 0.07812vw; } }
  @media screen and (max-width: 45rem) {
    .page-news__post-link-source {
      margin-left: 0;
      margin-top: 0.5rem; } }

.page-news__post-image {
  display: block;
  font-size: 0;
  margin: var(--section-offset) 0; }
  .page-news__post-image picture {
    display: block;
    width: 100%; }
  .page-news__post-image img {
    width: 100%;
    max-width: 100%;
    height: 31.25rem;
    object-fit: cover; }
    @media screen and (min-width: 160.0625rem) {
      .page-news__post-image img {
        height: 19.53125vw; } }
    @media screen and (max-width: 103.125rem) {
      .page-news__post-image img {
        height: 25rem; } }
    @media screen and (max-width: 70.5rem) {
      .page-news__post-image img {
        height: 21.875rem; } }
    @media screen and (max-width: 45rem) {
      .page-news__post-image img {
        height: 15.625rem; } }

.page-faq .page__bottom {
  margin-top: 0;
  border: 0; }

.page-faq #mse2_results {
  margin-bottom: 0; }

.page-faq__accordion-list .block-accordion:first-child .block-accordion__header {
  padding-top: 0; }

.page-faq__accordion-list .block-accordion__content {
  max-width: 67.375rem; }
  @media screen and (min-width: 160.0625rem) {
    .page-faq__accordion-list .block-accordion__content {
      max-width: 42.10938vw; } }
  @media screen and (max-width: 131.25rem) {
    .page-faq__accordion-list .block-accordion__content {
      max-width: 50.5rem; } }
  @media screen and (max-width: 103.125rem) {
    .page-faq__accordion-list .block-accordion__content {
      max-width: 38.25rem; } }
  @media screen and (max-width: 70.5rem) {
    .page-faq__accordion-list .block-accordion__content {
      max-width: 100%; } }

.page-projects__list .project-card:not(:last-child) {
  margin-bottom: 4.25rem; }
  @media screen and (min-width: 160.0625rem) {
    .page-projects__list .project-card:not(:last-child) {
      margin-bottom: 2.65625vw; } }
  @media screen and (max-width: 70.5rem) {
    .page-projects__list .project-card:not(:last-child) {
      margin-bottom: 1.5rem; } }
  @media screen and (max-width: 45rem) {
    .page-projects__list .project-card:not(:last-child) {
      margin-bottom: 1rem; } }

.page-projects__list .project-card:nth-child(even) {
  flex-direction: row-reverse; }
  @media screen and (max-width: 45rem) {
    .page-projects__list .project-card:nth-child(even) {
      flex-direction: column; } }

.page-news-list .page__row {
  padding-bottom: 0; }
  @media screen and (max-width: 70.5rem) {
    .page-news-list .page__row {
      padding-bottom: 1rem; } }
  @media screen and (max-width: 45rem) {
    .page-news-list .page__row {
      padding-bottom: 0.75rem; } }

.page-news-list .page__pagination {
  padding-top: 0; }

.page-news-list #mse2_results {
  margin-bottom: 0; }

.page-news-list__list {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -3.125rem; }
  @media screen and (min-width: 160.0625rem) {
    .page-news-list__list {
      margin: 0 -1.95312vw; } }
  @media screen and (max-width: 131.25rem) {
    .page-news-list__list {
      margin: 0 -2.125rem; } }
  @media screen and (max-width: 103.125rem) {
    .page-news-list__list {
      margin: 0 -1.5rem; } }
  @media screen and (max-width: 70.5rem) {
    .page-news-list__list {
      margin: 0 -0.75rem; } }
  @media screen and (max-width: 45rem) {
    .page-news-list__list {
      margin: 0; } }
  .page-news-list__list .news-card {
    width: calc(50% - 6.25rem);
    margin: 0 3.125rem;
    margin-bottom: var(--section-offset); }
    @media screen and (min-width: 160.0625rem) {
      .page-news-list__list .news-card {
        width: calc(50% - 3.90625vw);
        margin: 0 1.95312vw;
        margin-bottom: var(--section-offset); } }
    @media screen and (max-width: 131.25rem) {
      .page-news-list__list .news-card {
        width: calc(50% - 4.25rem);
        margin: 0 2.125rem;
        margin-bottom: var(--section-offset); } }
    @media screen and (max-width: 103.125rem) {
      .page-news-list__list .news-card {
        width: calc(50% - 3rem);
        margin: 0 1.5rem;
        margin-bottom: var(--section-offset); } }
    @media screen and (max-width: 70.5rem) {
      .page-news-list__list .news-card {
        width: calc(50% - 1.5rem);
        margin: 0 0.75rem;
        margin-bottom: 1.5rem; } }
    @media screen and (max-width: 45rem) {
      .page-news-list__list .news-card {
        width: 100%;
        margin: 0;
        margin-bottom: 1rem; } }

.page-reviews-list {
  background-color: var(--color-gray-3);
  padding-bottom: 0; }
  .page-reviews-list .page__row {
    padding-bottom: 0; }
    @media screen and (max-width: 70.5rem) {
      .page-reviews-list .page__row {
        padding-bottom: 1rem; } }
    @media screen and (max-width: 45rem) {
      .page-reviews-list .page__row {
        padding-bottom: 0.75rem; } }
  .page-reviews-list .page__pagination {
    padding-top: 0; }
  .page-reviews-list__list {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -3.125rem; }
    @media screen and (min-width: 160.0625rem) {
      .page-reviews-list__list {
        margin: 0 -1.95312vw; } }
    @media screen and (max-width: 131.25rem) {
      .page-reviews-list__list {
        margin: 0 -2.125rem; } }
    @media screen and (max-width: 103.125rem) {
      .page-reviews-list__list {
        margin: 0 -1.5rem; } }
    @media screen and (max-width: 70.5rem) {
      .page-reviews-list__list {
        margin: 0; } }
    .page-reviews-list__list .review-card {
      width: calc(50% - 6.25rem);
      margin: 0 3.125rem;
      margin-bottom: var(--section-offset); }
      @media screen and (min-width: 160.0625rem) {
        .page-reviews-list__list .review-card {
          width: calc(50% - 3.90625vw);
          margin: 0 1.95312vw;
          margin-bottom: var(--section-offset); } }
      @media screen and (max-width: 131.25rem) {
        .page-reviews-list__list .review-card {
          width: calc(50% - 4.25rem);
          margin: 0 2.125rem;
          margin-bottom: var(--section-offset); } }
      @media screen and (max-width: 103.125rem) {
        .page-reviews-list__list .review-card {
          width: calc(50% - 3rem);
          margin: 0 1.5rem;
          margin-bottom: var(--section-offset); } }
      @media screen and (max-width: 70.5rem) {
        .page-reviews-list__list .review-card {
          width: 100%;
          margin: 0;
          margin-bottom: 1.5rem; } }

.page-contacts .page__content {
  width: 100%; }

.page-contacts__tabs {
  width: 100%;
  overflow: hidden;
  padding-bottom: var(--section-offset); }
  @media screen and (max-width: 45rem) {
    .page-contacts__tabs {
      padding-bottom: 2.5rem; } }
  .page-contacts__tabs-list {
    width: 100%;
    overflow: auto hidden;
    border-bottom: 1px solid var(--stroke-dark-secondary); }
    .page-contacts__tabs-list-wrap-scroll {
      display: flex;
      align-items: flex-end;
      gap: 2.5rem; }
      @media screen and (min-width: 160.0625rem) {
        .page-contacts__tabs-list-wrap-scroll {
          gap: 1.5625vw; } }
      @media screen and (max-width: 131.25rem) {
        .page-contacts__tabs-list-wrap-scroll {
          gap: 2rem; } }
      .page-contacts__tabs-list-wrap-scroll label {
        position: relative; }
    .page-contacts__tabs-list .button-tab-line {
      margin-bottom: -1px;
      width: max-content; }
  .page-contacts__tabs-content {
    padding-top: var(--section-offset); }
    @media screen and (max-width: 70.5rem) {
      .page-contacts__tabs-content {
        padding-top: 1.875rem; } }
    .page-contacts__tabs-content .block-contact-full {
      display: none; }
      .page-contacts__tabs-content .block-contact-full.js_active {
        display: flex; }

.page-contacts__row {
  display: flex;
  padding-top: var(--section-offset);
  gap: var(--section-offset); }
  @media screen and (max-width: 103.125rem) {
    .page-contacts__row {
      padding-top: 3rem;
      gap: 4.25rem; } }
  @media screen and (max-width: 70.5rem) {
    .page-contacts__row {
      padding-top: 2.5rem;
      flex-direction: column; } }

.page-contacts__information {
  flex: 1; }
  @media screen and (max-width: 70.5rem) {
    .page-contacts__information {
      width: 100%; } }
  .page-contacts__information .table-information {
    margin-top: var(--section-offset);
    width: 100%;
    border-collapse: collapse;
    font: var(--font-body-M); }
    .page-contacts__information .table-information tr {
      border-bottom: 1px solid var(--stroke-dark-secondary); }
      @media screen and (max-width: 45rem) {
        .page-contacts__information .table-information tr {
          display: flex;
          flex-direction: column;
          padding: 1rem 0; } }
      .page-contacts__information .table-information tr td:first-child {
        padding-left: 0;
        color: var(--color-text-dark-secondary);
        min-width: 18.125rem; }
        @media screen and (min-width: 160.0625rem) {
          .page-contacts__information .table-information tr td:first-child {
            min-width: 11.32812vw; } }
        @media screen and (max-width: 131.25rem) {
          .page-contacts__information .table-information tr td:first-child {
            min-width: 14.0625rem; } }
        @media screen and (max-width: 103.125rem) {
          .page-contacts__information .table-information tr td:first-child {
            min-width: 10.625rem; } }
        @media screen and (max-width: 70.5rem) {
          .page-contacts__information .table-information tr td:first-child {
            min-width: 13.125rem; } }
        @media screen and (max-width: 45rem) {
          .page-contacts__information .table-information tr td:first-child {
            min-width: 100%;
            margin-bottom: 0.5rem; } }
      .page-contacts__information .table-information tr td:last-child {
        padding-right: 0; }
      @media screen and (max-width: 45rem) {
        .page-contacts__information .table-information tr:first-child {
          padding-top: 0; } }
      .page-contacts__information .table-information tr:first-child td {
        padding-top: 0; }
    .page-contacts__information .table-information td {
      padding: 2.5rem 6.25rem;
      padding-right: 0;
      transition: background-color var(--anim-direction); }
      @media screen and (min-width: 160.0625rem) {
        .page-contacts__information .table-information td {
          padding: 1.5625vw 3.90625vw;
          padding-right: 0; } }
      @media screen and (max-width: 131.25rem) {
        .page-contacts__information .table-information td {
          padding: 2rem 4.25rem;
          padding-right: 0; } }
      @media screen and (max-width: 103.125rem) {
        .page-contacts__information .table-information td {
          padding: 1.5rem 3rem;
          padding-right: 0; } }
      @media screen and (max-width: 45rem) {
        .page-contacts__information .table-information td {
          padding: 0; } }
  .page-contacts__information .file-button {
    margin-top: 2.5rem; }
    @media screen and (min-width: 160.0625rem) {
      .page-contacts__information .file-button {
        margin-top: 1.5625vw; } }
    @media screen and (max-width: 131.25rem) {
      .page-contacts__information .file-button {
        margin-top: 2rem; } }
    @media screen and (max-width: 103.125rem) {
      .page-contacts__information .file-button {
        margin-top: 1.5rem; } }

.page-contacts__cta {
  flex: 1; }
  @media screen and (max-width: 70.5rem) {
    .page-contacts__cta {
      display: none; } }
  .page-contacts__cta .cta-form-light {
    position: sticky;
    top: 0;
    left: 0;
    background-color: var(--color-gray-3); }

.page-services .page-banner {
  background-color: var(--color-gray-3); }

.page-services-list {
  padding-bottom: 0; }
  .page-services-list .page__row {
    padding-bottom: 0; }
  .page-services-list__list {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -3.125rem; }
    @media screen and (min-width: 160.0625rem) {
      .page-services-list__list {
        margin: 0 -1.95312vw; } }
    @media screen and (max-width: 131.25rem) {
      .page-services-list__list {
        margin: 0 -2.125rem; } }
    @media screen and (max-width: 103.125rem) {
      .page-services-list__list {
        margin: 0 -1.5rem; } }
    @media screen and (max-width: 70.5rem) {
      .page-services-list__list {
        margin: 0 -0.75rem; } }
    @media screen and (max-width: 45rem) {
      .page-services-list__list {
        margin: 0; } }
    .page-services-list__list .services-card {
      width: calc(25% - 6.25rem);
      margin: 0 3.125rem;
      margin-bottom: 6.25rem; }
      @media screen and (min-width: 160.0625rem) {
        .page-services-list__list .services-card {
          width: calc(25% - 3.90625vw);
          margin: 0 1.95312vw;
          margin-bottom: 3.90625vw; } }
      @media screen and (max-width: 131.25rem) {
        .page-services-list__list .services-card {
          width: calc(25% - 4.25rem);
          margin: 0 2.125rem;
          margin-bottom: 4.25rem; } }
      @media screen and (max-width: 103.125rem) {
        .page-services-list__list .services-card {
          width: calc(25% - 3rem);
          margin: 0 1.5rem;
          margin-bottom: 3rem; } }
      @media screen and (max-width: 70.5rem) {
        .page-services-list__list .services-card {
          width: calc(50% - 1.5rem);
          margin: 0 0.75rem;
          margin-bottom: 1.5rem; } }
      @media screen and (max-width: 45rem) {
        .page-services-list__list .services-card {
          width: 100%;
          margin: 0;
          margin-bottom: 1rem; } }
  .page-services-list .sec-cta {
    background-color: transparent; }
    .page-services-list .sec-cta .cta-form-light {
      background-color: var(--color-gray-3); }
  @media screen and (max-width: 45rem) {
    .page-services-list .sec-incoterms {
      display: none; } }

.page-about {
  padding-bottom: 0; }

.text-editor {
  font: var(--font-body-M);
  letter-spacing: var(--text-spacing); }
  .text-editor h1 {
    font: var(--font-h3);
    letter-spacing: var(--letter-spacing-h3); }
  .text-editor h2 {
    margin-top: 6.25rem;
    margin-bottom: 2rem;
    font: var(--font-h4);
    letter-spacing: var(--letter-spacing-h4); }
    @media screen and (min-width: 160.0625rem) {
      .text-editor h2 {
        margin-top: 3.90625vw;
        margin-bottom: 1.25vw; } }
    @media screen and (max-width: 131.25rem) {
      .text-editor h2 {
        margin-top: 4.25rem;
        margin-bottom: 1.5rem; } }
    @media screen and (max-width: 103.125rem) {
      .text-editor h2 {
        margin-top: 3rem; } }
    @media screen and (max-width: 70.5rem) {
      .text-editor h2 {
        margin-top: 2.5rem;
        margin-bottom: 1rem; } }
    @media screen and (max-width: 45rem) {
      .text-editor h2 {
        margin-top: 1.75rem; } }
  .text-editor h3 {
    margin-top: 4.25rem;
    margin-bottom: 1.5rem;
    font: var(--font-h5);
    letter-spacing: var(--letter-spacing-h5); }
    @media screen and (min-width: 160.0625rem) {
      .text-editor h3 {
        margin-top: 2.65625vw;
        margin-bottom: 0.9375vw; } }
    @media screen and (max-width: 131.25rem) {
      .text-editor h3 {
        margin-top: 3rem; } }
    @media screen and (max-width: 103.125rem) {
      .text-editor h3 {
        margin-top: 2.5rem;
        margin-bottom: 1rem; } }
    @media screen and (max-width: 70.5rem) {
      .text-editor h3 {
        margin-top: 1.75rem; } }
    @media screen and (max-width: 45rem) {
      .text-editor h3 {
        margin-top: 1.25rem; } }
  .text-editor h4 {
    margin-top: 4.25rem;
    margin-bottom: 1.5rem;
    font: var(--font-h5);
    letter-spacing: var(--letter-spacing-h5); }
    @media screen and (min-width: 160.0625rem) {
      .text-editor h4 {
        margin-top: 2.65625vw;
        margin-bottom: 0.9375vw; } }
    @media screen and (max-width: 131.25rem) {
      .text-editor h4 {
        margin-top: 3rem; } }
    @media screen and (max-width: 103.125rem) {
      .text-editor h4 {
        margin-top: 2.5rem;
        margin-bottom: 1rem; } }
    @media screen and (max-width: 70.5rem) {
      .text-editor h4 {
        margin-top: 1.75rem; } }
    @media screen and (max-width: 45rem) {
      .text-editor h4 {
        margin-top: 1.25rem; } }
  .text-editor p {
    margin: 2rem 0; }
    @media screen and (min-width: 160.0625rem) {
      .text-editor p {
        margin: 1.25vw 0; } }
    @media screen and (max-width: 131.25rem) {
      .text-editor p {
        margin: 1.625rem 0; } }
    @media screen and (max-width: 103.125rem) {
      .text-editor p {
        margin: 1.5rem 0; } }
    @media screen and (max-width: 70.5rem) {
      .text-editor p {
        margin: 1.25rem 0; } }
    .text-editor p:first-child {
      margin-top: 0; }
    .text-editor p a {
      color: var(--color-secondary); }
  .text-editor ul,
  .text-editor ol {
    margin: 1.25rem 0; }
    @media screen and (min-width: 160.0625rem) {
      .text-editor ul,
      .text-editor ol {
        margin: 0.78125vw 0; } }
    @media screen and (max-width: 103.125rem) {
      .text-editor ul,
      .text-editor ol {
        margin: 1rem 0; } }
    @media screen and (max-width: 45rem) {
      .text-editor ul,
      .text-editor ol {
        padding-left: 1.75rem; } }
    .text-editor ul:first-child,
    .text-editor ol:first-child {
      margin-top: 0; }
    .text-editor ul li,
    .text-editor ol li {
      padding-left: 1.125rem; }
      @media screen and (min-width: 160.0625rem) {
        .text-editor ul li,
        .text-editor ol li {
          padding-left: 0.70312vw; } }
      @media screen and (max-width: 103.125rem) {
        .text-editor ul li,
        .text-editor ol li {
          padding-left: 1rem; } }
      @media screen and (max-width: 45rem) {
        .text-editor ul li,
        .text-editor ol li {
          padding-left: 0.75rem; } }
      .text-editor ul li:not(:last-child),
      .text-editor ol li:not(:last-child) {
        margin-bottom: 1.25rem; }
        @media screen and (min-width: 160.0625rem) {
          .text-editor ul li:not(:last-child),
          .text-editor ol li:not(:last-child) {
            margin-bottom: 0.78125vw; } }
        @media screen and (max-width: 131.25rem) {
          .text-editor ul li:not(:last-child),
          .text-editor ol li:not(:last-child) {
            margin-bottom: 1rem; } }
      .text-editor ul li::marker,
      .text-editor ol li::marker {
        color: var(--color-secondary); }
  .text-editor img {
    max-width: 100%;
    height: auto; }
  .text-editor table {
    width: 100%;
    border-collapse: collapse;
    font: var(--font-body-M);
    border: 1px solid var(--stroke-dark-secondary); }
    .text-editor table tr {
      border: 1px solid var(--stroke-dark-secondary); }
      .text-editor table tr:hover td {
        background-color: var(--color-gray-3); }
    .text-editor table td, .text-editor table th {
      padding: 2.5rem;
      transition: background-color var(--anim-direction); }
      @media screen and (min-width: 160.0625rem) {
        .text-editor table td, .text-editor table th {
          padding: 1.5625vw; } }
      @media screen and (max-width: 131.25rem) {
        .text-editor table td, .text-editor table th {
          padding: 2rem; } }
      @media screen and (max-width: 103.125rem) {
        .text-editor table td, .text-editor table th {
          padding: 1.5rem; } }
      @media screen and (max-width: 70.5rem) {
        .text-editor table td, .text-editor table th {
          padding: 1rem; }
          .text-editor table td ul li, .text-editor table td ol li, .text-editor table th ul li, .text-editor table th ol li {
            margin-bottom: 0.3125rem !important; } }
      .text-editor table td:not(:last-child), .text-editor table th:not(:last-child) {
        border-right: 1px solid var(--stroke-dark-secondary); }
    .text-editor table th {
      color: var(--color-text-dark-secondary);
      font-weight: 400;
      text-align: left; }
  .text-editor .button,
  .text-editor .file-button {
    margin-top: 2.5rem; }
    @media screen and (min-width: 160.0625rem) {
      .text-editor .button,
      .text-editor .file-button {
        margin-top: 1.5625vw; } }
    @media screen and (max-width: 131.25rem) {
      .text-editor .button,
      .text-editor .file-button {
        margin-top: 2rem; } }
    @media screen and (max-width: 103.125rem) {
      .text-editor .button,
      .text-editor .file-button {
        margin-top: 1.5rem; } }
    @media screen and (max-width: 45rem) {
      .text-editor .button,
      .text-editor .file-button {
        margin-top: 1rem; } }
  .text-editor blockquote {
    padding: 0;
    margin: 2rem 0;
    font: var(--font-h4);
    letter-spacing: var(--letter-spacing-h4); }
    @media screen and (min-width: 160.0625rem) {
      .text-editor blockquote {
        margin: 1.25vw 0; } }
    @media screen and (max-width: 131.25rem) {
      .text-editor blockquote {
        margin: 1.625rem 0; } }
    @media screen and (max-width: 103.125rem) {
      .text-editor blockquote {
        margin: 1.5rem 0; } }
    @media screen and (max-width: 70.5rem) {
      .text-editor blockquote {
        margin: 1.25rem 0; } }
    .text-editor blockquote:first-child {
      margin-top: 0; }
    .text-editor blockquote:before {
      content: "";
      display: block;
      width: 2rem;
      height: 2rem;
      background-image: url("/assets/templates/img/common/blockquote.svg");
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      margin-bottom: 1rem; }
      @media screen and (min-width: 160.0625rem) {
        .text-editor blockquote:before {
          width: 1.25vw;
          height: 1.25vw;
          margin-bottom: 0.625vw; } }
      @media screen and (max-width: 131.25rem) {
        .text-editor blockquote:before {
          width: 1.5rem;
          height: 1.5rem; } }
      @media screen and (max-width: 103.125rem) {
        .text-editor blockquote:before {
          margin-bottom: 0.75rem; } }
      @media screen and (max-width: 45rem) {
        .text-editor blockquote:before {
          margin-bottom: 0.5rem; } }

@keyframes anim_img_404 {
  0%,
  100% {
    transform: rotate(0); }
  50% {
    transform: rotate(2deg); } }

[data-anim-page],
[data-anim-hidden] {
  opacity: 0; }

[data-anim-title] .line {
  overflow: hidden;
  line-height: 1.08; }
