:root {

	// ===================================================
	// Fonts
	--font-primary: "Mulish", sans-serif;

	--font-h1: 500 #{rem(150)}/0.88 var(--font-primary);
	--font-h2: 500 #{rem(85)}/1.1 var(--font-primary);
	--font-h3: 500 #{rem(57)}/1.12 var(--font-primary);
	--font-h4: 500 #{rem(35)}/1.14 var(--font-primary);
	--font-h5: 500 #{rem(27)}/1.18 var(--font-primary);

	--font-body-M: 400 #{rem(22)}/1.45 var(--font-primary);
	--font-body-M-semibold: 600 #{rem(22)}/1.45 var(--font-primary);
	--font-body-M-caption: 400 #{rem(18)}/1.33 var(--font-primary);

	--font-body-S: 400 #{rem(18)}/1.33 var(--font-primary);

	--font-link: 400 #{rem(22)}/1.45 var(--font-primary);

	--letter-spacing-h1: -4.5px;
	--letter-spacing-h2: -2.55px;
	--letter-spacing-h3: -1.71px;
	--letter-spacing-h4: -1.05px;
	--letter-spacing-h5: -0.81px;
	--text-spacing: -0.66px;

	@include mediaBigDesktop {
		--font-h1: 500 #{big(150)}/0.88 var(--font-primary);
		--font-h2: 500 #{big(85)}/1.1 var(--font-primary);
		--font-h3: 500 #{big(57)}/1.12 var(--font-primary);
		--font-h4: 500 #{big(35)}/1.14 var(--font-primary);
		--font-h5: 500 #{big(27)}/1.18 var(--font-primary);

		--font-body-M: 400 #{big(22)}/1.45 var(--font-primary);
		--font-body-M-semibold: 600 #{big(22)}/1.45 var(--font-primary);
		--font-body-M-caption: 400 #{big(18)}/1.33 var(--font-primary);

		--font-body-S: 400 #{big(18)}/1.33 var(--font-primary);

		--font-link: 400 #{big(22)}/1.45 var(--font-primary);
	}

	@include mediaDesktop {
		--font-h1: 500 #{rem(120)}/0.93 var(--font-primary);
		--font-h2: 500 #{rem(68)}/0.94 var(--font-primary);
		--font-h3: 500 #{rem(46)}/1.13 var(--font-primary);
		--font-h4: 500 #{rem(28)}/1.14 var(--font-primary);
		--font-h5: 500 #{rem(22)}/1.27 var(--font-primary);

		--font-body-M: 400 #{rem(18)}/1.44 var(--font-primary);
		--font-body-M-semibold: 600 #{rem(18)}/1.44 var(--font-primary);
		--font-body-M-caption: 400 #{rem(14)}/1.42 var(--font-primary);

		--font-body-S: 400 #{rem(18)}/1.33 var(--font-primary);

		--font-link: 400 #{rem(18)}/1.44 var(--font-primary);

		--letter-spacing-h1: -3.6px;
		--letter-spacing-h2: -2.04px;
		--letter-spacing-h3: -1.38px;
		--letter-spacing-h4: -0.84px;
		--letter-spacing-h5: -0.66px;
		--text-spacing: -0.54px;
	}

	@include mediaLaptop {
		--font-h1: 500 #{rem(80)}/1.0 var(--font-primary);
		--font-h2: 500 #{rem(48)}/0.91 var(--font-primary);
		--font-h3: 500 #{rem(34)}/1.11 var(--font-primary);
		--font-h4: 500 #{rem(20)}/1.2 var(--font-primary);
		--font-h5: 500 #{rem(18)}/1.33 var(--font-primary);

		--font-body-M: 400 #{rem(16)}/1.5 var(--font-primary);
		--font-body-M-semibold: 600 #{rem(16)}/1.5 var(--font-primary);
		--font-body-M-caption: 400 #{rem(12)}/1.33 var(--font-primary);

		--font-body-S: 400 #{rem(14)}/1.28 var(--font-primary);

		--font-link: 400 #{rem(16)}/1.5 var(--font-primary);

		--letter-spacing-h1: -2.4px;
		--letter-spacing-h2: -1.44px;
		--letter-spacing-h3: -1.02px;
		--letter-spacing-h4: -0.6px;
		--letter-spacing-h5: -0.54px;
		--text-spacing: -0.48px;
	}

	@include mediaTablet {
		--font-h1: 500 #{rem(52)}/1.0 var(--font-primary);
		--font-h2: 500 #{rem(40)}/1.0 var(--font-primary);
		--font-h3: 500 #{rem(28)}/1.14 var(--font-primary);
		--font-h4: 500 #{rem(18)}/1.33 var(--font-primary);
		--font-h5: 500 #{rem(16)}/1.25 var(--font-primary);

		--font-body-M: 400 #{rem(14)}/1.42 var(--font-primary);
		--font-body-M-semibold: 600 #{rem(14)}/1.42 var(--font-primary);
		--font-body-M-caption: 400 #{rem(12)}/1.33 var(--font-primary);

		--font-body-S: 400 #{rem(12)}/1.33 var(--font-primary);

		--font-link: 400 #{rem(14)}/1.42 var(--font-primary);

		--letter-spacing-h1: -1.56px;
		--letter-spacing-h2: -1.2px;
		--letter-spacing-h3: -0.84px;
		--letter-spacing-h4: -0.54px;
		--letter-spacing-h5: -0.48px;
		--text-spacing: -0.42px;
	}

	@include mediaMobile {
		--font-h1: 500 #{rem(30)}/1.06 var(--font-primary);
		--font-h2: 500 #{rem(24)}/1.08 var(--font-primary);
		--font-h3: 500 #{rem(20)}/1.2 var(--font-primary);
		--font-h4: 500 #{rem(18)}/1.33 var(--font-primary);
		--font-h5: 500 #{rem(16)}/1.25 var(--font-primary);

		--font-body-M: 400 #{rem(14)}/1.42 var(--font-primary);
		--font-body-M-semibold: 600 #{rem(14)}/1.42 var(--font-primary);
		--font-body-M-caption: 400 #{rem(12)}/1.33 var(--font-primary);

		--font-body-S: 400 #{rem(12)}/1.33 var(--font-primary);

		--font-link: 400 #{rem(14)}/1.42 var(--font-primary);

		--letter-spacing-h1: -0.9px;
		--letter-spacing-h2: -0.72px;
		--letter-spacing-h3: -0.6px;
		--letter-spacing-h4: -0.54px;
		--letter-spacing-h5: -0.48px;
	}

	// ===================================================
	// General Colors
	--color-primary: #ae2631;
	--color-secondary: #2A67DD;

	--color-white: #fff;
	--color-dark: #363636;

	--color-gray: #F2F2F2;
	--color-gray-2: #AFAFAF;
	--color-gray-3: #F9F9F9;
	--color-gray-7: #EFEFEF;

	// ===================================================
	//Text Colors
	--color-text-dark: #363636;
	--color-text-dark-secondary: rgba(54, 54, 54, 0.40);
	--color-text-dark-disable: rgba(54, 54, 54, 0.20);

	--color-text-light: #FFFFFF;
	--color-text-light-secondary: rgba(255, 255, 255, 0.60);
	--color-text-light-disable: rgba(255, 255, 255, 0.40);

	// ===================================================
	//Border Colors
	--stroke-dark: #363636;
	--stroke-dark-secondary: rgba(0, 0, 0, 0.08);
	--stroke-light: #FFFFFF;
	--stroke-light-secondary: rgba(255, 255, 255, 0.15);

	// ===================================================
	//System Colors
	--system-states-success: #40D157;
	--system-states-validation: #FFC700;
	--system-states-error: #D6252C;

	// ===================================================
	// Animate
	--anim-direction: 0.5s;
	--anim-cubic: cubic-bezier(.36, .3, 0, 1);

	// ===================================================
	// Section padding
	--section-padding: #{rem(100)} 0;
	--section-offset: #{rem(100)};

	@include mediaBigDesktop {
		--section-padding: #{big(100)} 0;
		--section-offset: #{big(100)};
	}

	@include mediaDesktop {
		--section-padding: #{rem(68)} 0;
		--section-offset: #{rem(68)};
	}

	@include mediaLaptop {
		--section-padding: #{rem(48)} 0;
		--section-offset: #{rem(48)};
	}

	@include mediaTablet {
		--section-padding: #{rem(40)} 0;
		--section-offset: #{rem(40)};
	}

	@include mediaMobile {
		--section-padding: #{rem(40)} 0;
		--section-offset: #{rem(28)};
	}

	// ===================================================
	// Header
	--height-menu: #{rem(100)};

	@include mediaBigDesktop {
		--height-menu: #{big(100)};
	}

	@include mediaDesktop {
		--height-menu: #{rem(96)};
	}

	@include mediaLaptop {
		--height-menu: #{rem(80)};
	}

	@include mediaTablet {
		--height-menu: #{rem(64)};
	}

	@include mediaMobile {
		--height-menu: #{rem(44)};
	}
}
