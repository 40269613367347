.sec-news-slider {
	&__list {
		@include mediaTablet {
			margin-right: rem(-40);
		}

		@include mediaMobile {
			margin-right: rem(-16);
		}
	}
}