@font-face {

  font-family: 'Mulish';
  src: url("../fonts/Mulish/Mulish-Regular/Mulish-Regular.woff2") format("woff2");
  src: url("../fonts/Mulish/Mulish-Regular/Mulish-Regular.woff") format("woff");
  src: url("../fonts/Mulish/Mulish-Regular/Mulish-Regular.ttf") format("ttf");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Mulish';
  src: url("../fonts/Mulish/Mulish-Medium/Mulish-Medium.woff2") format("woff2");
  src: url("../fonts/Mulish/Mulish-Medium/Mulish-Medium.woff") format("woff");
  src: url("../fonts/Mulish/Mulish-Medium/Mulish-Medium.ttf") format("ttf");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Mulish';
  src: url("../fonts/Mulish/Mulish-SemiBold/Mulish-SemiBold.woff2") format("woff2");
  src: url("../fonts/Mulish/Mulish-SemiBold/Mulish-SemiBold.woff") format("woff");
  src: url("../fonts/Mulish/Mulish-SemiBold/Mulish-SemiBold.ttf") format("ttf");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
