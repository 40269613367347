.sec-dop-services {
	&__slider {
		padding-bottom: 1px;

		@include mediaTablet {
			margin-right: rem(-40);
		}

		@include mediaMobile {
			margin-right: rem(-16);
		}
	}
}