.page-banner {
	padding-top: rem(110);
	padding-bottom: rem(100);
	position: relative;
	z-index: 2;
	color: var(--color-white);
	background-image: url("/assets/templates/img/banners/projects-bg.jpg");
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
	overflow: hidden;

	@include mediaBigDesktop {
		padding-top: big(110);
		padding-bottom: big(100);
	}

	@include mediaDesktop {
		padding-top: rem(95);
		padding-bottom: rem(68);
	}

	@include mediaLaptop {
		padding-top: rem(80);
		padding-bottom: rem(48);
	}

	@include mediaTablet {
		padding-top: rem(64);
		padding-bottom: rem(40);
	}

	@include mediaMobile {
		padding-top: rem(44);
	}

	&:before {
		content: "";
		display: block;
		width: 100%;
		height: 100%;
		position: absolute;
		left: 0;
		top: 0;
		z-index: 1;
		background-color: rgba(0, 0, 0, 0.5);
	}

	.container {
		position: relative;
		z-index: 2;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		min-height: rem(630);

		@include mediaBigDesktop {
			min-height: big(630);
		}

		@include mediaDesktop {
			min-height: rem(494);
		}

		@include mediaLaptop {
			min-height: rem(372);
		}

		@include mediaTablet {
			min-height: rem(396);
		}

		@include mediaMobile {
			min-height: rem(456);
		}
	}

	&__breadcrumbs {
		padding: rem(32) 0;

		@include mediaBigDesktop {
			padding: big(32) 0;
		}

		@include mediaLaptop {
			padding: rem(24) 0;
		}

		@include mediaMobile {
			padding: rem(14) 0;
		}
	}

	&__row {
		flex: 1;
		width: 100%;
		display: flex;
		justify-content: space-between;
		gap: rem(100);

		@include mediaBigDesktop {
			gap: big(100);
		}

		@include mediaDesktop {
			gap: rem(68);
		}

		@include mediaLaptop {
			gap: rem(48);
		}

		@include mediaTablet {
			gap: 0;
			flex-direction: column;
		}

		.page-banner {
			&__utp {
				@include media(#{rem(1300)}) {
					flex-direction: column;

					.block-banner-utp {
						&:not(:first-child) {
							margin-left: 0;
						}
					}
				}

				@include mediaTablet {
					flex-direction: row;
					margin-top: rem(60);

					.block-banner-utp {
						&:not(:first-child) {
							margin-left: -1px;
						}
					}
				}

				@include mediaMobile {
					flex-direction: column;
					margin-top: rem(20);

					.block-banner-utp {
						flex-direction: row;

						&:not(:first-child) {
							margin-left: 0
						}
					}
				}
			}
		}
	}

	&__col {
		display: flex;
		flex-direction: column;
	}

	&__title {
		margin-top: rem(68);

		@include mediaBigDesktop {
			margin-top: big(68);
		}

		@include mediaDesktop {
			margin-top: rem(48);
		}

		@include mediaLaptop {
			margin-top: rem(24);
		}

		@include mediaTablet {
			margin-top: rem(16);
		}
	}

	&__desc {
		margin: rem(40) 0;
		max-width: rem(885);
		font: var(--font-body-M);

		@include mediaBigDesktop {
			margin: big(40) 0;
			max-width: big(885);
		}

		@include mediaDesktop {
			margin: rem(32) 0;
			max-width: rem(662);
		}

		@include mediaLaptop {
			margin: rem(24) 0;
			max-width: rem(612);
		}

		@include mediaTablet {
			max-width: rem(332);
		}

		@include mediaMobile {
			margin: rem(16) 0;
			max-width: 100%;
		}

		p {
			margin-bottom: 0;

			&:first-child {
				margin-top: 0;
			}
		}
	}

	&__nav {
		margin-top: auto;
		max-width: rem(1470);

		@include mediaBigDesktop {
			max-width: big(1470);
		}

		@include mediaDesktop {
			max-width: rem(1245);
		}

		@include mediaLaptop {
			max-width: rem(940);
		}

		@include mediaTablet {
			max-width: 100%;
			overflow: auto;
		}

		&-wrap-scroll {
			display: flex;
			flex-wrap: wrap;
			gap: rem(12);

			@include mediaBigDesktop {
				gap: big(12);
			}

			@include mediaLaptop {
				gap: rem(8);
			}

			@include mediaMobile {
				flex-wrap: nowrap;
				width: max-content;
			}

			label {
				position: relative;

				overflow: hidden;
				border-radius: rem(60);

				@include mediaBigDesktop {
					border-radius: big(60);
				}
			}
		}
	}

	&__utp {
		display: flex;
		margin-top: auto;
		max-width: rem(1477);

		@include mediaBigDesktop {
			max-width: big(1477);
		}

		@include mediaDesktop {
			max-width: rem(1100);
		}

		@include mediaLaptop {
			max-width: 100%;
			width: 100%;
		}

		@include mediaMobile {
			flex-direction: column;
			padding-top: rem(12);
		}

		.block-banner-utp {
			flex: 1;

			&:not(:first-child) {
				margin-left: -1px;

				@include mediaMobile {
					margin-left: 0;
					margin-top: -1px;
				}
			}
		}
	}

	&__form {
		position: relative;
		width: rem(688);
		margin-top: rem(60);

		@include mediaBigDesktop {
			width: big(688);
			margin-top: big(60);
		}

		@include mediaDesktop {
			width: rem(516);
			margin-top: auto;
		}

		@include mediaLaptop {
			width: rem(392);
		}

		@include mediaTablet {
			width: 100%;
			margin-top: rem(50);
			border: 1px solid var(--stroke-dark-secondary);
		}

		@include mediaMobile {
			margin-top: rem(28);
		}
	}

	&_full {
		@include mediaTablet {
			background-size: auto 100vh;
			background-position: top;

			&:before {
				min-height: 100vh;
			}
		}

		.container {
			//min-height: rem(800);
			min-height: calc(100vh - #{rem(210)});

			@include mediaBigDesktop {
				//min-height: big(800);
				min-height: calc(100vh - #{big(210)});
			}

			@include mediaDesktop {
				//min-height: rem(700);
				min-height: calc(100vh - #{rem(163)});
			}

			@include mediaLaptop {
				//min-height: rem(600);
				min-height: calc(100vh - #{rem(128)});
			}

			@include mediaTablet {
				//height: calc(100vh - #{rem(104)});
				height: auto;
				min-height: auto;
			}

			@include mediaMobile {
				//height: calc(100vh - #{rem(84)});
			}
		}
	}
}

@supports(height: 100dvh) {
	.page-banner {
		&_full {
			@include mediaTablet {
				&:before {
					min-height: 100dvh;
				}
			}

			.container {
				min-height: calc(100dvh - #{rem(210)});

				@include mediaBigDesktop {
					min-height: calc(100dvh - #{big(210)});
				}

				@include mediaDesktop {
					min-height: calc(100dvh - #{rem(163)});
				}

				@include mediaLaptop {
					min-height: calc(100dvh - #{rem(128)});
				}

				@include mediaTablet {
					height: auto;
					min-height: auto;
				}
			}
		}
	}
}

.block-banner-utp {
	display: flex;
	align-items: center;
	padding: rem(40);
	color: var(--color-white);
	border: 1px solid var(--stroke-light-secondary);
	gap: rem(24);
	width: 100%;

	@include mediaBigDesktop {
		padding: big(40);
		gap: big(24);
	}

	@include mediaDesktop {
		padding: rem(32);
		gap: rem(20);
	}

	@include mediaLaptop {
		padding: rem(24);
		gap: rem(16);
	}

	@include mediaTablet {
		gap: rem(12);
		flex-direction: column;
		align-items: flex-start;
	}

	@include mediaMobile {
		padding: rem(20);
		gap: rem(12);
	}

	&__icon {
		min-width: rem(48);
		width: rem(48);
		height: rem(48);
		display: block;
		fill: currentColor;

		@include mediaBigDesktop {
			min-width: big(48);
			width: big(48);
			height: big(48);
		}

		@include mediaTablet {
			min-width: rem(32);
			width: rem(32);
			height: rem(32);
		}

		img {
			width: 100%;
			height: 100%;
			object-fit: contain;
		}
	}

	&__text {
		font: var(--font-body-M-semibold);
	}
}