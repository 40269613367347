.cta-form {
	width: 100%;

	&__title {
		display: block;
		font: var(--font-h4);
		letter-spacing: var(--letter-spacing-h4);
		color: var(--color-text-dark);
		margin-bottom: rem(40);

		@include mediaBigDesktop {
			margin-bottom: big(40);
		}

		@include mediaDesktop {
			margin-bottom: rem(30);
		}

		@include mediaLaptop {
			margin-bottom: rem(24);
		}

		span {
			color: var(--color-secondary);
		}
	}

	&__content {
		padding: rem(100);
		color: var(--color-text-dark);
		background-color: var(--color-gray-3);

		@include mediaBigDesktop {
			padding: big(100);
		}

		@include mediaDesktop {
			padding: rem(68);
		}

		@include mediaLaptop {
			padding: rem(48);
		}

		form {
			.label-group {
				margin-bottom: rem(24);

				@include mediaBigDesktop {
					margin-bottom: big(24);
				}

				@include mediaDesktop {
					margin-bottom: rem(20);
				}
			}

			.checkbox {
				margin-top: rem(40);

				@include mediaBigDesktop {
					margin-top: big(40);
				}

				@include mediaDesktop {
					margin-top: rem(32);
				}

				@include mediaLaptop {
					margin-top: rem(24);
				}
			}

			.button {
				width: 100%;
				margin-top: rem(40);

				@include mediaBigDesktop {
					margin-top: big(40);
				}

				@include mediaDesktop {
					margin-top: rem(32);
				}

				@include mediaLaptop {
					margin-top: rem(24);
				}
			}
		}
	}

	&__footer {
		padding: rem(100);
		background-color: var(--color-gray-7);
		font: var(--font-body-M);

		@include mediaBigDesktop {
			padding: big(100);
		}

		@include mediaDesktop {
			padding: rem(68);
		}

		@include mediaLaptop {
			padding: rem(48);
		}

		.icon-text-button {
			gap: rem(20);
			color: var(--color-secondary);
			margin-bottom: rem(24);

			@include mediaBigDesktop {
				gap: big(20);
				margin-bottom: big(24);
			}

			@include mediaDesktop {
				margin-bottom: rem(20);
			}

			@include mediaLaptop {
				margin-bottom: rem(16);
			}
		}

		p {
			margin: 0;
		}
	}
}

.cta-call {
	padding: rem(40);
	gap: rem(40);

	display: flex;

	background-color: var(--color-gray-3);
	color: var(--color-text-dark);

	@include mediaBigDesktop {
		padding: big(40);
		gap: big(40);
	}

	@include mediaDesktop {
		padding: rem(32);
		gap: rem(32);
	}

	@include mediaLaptop {
		padding: rem(24);
	}

	@include mediaMobile {
		padding: rem(20);
		gap: rem(20);

		flex-direction: column;
	}

	&__content {
		font: var(--font-body-M);
		flex: 1;

		.icon-text-button {
			gap: rem(20);
			color: var(--color-secondary);

			@include mediaBigDesktop {
				gap: big(20);
			}
		}

		p {
			margin: 0;
			margin-top: rem(20);

			@include mediaBigDesktop {
				margin-top: big(20);
			}

			@include mediaLaptop {
				margin-top: rem(16);
			}
		}
	}

	&__btns {
		align-self: flex-end;
		flex-basis: rem(267);

		@include mediaBigDesktop {
			flex-basis: big(267);
		}

		@include mediaDesktop {
			flex-basis: rem(227);
		}

		@include mediaLaptop {
			flex-basis: rem(191);
		}

		@include mediaTablet {
			flex-basis: rem(168);
		}

		@include mediaMobile {
			flex-basis: 100%;
			width: 100%;
		}

		.button {
			width: 100%;
		}
	}
}

.cta-form-light {
	display: block;
	padding: rem(100);
	background-color: var(--color-white);
	color: var(--color-text-dark);

	@include mediaBigDesktop {
		padding: big(100);
	}

	@include mediaDesktop {
		padding: rem(68);
	}

	@include mediaLaptop {
		padding: rem(48);
	}

	@include mediaTablet {
		padding: rem(40);
	}

	@include mediaMobile {
		padding: rem(20);
	}

	&__title {
		color: var(--color-text-dark);
		font: var(--font-h3);
		letter-spacing: var(--letter-spacing-h3);

		span {
			color: var(--color-secondary);
		}
	}

	&__text {
		font: var(--font-body-M);
		margin-top: rem(24);

		@include mediaBigDesktop {
			margin-top: big(24);
		}

		@include mediaDesktop {
			margin-top: rem(20);
		}

		@include mediaTablet {
			margin-top: rem(16);
		}

		@include mediaTablet {
			margin-top: rem(12);
		}
	}

	&__form {
		margin-top: rem(48);

		@include mediaBigDesktop {
			margin-top: big(48);
		}

		@include mediaDesktop {
			margin-top: rem(32);
		}

		@include mediaMobile {
			margin-top: rem(20);
		}

		form {
			display: flex;
			flex-direction: column;
			gap: rem(48);

			@include mediaBigDesktop {
				gap: big(48);
			}

			@include mediaDesktop {
				gap: rem(40);
			}

			@include mediaLaptop {
				gap: rem(32);
			}

			@include mediaTablet {
				gap: rem(24);
			}

			@include mediaMobile {
				gap: rem(20);
			}
		}

		.label-form-variable {
			display: flex;
			align-items: center;
			width: 100%;

			.label-variable-btn {
				width: 100%;
			}
		}

		.label-row {
			display: flex;
			align-items: flex-start;
			gap: rem(32);

			@include mediaBigDesktop {
				gap: big(32);
			}

			@include mediaTablet {
				gap: rem(24);
			}

			@include mediaMobile {
				flex-direction: column;
				gap: rem(16);
			}
		}

		.label-group {
			display: block;
		}

		.form-footer {
			display: flex;
			align-items: center;
			justify-content: space-between;
			gap: rem(32);

			@include mediaBigDesktop {
				gap: big(32);
			}

			@include mediaDesktop {
				.checkbox {
					width: 50%;
				}
			}

			@include mediaTablet {
				gap: rem(24);

				.button,
				.checkbox {
					width: 100%;
				}

				.button {
					width: calc(100% - #{rem(24)});
				}
			}

			@include mediaMobile {
				flex-direction: column;
				align-items: flex-start;

				.button {
					width: 100%;
					margin-top: rem(4);
				}
			}
		}
	}
}

.cta-form-calc {
	padding: rem(60);
	background-color: var(--color-white);
	color: var(--color-text-dark);

	@include mediaBigDesktop {
		padding: big(60);
	}

	@include mediaDesktop {
		padding: rem(48);
	}

	@include mediaLaptop {
		padding: rem(36);
	}

	@include mediaTablet {
		padding: rem(40);
	}

	@include mediaMobile {
		padding: rem(20);
	}

	&__title {
		display: block;
		font: var(--font-h4);
		letter-spacing: var(--letter-spacing-h4);
		margin-bottom: rem(40);

		@include mediaBigDesktop {
			margin-bottom: big(40);
		}

		@include mediaDesktop {
			margin-bottom: rem(32);
		}

		@include mediaLaptop {
			margin-bottom: rem(24);
		}

		@include mediaTablet {
			font: var(--font-h3);
			letter-spacing: var(--letter-spacing-h3);
		}

		@include mediaMobile {
			margin-bottom: rem(20);
		}

		span {
			color: var(--color-secondary);
		}
	}

	&__form {
		display: flex;
		flex-direction: column;
		gap: rem(20);

		@include mediaBigDesktop {
			gap: big(20);
		}

		@include mediaLaptop {
			gap: rem(16);
		}

		@include mediaTablet {
			gap: rem(24);
		}

		@include mediaMobile {
			gap: rem(16);
		}

		.double-group {
			margin-bottom: rem(4);

			@include mediaBigDesktop {
				margin-bottom: big(4);
			}

			@include mediaDesktop {
				margin-bottom: 0;
			}
		}

		.label-row {
			display: flex;
			align-items: flex-start;
			gap: rem(24);
			margin-bottom: rem(4);

			@include mediaBigDesktop {
				gap: big(24);
				margin-bottom: big(4);
			}

			@include mediaDesktop {
				gap: rem(20);
				margin-bottom: 0;
			}

			@include mediaLaptop {
				gap: rem(16);
			}

			@include mediaTablet {
				gap: rem(24);
			}

			@include mediaMobile {
				flex-direction: column;
				gap: rem(16);
			}

			.select-input, .label-group {
				max-width: 50%;

				@include mediaMobile {
					max-width: 100%;
				}
			}
		}

		.label-row-tablet {
			display: flex;
			flex-direction: column;
			gap: rem(20);

			@include mediaBigDesktop {
				gap: big(20);
			}

			@include mediaLaptop {
				gap: rem(16);
			}

			@include mediaTablet {
				flex-direction: row;
				align-items: center;
				gap: rem(24);
			}

			@include mediaMobile {
				flex-direction: column;
				gap: rem(16);
			}
		}

		&-footer {
			display: flex;
			flex-direction: column;
			gap: rem(20);

			@include mediaBigDesktop {
				gap: big(20);
			}

			@include mediaLaptop {
				gap: rem(16);
			}

			@include mediaTablet {
				flex-direction: row;
				align-items: center;
				justify-content: space-between;
				gap: rem(24);
			}

			@include mediaMobile {
				flex-direction: column;
				align-items: flex-start;
				gap: rem(20);
				margin-top: rem(4);
			}
		}

		.checkbox {
			margin-top: rem(20);

			@include mediaBigDesktop {
				margin-top: big(20);
			}

			@include mediaDesktop {
				margin-top: rem(4);
			}

			@include mediaLaptop {
				margin-top: rem(8);
			}

			@include mediaTablet {
				margin-top: 0;
			}

			.checkbox__text {
				font: var(--font-body-S);
				font-size: rem(16);

				@include mediaBigDesktop {
					font-size: big(16);
				}

				@include mediaLaptop {
					font-size: rem(14);
				}

				@include mediaTablet {
					font-size: rem(16);
				}

				@include mediaMobile {
					font-size: rem(14);
				}
			}
		}

		.button {
			margin-top: rem(12);
			width: 100%;

			@include mediaBigDesktop {
				margin-top: big(12);
			}

			@include mediaDesktop {
				margin-top: rem(4);
			}

			@include mediaLaptop {
				margin-top: rem(8);
			}

			@include mediaTablet {
				margin-top: 0;
				width: auto;
			}

			@include mediaMobile {
				width: 100%;
			}
		}
	}
}

.cta-form-calc-2 {
	padding: var(--section-offset);
	background-color: var(--color-white);
	color: var(--color-text-dark);

	@include mediaMobile {
		padding: rem(20);
	}

	&__title {
		display: block;
		font: var(--font-h3);
		letter-spacing: var(--letter-spacing-h3);
		margin-bottom: rem(40);

		@include mediaBigDesktop {
			margin-bottom: big(40);
		}

		@include mediaLaptop {
			margin-bottom: rem(32);
		}

		@include mediaTablet {
			margin-bottom: rem(24);
		}

		@include mediaMobile {
			margin-bottom: rem(20);
		}

		span {
			color: var(--color-secondary);
		}
	}

	&__form {
		display: flex;
		flex-direction: column;
		gap: rem(32);


		@include mediaBigDesktop {
			gap: big(32);
		}

		@include mediaLaptop {
			gap: rem(24);
		}

		@include mediaMobile {
			gap: rem(16);
		}

		.label-row {
			display: flex;
			align-items: flex-start;
			gap: rem(32);

			@include mediaBigDesktop {
				gap: big(32);
			}

			@include mediaLaptop {
				gap: rem(24);
				margin-bottom: 0;
			}

			@include mediaTablet {
				gap: rem(24);
			}

			@include mediaMobile {
				flex-direction: column;
				gap: rem(16);
			}
		}

		.label-row-tablet {
			display: flex;
			flex-direction: column;
			gap: rem(32);

			@include mediaBigDesktop {
				gap: big(32);
			}

			@include mediaLaptop {
				gap: rem(24);
			}

			@include mediaTablet {
				flex-direction: row;
				align-items: center;
			}

			@include mediaMobile {
				flex-direction: column;
				gap: rem(16);
			}
		}

		&-footer {
			display: flex;
			align-items: center;
			justify-content: space-between;
			gap: rem(32);
			margin-top: rem(16);

			@include mediaBigDesktop {
				gap: big(32);
				margin-top: big(16);
			}

			@include mediaDesktop {
				margin-top: rem(8);
			}

			@include mediaLaptop {
				gap: rem(16);
			}

			@include mediaTablet {
				flex-direction: row;
				align-items: center;
				justify-content: space-between;
				gap: rem(24);
			}

			@include mediaMobile {
				flex-direction: column;
				align-items: flex-start;
				gap: rem(20);
				margin-top: rem(4);
			}
		}

		.button {
			@include mediaMobile {
				width: 100%;
			}
		}
	}
}

.label-variable-btn {
	display: inline-flex;

	&__input {
		&:checked {
			+ .label-variable-btn {
				&__content {
					background-color: var(--color-dark);
					border-color: var(--stroke-dark);
					color: var(--color-white);
				}
			}
		}
	}

	&__content {
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: transparent;
		border: 1px solid var(--stroke-dark-secondary);
		color: var(--color-text-dark);
		font: var(--font-body-M-caption);
		text-transform: uppercase;
		height: rem(59);
		width: 100%;
		padding: 0 rem(20);
		gap: rem(12);
		cursor: pointer;

		transition: background-color var(--anim-direction), color var(--anim-direction), border-color var(--anim-direction);

		@include mediaBigDesktop {
			height: big(59);
			padding: 0 big(20);
			gap: big(12);
		}

		@include mediaDesktop {
			height: rem(53);
		}

		@include mediaLaptop {
			height: rem(43);
		}

		@include mediaTablet {
			height: rem(47);
		}

		@include mediaMobile {
			height: rem(43);
			gap: rem(8);
		}
	}

	&__icon {
		width: rem(24);
		height: rem(24);
		fill: currentColor;

		transition: fill var(--anim-direction);

		@include mediaBigDesktop {
			width: big(24);
			height: big(24);
		}

		@include mediaMobile {
			width: rem(20);
			height: rem(20);
		}
	}
}

.form-tabs {
	display: block;

	&__tab {
		margin-bottom: rem(48);

		@include mediaBigDesktop {
			margin-bottom: big(48);
		}

		@include mediaDesktop {
			margin-bottom: rem(40);
		}

		@include mediaLaptop {
			margin-bottom: rem(32);
		}

		@include mediaTablet {
			margin-bottom: rem(24);
		}

		@include mediaMobile {
			margin-bottom: rem(20);
		}
	}

	&__content {
		display: none;

		&.js_active {
			display: block;
		}
	}
}