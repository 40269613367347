.sec-about {
	background-color: var(--color-gray-3);

	.sec {
		&__title {
			margin-bottom: rem(60);

			@include mediaBigDesktop {
				margin-bottom: big(60);
			}

			@include mediaDesktop {
				margin-bottom: rem(52);
			}

			@include mediaLaptop {
				margin-bottom: rem(36);
			}

			@include mediaTablet {
				margin-bottom: rem(24);
			}

			@include mediaMobile {
				margin-bottom: rem(20);
			}
		}
	}

	&__row {
		display: flex;
		gap: var(--section-offset);
		border-bottom: 1px solid var(--color-dark);
		padding-bottom: var(--section-offset);

		@include mediaTablet {
			flex-direction: column;
			gap: rem(24);
		}

		@include mediaMobile {
			gap: rem(20);
		}
	}

	&__text {
		flex: 1;
		padding-right: var(--section-offset);

		p {
			&:last-child {
				margin-bottom: 0;
			}
		}
	}

	&__row-counts {
		margin: 0 rem(-50);
		display: flex;
		align-items: flex-start;
		flex-wrap: wrap;

		@include mediaBigDesktop {
			margin: 0 big(-50);
		}

		@include mediaDesktop {
			margin: 0 rem(-24);
		}

		@include mediaMobile {
			flex-direction: column;
			margin: 0;
		}

		.block-count {
			width: calc(33.333% - #{rem(100)});
			margin: 0 rem(50);
			margin-top: var(--section-offset);

			@include mediaBigDesktop {
				width: calc(33.333% - #{big(100)});
				margin: 0 big(50);
				margin-top: var(--section-offset);
			}

			@include mediaDesktop {
				width: calc(33.333% - #{rem(48)});
				margin: 0 rem(24);
				margin-top: var(--section-offset);
			}

			@include mediaMobile {
				width: 100%;
				margin: 0;
				margin-top: var(--section-offset);
			}
		}
	}
}

.block-count {
	&__image {
		font-size: 0;

		picture {
			display: block;
		}

		img {
			object-fit: contain;
			width: auto;
			height: rem(137);

			@include mediaBigDesktop {
				height: big(137);
			}

			@include mediaDesktop {
				height: rem(110);
			}

			@include mediaLaptop {
				height: rem(60);
			}

			@include mediaTablet {
				height: rem(40);
			}
		}
	}

	&__text {
		margin-top: rem(24);
		font: var(--font-body-M);

		@include mediaBigDesktop {
			margin-top: big(24);
			padding-top: big(24);
		}

		@include mediaDesktop {
			margin-top: rem(20);
			padding-top: rem(20);
		}

		@include mediaLaptop {
			margin-top: rem(16);
			padding-top: rem(16);
		}

		@include mediaTablet {
			margin-top: rem(12);
			padding-top: rem(12);
		}
	}
}
