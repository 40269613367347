.block-accordion {
	position: relative;

	&__header {
		padding: rem(40) 0;
		display: flex;
		align-items: center;
		justify-content: space-between;

		gap: rem(100);
		cursor: pointer;
		border-bottom: 1px solid var(--stroke-dark-secondary);

		transition: border-color var(--anim-direction);

		@include mediaBigDesktop {
			padding: big(40) 0;
			gap: big(100);
		}

		@include mediaDesktop {
			padding: rem(32) 0;
			gap: rem(68);
		}

		@include mediaLaptop {
			padding: rem(24) 0;
			gap: rem(48);
		}

		@include mediaMobile {
			padding: rem(16) 0;
			gap: rem(16);
		}
	}

	&__title {
		font: var(--font-h4);
		letter-spacing: var(--letter-spacing-h4);
	}

	&__content {
		height: 0;
		overflow: hidden;
		opacity: 0;
		visibility: hidden;

		transition: opacity var(--anim-direction), visibility var(--anim-direction);

		p {
			&:first-child {
				margin-top: 0;
			}

			&:last-child {
				margin-bottom: 0;
			}
		}
	}

	&.toggle_active {
		.block-accordion {
			&__header {
				border-color: var(--color-secondary);
			}

			&__content {
				padding: rem(40) 0;
				height: auto;
				opacity: 1;
				visibility: visible;

				@include mediaBigDesktop {
					padding: big(40) 0;
				}

				@include mediaDesktop {
					padding: rem(32) 0;
				}

				@include mediaLaptop {
					padding: rem(24) 0;
				}

				@include mediaMobile {
					padding: rem(16) 0;
				}
			}

			&__btn {
				.block-accordion-btn {
					background-color: var(--color-dark);

					&:before,
					&:after {
						background-color: var(--color-white);
					}

					&:after {
						transform: translate(-50%, -50%) rotate(0);
					}
				}
			}
		}
	}
}

.block-accordion-btn {
	position: relative;

	&:before,
	&:after {
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		z-index: 2;
		content: "";
		display: block;
		width: rem(14);
		height: rem(2);
		background-color: var(--color-dark);

		transition: background-color var(--anim-direction), transform var(--anim-direction);

		@include mediaBigDesktop {
			width: big(14);
			height: big(2);
		}

		@include mediaLaptop {
			width: rem(12);
		}
	}

	&:after {
		transform: translate(-50%, -50%) rotate(90deg);
	}

	&:hover {
		&:before,
		&:after {
			background-color: var(--color-white);
		}
	}
}