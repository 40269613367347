.contact-block {
	&__label {
		display: block;
		font: var(--font-body-M-caption);
		color: var(--color-text-dark-secondary);
		text-transform: uppercase;

		margin-bottom: rem(8);
		
		@include mediaBigDesktop {
			margin-bottom: big(8);
		}

		@include mediaDesktop {
			margin-bottom: rem(4);
		}
	}

	&__content {
		font: var(--font-body-M);

		a:not(.text-button) {
			color: var(--color-text-dark);
			text-decoration: none;
		}

		p {
			margin: 0;
		}

		.text-button {
			margin-top: rem(20);

			@include mediaBigDesktop {
				margin-top: big(20);
			}

			@include mediaDesktop {
				margin-top: rem(16);
			}

			@include mediaLaptop {
				margin-top: rem(12);
			}
		}

		.icon-button {
			margin-top: rem(12);

			@include mediaBigDesktop {
				margin-top: big(12);
			}
		}
	}
}
