.footer {
	--footer-gap: #{rem(40)};

	position: relative;
	display: block;
	width: 100%;
	background: var(--color-gray-3);
	padding-top: rem(100);

	@include mediaBigDesktop {
		--footer-gap: #{big(40)};
		padding-top: big(100);
	}

	@include mediaDesktop {
		--footer-gap: #{rem(32)};
		padding-top: rem(68);
	}

	@include mediaLaptop {
		--footer-gap: #{rem(24)};
		padding-top: rem(48);
	}

	@include mediaTablet {
		padding-top: rem(40);
	}

	@include mediaMobile {
		--footer-gap: #{rem(16)};
		padding-top: rem(40);
		padding-bottom: rem(16);
	}

	&__main {
		display: flex;
		flex-wrap: wrap;
		gap: rem(100);

		@include mediaBigDesktop {
			gap: big(100);
		}

		@include mediaDesktop {
			gap: rem(68);
		}

		@include mediaLaptop {
			gap: rem(48);
		}

		@include mediaTablet {
			gap: rem(24);
		}

		@include mediaMobile {
			flex-direction: column;
			gap: 0;
		}
	}

	&__col {
		&_info {
			flex-basis: rem(294);

			@include mediaBigDesktop {
				flex-basis: big(294);
			}

			@include mediaDesktop {
				flex-basis: rem(224);
			}

			@include mediaLaptop {
				flex-basis: rem(172);
			}

			@include mediaTablet {
				flex-basis: rem(154);
			}

			@include mediaMobile {
				flex-basis: 100%;
				margin-bottom: rem(20);
			}
		}

		&_nav {
			flex-basis: rem(688);

			@include mediaBigDesktop {
				flex-basis: big(688);
			}

			@include mediaDesktop {
				flex-basis: rem(516);
			}

			@include mediaLaptop {
				flex-basis: rem(392);
			}

			@include mediaTablet {
				flex-basis: rem(212);
			}

			@include mediaMobile {
				flex-basis: 100%;
			}
		}

		&_contacts {
			flex: 1;

			.footer-toggle {
				&__title {
					//@include mediaMobile {
					//	display: none;
					//}

					.select-input {
						width: auto;

						@include mediaMobile {
							width: 100%;
						}
					}

					.select-current {
						padding: 0;
						background: 0;
						margin: 0;
						border: 0;
						height: auto;
						font: var(--font-body-M-semibold);
						gap: rem(8);
					}
				}
			}

			.contact-block {
				max-width: rem(790);

				@include mediaBigDesktop {
					max-width: big(790);
				}

				@include mediaDesktop {
					max-width: rem(517);
				}

				@include mediaLaptop {
					max-width: rem(395);
				}

				@include mediaTablet {
					max-width: 100%;
				}

				&:not(:last-child) {
					margin-bottom: rem(32);

					@include mediaBigDesktop {
						margin-bottom: big(32);
					}

					@include mediaDesktop {
						margin-bottom: rem(24);
					}

					@include mediaTablet {
						margin-bottom: rem(16);
					}
				}
			}
		}
	}

	&__logo {
		font-size: 0;
		padding-bottom: var(--footer-gap);
		margin-bottom: var(--footer-gap);
		border-bottom: 1px solid var(--stroke-dark-secondary);

		@include mediaMobile {
			padding-bottom: 0;
			border-bottom: none;
		}

		&-link {
			display: inline-block;
			font-size: 0;
			text-decoration: none;
		}

		&-img {
			width: auto;
			height: rem(41);

			@include mediaBigDesktop {
				height: big(41);
			}

			@include mediaBigDesktop {
				height: big(41);
			}

			@include mediaDesktop {
				height: rem(33);
			}
		}
	}

	&__partners {
		margin-bottom: var(--footer-gap);
	}

	&__social {
		display: flex;
		align-items: center;
		gap: rem(12);
		padding: 0;
		margin: 0;
		list-style-type: none;

		@include mediaBigDesktop {
			gap: big(12);
		}

		@include mediaLaptop {
			gap: rem(8);
		}
	}

	&__contacts-accordion {
		.footer-toggle {
			&__title {
				display: none;

				@include mediaMobile {
					display: flex;
				}
			}

			&:not(.active) {
				display: none;

				@include mediaMobile {
					display: block;
				}
			}
		}
	}

	&__bottom {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding-top: rem(100);
		padding-bottom: rem(40);

		font: 400 #{rem(16)}/1.25 var(--font-primary);
		letter-spacing: -0.48px;
		text-transform: uppercase;

		@include mediaBigDesktop {
			padding-top: big(100);
			padding-bottom: big(40);
			font: 400 #{big(16)}/1.25 var(--font-primary);
		}

		@include mediaDesktop {
			padding-top: rem(68);
			padding-bottom: rem(32);
			font: 400 #{rem(14)}/1.42 var(--font-primary);
			letter-spacing: -0.42px;
		}

		@include mediaLaptop {
			padding-top: rem(50);
			padding-bottom: rem(26);
			font: 400 #{rem(12)}/1.33 var(--font-primary);
			letter-spacing: -0.36px;
		}

		@include mediaTablet {
			flex-direction: column;
			align-items: flex-start;
			padding-top: rem(40);
			padding-bottom: rem(42);
		}

		@include mediaMobile {
			padding-top: rem(20);
			padding-bottom: rem(16);

			border-top: 1px solid var(--stroke-dark-secondary);
		}

		&-text {
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			gap: rem(32);

			@include mediaBigDesktop {
				gap: big(32);
			}

			@include mediaLaptop {
				gap: rem(24);
			}

			@include mediaTablet {
				margin-bottom: rem(24);
			}

			@include mediaMobile {
				flex-direction: column;
				align-items: flex-start;
				gap: rem(12);
				margin-bottom: rem(12);
			}

			p {
				margin: 0;
				line-height: rem(20);
			}

			a {
				color: var(--color-dark);
				text-decoration: underline;
				text-underline-offset: 2px;
			}
		}
	}

	&__btn-top {
		position: absolute;
		right: rem(100);
		bottom: rem(160);
		z-index: 2;

		@include mediaBigDesktop {
			right: big(100);
			bottom: big(160);
		}

		@include mediaDesktop {
			right: rem(68);
			bottom: rem(120);
		}

		@include mediaLaptop {
			right: rem(49);
			bottom: rem(92);
		}

		@include mediaTablet {
			right: rem(40);
			bottom: rem(40);
		}

		@include mediaMobile {
			right: rem(16);
			bottom: rem(16);
		}
	}
}

.partner-block {
	display: inline-flex;
	flex-direction: column;
	align-items: flex-start;
	background: var(--color-gray);
	padding: rem(20);
	font: var(--font-body-S);
	color: var(--color-secondary);

	@include mediaBigDesktop {
		padding: big(20);
	}

	@include mediaTablet {
		padding: rem(12);
	}

	@include mediaMobile {
		flex-direction: row;
		align-items: center;
	}

	&__icon {
		width: rem(24);
		height: rem(24);
		fill: var(--color-secondary);
		margin-bottom: rem(12);

		@include mediaBigDesktop {
			width: big(24);
			height: big(24);
			margin-bottom: big(12);
		}

		@include mediaLaptop {
			margin-bottom: big(8);
		}

		@include mediaMobile {
			margin-bottom: 0;
			margin-right: rem(8);
		}
	}
}

.footer-toggle {
	&__title {
		display: flex;
		align-items: center;
		padding-bottom: var(--footer-gap);
		margin-bottom: var(--footer-gap);
		border-bottom: 1px solid var(--stroke-dark-secondary);
		font: var(--font-body-M-semibold);

		@include mediaMobile {
			justify-content: space-between;
			padding-top: rem(20);
			padding-bottom: 0;
			margin-bottom: rem(20);
			border-top: 1px solid var(--stroke-dark-secondary);
			border-bottom: 0;
		}

		&-icon {
			display: none;
			width: rem(24);
			height: rem(24);
			fill: var(--color-gray-2);
			margin-left: rem(8);

			transition: transform var(--anim-direction);

			@include mediaTablet {
				width: rem(20);
				height: rem(20);
			}

			@include mediaMobile {
				display: block;
			}
		}
	}

	&__content {
		@include mediaMobile {
			display: none;
			padding-bottom: rem(20);
		}
	}

	&.toggle_active {
		.footer-toggle {
			&__title {
				@include mediaMobile {
					margin-bottom: rem(16);
				}

				&-icon {
					transform: rotate(180deg);
				}
			}

			&__content {
				display: block;
			}
		}
	}
}

.footer-menu {
	list-style-type: none;
	padding: 0;
	margin: 0;
	font: var(--font-body-M);

	li {
		&:not(:last-child) {
			margin-bottom: rem(16);

			@include mediaBigDesktop {
				margin-bottom: big(16);
			}

			@include mediaDesktop {
				margin-bottom: rem(12);
			}

			@include mediaLaptop {
				margin-bottom: rem(8);
			}

			@include mediaTablet {
				margin-bottom: rem(12);
			}
		}

		a {
			color: var(--color-dark);
			text-decoration: none;
		}
	}
}

.site-dev {
	display: flex;
	align-items: center;

	&__img {
		width: rem(20);
		height: auto;
		margin-right: rem(15);

		@include mediaBigDesktop {
			width: big(20);
			margin-right: big(15);
		}
	}

	&__text {
		display: inline-flex;
		align-items: center;
		line-height: rem(32);

		@include mediaBigDesktop {
			line-height: big(32);
		}

		@include mediaDesktop {
			line-height: rem(24);
		}

		@include mediaTablet {
			line-height: rem(20);
		}

		a {
			color: var(--color-dark);
			text-decoration: underline;
			text-underline-offset: 2px;
			margin-left: rem(5);

			@include mediaBigDesktop {
				margin-left: big(5);
			}
		}
	}
}