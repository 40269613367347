.button {
	position: relative;
	display: flex;
	flex-direction: column;

	padding: rem(16) rem(20);
	margin: 0;

	width: fit-content;

	font: var(--font-body-M-semibold);
	letter-spacing: var(--text-spacing);
	font-style: normal;
	text-align: left;
	text-decoration: none;

	background: transparent;
	border: none;
	border-radius: 0;

	cursor: pointer;
	user-select: none;
	overflow: hidden;

	transition: color var(--anim-direction);

	@include mediaBigDesktop {
		padding: big(16) big(20);
	}

	@include mediaDesktop {
		padding: rem(12) rem(16);
	}

	@include mediaTablet {
		padding: rem(8) rem(12);
	}

	&__text {
		position: relative;
		padding-right: rem(52);
		z-index: 2;

		@include mediaBigDesktop {
			padding-right: big(52);
		}

		@include mediaDesktop {
			padding-right: rem(52);
		}

		@include mediaLaptop {
			padding-right: rem(32);
		}
	}

	&__icon {
		position: relative;
		margin-top: rem(14);
		align-self: flex-end;
		width: rem(32);
		height: rem(32);
		fill: var(--color-white);
		z-index: 2;
		transition: transform var(--anim-direction), fill var(--anim-direction);

		@include mediaBigDesktop {
			margin-top: big(14);
			width: big(32);
			height: big(32);
		}

		@include mediaDesktop {
			width: rem(24);
			height: rem(24);
			margin-top: rem(22);
		}

		@include mediaLaptop {
			margin-top: rem(8);
		}

		@include mediaTablet {
			width: rem(20);
			height: rem(20);
			margin-top: rem(12);
		}
	}

	.button-hover-effects {
		position: absolute;
		top: 0;
		right: -50px;
		display: block;
		width: 0;
		height: 0;
		background-color: var(--color-dark);
		transition: width var(--anim-direction);
		z-index: 1;

		&::before {
			content: "";
			padding-top: 100%;
			width: 100%;
			float: left;
			border-radius: 50%;
			transform: translate(-50%, -50%);
			background-color: inherit;
			transition: width var(--anim-direction), transform var(--anim-direction);
		}
	}

	&:disabled,
	&.disabled {
		cursor: default;
		color: var(--color-text-light-disable);

		.button {
			&__icon {
				fill: var(--color-text-light-disable);
			}
		}
	}

	&:hover:not(:disabled):not(.disabled) {
		.button {
			&__icon {
				transform: rotate(-45deg);
			}
		}

		.button-hover-effects {
			width: 250%;
		}
	}

	&:focus-visible {
		outline: 0;
		box-shadow: 0 0 0 3px var(--color-secondary);
	}

	&_primary {
		background-color: var(--color-primary);
		color: var(--color-white);
	}

	&_secondary {
		background-color: var(--color-secondary);
		color: var(--color-white);
	}

	&_tertiary {
		padding: rem(15) rem(19);
		background-color: transparent;
		color: var(--color-secondary);
		border: 1px solid var(--stroke-dark-secondary);
		transition: border-color var(--anim-direction), color var(--anim-direction);

		@include mediaBigDesktop {
			padding: big(15) big(19);
		}

		@include mediaDesktop {
			padding: rem(11) rem(15);
		}

		.button {
			&__icon {
				fill: var(--color-secondary);
			}
		}

		.button-hover-effects {
			background-color: var(--color-secondary);
		}

		&:hover {
			color: var(--color-white);
			border-color: var(--color-secondary);

			.button {
				&__icon {
					fill: var(--color-white);
				}
			}
		}

		&:disabled,
		.disabled {
			border-color: var(--stroke-dark-secondary);
			color: var(--color-text-dark-disable);

			.button {
				&__icon {
					fill: var(--color-text-dark-disable);
				}
			}
		}
	}

	&_h-light {
		.button-hover-effects {
			background-color: var(--color-white);
		}

		&.button {
			&_tertiary {
				border-color: var(--stroke-light-secondary);
				color: var(--color-white);

				.button__icon {
					fill: var(--color-white);
				}

				&:disabled,
				&.disabled {
					border-color: var(--stroke-light-secondary);
					color: var(--color-text-light-disable);

					.button__icon {
						fill: var(--color-text-light-disable);
					}
				}
			}
		}

		&:hover:not(:disabled):not(.disabled) {
			&.button {
				&_primary {
					color: var(--color-primary);

					.button__icon {
						fill: var(--color-primary);
					}
				}

				&_secondary {
					color: var(--color-secondary);

					.button__icon {
						fill: var(--color-secondary);
					}
				}

				&_tertiary {
					border-color: var(--color-white);
					color: var(--color-secondary);

					.button__icon {
						fill: var(--color-secondary);
					}
				}
			}
		}
	}
}

.file-button {
	position: relative;
	display: flex;
	flex-direction: column;

	padding: rem(16) rem(20);
	margin: 0;

	width: fit-content;

	font: var(--font-body-M-semibold);
	letter-spacing: var(--text-spacing);
	text-align: left;
	text-decoration: none;

	background: transparent;
	border: 1px solid var(--stroke-dark-secondary);
	border-radius: 0;

	cursor: pointer;
	user-select: none;
	overflow: hidden;

	transition: color var(--anim-direction), border-color var(--anim-direction);

	@include mediaBigDesktop {
		padding: big(16) big(20);
	}

	@include mediaDesktop {
		padding: rem(12) rem(16);
	}

	@include mediaTablet {
		padding: rem(8) rem(12);
	}
	
	&__text {
		position: relative;
		display: block;
		color: var(--color-secondary);
		transition: color var(--anim-direction);

		padding-right: rem(52);
		z-index: 2;

		@include mediaBigDesktop {
			padding-right: big(52);
		}

		@include mediaDesktop {
			padding-right: rem(40);
		}

		@include mediaLaptop {
			padding-right: rem(32);
		}
	}

	&__desc {
		position: relative;
		display: block;
		color: var(--color-text-dark-secondary);
		font: var(--font-body-M-caption);
		text-transform: uppercase;
		padding-right: rem(52);
		z-index: 2;
		transition: color var(--anim-direction);

		@include mediaBigDesktop {
			padding-right: big(52);
		}

		@include mediaDesktop {
			padding-right: rem(40);
		}

		@include mediaLaptop {
			padding-right: rem(32);

		}
	}

	&__icon {
		position: relative;
		align-self: flex-end;
		width: rem(32);
		height: rem(32);
		fill: var(--color-secondary);
		z-index: 2;
		margin-top: rem(-12);
		transition: transform var(--anim-direction), fill var(--anim-direction);

		@include mediaBigDesktop {
			width: big(24);
			height: big(24);
			margin-top: big(-12);
		}

		@include mediaDesktop {
			width: rem(24);
			height: rem(24);
			margin-top: rem(2);
		}

		@include mediaLaptop {
			margin-top: rem(-8);
		}

		@include mediaTablet {
			width: rem(20);
			height: rem(20);
			margin-top: rem(-2);
		}
	}

	.button-hover-effects {
		position: absolute;
		top: 0;
		right: -50px;
		display: block;
		width: 0;
		height: 0;
		background-color: var(--color-secondary);
		transition: width var(--anim-direction);
		z-index: 1;

		&::before {
			content: "";
			padding-top: 100%;
			width: 100%;
			float: left;
			border-radius: 50%;
			transform: translate(-50%, -50%);
			background-color: inherit;
			transition: width var(--anim-direction), transform var(--anim-direction);
		}
	}

	&:disabled,
	&.disabled {
		cursor: default;
		border-color: var(--stroke-dark-secondary);
		color: var(--color-text-dark-disable);

		.file-button {
			&__text,
			&__desc {
				color: var(--color-text-dark-disable);
			}

			&__icon {
				fill: var(--color-text-dark-disable);
			}
		}
	}

	&:hover:not(:disabled):not(.disabled) {
		border-color: var(--color-secondary);
		color: var(--color-white);

		.file-button {
			&__text {
				color: var(--color-white);
			}

			&__desc {
				color: var(--color-text-light-secondary);
			}

			&__icon {
				fill: var(--color-white);
			}
		}

		.button-hover-effects {
			width: 250%;
		}
	}

	&:focus-visible {
		outline: 0;
		box-shadow: 0 0 0 3px var(--color-secondary);
	}

	&_light {
		color: var(--color-white);
		border-color: var(--stroke-light-secondary);

		.file-button {
			&__text {
				color: var(--color-white);
			}

			&__desc {
				color: var(--color-text-light-secondary);
			}

			&__icon {
				fill: var(--color-white);
			}
		}

		.button-hover-effects {
			background-color: var(--color-white);
		}

		&:hover:not(:disabled):not(.disabled) {
			color: var(--color-secondary);
			border-color: var(--color-white);

			.file-button {
				&__text {
					color: var(--color-secondary);
				}

				&__desc {
					color: var(--color-text-dark-secondary);
				}

				&__icon {
					fill: var(--color-secondary);
				}
			}
		}

		&:disabled,
		&.disabled {
			border-color: var(--stroke-light-secondary);
			color: var(--color-text-light-disable);

			.file-button {
				&__text,
				&__desc {
					color: var(--color-text-light-disable);
				}

				&__icon {
					fill: var(--color-text-light-disable);
				}
			}
		}
	}
}

.text-button {
	position: relative;
	display: flex;
	gap: rem(8);
	align-items: center;

	padding: 0;
	margin: 0;
	width: fit-content;

	font: var(--font-body-M-semibold);
	letter-spacing: var(--text-spacing);
	text-align: left;
	text-decoration: none;

	color: var(--color-secondary);

	background: transparent;
	border: none;
	border-radius: 0;

	cursor: pointer;
	user-select: none;
	overflow: hidden;

	@include mediaBigDesktop {
		font-size: big(22);
		gap: big(8);
	}

	@include mediaDesktop {
		font-size: rem(18);
		gap: rem(6);
	}

	@include mediaLaptop {
		font-size: rem(16);
		gap: rem(4);
	}

	@include mediaTablet {
		font-size: rem(14);
	}

	&__text {
		display: block;
	}

	&__icon {
		min-width: rem(32);
		width: rem(32);
		height: rem(32);
		fill: var(--color-secondary);
		transition: transform var(--anim-direction), fill var(--anim-direction);

		@include mediaBigDesktop {
			min-width: big(32);
			width: big(32);
			height: big(32);
		}

		@include mediaDesktop {
			min-width: rem(24);
			width: rem(24);
			height: rem(24);
		}

		@include mediaTablet {
			min-width: rem(20);
			width: rem(20);
			height: rem(20);
		}
	}

	&:disabled,
	&.disabled {
		cursor: default;
		color: var(--color-text-dark-disable);
		pointer-events: none;

		.text-button {
			&__icon {
				fill: var(--color-text-dark-disable);
			}
		}
	}

	&:focus-visible {
		outline: 0;
		box-shadow: 0 0 0 3px var(--color-secondary);
	}

	&_secondary {
		color: var(--color-text-dark-secondary);

		.text-button__icon {
			fill: var(--color-text-dark-secondary);
		}
	}
}

.icon-button {
	display: flex;
	align-items: center;
	justify-content: center;

	position: relative;
	padding: 0;
	margin: 0;

	min-width: rem(60);
	width: rem(60);
	height: rem(60);

	font-size: 0;
	text-decoration: none;

	background: var(--color-dark);
	border: 0;
	border-radius: 50%;

	cursor: pointer;
	user-select: none;
	overflow: hidden;

	transition: border-color var(--anim-direction), background var(--anim-direction);

	@include mediaBigDesktop {
		min-width: big(60);
		width: big(60);
		height: big(60);
	}

	@include mediaDesktop {
		min-width: rem(54);
		width: rem(54);
		height: rem(54);
	}

	@include mediaLaptop {
		min-width: rem(44);
		width: rem(44);
		height: rem(44);
	}

	&__icon {
		position: relative;
		z-index: 2;
		width: rem(24);
		height: rem(24);
		fill: var(--color-white);
		transition: transform var(--anim-direction), fill var(--anim-direction);

		@include mediaBigDesktop {
			width: big(24);
			height: big(24);
		}

		@include mediaDesktop {
			width: rem(20);
			height: rem(20);
		}
	}

	.button-hover-effects {
		position: absolute;
		top: 0;
		right: -50%;
		display: block;
		width: 0;
		height: 0;
		background-color: var(--color-secondary);
		transition: width var(--anim-direction);
		z-index: 1;

		&::before {
			content: "";
			padding-top: 100%;
			width: 100%;
			float: left;
			border-radius: 50%;
			transform: translate(-50%, -50%);
			background-color: inherit;
			transition: width var(--anim-direction), transform var(--anim-direction);
		}
	}

	&:disabled,
	&.disabled {
		cursor: default;

		.icon-button {
			&__icon {
				fill: var(--stroke-light-secondary);
			}
		}
	}

	&:hover:not(:disabled):not(.disabled) {
		border-color: var(--color-secondary);

		@include mediaMobile {
			background-color: var(--color-secondary);
		}

		.icon-button__icon {
			fill: var(--color-white);
		}

		.button-hover-effects {
			width: 250%;
		}
	}

	&:focus-visible {
		outline: 0;
		box-shadow: 0 0 0 3px var(--color-secondary);
	}

	&_stroke {
		min-width: rem(59);
		width: rem(59);
		height: rem(59);

		background: transparent;
		border: 1px solid var(--stroke-dark-secondary);

		@include mediaBigDesktop {
			min-width: big(59);
			width: big(59);
			height: big(59);
		}

		@include mediaDesktop {
			min-width: rem(53);
			width: rem(53);
			height: rem(53);
		}

		@include mediaLaptop {
			min-width: rem(43);
			width: rem(43);
			height: rem(43);
		}

		.icon-button {
			&__icon {
				fill: var(--color-dark);
			}
		}

		&:disabled,
		&.disabled {
			border-color: var(--stroke-dark-secondary);

			.icon-button {
				&__icon {
					fill: var(--color-text-dark-disable);
				}
			}
		}
	}

	&_light {
		background: var(--color-white);

		.icon-button {
			&__icon {
				fill: var(--color-dark);
			}
		}

		&:disabled,
		&.disabled {
			.icon-button {
				&__icon {
					fill: var(--color-text-dark-disable);
				}
			}
		}

		&.icon-button {
			&_stroke {
				background: transparent;
				border-color: var(--stroke-light-secondary);

				.icon-button__icon {
					fill: var(--color-white);
				}

				&:disabled,
				&.disabled {
					.icon-button {
						&__icon {
							fill: var(--color-text-light-disable);
						}
					}
				}
			}
		}
	}
}

.icon-text-button {
	position: relative;
	display: flex;
	gap: rem(12);
	align-items: center;

	padding: 0;
	margin: 0;
	width: fit-content;

	font: var(--font-body-M-semibold);
	letter-spacing: var(--text-spacing);
	text-align: left;
	text-decoration: none;

	color: var(--color-white);

	background: transparent;
	border: none;
	border-radius: 0;

	cursor: pointer;
	user-select: none;
	overflow: hidden;

	@include mediaBigDesktop {
		gap: big(12);
	}

	&__circle {
		min-width: rem(60);
		width: rem(60);
		height: rem(60);

		border-radius: 50%;
		background: var(--color-white);

		@include mediaBigDesktop {
			min-width: big(60);
			width: big(60);
			height: big(60);
		}

		@include mediaDesktop {
			min-width: rem(54);
			width: rem(54);
			height: rem(54);
		}

		@include mediaLaptop {
			min-width: rem(44);
			width: rem(44);
			height: rem(44);
		}
	}

	&__icon {
		position: relative;
		z-index: 2;
		width: rem(24);
		height: rem(24);
		fill: var(--color-secondary);
		transition: transform var(--anim-direction), fill var(--anim-direction);

		@include mediaBigDesktop {
			width: big(24);
			height: big(24);
		}

		@include mediaDesktop {
			width: rem(20);
			height: rem(20);
		}
	}

	&__text {
		text-decoration: none;
	}

	.button-hover-effects {
		position: absolute;
		top: 0;
		right: -50px;
		display: block;
		width: 0;
		height: 0;
		background-color: var(--color-secondary);
		transition: width var(--anim-direction), transform var(--anim-direction);
		z-index: 1;

		&::before {
			content: "";
			padding-top: 100%;
			width: 100%;
			float: left;
			border-radius: 50%;
			transform: translate(-50%, -50%);
			background-color: inherit;
			transition: width var(--anim-direction), transform var(--anim-direction);
		}
	}

	&:disabled,
	&.disabled {
		cursor: default;
		color: var(--color-white);

		.icon-text-button {
			&__icon-button {
				background: var(--color-white);

				.icon-button {
					&__icon {
						fill: var(--color-text-dark-disable);
					}
				}

				.button-hover-effects {
					display: none;
				}
			}
		}
	}

	&:hover:not(:disabled):not(.disabled) {
		.icon-text-button__circle {
			background: var(--color-secondary);
		}

		.icon-text-button__icon {
			fill: var(--color-white);
		}

		.button-hover-effects {
			width: 250%;
			height: 250%;
		}
	}

	&:focus-visible {
		outline: 0;
		box-shadow: 0 0 0 3px var(--color-secondary);
	}
}

[data-text-hover] {
	display: block;
	overflow: hidden;
	white-space: nowrap;
	width: max-content;
	text-overflow: ellipsis;

	line-height: rem(32);
	height: rem(32);

	@include mediaBigDesktop {
		line-height: big(32);
		height: big(32);
	}

	@include mediaDesktop {
		line-height: rem(24);
		height: rem(24);
	}

	@include mediaTablet {
		line-height: rem(20);
		height: rem(20);
	}

	&:after {
		content: attr(data-text-hover);
		display: block;
		pointer-events: none;
		transition: transform var(--anim-direction), opacity var(--anim-direction), color var(--anim-direction);
	}

	span {
		display: block;
		transition: transform var(--anim-direction), opacity var(--anim-direction), color var(--anim-direction);
	}

	&:hover {
		&:after,
		span {
			transform: translateY(-100%);
			opacity: 1;
		}
	}

	&.h4 {
		line-height: rem(32);
		height: rem(32);

		@include mediaBigDesktop {
			line-height: big(32);
			height: big(32);
		}

		//@include mediaDesktop {
		//	line-height: rem(32);
		//	height: rem(32);
		//}

		@include mediaTablet {
			line-height: rem(24);
			height: rem(24);
		}
	}
}