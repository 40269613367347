.breadcrumbs {
	display: flex;

	list-style-type: none;
	padding: 0;
	margin: 0;

	&__item {
		display: inline-flex;
		align-items: center;
		justify-content: center;

		color: var(--color-text-dark);
		font: var(--font-body-M-caption);
		letter-spacing: -0.54px;
		text-transform: uppercase;

		@include mediaDesktop {
			letter-spacing: -0.42px;
		}

		@include mediaLaptop {
			letter-spacing: -0.36px;
		}

		&:not(:last-child) {
			&:after {
				content: "";
				margin: 0 rem(16);

				width: rem(2);
				height: rem(2);
				display: inline-block;
				min-width: rem(2);

				background: var(--color-text-dark-secondary);

				@include mediaBigDesktop {
					min-width: big(2);
					width: big(2);
					height: big(2);
					margin: 0 big(16);
				}

				@include mediaDesktop {
					margin: 0 rem(12);
				}

				@include mediaMobile {
					margin: 0 rem(6);
				}
			}
		}

		a {
			text-decoration: none;
			color: var(--color-text-dark-secondary);
		}

		&_active {
			display: inline-flex;
			line-height: rem(24);
			height: rem(24);

			@include mediaBigDesktop {
				line-height: big(24);
				height: big(24);
			}

			@include mediaDesktop {
				line-height: rem(20);
				height: rem(20);
			}

			@include mediaLaptop {
				line-height: rem(16);
				height: rem(16);
			}

			span {
				overflow: hidden;
				white-space: nowrap;
				text-overflow: ellipsis;
			}
		}
	}

	&__item-text {
		display: block;
		line-height: rem(24);
		height: rem(24);

		@include mediaBigDesktop {
			line-height: big(24);
			height: big(24);
		}

		@include mediaDesktop {
			line-height: rem(20);
			height: rem(20);
		}

		@include mediaLaptop {
			line-height: rem(16);
			height: rem(16);
		}
	}

	&_dark {
		.breadcrumbs {
			&__item {
				color: var(--color-text-light);

				&:after {
					background: #D9D9D9;
				}

				a {
					color: var(--color-text-light-secondary);
				}
			}
		}
	}
}
