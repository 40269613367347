.sec-cta-image {
	position: relative;
	z-index: 2;
	background-color: var(--color-secondary);
	overflow: hidden;
	height: 100vh;

	@include mediaTablet {
		height: auto;
	}

	.container {
		height: 100%;
	}

	&__row {
		position: relative;
		display: flex;
		justify-content: flex-end;
		height: 100%;

		@include mediaTablet {
			flex-direction: column;
			height: 100%;
		}
	}

	&__img {
		position: absolute;
		top: rem(-100);
		left: 0;
		z-index: 1;
		max-width: rem(1308);
		width: 100%;
		font-size: 0;

		@include mediaBigDesktop {
			top: big(-100);
			max-width: big(1308);
		}

		@include mediaDesktop {
			top: rem(-68);
			max-width: rem(955);
		}

		@include mediaLaptop {
			top: rem(-48);
			max-width: rem(722);
		}

		@include mediaTablet {
			top: rem(-40);
			right: 0;
			margin: 0 auto;
			max-width: rem(570);
		}

		@include mediaMobile {
			max-width: 100%;
		}

		picture {
			display: block;
			width: 100%;
		}

		img {
			max-width: 100%;
			width: 100%;
			height: auto;
		}
	}

	&__form {
		position: relative;
		z-index: 2;
		height: 100%;
		max-width: rem(1082);
		display: flex;
		align-items: center;

		@include mediaBigDesktop {
			max-width: big(1082);
		}

		@include mediaDesktop {
			max-width: rem(808);
		}

		@include mediaLaptop {
			max-width: rem(612);
		}

		@include mediaTablet {
			max-width: 100%;
			display: block;
			padding-top: 48vw;
		}

		@include mediaMobile {
			padding-top: 50vw;
		}
	}
}

@supports(height: 100dvh) {
	.sec-cta-image {
		height: 100dvh;

		@include mediaTablet {
			height: auto;
		}
	}
}
