.text-editor {
	font: var(--font-body-M);
	letter-spacing: var(--text-spacing);

	h1 {
		font: var(--font-h3);
		letter-spacing: var(--letter-spacing-h3);
	}

	h2 {
		margin-top: rem(100);
		margin-bottom: rem(32);
		font: var(--font-h4);
		letter-spacing: var(--letter-spacing-h4);

		@include mediaBigDesktop {
			margin-top: big(100);
			margin-bottom: big(32);
		}

		@include mediaDesktop {
			margin-top: rem(68);
			margin-bottom: rem(24);
		}

		@include mediaLaptop {
			margin-top: rem(48);
		}

		@include mediaTablet {
			margin-top: rem(40);
			margin-bottom: rem(16);
		}

		@include mediaMobile {
			margin-top: rem(28);
		}
	}

	h3 {
		margin-top: rem(68);
		margin-bottom: rem(24);
		font: var(--font-h5);
		letter-spacing: var(--letter-spacing-h5);

		@include mediaBigDesktop {
			margin-top: big(68);
			margin-bottom: big(24);
		}

		@include mediaDesktop {
			margin-top: rem(48);
		}

		@include mediaLaptop {
			margin-top: rem(40);
			margin-bottom: rem(16);
		}

		@include mediaTablet {
			margin-top: rem(28);
		}

		@include mediaMobile {
			margin-top: rem(20);
		}
	}

	h4 {
		margin-top: rem(68);
		margin-bottom: rem(24);
		font: var(--font-h5);
		letter-spacing: var(--letter-spacing-h5);

		@include mediaBigDesktop {
			margin-top: big(68);
			margin-bottom: big(24);
		}

		@include mediaDesktop {
			margin-top: rem(48);
		}

		@include mediaLaptop {
			margin-top: rem(40);
			margin-bottom: rem(16);
		}

		@include mediaTablet {
			margin-top: rem(28);
		}

		@include mediaMobile {
			margin-top: rem(20);
		}
	}

	p {
		margin: rem(32) 0;

		@include mediaBigDesktop {
			margin: big(32) 0;
		}

		@include mediaDesktop {
			margin: rem(26) 0;
		}

		@include mediaLaptop {
			margin: rem(24) 0;
		}

		@include mediaTablet {
			margin: rem(20) 0;
		}

		&:first-child {
			margin-top: 0;
		}

		a {
			color: var(--color-secondary);
		}
	}

	ul,
	ol {
		margin: rem(20) 0;

		@include mediaBigDesktop {
			margin: big(20) 0;
		}

		@include mediaLaptop {
			margin: rem(16) 0;
		}

		@include mediaMobile {
			padding-left: rem(28);
		}

		&:first-child {
			margin-top: 0;
		}

		li {
			padding-left: rem(18);

			@include mediaBigDesktop {
				padding-left: big(18);
			}

			@include mediaLaptop {
				padding-left: rem(16);
			}

			@include mediaMobile {
				padding-left: rem(12);
			}

			&:not(:last-child) {
				margin-bottom: rem(20);

				@include mediaBigDesktop {
					margin-bottom: big(20);
				}

				@include mediaDesktop {
					margin-bottom: rem(16);
				}
			}

			&::marker {
				color: var(--color-secondary);
			}
		}
	}

	img {
		max-width: 100%;
		height: auto;
	}

	table {
		width: 100%;
		border-collapse: collapse;
		font: var(--font-body-M);
		border: 1px solid var(--stroke-dark-secondary);

		tr {
			border: 1px solid var(--stroke-dark-secondary);

			&:hover {
				td {
					background-color: var(--color-gray-3);
				}
			}
		}

		td,th {
			padding: rem(40);

			transition: background-color var(--anim-direction);

			@include mediaBigDesktop {
				padding: big(40);
			}

			@include mediaDesktop {
				padding: rem(32);
			}

			@include mediaLaptop {
				padding: rem(24);
			}

			@include mediaTablet {
				padding: rem(16);

				ul, ol {
					li {
						margin-bottom: rem(5) !important;
					}
				}
			}

			&:not(:last-child) {
				border-right: 1px solid var(--stroke-dark-secondary);
			}
		}

		th {
			color: var(--color-text-dark-secondary);
			font-weight: 400;
			text-align: left;
		}
	}

	.button,
	.file-button {
		margin-top: rem(40);

		@include mediaBigDesktop {
			margin-top: big(40);
		}

		@include mediaDesktop {
			margin-top: rem(32);
		}

		@include mediaLaptop {
			margin-top: rem(24);
		}

		@include mediaMobile {
			margin-top: rem(16);
		}
	}

	blockquote {
		padding: 0;
		margin: rem(32) 0;
		font: var(--font-h4);
		letter-spacing: var(--letter-spacing-h4);

		@include mediaBigDesktop {
			margin: big(32) 0;
		}

		@include mediaDesktop {
			margin: rem(26) 0;
		}

		@include mediaLaptop {
			margin: rem(24) 0;
		}

		@include mediaTablet {
			margin: rem(20) 0;
		}

		&:first-child {
			margin-top: 0;
		}

		&:before {
			content: "";
			display: block;
			width: rem(32);
			height: rem(32);
			background-image: url("/assets/templates/img/common/blockquote.svg");
			background-size: contain;
			background-repeat: no-repeat;
			background-position: center;
			margin-bottom: rem(16);

			@include mediaBigDesktop {
				width: big(32);
				height: big(32);
				margin-bottom: big(16);
			}

			@include mediaDesktop {
				width: rem(24);
				height: rem(24);
			}

			@include mediaLaptop {
				margin-bottom: rem(12);
			}

			@include mediaMobile {
				margin-bottom: rem(8);
			}
		}
	}
}