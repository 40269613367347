.sec-team {
	&__slider {
		margin-right: rem(-100);

		@include mediaBigDesktop {
			margin-right: big(-100);
		}

		@include mediaDesktop {
			margin-right: 0;
		}

		@include mediaLaptop {
			margin-right: 0;
		}

		@include mediaTablet {
			margin-right: rem(-40);
		}

		@include mediaMobile {
			margin-right: rem(-16);
		}
	}
}