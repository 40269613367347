.sec-steps {
	&__slider-nav {
		border-bottom: 1px solid var(--stroke-dark-secondary);

		.swiper-wrapper {
			display: flex;
			justify-content: space-between;
			min-width: 100%;
		}

		.swiper-slide {
			font-size: 0;
			width: auto;
		}

		.swiper-slide-thumb-active {
			.button-tab-line {
				&__count {
					background-color: var(--color-secondary);
					color: var(--color-white);
				}

				&:after {
					width: 100%;
				}
			}
		}

		.button-tab-line {
			margin-bottom: -1px;

			@media (any-hover: hover) {
				&:hover {
					&:after {
						width: 100%;
					}
				}
			}
		}
	}

	&__slider {
		.swiper-slide {
			height: auto;
		}

		.step-slide {
			height: 100%;
		}
	}

	&_dark {
		background-color: var(--color-dark);
		color: var(--color-white);

		.sec-steps {
			&__slider-nav {
				border-color: var(--stroke-light-secondary);

				.button-tab-line {
					@media (any-hover: hover) {
						&:hover {
							&:after {
								width: 100%;
							}
						}
					}
				}
			}
		}

		.step-slide {
			background-color: var(--color-dark);
			color: var(--color-white);

			&__label {
				color: var(--color-text-light-secondary);
			}
		}
	}
}

.step-slide {
	display: flex;
	gap: var(--section-offset);
	padding-top: var(--section-offset);
	background-color: var(--color-white);

	@include mediaMobile {
		flex-direction: column;
		padding-top: rem(28);
		gap: rem(28);
	}

	&__img {
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 0;
		flex: 1;
		padding: rem(40) 0;

		@include mediaBigDesktop {
			padding: big(40) 0;
		}

		@include mediaDesktop {
			padding: rem(30) 0;
		}

		@include mediaLaptop {
			padding: 0;
		}

		@include mediaTablet {
			justify-content: flex-start;
			align-items: flex-start;
			flex: 0.3;
		}

		@include mediaMobile {
			flex: none;
			width: 100%;
		}

		picture {
			display: inline-block;
			width: 100%;
			text-align: center;

			@include mediaTablet {
				text-align: left;
			}
		}

		img {
			max-width: rem(656);
			width: 100%;
			height: auto;

			@include mediaBigDesktop {
				max-width: big(656);
			}

			@include mediaDesktop {
				max-width: rem(450);
			}

			@include mediaLaptop {
				max-width: rem(408);
			}

			@include mediaTablet {
				max-width: rem(155);
			}

			@include mediaMobile {
				max-width: rem(100);
			}
		}
	}

	&__content {
		display: flex;
		flex-direction: column;
		gap: rem(24);
		flex: 1;

		@include mediaBigDesktop {
			gap: big(24);
		}

		@include mediaDesktop {
			gap: rem(20);
		}

		@include mediaLaptop {
			gap: rem(16);
		}

		@include mediaTablet {
			gap: rem(12);
			flex: 0.7;
		}

		@include mediaMobile {
			gap: rem(8);
			flex: 1;
		}
	}

	&__label {
		font: var(--font-body-M-caption);
		color: var(--color-secondary);
		text-transform: uppercase;
	}

	&__text {
		display: block;
		font: var(--font-h3);
		letter-spacing: var(--letter-spacing-h3);
		margin-right: rem(100);

		@include mediaBigDesktop {
			margin-right: big(100);
		}

		@include mediaDesktop {
			margin-right: rem(76);
		}

		@include mediaLaptop {
			margin-right: rem(48);
		}

		@include mediaTablet {
			margin-right: 0;
		}

		.line {
			overflow: hidden;
		}
	}

	&__btns {
		display: flex;
		align-items: center;
		gap: rem(12);
		margin-top: auto;

		@include mediaBigDesktop {
			gap: big(12);
		}

		@include mediaLaptop {
			gap: rem(8);
		}

		@include mediaTablet {
			display: none;
		}
	}
}
