@mixin scrollbar {
  &::-webkit-scrollbar {
    width: rem(4);

    @include mediaBigDesktop {
      width: big(4);
    }
  }

  &::-webkit-scrollbar-track {
    background: var(--color-white);
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--color-dark);
  }
}

@mixin scrollbarHorizontal {
  &::-webkit-scrollbar {
    height: rem(4);

    @include mediaBigDesktop {
      height: big(4);
    }
  }

  &::-webkit-scrollbar-track {
    background-color: var(--color-white);
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--color-dark);
  }
}

@mixin scrollbarDelete {
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  &::-webkit-scrollbar-track {
    display: none;
  }

  &::-webkit-scrollbar-thumb {
    display: none;
  }
}
