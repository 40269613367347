.project-card {
	display: flex;
	width: 100%;
	min-height: rem(800);
	background: var(--color-gray-3);

	@include mediaBigDesktop {
		min-height: big(800);
	}

	@include mediaDesktop {
		min-height: rem(600);
	}

	@include mediaLaptop {
		min-height: rem(530);
	}

	@include mediaTablet {
		min-height: rem(350);
	}

	@include mediaMobile {
		min-height: auto;
		flex-direction: column;
	}

	&__slider {
		width: 50%;

		@include mediaMobile {
			width: 100%;
			height: rem(240);
		}
	}

	&__content {
		position: relative;
		width: 50%;
		padding: rem(100) rem(100) rem(152);

		@include mediaBigDesktop {
			padding: big(100) big(100) big(152);
		}

		@include mediaDesktop {
			padding: rem(68) rem(68) rem(120);
		}

		@include mediaLaptop {
			padding: rem(48) rem(48) rem(96);
		}

		@include mediaTablet {
			padding: rem(40);
		}

		@include mediaMobile {
			width: 100%;
			padding: rem(20);
		}
	}

	&__tags {
		display: flex;
		flex-wrap: wrap;

		margin-bottom: rem(24);

		@include mediaBigDesktop {
			margin-bottom: big(24);
		}

		@include mediaDesktop {
			margin-bottom: rem(20);
		}

		@include mediaTablet {
			margin-bottom: rem(16);
		}

		@include mediaMobile {
			margin-bottom: rem(12);
		}
	}

	&__title {
		display: block;
		font: var(--font-h3);
		color: var(--stroke-dark);
		text-decoration: none;

		transition: color var(--anim-direction);
	}

	&__text {
		margin-top: rem(20);
		font: var(--font-body-M);

		@include mediaBigDesktop {
			margin-top: big(20);
		}

		@include mediaTablet {
			margin-top: rem(16);

			overflow: hidden;
			text-overflow: ellipsis;
			-webkit-box-orient: vertical;
			display: -webkit-box;
			-webkit-box-orient: vertical;
			-webkit-line-clamp: 5;
		}

		@include mediaMobile {
			margin-top: rem(12);
		}

		p {
			margin: 0;
		}
	}

	&__btn {
		position: absolute;
		left: rem(100);
		bottom: rem(100);
		opacity: 0;
		transition: opacity var(--anim-direction);

		@include mediaBigDesktop {
			left: big(100);
			bottom: big(100);
		}

		@include mediaDesktop {
			left: rem(68);
			bottom: rem(68);
		}

		@include mediaLaptop {
			left: rem(48);
			bottom: rem(48);
		}

		@include mediaTablet {
			position: static;
			opacity: 1;
			margin-top: rem(16);
		}

		@include mediaMobile {
			margin-top: rem(12);
		}
	}

	&:hover {
		.project-card {
			&__btn {
				opacity: 1;
			}
		}
	}

	&_reverse {
		flex-direction: row-reverse;

		@include mediaMobile {
			flex-direction: column;
		}
	}
}

.slider-hover {
	position: relative;
	width: 100%;
	height: 100%;

	&__list	{
		position: absolute;
		left: 0;
		top: 0;
		z-index: 2;
		width: 100%;
		height: 100%;
		display: flex;
	}

	&__img {
		position: absolute;
		left: 50%;
		top: 0;
		z-index: 2;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		height: 100%;
		margin: 0;
		pointer-events: none;
		background-color: #fff;
		transform: translateX(-50%);
		opacity: 0;
		transition: opacity var(--anim-direction);

		&:before {
			content: "";
			position: absolute;
			left: 0;
			bottom: 0;
			display: block;
			width: 100%;
			height: 100%;
			background: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 79.5%, rgba(0, 0, 0, 0.50) 100%);
		}

		img {
			display: block;
			width: 100%;
			max-width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}

	&__item {
		flex-grow: 1;

		&:first-child {
			.slider-hover {
				&__img {
					opacity: 1;
					z-index: -1;
				}
			}
		}

		&:hover {
			.slider-hover {
				&__img {
					opacity: 1;
					z-index: -1;
				}
			}
		}
	}

	&__nav {
		position: absolute;
		left: 0;
		bottom: 0;
		z-index: 3;

		display: flex;
		align-items: center;
		gap: rem(12);

		width: 100%;
		padding: rem(40);

		@include mediaBigDesktop {
			gap: big(12);
			padding: big(40);
		}

		@include mediaDesktop {
			padding: rem(32);
		}

		@include mediaLaptop {
			padding: rem(25) rem(23);
		}

		@include mediaTablet {
			gap: rem(8);
			padding: rem(20);
		}
	}

	&__nav-item {
		display: inline-block;
		width: 100%;
		height: rem(4);
		background: var(--color-white);
		opacity: 0.5;
		transition: opacity var(--anim-direction);

		@include mediaBigDesktop {
			height: big(4);
		}

		@include mediaTablet {
			height: rem(3);
		}

		@include mediaMobile {
			height: rem(2);
		}

		&_active {
			opacity: 1;
		}
	}
}