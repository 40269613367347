* {
	box-sizing: border-box;
}

::selection {
	background: var(--color-secondary);
	color: var(--color-text-light)
}

::-moz-selection {
	background: var(--color-secondary);
	color: var(--color-text-light)
}

img {
	max-width: 100%;
	height: auto;
}

figure {
	margin: 0;
}

label {
	display: inline-block;
	line-height: 0;
}

.html {
	height: 100%;

	font-family: var(--font-primary);
	color: var(--text-primary);
	font-style: normal;
	font-weight: 400;
	-webkit-text-size-adjust: 100%;
	//scroll-behavior: smooth;
}

.main {
	height: 100%;
	display: flex;
	flex-direction: column;
}

.disable-scroll {
	width: 100%;
	height: 100vh;
	overflow: hidden;
}

.wrapper {
	min-height: 100vh;
	display: flex;
	align-items: stretch;
	flex-direction: column;
}

.container {
	padding-right: rem(100);
	padding-left: rem(200);

	@include mediaBigDesktop {
		padding-right: big(100);
		padding-left: big(200);
	}

	@include mediaDesktop {
		padding-right: rem(68);
		padding-left: rem(168);
	}

	@include mediaLaptop {
		padding-right: rem(48);
		padding-left: rem(120);
	}

	@include mediaTablet {
		padding-right: rem(40);
		padding-left: rem(40);
	}

	@include mediaMobile {
		padding-right: rem(16);
		padding-left: rem(16);
	}
}

.footer {
	margin-top: auto;
}

.input {
	-webkit-appearance: none;
	appearance: none;
	border: none;
	border-radius: 0;
	background-color: var(--color-white);

	&::-webkit-search-decoration,
	&::-webkit-search-cancel-button,
	&::-webkit-search-results-button,
	&::-webkit-search-results-decoration {
		display: none;
	}

	&::-webkit-outer-spin-button,
	&::-webkit-inner-spin-button {
		-webkit-appearance: none;
	}

	&[type=number] {
		appearance: none;
		-webkit-appearance: none;
		-moz-appearance: textfield;
	}
}

.visually-hidden {
	position: absolute;
	overflow: hidden;
	margin: -1px;
	border: 0;
	padding: 0;
	width: 1px;
	height: 1px;
	clip: rect(0 0 0 0);
}

.reset-styles {
	padding: 0;
	margin: 0;
}

.text-underline {
	text-decoration: underline !important;
	text-underline-offset: 2px;
}


@include mediaMobile {
	table {
		&.bt tbody {
			td {
				flex-direction: column;

				&:before {
					width: 100%;
					display: block;
					color: var(--color-text-dark-secondary);
					margin-bottom: rem(4);
				}

				.bt-content {
					width: 100%;
					display: block;
				}
			}

			tr td:not(:first-child) {
				padding-top: 0;
			}
		}
	}
}