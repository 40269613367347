.model {
	--anim-direction-model: 1s;

	position: fixed;
	left: 0;
	top: 0;
	z-index: 20;
	width: 100%;
	height: 100%;
	visibility: hidden;
	pointer-events: none;

	transition: visibility var(--anim-direction-model) var(--anim-cubic);

	&__btn-close {
		position: absolute;
		right: rem(40);
		top: rem(40);
		z-index: 3;
		opacity: 0;

		transition: opacity var(--anim-direction-model) var(--anim-cubic);

		@include mediaBigDesktop {
			right: big(40);
			top: big(40);
		}

		@include mediaDesktop {
			right: rem(32);
			top: rem(32);
		}

		@include mediaTablet {
			right: rem(40);
			top: rem(40);
		}

		@include mediaMobile {
			right: rem(16);
			top: rem(16);
		}
	}

	&__bg {
		position: absolute;
		left: 0;
		top: 0;
		z-index: 2;
		width: 50%;
		height: 100%;
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;

		transform: translateX(-100%);

		transition: transform var(--anim-direction-model) var(--anim-cubic);

		@include mediaTablet {
			display: none;
		}
	}

	&__content {
		position: absolute;
		right: 0;
		top: 0;
		z-index: 2;
		width: 50%;
		height: 100%;
		background-color: var(--color-white);
		color: var(--color-dark);
		padding: rem(200) rem(295);
		min-width: rem(700);
		overflow: auto;

		transform: translateX(100%);
		transition: transform var(--anim-direction-model) var(--anim-cubic), opacity var(--anim-direction-model) var(--anim-cubic);

		@include scrollbar();

		@include mediaBigDesktop {
			padding: big(200) big(295);
		}

		@include mediaDesktop {
			padding: rem(136) rem(230);
		}

		@include mediaLaptop {
			padding: rem(96) rem(160)
		}

		@include mediaTablet {
			width: 100%;
			min-width: auto;
			padding: rem(80) rem(40);
		}

		@include mediaMobile {
			padding: rem(40) rem(16);
		}

		.icon-success {
			margin-bottom: rem(48);

			@include mediaBigDesktop {
				margin-bottom: big(48);
			}

			@include mediaLaptop {
				margin-bottom: rem(32);
			}

			@include mediaTablet {
				margin-bottom: rem(24);
			}

			@include mediaMobile {
				margin-bottom: rem(16);
			}
		}
	}

	&__title {
		display: block;
	}

	&__text {
		margin-top: rem(20);
		margin-bottom: 0;

		@include mediaBigDesktop {
			margin-top: big(20);
		}

		@include mediaDesktop {
			margin-top: rem(16);
		}

		@include mediaLaptop {
			margin-top: rem(12);
		}

		@include mediaMobile {
			margin-top: rem(8);
		}
	}

	&__form {
		margin-top: rem(48);

		@include mediaBigDesktop {
			margin-top: big(48);
		}

		@include mediaLaptop {
			margin-top: rem(32);
		}

		@include mediaMobile {
			margin-top: rem(20);
		}

		.label-group,
		.double-group,
		.select-input {
			margin-bottom: rem(40);

			@include mediaBigDesktop {
				margin-bottom: big(40);
			}

			@include mediaDesktop {
				margin-bottom: rem(32);
			}

			@include mediaLaptop {
				margin-bottom: rem(24);
			}

			@include mediaMobile {
				margin-bottom: rem(18);
			}
		}

		.checkbox {
			margin-top: rem(48);

			@include mediaBigDesktop {
				margin-top: big(48);
			}

			@include mediaLaptop {
				margin-top: rem(32);
			}

			@include mediaMobile {
				margin-top: rem(20);
			}
		}

		.button {
			margin-top: rem(48);

			@include mediaBigDesktop {
				margin-top: big(48);
			}

			@include mediaLaptop {
				margin-top: rem(32);
			}

			@include mediaMobile {
				margin-top: rem(20);
			}
		}
	}

	&__overlay {
		position: fixed;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		background: rgba(0, 0, 0, 0.50);
		opacity: 0;

		transition: opacity var(--anim-direction-model) var(--anim-cubic);
	}

	&.js_active {
		visibility: visible;
		pointer-events: all;

		.model {
			&__btn-close {
				opacity: 1;
			}

			&__bg {
				transform: translateX(0);
			}

			&__content {
				transform: translateX(0);
			}

			&__overlay {
				opacity: 1;
			}
		}
	}

	&_mobile-win {
		.model__content {
			@include mediaTablet {
				max-width: 450px;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				height: auto;
				padding: rem(40);
			}

			@include mediaMobile {
				max-width: 90%;
				padding: rem(20);
			}
		}

		&.js_active {
			.model__content {
				@include mediaTablet {
					transform: translate(-50%, -50%);
				}
			}
		}
	}

	&_win {
		.model__title {
			margin-bottom: rem(48);

			@include mediaBigDesktop {
				margin-bottom: big(48);
			}

			@include mediaLaptop {
				margin-bottom: rem(32);
			}

			@include mediaMobile {
				margin-bottom: rem(20);
			}
		}

		.model__content {
			max-width: rem(800);
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			height: auto;
			padding: rem(100);
			opacity: 0;

			@include mediaBigDesktop {
				max-width: big(800);
				padding: big(100);
			}

			@include mediaDesktop {
				padding: rem(75);
			}

			@include mediaLaptop {
				max-width: rem(600);
				padding: rem(50);
			}

			@include mediaTablet {
				max-width: rem(450);
				padding: rem(40);
			}

			@include mediaMobile {
				max-width: 90%;
				padding: rem(20);
				padding-right: rem(50);
			}
		}

		&.js_active {
			.model__content {
				opacity: 1;
				transform: translate(-50%, -50%);
			}
		}
	}
}

.model-close-btn {
	display: flex;
	align-items: center;
	justify-content: center;
	padding: rem(10);

	width: rem(48);
	height: rem(48);

	background-color: var(--color-white);
	border: 0;
	border-radius: 0;

	cursor: pointer;
	transform-origin: center;
	transition: transform 0.5s;

	@include mediaBigDesktop {
		padding: big(10);
		width: big(48);
		height: big(48);
	}

	@include mediaTablet {
		padding: rem(3);
		width: rem(24);
		height: rem(24);
	}

	svg {
		margin: 0;
		width: 100%;
		height: 100%;
		fill: var(--stroke-dark);
	}

	&:hover {
		transform: rotate(180deg);
	}
}

.model-content-review {
	.review-card {
		flex-direction: column;
		padding: 0;

		&__header {
			padding-bottom: rem(48);

			@include mediaBigDesktop {
				padding-bottom: big(48);
			}

			@include mediaLaptop {
				padding-bottom: rem(32);
			}

			@include mediaMobile {
				padding-bottom: rem(20);
			}
		}

		&__text {
			padding-top: rem(48);
			overflow: visible;
			display: block;
			-webkit-line-clamp: initial;

			@include mediaBigDesktop {
				padding-top: big(48);
			}

			@include mediaLaptop {
				padding-top: rem(32);
			}

			@include mediaMobile {
				padding-top: rem(20);
			}
		}

		&__btn {
			display: none;
		}

		&__media {
			margin-left: 0;
			margin-top: rem(48);

			@include mediaBigDesktop {
				margin-top: big(48);
			}

			@include mediaLaptop {
				margin-top: rem(32);
			}

			@include mediaMobile {
				margin-top: rem(20);
			}

			.icon-button {
				.button-hover-effects {
					display: none;
				}
			}
		}
	}
}

.icon-success {
	display: flex;
	align-items: center;
	justify-content: center;
	width: var(--section-offset);
	min-width: var(--section-offset);
	height: var(--section-offset);
	border-radius: 50%;
	background-color: var(--system-states-success);

	@include mediaMobile {
		width: rem(40);
		min-width: rem(40);
		height: rem(40);
	}

	svg {
		fill: var(--color-white);
		width: rem(32);
		height: rem(32);

		@include mediaBigDesktop {
			width: big(32);
			height: big(32);
		}

		@include mediaDesktop {
			width: rem(24);
			height: rem(24);
		}

		@include mediaTablet {
			width: rem(20);
			height: rem(20);
		}
	}
}