.sec-map {
	.sec {
		&__header {
			align-items: flex-start;
		}

		&__title {
			br {
				@include mediaTablet {
					display: none;
				}
			}
		}

		&__actions {
			flex-direction: column;
			justify-content: flex-start;
			align-items: flex-start;

			@include mediaMobile {
				display: flex;
			}

			.scheme-label {
				&:not(:last-child) {
					margin-bottom: rem(20);

					@include mediaBigDesktop {
						margin-bottom: big(20);
					}

					@include mediaDesktop {
						margin-bottom: rem(16);
					}

					@include mediaLaptop {
						margin-bottom: rem(12);
					}

					@include mediaTablet {
						margin-bottom: rem(8);
					}
				}
			}
		}
	}

	&__main {
		display: flex;
		align-items: center;
		justify-content: center;
		overflow: hidden;
		margin: 0 auto;

		max-width: rem(1870);

		@include mediaBigDesktop {
			max-width: big(1870);
		}

		@include mediaDesktop {
			max-width: rem(1392);
		}

		@include mediaLaptop {
			max-width: rem(1050);
		}

		@include mediaTablet {
			max-width: rem(570);
		}

		@include mediaMobile {
			max-width: 100%;
		}
	}

	&__video {
		width: 100%;
		height: auto;

		transform: scale(1.005);
	}
}
