.sec-quote {
	height: 100vh;
	background-image: url("/assets/templates/img/common/bg_quote.jpg");
	background-repeat: no-repeat;
	background-size: cover;
	background-position: top center;
	min-height: rem(900);

	@include mediaBigDesktop {
		min-height: big(900);
	}

	@include mediaTablet {
		background-image: url("/assets/templates/img/common/bg_quote_mobile.jpg");
		height: auto;
		min-height: rem(500);
		padding-top: rem(600);
	}

	@include mediaMobile {
		padding-top: rem(400);
	}

	.container {
		display: flex;
		justify-content: flex-end;
		align-items: flex-end;
		height: 100%;
		gap: rem(96);

		@include mediaBigDesktop {
			gap: big(96);
		}

		@include mediaDesktop {
			gap: var(--section-offset);
		}

		@include mediaTablet {
			align-items: flex-start;
			flex-direction: column;
		}
	}

	&__box {
		width: 100%;
		max-width: 50%;
		padding-left: rem(50);

		@include mediaBigDesktop {
			padding-left: big(50);
		}

		@include mediaDesktop {
			padding-left: rem(34);
		}

		@include mediaLaptop {
			padding-left: rem(24);
		}

		@include mediaTablet {
			padding-left: 0;
			max-width: 100%;
			margin-left: 0;
		}
	}
}

@supports(height: 100dvh) {
	.sec-quote {
		height: 100dvh;

		@include mediaTablet {
			height: auto;
		}
	}
}

.block-quote {
	padding: var(--section-offset);
	background-color: var(--color-white);
	color: var(--color-text-dark);
	margin: 0;

	&__header {
		display: flex;
		justify-content: space-between;
		gap: rem(32);
		padding-bottom: rem(40);
		margin-bottom: rem(40);
		border-bottom: 1px solid var(--stroke-dark-secondary);

		@include mediaBigDesktop {
			gap: big(32);
			padding-bottom: big(40);
			margin-bottom: big(40);
		}

		@include mediaDesktop {
			padding-bottom: rem(32);
			margin-bottom: rem(32);
		}

		@include mediaLaptop {
			padding-bottom: rem(24);
			margin-bottom: rem(24);
		}

		@include mediaMobile {
			padding-bottom: rem(16);
			margin-bottom: rem(16);
		}

		&-label {
			font: var(--font-body-M-caption);
			color: var(--color-text-dark-secondary);
			text-transform: uppercase;
			margin-bottom: rem(8);

			@include mediaBigDesktop {
				margin-bottom: big(8);
			}

			@include mediaLaptop {
				margin-bottom: rem(4);
			}
		}

		&-name {
			font: var(--font-body-M-semibold);
		}

		&-icon {
			width: rem(24);
			height: rem(24);
			fill: var(--color-secondary);

			@include mediaBigDesktop {
				width: big(24);
				height: big(24);
			}
		}
	}

	&__body {
		font: var(--font-h3);
		letter-spacing: var(--letter-spacing-h3);

		p {
			&:last-child {
				margin-bottom: 0;
			}

			span,
			strong {
				color: var(--color-secondary);
			}
		}
	}
}
