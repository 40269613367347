.block-contact-full {
	display: flex;
	gap: rem(100);

	@include mediaBigDesktop {
		gap: big(100);
	}

	@include mediaDesktop {
		gap: rem(68);
	}

	@include mediaTablet {
		gap: rem(40);
		flex-direction: column;
	}

	@include mediaMobile {
		gap: rem(28);
	}

	&__content {
		display: flex;
		flex-direction: column;
		gap: rem(40);
		flex: 1;

		@include mediaBigDesktop {
			gap: big(40);
		}

		@include mediaDesktop {
			gap: rem(32);
		}

		@include mediaLaptop {
			gap: rem(24);
		}

		@include mediaMobile {
			gap: rem(20);
		}

		.contact-block {
			padding-right: rem(100);

			@include mediaBigDesktop {
				padding-right: big(100);
			}

			@include mediaDesktop {
				padding-right: rem(68);
			}

			@include mediaLaptop {
				padding-right: rem(48);
			}

			@include mediaTablet {
				padding-right: 0;
			}
		}
	}

	&__map {
		flex: 1;
		background-color: var(--color-gray-3);

		.ya-map {
			display: flex;
			align-items: center;
			justify-content: center;
			width: 100%;
			height: 100%;
			min-height: rem(400);

			@include mediaBigDesktop {
				min-height: big(400);
			}

			@include mediaTablet {
				height: rem(400);
			}

			@include mediaMobile {
				min-height: rem(300);
				height: rem(300);
			}
		}
	}
}