@keyframes anim_img_404 {
	0%,
	100% {
		transform: rotate(0);
	}
	50% {
		transform: rotate(2deg);
	}
}

[data-anim-page],
[data-anim-hidden] {
	opacity: 0;
}

[data-anim-title] {
	.line {
		overflow: hidden;
		line-height: 1.08;
	}
}