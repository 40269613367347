.sec-faq {
	&__accordion-list {
		.block-accordion {
			&:first-child {
				.block-accordion__header {
					padding-top: 0;
				}
			}
		}

		.block-accordion {
			&__content {
				max-width: rem(1078);

				@include mediaBigDesktop {
					max-width: big(1078);
				}

				@include mediaDesktop {
					max-width: rem(808);
				}

				@include mediaLaptop {
					max-width: rem(612);
				}

				@include mediaTablet {
					max-width: 100%;
				}
			}
		}
	}
}
