.sec-about-services {
	background-color: var(--color-dark);
	color: var(--color-white);

	.sec {
		&__title {
			max-width: 45%;

			@include mediaTablet {
				max-width: 100%;
			}
		}
	}

	&__row {
		display: flex;
		border-top: 1px solid var(--color-white);
		padding-top: var(--section-offset);

		@include mediaTablet {
			flex-direction: column;
		}

		.sec-about-services__text {
			&:first-child {
				margin-right: var(--section-offset);

				@include mediaTablet {
					margin-right: 0;
					margin-bottom: rem(40);
				}

				@include mediaMobile {
					margin-bottom: rem(32);
				}
			}
		}
	}

	&__text {
		flex: 1;

		p {
			&:last-child {
				margin-bottom: 0;
			}
		}

		&.text-editor {
			blockquote {
				margin-bottom: 0;
			}
		}

		blockquote {
			max-width: rem(688);

			@include mediaBigDesktop {
				max-width: big(688);
			}

			@include mediaDesktop {
				max-width: rem(662);
			}

			@include mediaLaptop {
				max-width: rem(502);
			}

			@include mediaTablet {
				max-width: 100%;
			}
		}
	}
}
