.services-card {
	position: relative;
	display: block;
	overflow: hidden;
	background: var(--color-gray-3);

	@include mediaTablet {
		display: flex;
		flex-direction: column;
	}

	&__content {
		position: relative;
		overflow: hidden;
		padding: rem(40);
		min-height: rem(200);

		@include mediaBigDesktop {
			padding: big(40);
			min-height: big(200);
		}

		@include mediaDesktop {
			padding: rem(32);
			min-height: rem(160);
		}

		@include mediaLaptop {
			padding: rem(24);
			min-height: rem(120);
		}

		@include mediaTablet {
			min-height: auto;
			order: 1;
		}

		@include mediaMobile {
			padding: rem(20);
		}
	}

	&__title {
		display: block;
		font: var(--font-h4);
		color: var(--color-dark);
		text-decoration: none;

		transition: color var(--anim-direction);

		&:after {
			content: "";
			display: block;
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			z-index: 2;
		}
	}

	&__link {
		display: block;
		font-size: 0;
	}

	&__fig {
		display: block;
		margin: 0;
		opacity: 1;
		overflow: hidden;

		transition: opacity var(--anim-direction);
	}

	&__pic {
		display: block;
		width: 100%;
		height: 100%;
		font-size: 0;
	}

	&__img {
		width: 100%;
		height: 100%;
		object-fit: cover;

		transition: transform var(--anim-direction);
	}

	&__hidden-content {
		position: absolute;
		bottom: -100%;
		left: 0;
		opacity: 0;
		width: 100%;
		z-index: 3;

		transition: bottom var(--anim-direction), opacity var(--anim-direction);

		@include mediaTablet {
			position: static;
			opacity: 1;
			order: 1;
		}
	}

	&:hover {
		.services-card {
			&__title {
				color: var(--color-secondary);
			}

			&__img {
				transform: scale(1.1);
			}
		}
	}

	&_full {
		&:hover {
			.services-card {
				&__title {
					color: var(--color-dark);
				}

				&__fig {
					opacity: 0;

					@include mediaTablet {
						opacity: 1;
					}
				}

				&__img {
					transform: none;

					@include mediaTablet {
						transform: scale(1.1);
					}
				}

				&__hidden-content {
					opacity: 1;
					bottom: 0;
				}
			}
		}
	}
}

.services-card-content {
	padding: rem(40);
	background: var(--color-gray-3);

	@include mediaBigDesktop {
		padding: big(40);
	}

	@include mediaDesktop {
		padding: rem(32);
	}

	@include mediaLaptop {
		padding: rem(24);
	}

	@include mediaTablet {
		padding: rem(24);
		padding-top: 0;
	}

	@include mediaMobile {
		padding: rem(20);
		padding-top: 0;
	}

	&__title {
		display: block;
		font: var(--font-body-S);
		color: var(--color-text-dark-secondary);
		margin: 0;
		margin-bottom: rem(20);

		@include mediaBigDesktop {
			margin-bottom: big(20);
		}

		@include mediaDesktop {
			margin-bottom: rem(16);
		}

		@include mediaLaptop {
			margin-bottom: rem(8);
		}
	}

	&__list {
		list-style-type: none;
		padding: 0;
		margin: 0;
		color: var(--color-dark);

		li {
			&:not(:first-child) {
				margin-top: rem(20);

				@include mediaBigDesktop {
					margin-top: big(20);
				}

				@include mediaDesktop {
					margin-top: rem(16);
				}

				@include mediaLaptop {
					margin-top: rem(8);
				}
			}

			a {
				text-decoration: none;
				color: var(--color-dark);

				transition: color var(--anim-direction);

				&:hover {
					color: var(--color-secondary);
				}
			}
		}
	}

	&__btn {
		margin-top: rem(32);

		@include mediaBigDesktop {
			margin-top: big(32);
		}

		@include mediaDesktop {
			margin-top: rem(24);
		}

		@include mediaLaptop {
			margin-top: rem(8);
		}

		@include mediaTablet {
			margin-top: rem(16);
		}
	}
}