.user-card {
	&__fig {
		display: block;
		margin: 0 0 rem(40) 0;
		padding: 0;

		@include mediaBigDesktop {
			margin: 0 0 big(40) 0;
		}

		@include mediaDesktop {
			margin: 0 0 rem(32) 0;
		}

		@include mediaLaptop {
			margin: 0 0 rem(24) 0;
		}

		@include mediaTablet {
			margin: 0 0 rem(16) 0;
		}
	}

	&__pic {
		display: block;
		width: 100%;
		height: 100%;

		font-size: 0;
	}

	&__img {
		width: 100%;
		height: auto;
		min-width: 100%;
	}

	&__subtitle {
		font: var(--font-body-M-caption);
		color: var(--color-text-dark-secondary);
		text-transform: uppercase;
		margin-bottom: rem(16);

		@include mediaBigDesktop {
			margin-bottom: big(16);
		}

		@include mediaDesktop {
			margin-bottom: rem(12);
		}

		@include mediaLaptop {
			margin-bottom: rem(8);
		}
	}

	&__title {
		font: var(--font-h5);
		color: var(--stroke-dark);
	}

	&__contacts {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		margin-top: rem(16);

		@include mediaBigDesktop {
			margin-top: big(16);
		}

		@include mediaDesktop {
			margin-top: rem(12);
		}

		@include mediaLaptop {
			margin-top: rem(8);
		}

		.user-card-contact {
			&:not(:first-child) {
				margin-top: rem(16);

				@include mediaBigDesktop {
					margin-top: big(16);
				}

				@include mediaDesktop {
					margin-top: rem(12);
				}

				@include mediaLaptop {
					margin-top: rem(8);
				}
			}
		}
	}
}

.user-card-contact {
	display: inline-flex;
	align-items: center;

	font: var(--font-body-M);
	color: var(--color-dark);
	text-decoration: none;
	
	transition: color var(--anim-direction);

	&__icon {
		width: rem(32);
		height: rem(32);
		margin-right: rem(12);
		fill: var(--color-gray-2);

		@include mediaBigDesktop {
			width: big(32);
			height: big(32);
			margin-right: big(12);
		}

		@include mediaDesktop {
			width: rem(24);
			height: rem(24);
			margin-right: rem(12);
		}

		@include mediaTablet {
			width: rem(20);
			height: rem(20);
			margin-right: rem(8);
		}
	}

	&:hover {
		color: var(--color-secondary);
	}
}