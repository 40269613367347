.pagination {
	margin: 0;

	padding: 0;
	display: flex;
	align-items: center;
	gap: rem(12);

	list-style-type: none;

	@include mediaBigDesktop {
		gap: big(12);
	}

	@include mediaTablet {
		gap: rem(8);
	}

	@include mediaMobile {
		flex-wrap: wrap;
		gap: rem(4);
	}

	&__item {
		.icon-button {
			color: var(--color-dark);

			span:not(.button-hover-effects) {
				position: relative;
				z-index: 2;
				
				font: var(--font-body-M);
				letter-spacing: var(--text-spacing);
				text-decoration: none;
				line-height: rem(24);

				transition: color var(--anim-direction);

				@include mediaBigDesktop {
					gap: big(24);
				}
			}

			&:hover {
				span {
					color: var(--color-white);
				}
			}
		}

		&_active {
			.icon-button {
				color: var(--color-white);

				background: var(--color-secondary);
				
				cursor: default;
			}
		}
	}
}
