.sec-bg-video {
	position: relative;
	z-index: 2;
	height: 100vh;
	color: var(--color-text-light);

	min-height: rem(500);

	@include mediaBigDesktop {
		min-height: big(500);
	}

	@include mediaMobile {
		min-height: rem(400);
	}

	&:before {
		content: "";
		display: block;
		width: 100%;
		height: 100%;
		position: absolute;
		left: 0;
		top: 0;
		z-index: 0;
		background: rgba(0, 0, 0, 0.50);
	}

	.sec {
		&__header {
			align-items: flex-start;
		}

		&__actions {
			display: flex;
			flex-direction: column;
			align-items: flex-start;

			p {
				margin: 0;
			}

			.button {
				margin-top: rem(40);

				@include mediaBigDesktop {
					margin-top: big(40);
				}

				@include mediaDesktop {
					margin-top: rem(36);
				}

				@include mediaLaptop {
					margin-top: rem(24);
				}

				@include mediaMobile {
					margin-top: rem(20);
				}
			}
		}
	}

	.container {
		position: relative;
		z-index: 3;
		height: 100%;
		display: flex;
		flex-direction: column;
	}

	&__btns {
		margin-top: auto;

		@include mediaTablet {
			overflow: auto hidden;
		}

		&-scroll {
			display: flex;
			flex-wrap: wrap;

			border-top: 1px solid var(--stroke-light-secondary);
			border-left: 1px solid var(--stroke-light-secondary);

			@include mediaTablet {
				flex-wrap: nowrap;
				border-top: 0;
			}
		}

		label {
			position: relative;
			display: block;
			width: 25%;

			border-bottom: 1px solid var(--stroke-light-secondary);
			border-right: 1px solid var(--stroke-light-secondary);

			@include mediaTablet {
				width: auto;
				min-width: rem(230);
				border-top: 1px solid var(--stroke-light-secondary);
			}

			.bg-video-btn {
				height: 100%;
			}
		}
	}

	&__dynamic-content {
		display: none;

		&.js_active {
			display: block;
		}
	}

	&__bg {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		object-fit: cover;
		z-index: 1;
	}

	&:before {
		content: "";
		display: block;
		width: 100%;
		height: 100%;
		position: absolute;
		left: 0;
		top: 0;
		z-index: 2;
		background-color: rgba(0, 0, 0, 0.5);
	}
}

@supports(height: 100dvh) {
	.sec-bg-video {
		height: 100dvh;
	}
}

.bg-video-btn {
	position: relative;
	display: flex;
	background: transparent;
	border: 0;
	border-radius: 0;
	padding: rem(40);
	color: var(--color-text-light);
	gap: rem(40);
	text-align: left;
	cursor: pointer;

	@include mediaBigDesktop {
		padding: big(40);
		gap: big(40);
	}

	@include mediaDesktop {
		padding: rem(32);
		gap: rem(32);
	}

	@include mediaLaptop {
		padding: rem(24);
		gap: rem(24);
	}

	@include mediaMobile {
		padding: rem(20);
		gap: rem(16);
	}

	&:before {
		content: "";
		display: block;
		width: 0;
		height: 100%;
		position: absolute;
		left: 0;
		top: 0;
		background-color: var(--color-secondary);

		transition: width var(--anim-direction);
	}

	&__count {
		position: relative;
		font: var(--font-body-M-caption);
		color: var(--color-text-light-secondary);
		text-transform: uppercase;
	}

	&__text {
		position: relative;
		font: var(--font-h4);
		letter-spacing: var(--letter-spacing-h4);
	}

	&:hover {
		&:before {
			width: 100%;
		}
	}

	&:focus-visible {
		outline: 0;
		box-shadow: 0 0 0 1px var(--color-secondary);
	}
}

.visually-hidden {
	&:checked {
		+ .bg-video-btn {
			&:before {
				width: 100%;
			}
		}
	}
}
