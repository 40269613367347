.sec {
	padding: var(--section-padding);

	&__header {
		display: flex;
		align-items: center;
		margin-bottom: var(--section-offset);

		@include mediaTablet {
			flex-direction: column;
			align-items: flex-start;
		}

		.sec {
			&__title {
				flex: 1;
				margin-bottom: 0;
				margin-right: var(--section-offset);

				@include mediaTablet {
					margin-right: 0;
				}
			}

			&__actions {
				flex: 1;
				display: flex;
				align-items: center;
				justify-content: flex-end;
				margin-left: calc(var(--section-offset) / 2);

				@include mediaTablet {
					margin-left: 0;
					margin-top: rem(24);
				}

				@include mediaMobile {
					display: none;
					margin-top: rem(20);
				}

				.icon-button {
					margin-left: rem(12);

					@include mediaBigDesktop {
						margin-left: big(12);
					}

					@include mediaLaptop {
						margin-left: rem(8);
					}

					@include mediaTablet {
						display: none;
					}
				}
			}
		}
	}

	&__title {
		margin-bottom: var(--section-offset);
		max-width: calc(50% - var(--section-offset));
		overflow: hidden;

		@include mediaTablet {
			max-width: 100%;
		}

		.line {
			overflow: hidden;
		}

		strong {
			font-weight: inherit;
			color: var(--color-secondary);
		}
	}

	&__row {
		display: flex;
		gap: rem(100);

		@include mediaBigDesktop {
			gap: big(100);
		}

		@include mediaDesktop {
			gap: rem(68);
		}

		@include mediaLaptop {
			gap: rem(48);
		}
	}

	&__content {
		flex: 1;
	}

	&__aside {
		flex-basis: rem(688);

		@include mediaBigDesktop {
			flex-basis: big(688);
		}

		@include mediaDesktop {
			flex-basis: rem(516);
		}

		@include mediaLaptop {
			flex-basis: rem(392);
		}

		@include mediaTablet {
			display: none;
		}

		.cta-form {
			position: sticky;
			top: 0;
			right: 0;
		}
	}
}