h1,.h1 {
  display: block;
  margin: 0;
  padding: 0;

  font: var(--font-h1);
  letter-spacing: var(--letter-spacing-h1);
}

h2,.h2 {
  display: block;
  margin: 0;
  padding: 0;
  
  font: var(--font-h2);
  letter-spacing: var(--letter-spacing-h2);
}

h3,.h3 {
  display: block;
  margin: 0;
  padding: 0;
  
  font: var(--font-h3);
  letter-spacing: var(--letter-spacing-h3);
}

h4,.h4 {
  display: block;
  margin: 0;
  padding: 0;
  
  font: var(--font-h4);
  letter-spacing: var(--letter-spacing-h4);
}

h5,.h5,
h6,.h6{
  display: block;
  margin: 0;
  padding: 0;

  font: var(--font-h5);
  letter-spacing: var(--letter-spacing-h5);
}

